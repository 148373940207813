const STORAGE_KEY = 'smbServiceLocation';
const DEFAULT_LOCATION_SERVICE = '{"address":"","address2":"","city":"","state":"","postalCode":""}';

export const isLocationServicesEqual = (a = {}, b = {}) => {
  let isEqual;

  try {
    isEqual = JSON.stringify(a) === JSON.stringify(b);
  } catch (err) {
    isEqual = a === b;
  }

  return isEqual;
};

export const getLocationService = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageLocationService = localStorage.getItem(STORAGE_KEY);

  if (!storageLocationService) {
    return;
  }

  let locationService;

  try {
    locationService = JSON.parse(storageLocationService);
  } catch (error) {
    locationService = JSON.parse(DEFAULT_LOCATION_SERVICE);
    console.error(error);
  }

  return locationService;
};

export const getValidLocationService = (serviceAddressRedux) => {
  const serviceAddressStorage = getLocationService();

  if (isLocationServicesEqual(serviceAddressRedux, serviceAddressStorage)) {
    return serviceAddressRedux;
  }

  if (serviceAddressStorage?.address && !serviceAddressRedux?.address) {
    return serviceAddressStorage;
  }

  return serviceAddressRedux;
};

export const saveLocationService = (data) => {
  if (typeof window === 'undefined') {
    return;
  }

  let locationService;

  try {
    locationService = JSON.stringify(data);
  } catch (error) {
    locationService = DEFAULT_LOCATION_SERVICE;
    console.error(error);
  }

  localStorage.setItem(STORAGE_KEY, locationService);
};

export const resetLocationService = () => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(STORAGE_KEY, DEFAULT_LOCATION_SERVICE);
};
