import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Button from '../Button';
import PPButton from '../PPButton/PPButton';
import { updateIsAutopayEnrolled, updateOpenModal } from 'store/appSlice';
import { useAppDispatch } from '../../store';
import styled from 'styled-components';
import { selectDeEnrollmentModal, selectIsEligibleEBBData } from '../../store/selectors/sharedSelectors';
import { useSelector } from 'react-redux';

const AutoPayModal = styled.div`
  display: flex;
  flex-direction: column;

  .auto-pay-title {
    font-family: PPObjectSans, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #333;
  }
  .auto-pay-content {
    font-family: PPObjectSans, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    margin: 16px 0 32px;
    color: #333;
  }

  .auto-pay-enroll-btn-group {
    display: flex;
    justify-content: space-between;
    align-self: center;
    button {
      margin: 0px 10px;
    }
  }

  @media (max-width: 768px) {
    .auto-pay-enroll-btn-group {
      height: 90px;
      flex-direction: column;
    }
  }
`;

const ChangeAutoPayEnrollmentModal = () => {
  const dispatch = useAppDispatch();
  const deEnrollmentModal = useSelector(selectDeEnrollmentModal);
  const isEligibleEBBData = useSelector(selectIsEligibleEBBData);
  const handleEnrollment = () => {
    dispatch(updateIsAutopayEnrolled(true));
    dispatch(updateOpenModal(null));
  };
  const handleDeEnrollment = () => {
    dispatch(updateIsAutopayEnrolled(false));
    dispatch(updateOpenModal(null));
  };

  return (
    <AutoPayModal>
      <span className="auto-pay-title">{deEnrollmentModal.cancelModalTitle.value}</span>
      <span className="auto-pay-content">{!isEligibleEBBData.isEligible ? deEnrollmentModal.cancelModalText.value : ''}</span>
      <div className={'auto-pay-enroll-btn-group'}>
        <Button
          model="primary"
          className="action-button"
          onClick={() => {
            handleEnrollment();
          }}
        >
          <Text tag="span" field={deEnrollmentModal.cancelModalCancelButtonText} />
        </Button>
        <PPButton
          variant="outlined"
          onClick={() => {
            handleDeEnrollment();
          }}
        >
          <Text tag="span" field={deEnrollmentModal.cancelModalContinueButtonText} />
        </PPButton>
      </div>
    </AutoPayModal>
  );
};

export default ChangeAutoPayEnrollmentModal;
