import { RootState } from 'store';
import {
  AutoPayRegistrationError,
  DeEnrollModalState,
  EmailAlreadyEnrolledError,
  ServiceLocation,
  TelephonePortingFormValues
} from 'store/state.model';

export const selectCacheId = (store) => store.appState.cacheId;
export const selectFiberDisclaimers = (store) => store.appState.siteCoreFiberDisclaimers;
export const selectAddressValidState = (store) => store.errorState.addressValid;
export const selectCreditCardValidState = (store) => store.errorState.creditCardValid;
export const selectBankAccountValidState = (store) => store.errorState.bankAccountValid;
export const selectBillMeLaterValidState = (store) => store.errorState.billMeLater;
export const selectNavData = (store) => store.appState.navData;
export const selectIsFetching = (store) => store.appState.isFetching;
export const selectVoiceTermsAccepted = (store) => store.appState.voiceTermsAccepted;
export const selectFiberProducts = (store) => store.appState.fiberProducts;
export const selectServiceability = (store) => store.appState.serviceability;
export const selectModernizationEligible = (store) => store.appState.modernizationEligible;
export const selectProspectId = (store) => store.appState.prospectData.id;
export const selectProspectData = (store) => store.appState.prospectData;
export const selectOpenModal = (store) => store.appState.openModal;
export const selectBtnClicked = (store) => store.appState.btnClicked;
export const selectMessagePageLoading = (store) => store.appState.messagePageLoading;
export const selectProductListTechnology = (store) => store.appState.productListTechnology;
export const selectServiceLocation = (store): ServiceLocation => store.appState.serviceLocation;
export const selectGmtOffset = (store) => store.appState.gmtOffset;
export const selectAlternateBillingAddress = (store): ServiceLocation => store.appState.alternateBillingAddress;
export const selectLoadingState = (store) => store.appState.loadingState;
export const selectLoadingMessage = (store) => store.appState.customLoadingMessage;
export const selectServiceAddressInteractionPage = (store) => store.appState.serviceAddressInteractionPage;
export const selectLeadFormData = (store) => store.appState.leadFormData;
export const selectOpenLeadFormModal = (store) => store.appState.openLeadFormModal;
export const selectGetAQuoteCTA = (store) => store.appState.getAQuoteCTA;
export const selectRoutingParamsMap = (store) => store.appState.routingParamsMap;
export const selectComparisonModal = (store) => store.appState.openComparisonModal;
export const selectTrafficSource = (store) => store.appState.trafficSource;
export const selectDeactivateUserModal = (store): boolean => store.appState.deactivateUserModal;
export const selectNewUserModal = (store): boolean => store.appState.newUserModal;
export const selectGetAssets = (store) => store.appState.getAssets;
export const selectPPUpdateUserStatus = (store) => store.appState.ppUpdateUserStatus;
export const selectPPUserData = (store: RootState) => store.appState.ppUserData;
export const selectPPCustomerInfo = (store: RootState) => store.appState.ppCustomerInfo;
export const selectReactivateUserModal = (store): boolean => store.appState.reactivateUserModal;
export const selectCancelQuoteModal = (store): boolean => store.appState.cancelQuoteModal;
export const selectPPTermsAndConditionsModal = (store): boolean => store.appState.ppTermsAndConditionsModal;
export const selectResetPasswordModal = (store): boolean => store.appState.resetPasswordModal;
export const selectSearchUsers = (store) => store.appState.searchUsers;
export const selectUnlockUserModal = (store): boolean => store.appState.unlockUserModal;
export const selectPpAditionalHeaders = (store) => store.appState.ppAditionalHeaders;
export const selectVoiceProductDetailsModalFields = (store) => store.appState.voiceProductDetailsModalFields;
export const selectPPUserListSearch = (store): { search: string; rows: any; rowsWOCurrentUser: any; page: number } =>
  store.appState.ppUserListSearch;
export const selectOpenEmergencyNumberServiceDisclosureModal = (store): boolean =>
  store.appState.openEmergencyNumberServiceDisclosureModal;

export const selectOpenPosIDModal = (store): boolean => store.appState.openPosIDModal;

export const selectLowIncomeModal = (store): string => store.appState.lowIncomeModal;
export const selectIsSecureOptionsSelected = (store): boolean => store.appState.isSecureOptionsSelected;
export const selectEbbOfferActive = (store): number | null => store.appState.ebbOfferActive;

export const selectRecordLocatorNumber = (store): string => store.appState.recordLocatorNumber;
export const selectOpenQuotePreviewModal = (store): boolean => store.appState.openQuotePreviewModal;
export const selectOpenQuoteRecapModal = (store): boolean => store.appState.openQuoteRecapModal;
export const selectVoiceSelectionOption = (store: RootState) => store.appState.voiceSelectionOption;
export const selectServiabilityMovingAddress = (store) => store.appState.serviabilityMovingAddress;
export const selectQuoteNumber = (store): string => store.appState.quoteNumber;
export const selectOpenCancellationReasonModal = (store): boolean => store.appState.openCancellationReasonModal;
export const selectCancellationReasonModalField = (store): any => store.appState.cancellationReasonModalField;
export const selectSnackbarIsOpen = (store): boolean => store.appState.snackbarIsOpen;
export const selectDeEnrollmentModal = (store): DeEnrollModalState => store.appState.deEnrollModalState;
export const selectEmailAlreadyEnrolledError = (store): EmailAlreadyEnrolledError =>
  store.appState.emailAlreadyEnrolledError;
export const selectAutopayRegistrationError = (store): AutoPayRegistrationError =>
  store.appState.autopayRegistrationError;
export const selectIsServiceabilityFormLoading = (store): boolean => store.appState.isServiceabilityLoading;
export const selectIsCustomerInformationValidated = (store): boolean => store.appState.isCustomerInformationValidated;
export const selectIsQuoteValidated = (store): boolean => store.appState.isQuoteValidated;
export const selectIsQuoteBeingValidated = (store): boolean => store.appState.isQuoteBeingValidated;
export const selectIsCreditCheckShown = (store): any => store.appState.isCreditCheckShown;
export const selectRenderPlaceholder = (store): any => store.appState.renderPlaceholder;
export const selectSummaryTotalValue = (store): any => store.appState.summaryTotalValue;
export const selectSummaryBackpayValue = (store): any => store.appState.summaryBackpayValue;
export const selectSummaryDepositValue = (store): any => store.appState.summaryDepositValue;
export const selectIsEligibleEBBData = (store: RootState) => store.appState.isEligibleEBBData;
export const selectEbbVerified = (store: RootState) => store.orderQuote.ebbVerified;
export const selectTelephonePortingFormValues = (store): TelephonePortingFormValues =>
  store.appState.telephonePortingFormValues;
