import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { SelectProductCofig, SelectProductResponse } from 'store/api/orderApiTypes';
import { orderQuoteHandlers } from './orderQuoteThunks';
import { initialState, OrderQuoteState } from './types';
type StepStatusType =
  | 'isStepOneComplete'
  | 'isStepTwoComplete'
  | 'isStepThreeComplete'
  | 'isStepFourComplete'
  | 'isStepFiveComplete'

const orderQuoteSilce = createSlice({
  name: 'orderQuote',
  initialState,
  reducers: {
    updateActiveBuyFlowProductOption: (
      state: OrderQuoteState,
      { payload }: PayloadAction<OrderQuoteState['activeBuyFlowProductOption']>
    ) => {
      state.activeBuyFlowProductOption = payload;
    },
    updateIsVoiceDirectoryOptionsFetched: (state: OrderQuoteState, { payload }: PayloadAction<boolean>) => {
      state.isVoiceDirectoryOptionsFetched = payload;
    },
    updateProductOffers: (state: OrderQuoteState, { payload }: PayloadAction<SelectProductResponse[]>) => {
      state.productOffers = payload;
    },
    updateHasRouterExtenderConfiguration: (state: OrderQuoteState, { payload }: PayloadAction<boolean>) => {
      state.hasRouterExtenderConfiguration = payload;
    },
    resetProducts: (state: OrderQuoteState) => {
      state.productOffers = [];
    },
    updateOrderState: (_, { payload }) => {
      return payload;
    }
  },
  extraReducers: (builder) => orderQuoteHandlers(builder)
});

export const selectOrder = (state: RootState) => state.orderQuote;
export const selectOrderQuoteId = (state: RootState) => state.orderQuote.quoteId;
export const selectChildProducts = (state: RootState) => state.orderQuote.childProducts as SelectProductCofig[];
export const selectProductOffers = (state: RootState) => state.orderQuote.productOffers as SelectProductResponse[];
export const selectIsVoiceDirectoryOptionsFetched = (state: RootState) =>
  state.orderQuote.isVoiceDirectoryOptionsFetched;
export const selectCompleteOrderProductSelection = (state: RootState) => state.orderQuote.completeOrderProductSelection;
export const selectconfigureOrderProduct = (state: RootState) => state.orderQuote.configureOrderProduct;
export const selectDeleteOrderProduct = (state: RootState) => state.orderQuote.deleteOrderProduct;
export const selectActiveProductOption = (state: RootState) => state.orderQuote.activeBuyFlowProductOption;
export const selectOrderQuoteProductId = (state: RootState) => {
  const product = state.orderQuote.productOffers[0] as SelectProductResponse;
  return product?.productId;
};
export const selectOrderQuoteInternetProductId = (state: RootState) => {
  const product = state.orderQuote.productOffers[0] as SelectProductResponse;
  return product?.productId;
};
export const selectHasRouterExtender = (state: RootState) => state.orderQuote.hasRouterExtenderConfiguration;
export const selectMobileTelephone = (state: RootState) => state.orderQuote.mobileTelephone;
export const selectFirstName = (state: RootState) => state.orderQuote.firstName;
export const selectLastName = (state: RootState) => state.orderQuote.lastName;
export const selectEmailAddress = (state: RootState) => state.orderQuote.emailAddress;
export const selectNotesToTechnician = (state: RootState) => state.orderQuote.notes;
export const selectSalesJourneyMilestone = (state: RootState) => state.orderQuote.salesJourneyMilestone;
export const selectStepStatus = (state: RootState, stepStatus: StepStatusType): boolean => state.orderQuote[stepStatus];
export const selectBackPay = (state: RootState) => state.orderQuote.backBalanceOwed;
export const selectDeposit = (state: RootState) => state.orderQuote.deposit;

export const {
  updateActiveBuyFlowProductOption,
  updateOrderState,
  updateIsVoiceDirectoryOptionsFetched,
  updateProductOffers,
  updateHasRouterExtenderConfiguration,
  resetProducts
} = orderQuoteSilce.actions;

export default orderQuoteSilce.reducer;
