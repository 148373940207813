import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Button from 'components/Button';
import StyledContactPhone from 'components/MessagingPage/ContactPhone';
import PhoneNumber from 'components/PhoneNumber';
import apiErrors from 'constants/apiErrors';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAdobeAnalytics, selectErrorContent, selectErrorModalCode, selectPPCustomerInfo } from 'store/selectors';
import { getValidAdobeAnalytics } from 'utils/adobeAnalyticsHandler';
import { adobeTrackData } from 'utils/adobeAnalyticsHelper';
import formatPhoneNumber from 'utils/normalizePhoneNumber';
import useSitecoreContext from 'utils/useSitecoreContext';

export const ApiErrorModalContent = () => {
  const sitecoreContext = useSitecoreContext();
  const isntPartnerPortal = sitecoreContext?.site?.name !== 'ftr-partner-portal';
  const errorContent = useSelector(selectErrorContent);
  const customerInfo = useSelector(selectPPCustomerInfo);
  const errorCode = useSelector(selectErrorModalCode);
  const contactPhone = errorContent.phoneNumber ? errorContent.phoneNumber.split('.').join() : '';
  const adobeAnalyticsStorage = getValidAdobeAnalytics(useSelector(selectAdobeAnalytics));
  const eVar2 = adobeAnalyticsStorage?.evars?.eVar2;
  const { apiErrorCodesWithAssistanceNeeded, ASSISTACE_NEEDED_ENABLED } = apiErrors;

  adobeTrackData({
    eVar2,
    eVar88: errorContent.header.toLowerCase().split(' ').join('-'),
    events: 'event88'
  });

  const getCustomerPhoneNumber = (): string => formatPhoneNumber(customerInfo.telephoneNumber, 'usePatternUSA') || 'your provided contact number';

  const showAssistanceNeededCTA = apiErrorCodesWithAssistanceNeeded.hasOwnProperty(errorCode)
    && ASSISTACE_NEEDED_ENABLED;

  return (
    <div className="fsm-body">
      <RichText className="fsm-header-copy fsm-header" tag="h1" field={{ value: errorContent.header }} />
      {
        isntPartnerPortal ?
          <h3 className="fsm-try-calling-us">
            {`${errorContent.tryCallingUs} `}
            <PhoneNumber className="fsm-phone-number light" phoneNumberLinkTarget={errorContent.phoneNumber}>
              {errorContent.phoneNumber}
            </PhoneNumber>
            {errorContent.endingText ? ` ${errorContent.endingText}` : ''}
          </h3> :
          <>
            <h3 className="fsm-try-calling-us">
              <StyledContactPhone className="contact-phone-container"
                msg={`${errorContent.tryCallingUs} ${errorCode === 'CF004' ? getCustomerPhoneNumber() : ''}`}
                hasIcon={errorContent.phoneNumber !== ''}
                contactPhone={{ value: errorContent.phoneNumber }}>
              </StyledContactPhone>
            </h3>
            {showAssistanceNeededCTA &&
              <>
                <br></br>
                <Button model="primary" className="assistance-buttons" >
                  {"Assistance Needed"}
                </Button>
              </>
            }
          </>
      }
    </div>
  );
};

export default ApiErrorModalContent;
