export default {
  addressNotServiceable: 'address-not-serviceable-modal',
  ExistingOpenQuoteWithPaidDepositModal: 'existing-open-quote-with-paid-deposit',
  termsAndConditions: 'terms-modal-id',
  checkingInfo: 'checking-info-modal',
  sitecoreErrorModal: 'sitecore-error-modal',
  apiErrorModal: 'api-error-modal',
  verificationCodeNotAccepted: 'verification-code-not-accepted',
  creditCheckCovid19Notice: 'credit-check-covid-19-notice',
  internetPickerProductModal: 'internet-picker-product-modal',
  modTwoInternetPickerProductModal: 'mod-two-internet-picker-product-modal',
  virifierQuestionsModal: 'verifier-questions-modal',
  callClientModal: 'call-client-modal',
  timeOutModal: 'timeout-modal',
  batteryBackupModal: 'battery-backup-modal',
  acceptedCardsModal: 'accepted-cards-modal',
  numberNotPortableModal: 'number-not-portable-modal',
  autoPayTooltip: 'auto-pay-tooltip',
  VoiceProductDetailsModal: 'voice-product-details-modal',
  ChangeAutoPayEnrollmentModal: 'change-auto-pay-enrollment',
  EmailAlreadyEnrolledErrorModal: 'email-already-enrolled-error-modal',
  AutoPayRegistrationErrorModal: 'auto-pay-registration-error-modal',
  posIdentification: 'pos-id-modal',
  lowIncomeModal: 'low-income-modal',
  ebbSubmitFormError: 'ebb-submit-form-error',
  creditFreezeModal : 'credit-freeze-modal'
};
