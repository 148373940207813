import axios, { AxiosResponse } from 'axios';
import { loadConfig } from 'config/config';
import {
  AddCustomerResponse,
  AddCustomerType,
  CompleteProductSelectionResponse,
  CompleteProductSelectionType,
  ConfigureProductType,
  CONSTANTS,
  CreditCheck,
  CreditCheckResponse,
  DeleteProductType,
  SelectProductResponse,
  SelectProductType
} from './orderApiTypes';
// import { frapiCustomerAcquisitionApiConfig } from './apiConfigs';
const { customer, orders, product, technology, creditCheckStr } = CONSTANTS;

const config = loadConfig();

const frapiCustomerAcquisitionApiConfig = {
  headers: {
    // /** comment out this object to use w mockoon */
    apikey: config.frapiCustomerAcquisitionApiKey,
    'requesting-application': 'PartnerPortal'
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  },
  baseURL: config.frapiCustomerAcquisitionApiUrl
};

export const selectProduct = async (data: SelectProductType): Promise<AxiosResponse<SelectProductResponse>> => {
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId
    }
  };

  const quoteItemId = data.quoteItemId ? `&quoteItemId=${data.quoteItemId}` : '';
  // const buildQueryParam = ``;
  const buildQueryParam = `?${quoteItemId}`;
  if (data.method === 'POST') {
    return axios.post(`${orders}/product${buildQueryParam}`, data.product, options);
  } else {
    return axios.put(`${orders}/product${buildQueryParam}`, data.product, options);
  }
};

export const deleteProduct = async (data: DeleteProductType): Promise<AxiosResponse<null>> => {
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId
    }
  };

  const buildQueryParam = `?quoteItemId=${data.quoteItemId}`;
  return axios.delete(`${orders}/product${buildQueryParam}`, options);
};

export const configureProduct = async <T>(
  data: ConfigureProductType<T>
): Promise<AxiosResponse<SelectProductResponse>> => {
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId
    }
  };
  return axios.patch(`${orders}/product/${data.quoteItemId}/configuration`, data.configurations, options);
};

export const addCustomer = async (data: AddCustomerType): Promise<AxiosResponse<AddCustomerResponse>> => {
  const skipCreditCheck = true;
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId
    }
  };

  return axios.post(`${orders}/${customer}?skipCreditCheck=${skipCreditCheck}`, data.customer, options);
};

export const updateCustomer = async (data: AddCustomerType): Promise<AxiosResponse<AddCustomerResponse>> => {
  const skipCreditCheck = true;
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId
    }
  };

  return axios.put(`${orders}/${customer}`, data.customer, options);
};

export const completeProductSelection = async (
  data: CompleteProductSelectionType
): Promise<AxiosResponse<CompleteProductSelectionResponse>> => {
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId
    }
  };

  return axios.patch(`${orders}/${product}?complete=true`, null, options);
};

export const creditCheck = async (
  data: CreditCheck
): Promise<AxiosResponse<CreditCheckResponse | AddCustomerResponse>> => {
  const options = {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': data.quoteId,
      'requesting-application': 'PartnerPortal'
    }
  };

  return axios.post(`${orders}/${creditCheckStr}`, data, options);
};

export const getQuoteResume = async (quoteId): Promise<AxiosResponse> => {
  return axios.get('orders/quote-resume', {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': quoteId,
      'requesting-application': 'PartnerPortal'
    }
  });
};

export const getAutopayData = async (quoteId): Promise<AxiosResponse> => {
  return axios.get('billing/autopay', {
    ...frapiCustomerAcquisitionApiConfig,
    headers: {
      ...frapiCustomerAcquisitionApiConfig.headers,
      'quote-id': quoteId,
      'requesting-application': 'PARTNER'
    }
  });
};

