import * as React from 'react';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';

const useSitecoreContext = () => {
  const sitecoreContextFactory = React.useContext(SitecoreContextReactContext);

  return sitecoreContextFactory.getSitecoreContext();
};

export default useSitecoreContext;
