import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const StyledHeroImageWrapper = styled.div`
  position: relative;
  color: black;

  @media screen and (min-width: ${breakpoints.sm}) {
    min-height: 480px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    flex: 1;
    min-height: 400px;
    color: white;
    display: flex;
    align-items: center;
  }

  @media screen and (min--moz-device-pixel-ratio: 0) and (max-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    min-height: 480px;
  }

  @media screen and (min-width: ${breakpoints['2xl']}) {
    min-height: 768px;
  }
`;

export default StyledHeroImageWrapper;
