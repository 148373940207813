import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectServiceAddressInteractionPage } from 'store/selectors';
import { pushWithParams } from 'utils/browserUtil';
import Button from '../../components/Button';
import apiErrors from '../../constants/apiErrors';
import { updateOpenModal } from '../../store/appSlice';

const TimeOutModal = () => {
  const dispatch = useDispatch();
  const serviceAddressInteractionPage = useSelector(selectServiceAddressInteractionPage);
  const history = useHistory();

  const landingPageRedirect = () => {
    dispatch(updateOpenModal(null));

    let redirect = '/';
    if (serviceAddressInteractionPage && serviceAddressInteractionPage !== history.location.pathname) {
      redirect = serviceAddressInteractionPage;
    }
    pushWithParams(history, redirect, null, '#serviceability-form');
  };

  return (
    <div className="ts-fsm-body">
      <Text tag="h3" className="fsm-header" field={{ value: apiErrors.apiErrorContent.SESSION_TIME_OUT.header }} />
      <Text tag="p" field={{ value: apiErrors.apiErrorContent.SESSION_TIME_OUT.copy }} />
      <Button model="primary" buttonId="terms-and-conditions" onClick={() => landingPageRedirect()}>
        {apiErrors.apiErrorContent.SESSION_TIME_OUT.buttonText}
      </Button>
    </div>
  );
};

export default TimeOutModal;
