import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from 'store/state.model';
import { AppApi } from '../../store/api/appApi';

export const submitEbbRequest = createAsyncThunk('app/submitEbbFlow', async (form: SubmitEbbRequestType) => {
  const result = await AppApi.submitEbbEnrollmentForm(form);
  return result.data;
});

export const ebbFlowHandlers = (builder: ActionReducerMapBuilder<AppState>) => {
  return builder
    .addCase(submitEbbRequest.fulfilled, (_, { payload }: any) => {
      console.log(payload);
    })
    .addCase(submitEbbRequest.rejected, (_, { error }: any) => {
      console.error('submitEbbRequest FAILED', error);
    });
};

export type SubmitEbbRequestType = {
  controlNumber: string;
  environment: string;
  applicationId: string;
  benefitQualifiedPerson: {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    las4Social: string;
    tribalId: string;
  };
  subscriber: {
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    las4Social: string;
    tribalId: string;
    email: string;
    phoneNumber: string;
    serviceLocation: {
      address: string;
      address2: string;
      city: string;
      state: string;
      postalCode: string;
      isBusiness: boolean;
    };
  };
  contactEmail: string;
  contactMobile: string;
  communicationPreference: string;
};
