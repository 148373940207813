import { runSafeSsr } from './browserUtil';

export const setCookie = () => {
  const searchParams = runSafeSsr(
    () => {
      return {};
    },
    () => {
      return window.location.search;
    }
  );

  const paramsList = window?.location?.search.replace('?', '').split('&');
  if (!!paramsList) {
    const cookieString = '';
    paramsList.forEach((item) => {
      document.cookie = `${item}; max-age=300 `;
    });
  }
};
