export type SelectProductType = {
  method: 'POST' | 'PUT';
  technology: 'COPPER' | 'FIBER' | 'IONT';
  /** Quote ID from FRAPI */
  quoteId: string;
  product: {
    productId: string;
    productType: 'Voice' | 'Video' | 'Broadband' | 'Secure' | 'AddOn';
  };
  quoteItemId?: string;
};
export type SelectProductResponse = {
  configurations: SelectProductCofig[];
  dataspeed: {
    uploadInKbps: string;
    downloadInKbps: string;
  };
  description: string;
  name: string;
  lineItems: any[];
  price: {
    value: string;
    currency: string;
    unit: string;
    discountValue?: string;
    discountDuration?: string;
  };
  productId: string;
  quoteItemId: string;
  type: 'Broadband' | 'Voice' | 'Video' | 'Secure' | 'INTERNET' | 'Add On';
};
export type AddCustomerType = {
  quoteId: string;
  customer: CustomerType;
};
export type AddCustomerResponse = {
  summary: {
    total: {
      value: string;
      currency: string;
      unit: string;
    };
    lineItems: LineItem[];
    additionalFees: AdditionalFee[];
  };
  telephoneNumber: string;
  existingBillingTelephoneNumber: string;
};
type CustomerType = {
  accountUuid: string;
  firstName?: string;
  lastName?: string;
  billingAddress?: {
    address: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
  mobileNumber?: string;
  email?: string;
  dob?: string;
  ssn?: string;
  communicationPreferences?: CommunicationPreferences[];
};

type CommunicationPreferences = {
  communicationType?: string,
  mediaType?: string
}

type AdditionalFee = {
  type: string;
  cost: {
    value: string;
    currency: string;
    unit: string;
  };
};
type LineItem = {
  product: {
    sku: string;
    productId: string;
    name: string;
    description: string;
    dataspeed: {
      uploadInKbps: string;
      downloadInKbps: string;
    };
    type: string;
    price: {
      value: string;
      currency: string;
      unit: string;
    };
    quoteItemId: string;
    configurations: LineItemConfiguration[];
  };
  cost: {
    value: string;
    currency: string;
    unit: string;
  };
};
type LineItemConfiguration = {
  type: string;
  options: LineItemConfigurationOption[];
};
type LineItemConfigurationOption = {
  id: string;
  name: string;
  configurationType: string;
  price: number;
};
export type SelectProductCofig = {
  type: 'Sweetener' | 'RequiredChoice' | 'NonConfigurable';
  options: SelectProductCofigOption[];
};
export type SelectProductCofigOption = {
  id: string;
  name: string;
  configurationType: 'Entity' | 'Value' | 'Characteristic';
  price: string;
  active?: boolean;
};

export type DirectoryListingOptions = {
  id: string;
  fields: {
    entityId: { value: string };
    name: { value: string };
    configurationType: { value: 'Entity' | 'Value' | 'Characteristic' };
    isListed: { value: boolean };
    characteristicId: { value: string };
    active?: boolean;
  };
};

export type ConfigureProductType<T> = {
  /** Quote ID from FRAPI */
  quoteId: string;
  quoteItemId: string;
  configurations: T[];
};
export type DeleteProductType = {
  quoteId: string;
  quoteItemId: string;
  productId: string;
};

export type ProductionConfiguration = {
  id: string;
  name: string;
  configurationType: 'Entity' | 'Value' | 'Characteristic';
  value?: string;
  price?: string;
  active?: boolean;
};

export type CompleteProductSelectionType = {
  quoteId: string;
};

export type CompleteProductSelectionResponse = {
  message: string;
  httpStatusCode: number;
  errorCode: string;
};

export type CreditCheck = {
  // creditCheck: CreditCheckRequest;
  requestingApplication: string;
  quoteId: any;
  accountUuid?: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  dob?: string;
  alternateMailingAddress?: {
    address: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
  previousAddress?: {
    address: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
  billingAddress: {
    address: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
  isCovidEnvironment: boolean;
  autoPay?: boolean;
  eBill?: boolean;
  directoryListing?: string;
  ssn: string;
  refuseSSN: boolean;
  communicationPreferences: [];
};
export type CreditCheckResponse = {
  securityQuestions: SecurityQuestion[];
  addressDiscrepancy: boolean;
};
type SecurityQuestion = {
  question: string;
  questionNumber: number;
  answerChoices: AnswerChoice[];
};
type AnswerChoice = {
  answerText: string;
  answerNumber: number;
};

export const CONSTANTS = {
  configureProductStr: 'configure-product',
  creditCheckStr: 'credit-check',
  customer: 'customer',
  order: 'order',
  orders: 'orders',
  product: 'product',
  selectProductStr: 'select-product',
  technology: 'technology'
};
type CreditCheckRequest = {
  accountUuid?: string;
  firstName: string;
  lastName: string;
  mobileNumber: number;
  email: string;
  dob?: string;
  productIds: string[];
  alternateMailingAddress?: {
    address: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
  billingAddress: {
    address: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
  };
  isCovidEnvironment: boolean;
  autoPay?: boolean;
  eBill?: boolean;
  directoryListing?: string;
  ssn: string;
};
