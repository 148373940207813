export const campaignId = (id): string => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Temporary solution - just use fiber_expansion instead of relying on utm tags
  let tempId: string = id;

  //   Query param is UTM_Campaign, force it to be case insenstive to ensure we pick it up
  urlParams.forEach((value, key, _) => {
    if (key.toLowerCase() === 'utm_campaign') {
      tempId = value;
    }
  });

  return tempId;
};
