const breakpoints = {
  xs: '320px',
  sm: '640px',
  smd: '712px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  '2xl': '1920px',
  '3xl': '2560px'
};

export default breakpoints;
