export default `
  <h1>General Residential Service Terms and Conditions</h1>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam faucibus urna nec risus pulvinar, ut pretium justo pellentesque. Vestibulum nec tristique lorem. Morbi quis eleifend ante. Pellentesque pellentesque diam porttitor tristique faucibus. Morbi tortor lacus, pellentesque nec venenatis vel, porttitor tristique tortor. Maecenas finibus finibus accumsan. Donec odio ex, porta et tellus a, aliquam dignissim libero. Curabitur tellus nibh, laoreet id fringilla vel, varius et nulla. Phasellus lobortis luctus dui, eu euismod diam. Mauris quis urna sagittis, malesuada tortor eu, consectetur enim. Ut vehicula id augue ut elementum.</p>
  <p>Vivamus dictum nec elit vitae accumsan. Duis id porta massa. In risus erat, vulputate a turpis a, facilisis gravida ligula. Proin porta nulla sed interdum feugiat. Maecenas quis rhoncus ante. Phasellus mattis orci at elit dapibus, a ullamcorper lacus eleifend. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse commodo lectus vel diam luctus condimentum. Ut nunc tellus, hendrerit egestas commodo vel, laoreet pharetra lorem. Sed eget mi in sem mollis pulvinar. Vestibulum non augue et ligula pharetra ultrices. Duis scelerisque ante lacus, in consectetur elit feugiat a.</p>
  <p>Pellentesque quam urna, facilisis molestie efficitur in, egestas id augue. Maecenas neque quam, viverra pellentesque enim nec, vehicula molestie est. Ut vitae ante erat. Donec dictum leo ex, id porta tellus ornare posuere. Nam eu mi sapien. Maecenas a justo mauris. Duis scelerisque vitae eros non facilisis. Pellentesque vulputate id magna non viverra. Vestibulum eu est ullamcorper, aliquet risus in, posuere neque. Quisque iaculis dolor pretium ligula commodo condimentum.</p>
  <p>Nulla facilisi. Etiam interdum vestibulum feugiat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras metus nisi, ultricies in ante sed, euismod gravida orci. Maecenas ac venenatis magna. Pellentesque blandit pharetra sodales. Nullam consequat feugiat pharetra. Quisque elementum est quis enim interdum dictum. Maecenas porttitor quam ligula, vel malesuada neque placerat a. Integer ut dolor neque. Ut eu vulputate velit. Aliquam faucibus quam neque. Cras ut metus ut ante imperdiet eleifend molestie quis ipsum. Nunc a auctor sapien, id viverra nunc. Integer eu nunc vel eros hendrerit egestas id sit amet tortor</p>
  <p>Aenean cursus ipsum ut sapien imperdiet finibus. Suspendisse vitae suscipit nulla. Quisque condimentum, erat et facilisis luctus, justo ligula cursus nisi, at ullamcorper dui ex et nisi. Sed non mollis magna, at consequat justo. Proin lobortis, nulla nec lacinia tincidunt, nisl lorem condimentum massa, placerat convallis urna mi eget nulla. Vestibulum congue massa quis rutrum dictum. Curabitur cursus eleifend justo eu volutpat. Suspendisse potenti. Integer aliquam nisi arcu, a facilisis lorem hendrerit at. Sed vulputate felis id mauris sollicitudin, id porta lectus finibus. Proin porttitor ante at lectus venenatis gravida. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas semper facilisis tortor, id ultrices magna malesuada ut</p>
  <p>Cras imperdiet vehicula tincidunt. Proin venenatis in nunc sit amet tincidunt. Donec consectetur augue ac nisl ornare auctor. Vestibulum eleifend mauris nec dui imperdiet iaculis. Morbi tempor accumsan metus, vitae viverra sem. Maecenas tempor, massa eget semper hendrerit, neque magna facilisis massa, eget lacinia diam sapien vitae tortor. Vivamus tempus, sapien vitae blandit rhoncus, enim est venenatis metus, non egestas nulla purus in nunc. Nam dapibus laoreet sollicitudin. Nullam in pulvinar tellus. Aenean id pretium nisi, sed egestas mauris. Morbi vel bibendum odio. Etiam tristique mauris fringilla ante accumsan placerat. Quisque pellentesque semper risus</p>
  <p>Duis aliquet, ipsum convallis elementum tristique, ipsum diam egestas lorem, ac mollis ipsum neque id sem. Cras congue diam felis, ut porta magna pulvinar quis. Mauris non vehicula mi, sit amet pellentesque mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque lacinia aliquet ligula, ac gravida odio gravida sit amet. Sed semper, dolor a mattis dignissim, nisi justo ultricies est, nec porttitor nulla urna consequat mauris. Nunc pharetra commodo est, sit amet malesuada libero cursus id. Quisque euismod ex ac metus iaculis, in hendrerit est faucibus. Morbi sit amet nulla vitae nisi accumsan malesuada at sed mauris. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas et augue turpis. Donec pretium dignissim eleifend. Donec massa arcu, interdum vitae viverra nec, facilisis sed ipsum. Etiam fermentum fringilla malesuada. Maecenas egestas nisi pellentesque turpis tempus fringilla</p>
`;
