import { createGlobalStyle } from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import StyledHeroImageWrapper from '../HeroImageWrapper/StyledHeroImageWrapper';

const GlobalStyle = createGlobalStyle`
  html {
   height: 100%;
  }

  body,
  #root {
    width: 100%;
    min-height: 100%;

    @media screen and (min-width: ${breakpoints.lg}) {
      display: flex;
      align-items: stretch;
    }
  }

  #serviceability-form {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .section-header {
      color: ${({ theme }) => theme.darkGray};
      margin-bottom: 0;

      h2 {
        color: ${({ theme }) => theme.darkGray};
        margin: 0 0 8px;
        font-size: 28px;
        line-height: 1.43;
      }

      p {
        color: ${({ theme }) => theme.dark2};
        font-size: 12px;
        font-weight: bold;
        margin: 0;
      }
    }

    form {
      .btnElement {
        @media screen and (min-width: ${breakpoints.lg}) {
          margin-top: 6px;
          margin-left: 16px;
        }
      }
    }
  }

  #serviceability-form > div {
    margin: 24px 0 0;
  }

  #Footer {
    margin-top: auto;
  }

  #product-list .product-radio-container {
    align-items: start;
  }

  #product-list .product-name {
    line-height: 2rem;
    font-size: 24px;
    margin-right: 23px;
    flex: 0.75;
  }

  #product-list .preferred .preferred-text {
    font-size: 14px;
    flex: 0.25;
    margin-right: 31px;
  }


  #root > div {
    @media screen and (min-width: ${breakpoints.lg}) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  ${StyledHeroImageWrapper} {
    @media screen and (min-width: ${breakpoints.lg}) {
      flex-grow: 1;
    }
  }

  .MuiPopover-select {
    .MuiSelect-selectMenu {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .MuiPopover-paper {
      border-radius: 0;
      border-bottom: 1px solid ${({ theme }) => theme.crimson};
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
      width: 335px;
    }

    .MuiMenu-list {
      padding: 0;
    }

    .MuiListItem-root.MuiMenuItem-root {
      color: #424242;
      font-family: PPObjectSans, sans-serif;
      font-size: 14px;
      line-height: 1.4;
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 48px;
      white-space: normal;
    }

    .MuiListItem-root.MuiMenuItem-root.Mui-selected {
      background-color: ${({ theme }) => theme.sand};
    }
  }
`;

export default GlobalStyle;
