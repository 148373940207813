import styled from 'styled-components';
import breakpoints from 'styles/breakpoints';
import { theme } from 'styles/theme';

const Root = styled.section`
  font-family: PPObjectSans, sans-serif;
  color: ${theme.dark2};
  text-align: center;

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const Title = styled.h1`
  font-size: 18px;
  line-height: 1.77;
  font-weight: 900;
  color: ${theme.darkGray};
  margin-bottom: 30px;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 1.33;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 30px;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
  }
`;

const ButtonWrapper = styled.div``;

export default { Root, Title, Text, ButtonWrapper };
