export const CREDIT_CARD_PRIORITY_LIST = [
  { creditCardBrand: 'Visa card', priority: 10 },
  { creditCardBrand: 'Mastercard', priority: 20 },
  { creditCardBrand: 'American card', priority: 30 },
  { creditCardBrand: 'Discover card', priority: 40 },
  { creditCardBrand: 'NYCE card', priority: 50 },
  { creditCardBrand: 'Plus card', priority: 60 },
  { creditCardBrand: 'Star card', priority: 70 }
];

export const CARD_DEFAULT_PRIORITY = 100;
