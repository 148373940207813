import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceabilityFormId } from 'components/ServiceabilityForm/serviceability-form-constants';
import streetSuffix from 'constants/streetSuffix';
import { ServiceabilityRequestType } from 'models/AddressFormTypes';
import { ProspectSubmission } from 'models/api.models';
import { AppApi } from 'store/api/appApi';
import {
  updateAdobeEvars,
  updateAdobeEvents,
  updateAdobeUserType,
  updateErrorModal,
  updateLastPostalCode,
  updateLeadAuditId,
  updateMessagePageLoading,
  updateModernizationEligible,
  updateNavData,
  updateOpenModal,
  updateOpenQuotes,
  updatePendingInOrderNumber,
  updateProductListTechnology,
  updateServiceabilityFormLoading,
  updateServiceabilityMovingAddess,
  updateServiceLocation,
  updateShowGetAQuoteAfterCheckServiceability
} from 'store/appSlice';
import {
  AppState,
  FiberProduct,
  LeadForm,
  MessagePageTypeEnum,
  ServiceabilityResponseType,
  ServiceGoalTypes,
  ServiceLocation,
  ServiceTypeEnum
} from 'store/state.model';
import {
  getAdobeAnalyticsEvents,
  saveAdobeAnalyticsEvars,
  saveAdobeAnalyticsEvents,
  saveAdobeAnalyticsServiceArea,
  saveAdobeAnalyticsUserType
} from 'utils/adobeAnalyticsHandler';
import { buildBuyFlowUrl } from 'utils/buyFlowUrlSearchParams';
import { getQueryStringParams } from 'utils/getQueryStringParams';
import { getSearchParams } from 'utils/getSearchParams';
import { sendGtagConversion } from 'utils/googleTagManagerHelper';
import { saveLocationService } from 'utils/serviceLocationHandler';
import { setCookie } from 'utils/setCookie';
import { getLocalStorageValue, setLocalStorageValue } from 'utils/storageUtil';
import { trackJssGoalItem } from 'utils/trackingUtil';
import normalizeQuotes from './../../utils/normalizeQuotes';
import modalIds from '../../constants/modalIds';
import { dataApi } from '@sitecore-jss/sitecore-jss-react';

const handleNavigateToBuyFlow = (request, offer, history): void => {
  const searchParams = buildBuyFlowUrl(request, request.samRecords, request.postalCode, offer);
  const copperOrder = offer === 'COPPER' ? '-copper' : '';
  const iontOrder = offer === 'IONT' ? '-iont' : '';
  navWithParams(history, `/buy-autopay/order${copperOrder}${iontOrder}?${searchParams}`);
};

export const getServiceability = createAsyncThunk(
  'app/getServiceability',
  async (
    payload: { history; request: ServiceabilityRequestType; partnerPortalApiUrl: string | undefined },
    { dispatch, getState }
  ) => {
    const { history, request, partnerPortalApiUrl } = payload;
    const { isBusiness, postalCode, samRecords, isAcp } = request;

    const newState: any = getState();
    const state: AppState = newState.appState;
    const utmCampaign = getQueryStringParams();
    const routingParamsMap = state.routingParamsMap;
    const utmCampaignParam = getSearchParams('utm_campaign');
    let abAppender = '';
    if (!!routingParamsMap && !!routingParamsMap.utm_campaign && routingParamsMap?.utm_campaign.length > 0) {
      routingParamsMap.utm_campaign.forEach((item) => {
        if (item === utmCampaignParam) abAppender = '-nb';
      });
    }

    setCookie();

    if (!samRecords) {
      navWithParams(
        history,
        !!isAcp ? '/acp-offer/outofterritory' : `/customer-message/?messagePageType=${ServiceTypeEnum.UNSERVICEABLE}`,
        null,
        null,
        ServiceabilityFormId
      );
    }

    const hasUtmCampaign = !!utmCampaign ? `${utmCampaign}#product-list` : '#product-list';
    const result = await AppApi.getServiceability(samRecords, null, null, !!isAcp);    
    if (result?.data) {
      result.data = {
        ...result?.data,
        ...{ isWhyFiberForm: false, isBusiness, isSMB: false },
        quotes: await normalizeQuotes({
          quotes: result?.data?.quotes,
          partnerPortalApiUrl
        })
      };
      dispatch(updateOpenQuotes(result?.data));
      setLocalStorageValue('openQuotes', JSON.stringify({...(result?.data), controlNumber: samRecords.controlNumber, environment: samRecords.environment }));
    }
    dispatch(updateServiceLocation(request));

    const { fiberModernization, leadAuditId, iONT } = result.data as ServiceabilityResponseType;

    if (!!isAcp) {
      dispatch(
        updateNavData({ ...request, controlNumber: samRecords.controlNumber, environment: samRecords.environment })
      );
    }
    dispatch(updateModernizationEligible(fiberModernization));
    if (leadAuditId) dispatch(updateLeadAuditId(leadAuditId));

    const buildMessagePath = (pageType: string) => `/customer-message/?messagePageType=${pageType}`;
    const buildProductPath = (
      extension: string,
      customerType?: string | null,
      hashPath: string | null = '#product-list'
    ) =>
      `/fiber-expansion/${isBusiness ? 'smb' : 'resi'}${
        customerType ? `-${customerType}` : ''
      }/${extension}${hashPath}`;

    /*
      const buildModExpansionProductPath = (
      extension: string,
      customerType?: string | null,
      hashPath: string | null = '#product-list'
    ) => {
      //${history.location.pathname}
      if (history?.location?.pathname?.charAt(history?.location?.pathname?.length - 1) === '/') {
        // const hasCustomerType = customerType ? `${customerType}` : '';
        const hasCustomerType = `/fiber-expansion/${isBusiness ? 'smb' : 'resi'}${customerType ? `-${customerType}` : ''
          }`;
        return `${hasCustomerType}/${extension}${hashPath}`;
      } else {
        // const hasCustomerType = customerType ? `/${customerType}` : '';
        const hasCustomerType = `/fiber-expansion/${isBusiness ? 'smb' : 'resi'}${customerType ? `-${customerType}` : ''
          }`;
        return `${hasCustomerType}/${extension}${hashPath}`;
      }
      // check history.location.pathname has a / if so
      // return `${history.location.pathname}${hasCustomerType}/${extension}${abAppender}${hashPath}`;
    };
    */

    const {
      serviceType, // Customer Service Type Enum (See Type Interface)
      futureServiceDate // DateString (future service available) or null (no future service available)
    } = result.data as ServiceabilityResponseType;
    // Adobe Analytics

    // trigger adobe analytics for service check payload eVar69 & eVar65
    if (
      serviceType === ServiceTypeEnum.PENDING_ORDER ||
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_VOICE ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER ||
      serviceType === ServiceTypeEnum.SERVICEABLE ||
      serviceType === ServiceTypeEnum.SERVICEABLE_FIBER
    ) {
      switch (serviceType) {
        case ServiceTypeEnum.PENDING_ORDER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.PENDING_ORDER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_PLANNED_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_PLANNED_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_COPPER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_COPPER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_VOICE:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_VOICE }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.SERVICEABLE:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.SERVICEABLE }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.SERVICEABLE_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.SERVICEABLE_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
      }
    }
    // Adobe Analytics evar70 for presales
    if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER && !!futureServiceDate) {
      dispatch(updateAdobeEvars({ eVar70: 'PreSale:Yes' }));
    } else if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER && !futureServiceDate) {
      dispatch(updateAdobeEvars({ eVar70: 'PreSale:No' }));
    }

    if (
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_VOICE
    ) {
      if (isBusiness) {
        dispatch(updateAdobeUserType('business:customer'));
      } else {
        dispatch(updateAdobeUserType('resi:customer'));
      }
    } else {
      if (isBusiness) {
        dispatch(updateAdobeUserType('business:prospect'));
      } else {
        dispatch(updateAdobeUserType('resi:prospect'));
      }
    }

    // Only set event29 if they are unserviceable
    if (serviceType === ServiceTypeEnum.UNSERVICEABLE) {
      dispatch(updateAdobeEvents('event29'));
    } else {
      dispatch(updateAdobeEvents('event156'));
    }

    dispatch(updateAdobeEvars({ eVar50: `fiber:${postalCode}` }));
    dispatch(updateAdobeEvents('event30,event100'));
    // dispatch(updateAdobeEvents('event100'));
    if (!!isAcp) {
      switch (serviceType) {
        case ServiceTypeEnum.UNSERVICEABLE:
          navWithParams(history, '/acp-offer/outofterritory');
          break;
        case ServiceTypeEnum.MULTIPLE:
          navWithParams(history, '/acp-offer/moreinfo');
          break;
        default:
          navWithParams(history, isAcp);
      }
    } else if (
      serviceType === ServiceTypeEnum.UNSERVICEABLE ||
      serviceType === ServiceTypeEnum.PENDING_ORDER ||
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER
    ) {
      // add eVar49  when at location where  no serviceable products available
      dispatch(updateAdobeEvars({ eVar49: 'not in service area' }));
      if (serviceType === ServiceTypeEnum.EXISTING_FIBER || serviceType === ServiceTypeEnum.EXISTING_COPPER) {
        const searchParams = buildBuyFlowUrl(request, samRecords, postalCode, result.data.offer);
        // MARK
        const productType = serviceType === ServiceTypeEnum.EXISTING_FIBER ? 'iont-fiber-products' : 'copper-products';
        dispatch(
          updateServiceabilityMovingAddess({
            showModal: true,
            serviceType,
            searchParams,
            productPath: buildProductPath(productType, 'new', hasUtmCampaign),
            hasPendingQuotes: result.data.quotes.length > 0
          })
        );
      } else {
        navWithParams(history, buildMessagePath(serviceType), null, null, ServiceabilityFormId);
      }
    } else {
      // dispatch(createProspect({ serviceLocation: request }));
      dispatch(updateAdobeEvars({ eVar49: 'in service area' }));

      if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER && !!futureServiceDate) {
        dispatch(updateProductListTechnology('copper')); // ADDED Derrick
        setLocalStorageValue('PreSaleProspect', buildProductPath('copper-products', 'new', hasUtmCampaign));
        navWithParams(
          history,
          buildProductPath('future-fiber-available', null, '#prospect-form'),
          null,
          null,
          ServiceabilityFormId
        );
      } else if (serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER) {
        navWithParams(
          history,
          buildProductPath('future-fiber-available', null, '#prospect-form'),
          null,
          null,
          ServiceabilityFormId
        );
      } else if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER && !futureServiceDate) {
        if (iONT) {
          abAppender = '';
          dispatch(updateProductListTechnology('iont'));
          navWithParams(
            history,
            buildProductPath('iont-fiber-products', 'new', hasUtmCampaign),
            null,
            null,
            ServiceabilityFormId
          );
        } else if (fiberModernization) {
          abAppender = '';
          dispatch(updateProductListTechnology('mod'));
          navWithParams(
            history,
            buildProductPath('mod-fiber-products', 'new', hasUtmCampaign),
            null,
            null,
            ServiceabilityFormId
          );
        } else {
          dispatch(updateProductListTechnology('fiber'));
          navWithParams(
            history,
            buildProductPath('fiber-products', 'new', hasUtmCampaign),
            null,
            null,
            ServiceabilityFormId
          );
        }
      } else if (serviceType === ServiceTypeEnum.PENDING_QUOTE_F) {
        dispatch(updateProductListTechnology('fiber'));
        navWithParams(
          history,
          buildProductPath('iont-fiber-products', 'new', hasUtmCampaign),
          null,
          null,
          ServiceabilityFormId
        );
      } else if (serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER) {
        dispatch(updateProductListTechnology('fiber'));
        navWithParams(history, buildProductPath('fiber-products', 'existing'), null, null, ServiceabilityFormId);
      } else if (serviceType === ServiceTypeEnum.SERVICEABLE) {
        dispatch(updateProductListTechnology('copper'));
        navWithParams(
          history,
          buildProductPath('copper-products', 'new', hasUtmCampaign),
          null,
          null,
          ServiceabilityFormId
        );
      } else if (serviceType === ServiceTypeEnum.PENDING_QUOTE_C) {
        dispatch(updateProductListTechnology('copper'));
        navWithParams(
          history,
          buildProductPath('copper-products', 'new', hasUtmCampaign),
          null,
          null,
          ServiceabilityFormId
        );
      } else if (serviceType === ServiceTypeEnum.EXISTING_VOICE) {
        dispatch(updateProductListTechnology('copper'));
        navWithParams(history, buildProductPath('copper-products', 'existing'), null, null, ServiceabilityFormId);
      } else if (serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER) {
        navWithParams(
          history,
          buildMessagePath(MessagePageTypeEnum.NO_FIBER_VISIT_DOTCOM),
          null,
          null,
          ServiceabilityFormId
        );
      }
    }

    return {
      ...result.data,
      environment: samRecords.environment,
      controlNumber: samRecords.controlNumber
    } as ServiceabilityResponseType;
  }
);

export const getSMBServiceability = createAsyncThunk(
  'app/getServiceability',
  async (
    payload: { history; request: ServiceabilityRequestType; partnerPortalApiUrl: string | undefined },
    { dispatch, getState }
  ) => {
    const { history, request, partnerPortalApiUrl } = payload;
    const { isBusiness, postalCode, samRecords } = request;

    if (!samRecords) {
      navWithParams(
        history,
        `/customer-message/?messagePageType=${ServiceTypeEnum.UNSERVICEABLE}`,
        null,
        null,
        ServiceabilityFormId
      );
    }

    const result = await AppApi.getServiceability(samRecords);
    if (result?.data) {
      result.data = {
        ...result?.data,
        ...{ isWhyFiberForm: false, isBusiness, isSMB: false },
        quotes: await normalizeQuotes({
          quotes: result?.data?.quotes,
          partnerPortalApiUrl
        })
      };
      dispatch(updateOpenQuotes(result?.data));
      setLocalStorageValue('openQuotes', JSON.stringify({...(result?.data), controlNumber: samRecords.controlNumber, environment: samRecords.environment }));
    }
    dispatch(updateServiceLocation(request));

    const newState: any = getState();
    const state: AppState = newState.appState;

    const buildMessagePath = (pageType: string) => `/customer-message/?messagePageType=${pageType}`;
    const buildProductPath = (
      extension: string,
      customerType?: string | null,
      hashPath: string | null = '#product-list'
    ) => {
      return `${customerType ? `/${customerType}` : ''}/${extension}${hashPath}`;
    };

    const {
      serviceType, // Customer Service Type Enum (See Type Interface)
      futureServiceDate, // DateString (future service available) or null (no future service available)
      correctedAddress, // Used to recreate address,
      leadAuditId
    } = result.data as ServiceabilityResponseType;

    let updatedRequest = request;

    if (correctedAddress) {
      const matcher = new RegExp('\\b' + correctedAddress?.streetSuffix + '\\b', 'i');
      const streetSuffixSMB =
        matcher.test(request.address) || !streetSuffix[correctedAddress?.streetSuffix]
          ? correctedAddress?.streetSuffix
          : streetSuffix[correctedAddress?.streetSuffix];
      updatedRequest = {
        ...request,
        address: `${correctedAddress?.streetNumber} ${correctedAddress?.streetName} ${streetSuffixSMB}`
      };
    }

    // GTM Analytics
    const gTag: string[] = [];

    switch (serviceType) {
      case ServiceTypeEnum.UNSERVICEABLE:
        // Trilia_.COM_SMB_New_LP_Homepage_Serviceability Fail
        gTag.push('DC-8577835/smblp0/smb_n00+standard');
        break;
      default:
        // Trilia_.COM_SMB_New_LP_Homepage_Serviceability Pass
        gTag.push('DC-8577835/smblp0/smb_n000+standard');
    }

    gTag.forEach((tag) => {
      sendGtagConversion(tag);
    });

    // Adobe Analytics
    const events = getAdobeAnalyticsEvents();
    if (
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER
    ) {
      dispatch(updateAdobeUserType('business:customer'));
      saveAdobeAnalyticsUserType('business:customer');

      if (
        serviceType !== ServiceTypeEnum.EXISTING_PLANNED_FIBER &&
        serviceType !== ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER
      ) {
        dispatch(updateAdobeEvents('event95'));
        if (!events || !events?.includes('event95')) saveAdobeAnalyticsEvents('event95');
      }
    } else {
      dispatch(updateAdobeUserType('business:prospect'));
      saveAdobeAnalyticsUserType('business:prospect');
    }
    const lastPostalCode = state.lastPostalCode;
    // Adobe Analytics
    dispatch(
      updateAdobeEvars({
        eVar50: `${lastPostalCode && lastPostalCode !== postalCode ? `${lastPostalCode}:` : ''}${postalCode}`
      })
    );
    dispatch(updateAdobeEvents('event30'));

    saveAdobeAnalyticsEvars({
      eVar50: `${lastPostalCode && lastPostalCode !== postalCode ? `${lastPostalCode}:` : ''}${postalCode}`
    });
    if (!events || !events?.includes('event30')) saveAdobeAnalyticsEvents('event30');

    // Only set event29 if they are unserviceables
    if (serviceType === ServiceTypeEnum.UNSERVICEABLE || serviceType === ServiceTypeEnum.EXISTING_COPPER) {
      dispatch(updateAdobeEvents('event29'));
      if (!events || !events?.includes('event29')) saveAdobeAnalyticsEvents('event29');
    } else if (serviceType === ServiceTypeEnum.PENDING_ORDER) {
      dispatch(updateAdobeEvents('event161'));
      if (!events || !events?.includes('event161')) saveAdobeAnalyticsEvents('event161');
    } else if (serviceType !== ServiceTypeEnum.EXISTING_FIBER) {
      dispatch(updateAdobeEvents('event156'));
      if (!events || !events?.includes('event156')) saveAdobeAnalyticsEvents('event156');
    }

    // valid API response
    if (result.status === 200) {
      dispatch(updateAdobeEvents('event100'));
      if (!events || !events?.includes('event100')) saveAdobeAnalyticsEvents('event100');
    }

    // display or hide Get a quote CTA
    if (serviceType !== ServiceTypeEnum.UNSERVICEABLE) {
      dispatch(updateShowGetAQuoteAfterCheckServiceability(true));
    } else {
      dispatch(updateShowGetAQuoteAfterCheckServiceability(false));
    }

    // Path Logic
    if (
      serviceType === ServiceTypeEnum.UNSERVICEABLE ||
      serviceType === ServiceTypeEnum.PENDING_ORDER ||
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER
    ) {
      dispatch(updateAdobeEvars({ eVar49: 'not in service area' }));
      saveAdobeAnalyticsServiceArea('not in service area');
      history.replace(`${history.location.pathname}#serviceability-form`);
      navWithParams(history, buildMessagePath(serviceType));
      saveLocationService(null);
    } else {
      // dispatch(createProspect({ serviceLocation: updatedRequest, campaign: 'SMB' }));
      dispatch(updateAdobeEvars({ eVar49: 'in service area' }));
      saveAdobeAnalyticsServiceArea('in service area');
      if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER && !!futureServiceDate) {
        navWithParams(history, buildProductPath('future-fiber-available', null, '#prospect-form'));
        saveLocationService(null);
      } else if (serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER) {
        navWithParams(history, buildProductPath('future-fiber-available', null, '#prospect-form'));
        saveLocationService(null);
      } else if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER && !futureServiceDate) {
        dispatch(updateProductListTechnology('fiber'));
        navWithParams(history, buildProductPath('fiber-products', 'new'));
        saveLocationService(updatedRequest);
      } else if (serviceType === ServiceTypeEnum.PENDING_QUOTE_F) {
        dispatch(updateProductListTechnology('fiber'));
        navWithParams(history, buildProductPath('fiber-products', 'new'));
        saveLocationService(updatedRequest);
      } else if (serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER) {
        dispatch(updateProductListTechnology('fiber'));
        navWithParams(history, buildProductPath('fiber-products', 'existing'));
        saveLocationService(updatedRequest);
      } else if (serviceType === ServiceTypeEnum.PENDING_QUOTE_C) {
        dispatch(updateProductListTechnology('copper'));
        navWithParams(history, buildProductPath('copper-products', 'new'));
        saveLocationService(updatedRequest);
      } else if (serviceType === ServiceTypeEnum.SERVICEABLE) {
        dispatch(updateProductListTechnology('copper'));
        navWithParams(history, buildProductPath('copper-products', 'new'));
        saveLocationService(updatedRequest);
      } else if (
        serviceType === ServiceTypeEnum.EXISTING_VOICE ||
        serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER
      ) {
        dispatch(updateProductListTechnology('copper'));
        navWithParams(history, buildProductPath('copper-products', 'existing'));
        saveLocationService(updatedRequest);
      } else {
        saveLocationService(updatedRequest);
      }
    }
    if (leadAuditId) dispatch(updateLeadAuditId(leadAuditId));
    dispatch(updateServiceLocation(updatedRequest));
    dispatch(updateLastPostalCode(updatedRequest?.postalCode));

    // return result.data as ServiceabilityResponseType;
    return {
      ...result.data,
      environment: samRecords.environment,
      controlNumber: samRecords.controlNumber
    } as ServiceabilityResponseType;
  }
);

export const getWhyFiberServiceability = createAsyncThunk(
  'app/getServiceability',
  async (
    payload: { history; request: ServiceabilityRequestType; partnerPortalApiUrl: string | undefined },
    { dispatch, getState }
  ) => {
    const { history, request, partnerPortalApiUrl } = payload;
    const { postalCode, samRecords } = request;
    const newState: any = getState();
    const state: AppState = newState.appState;
    const routingParamsMap = state.routingParamsMap;
    const utmCampaign = getQueryStringParams();
    const utmCampaignParam = getSearchParams('utm_campaign');
    const hasUtmCampaignString = utmCampaign.length > 0;
    dispatch(updateServiceabilityFormLoading(true));
    let abAppender = '';
    if (!!routingParamsMap && !!routingParamsMap.utm_campaign && routingParamsMap?.utm_campaign.length > 0) {
      routingParamsMap.utm_campaign.forEach((item) => {
        if (item === utmCampaignParam) abAppender = '-nb';
      });
    }

    setCookie();

    if (!samRecords) {
      navWithParams(
        history,
        `/customer-message/?messagePageType=${ServiceTypeEnum.UNSERVICEABLE}`,
        null,
        null,
        ServiceabilityFormId
      );
    }

    const hasUtmCampaign = !!utmCampaign ? `${utmCampaign}#product-list` : '#product-list';
    const result = await AppApi.getServiceability(samRecords, false, history.location.pathname.includes('/mod'));
    if (result?.data) {
      result.data = {
        ...result?.data,
        ...{ isWhyFiberForm: true, isBusiness: request?.isBusiness, isSMB: false },
        quotes: await normalizeQuotes({
          quotes: result?.data?.quotes,
          partnerPortalApiUrl
        })
      };

      dispatch(updateServiceabilityFormLoading(true));
      dispatch(updateOpenQuotes(result?.data));

      setLocalStorageValue('openQuotes', JSON.stringify({...(result?.data), controlNumber: samRecords.controlNumber, environment: samRecords.environment }));
    }
    const { fiberModernization, leadAuditId, iONT } = result.data as ServiceabilityResponseType;
    if (leadAuditId) dispatch(updateLeadAuditId(leadAuditId));

    dispatch(updateModernizationEligible(fiberModernization));

    dispatch(updateServiceLocation(request));

    const buildMessagePath = (pageType: string) => `/customer-message/?messagePageType=${pageType}`;
    const buildProductPath = (
      extension: string,
      customerType?: string | null,
      hashPath: string | null = '#product-list'
    ) => {
      if (history?.location?.pathname?.charAt(history?.location?.pathname?.length - 1) === '/') {
        const hasCustomerType = customerType ? `${customerType}` : '';
        return `${history.location.pathname}${hasCustomerType}/${extension}${abAppender}${hashPath}`;
      } else {
        const hasCustomerType = customerType ? `/${customerType}` : '';
        return `${history.location.pathname}${hasCustomerType}/${extension}${abAppender}${hashPath}`;
      }
      // check history.location.pathname has a / if so
      // return `${history.location.pathname}${hasCustomerType}/${extension}${abAppender}${hashPath}`;
    };

    const {
      serviceType, // Customer Service Type Enum (See Type Interface)
      futureServiceDate, // DateString (future service available) or null (no future service available)
      pendingInOrderNumber
    } = result.data as ServiceabilityResponseType;
    let offerData = result.data.offer;


    
    // GTM Analytics
    const gTag: string[] = [];

    // Send a generic "success" or "failure" tag for any success or fail state
    const genericGtagSuccess = 'DC-8577835/ftrfiber/trili00a+standard';
    const genericGtagFailure = 'DC-8577835/ftrfiber/trili00b+standard';

    switch (serviceType) {
      case ServiceTypeEnum.UNSERVICEABLE:
        // Trilia_.COM_Why Fiber_Serviceability Fail
        gTag.push(genericGtagFailure);
        gTag.push('DC-8577835/ftrfiber/trili00g+standard');
        break;
      case ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER:
        // No specific tag for this, but it is still considered "success"
        gTag.push(genericGtagSuccess);
        break;
      case ServiceTypeEnum.EXISTING_COPPER:
        // No specific tag, considered failure
        gTag.push(genericGtagSuccess);
        break;
      case ServiceTypeEnum.EXISTING_PLANNED_FIBER:
        gTag.push('DC-8577835/ftrfiber/trili00f+standard');
        gTag.push(genericGtagFailure);
        break;
      case ServiceTypeEnum.PENDING_ORDER:
        // Trilia_.COM_Why Fiber_Serviceability Fail_Pending Order
        gTag.push(genericGtagFailure);
        gTag.push('DC-8577835/ftrfiber/trili00h+standard');
        break;
      case ServiceTypeEnum.SERVICEABLE:
        // Trilia_.COM_Why Fiber_Serviceability Success
        gTag.push(genericGtagSuccess);
        gTag.push('DC-8577835/ftrfiber/trili00e+standard');
        break;
      case ServiceTypeEnum.EXISTING_FIBER:
        // Trilia_.COM_Why Fiber_Serviceability Success_Existing Customer
        gTag.push(genericGtagSuccess);
        gTag.push('DC-8577835/ftrfiber/trili00d+standard');
        break;
      case ServiceTypeEnum.SERVICEABLE_FIBER:
        if (futureServiceDate != null) {
          // Trilia_.COM_Why Fiber_Serviceability Fail_Future Fiber
          gTag.push(genericGtagFailure);
          gTag.push('DC-8577835/ftrfiber/trili00f+standard');
        } else {
          // Trilia_.COM_Why Fiber_Serviceability Success_New Customer
          gTag.push(genericGtagSuccess);
          gTag.push('DC-8577835/ftrfiber/trili00c+standard');
        }
        break;
      default:
      // Send nothing
    }

    gTag.forEach((tag) => {
      sendGtagConversion(tag);
    });

    // Adobe Analytics

    // trigger adobe analytics for service check payload eVar69 & eVar65
    if (
      serviceType === ServiceTypeEnum.PENDING_ORDER ||
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_VOICE ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER ||
      serviceType === ServiceTypeEnum.SERVICEABLE ||
      serviceType === ServiceTypeEnum.SERVICEABLE_FIBER
    ) {
      switch (serviceType) {
        case ServiceTypeEnum.PENDING_ORDER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.PENDING_ORDER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_PLANNED_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_PLANNED_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_COPPER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_COPPER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_VOICE:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_VOICE }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.SERVICEABLE:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.SERVICEABLE }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
        case ServiceTypeEnum.SERVICEABLE_FIBER:
          dispatch(updateAdobeEvars({ eVar69: ServiceTypeEnum.SERVICEABLE_FIBER }));
          dispatch(updateAdobeEvars({ eVar65: window.location.hostname }));
          break;
      }
    }

    if (
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER
    ) {
      dispatch(updateAdobeUserType('resi:customer'));
    } else {
      dispatch(updateAdobeUserType('resi:prospect'));
    }

    // Only set event29 if they are unserviceable
    if (serviceType === ServiceTypeEnum.UNSERVICEABLE) {
      dispatch(updateAdobeEvents('event29'));
    }

    dispatch(updateAdobeEvars({ eVar50: `why-fiber:${postalCode}` }));
    dispatch(updateAdobeEvents('event30,event100'));

    if (serviceType === ServiceTypeEnum.SERVICEABLE || serviceType === ServiceTypeEnum.SERVICEABLE_FIBER) {
      dispatch(updateAdobeEvents('event156'));
    }

    if (
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER ||
      serviceType === ServiceTypeEnum.EXISTING_VOICE
    ) {
      dispatch(updateAdobeEvents('event95,event156'));
    }

    const pendingInOrderNumberCallback = () => {
      if (result.data.quotes.length === 0) {
        handleNavigateToBuyFlow(request, offerData, history);
      } else {
        navWithParams(history, '/OpenQuote', null, null, ServiceabilityFormId);
      }
    }

    // Path Logic
    if (
      serviceType === ServiceTypeEnum.UNSERVICEABLE ||
      serviceType === ServiceTypeEnum.PENDING_ORDER ||
      serviceType === ServiceTypeEnum.EXISTING_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_COPPER
    ) {
      switch (serviceType) {
        case ServiceTypeEnum.EXISTING_FIBER:
        case ServiceTypeEnum.EXISTING_COPPER:
          dispatch(updateAdobeEvents('event95'));
          break;
        case ServiceTypeEnum.PENDING_ORDER:
          dispatch(updateAdobeEvents('event161'));
          break;
      }
      dispatch(updateAdobeEvars({ eVar49: 'not in service area' }));
      if (serviceType === ServiceTypeEnum.EXISTING_FIBER || serviceType === ServiceTypeEnum.EXISTING_COPPER) {
        const technologyType =
          result.data.offer || (serviceType === ServiceTypeEnum.EXISTING_FIBER ? 'FIBER' : 'COPPER');
        const searchParams = buildBuyFlowUrl(request, samRecords, postalCode, technologyType);
        // MARK
        dispatch(
          updateServiceabilityMovingAddess({
            showModal: true,
            serviceType,
            searchParams,
            productPath: null,
            hasPendingQuotes: result.data.quotes.length > 0
          })
        );
      } else if (typeof(pendingInOrderNumber) === 'string' && pendingInOrderNumber.length > 0){
        dispatch(updateErrorModal({data: pendingInOrderNumber, type:'PENDING_QUOTE'}));
        dispatch(updateOpenModal(modalIds.apiErrorModal));
      } else {
        navWithParams(history, buildMessagePath(serviceType), null, null, ServiceabilityFormId);
      }
    } else {
      dispatch(updateAdobeEvars({ eVar49: 'in service area' }));
      // dispatch(createProspect({ serviceLocation: request }));
      const searchParams = buildBuyFlowUrl(request, samRecords, postalCode, result.data.offer);
      switch (serviceType) {
        case ServiceTypeEnum.SERVICEABLE_FIBER:
          if (!!futureServiceDate) {
            const futureFiberParams = buildBuyFlowUrl(request, samRecords, postalCode, 'COPPER');
            dispatch(updateProductListTechnology('copper'));
            setLocalStorageValue('PreSaleProspect', buildProductPath('copper-products', 'new', hasUtmCampaign));

            navWithParams(history, `/buy-autopay/order-copper?${futureFiberParams}`, null, null, ServiceabilityFormId);
          } else if (iONT) {
            // TODO: redirect to /buy-autopay/order-iont
            abAppender = '';
            dispatch(updateProductListTechnology('iont'));
            navWithParams(history, `/buy-autopay/order?${searchParams}`, null, null, ServiceabilityFormId);
          } else if (fiberModernization) {
            abAppender = '';
            dispatch(updateProductListTechnology('mod'));
            // navWithParams(history, buildProductPath('mod-fiber-products', 'new'), null, null, ServiceabilityFormId);
            navWithParams(history, `/buy-autopay/order-mod-two?${searchParams}`, null, null, ServiceabilityFormId);
          } else {
            dispatch(updateProductListTechnology('fiber'));
            navWithParams(
              history,
              // buildProductPath('fiber-products', 'new', hasUtmCampaign),
              `/buy-autopay/order?${searchParams}`,
              null,
              null,
              ServiceabilityFormId
            );
          }
          break;
        case ServiceTypeEnum.PENDING_QUOTE_F:
          dispatch(updateProductListTechnology('fiber'));
          if (result.data.quotes.length === 0) {
            handleNavigateToBuyFlow(request, 'FIBER', history);
          } else if(!pendingInOrderNumber) {
            navWithParams(history, '/OpenQuote', null, null, ServiceabilityFormId);
          } else if(typeof(pendingInOrderNumber) === 'string' && pendingInOrderNumber.length > 0){
            dispatch(updateErrorModal({data: pendingInOrderNumber, type:'PENDING_QUOTE'}));
            dispatch(updateOpenModal(modalIds.apiErrorModal));
          }
          break;
        case ServiceTypeEnum.EXISTING_PLANNED_FIBER:
          const futureServiceableParams = buildBuyFlowUrl(request, samRecords, postalCode, 'COPPER');
          navWithParams(
            history,
            `/buy-autopay/order-copper?${futureServiceableParams}`,
            null,
            null,
            ServiceabilityFormId
          );

          break;
        case ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER:
          // TODO: open the "existing order modal and redirect after to open quotes page"
          dispatch(updateProductListTechnology('fiber'));
          // navWithParams(history, buildProductPath('fiber-products', 'existing'), null, null, ServiceabilityFormId);
          if (result.data.quotes.length === 0) {
            handleNavigateToBuyFlow(request, 'FIBER', history);
          } else {
            navWithParams(history, '/OpenQuote', null, null, ServiceabilityFormId);
          }

          break;
        case ServiceTypeEnum.PENDING_QUOTE_C:
          // dispatch(updateProductListTechnology('copper'));
          // navWithParams(
          //   history,
          //   buildProductPath('copper-products', 'new', hasUtmCampaign),
          //   null,
          //   null,
          //   ServiceabilityFormId
          // );
          if (result.data.quotes.length === 0) {
            handleNavigateToBuyFlow(request, 'COPPER', history);
          } else if(!pendingInOrderNumber) {
            navWithParams(history, '/OpenQuote', null, null, ServiceabilityFormId);
          } else if(typeof(pendingInOrderNumber) === 'string' && pendingInOrderNumber.length > 0){
            dispatch(updateErrorModal({data: pendingInOrderNumber, type:'PENDING_QUOTE'}));
            dispatch(updateOpenModal(modalIds.apiErrorModal));
          }

          break;
        case ServiceTypeEnum.SERVICEABLE:
          dispatch(updateProductListTechnology('copper'));
          // navWithParams(
          //   history,
          //   buildProductPath('copper-products', 'new', hasUtmCampaign),
          //   null,
          //   null,
          //   ServiceabilityFormId
          // );
          navWithParams(history, `/buy-autopay/order-copper?${searchParams}`, null, null, ServiceabilityFormId);
          break;
        case ServiceTypeEnum.EXISTING_VOICE:
        case ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER:
          // TODO: open the "existing order modal and redirect after to open quotes page"
          dispatch(updateProductListTechnology('copper'));
          if (result.data.quotes.length === 0) {
            handleNavigateToBuyFlow(request, 'COPPER', history);
          } else {
            navWithParams(history, '/OpenQuote', null, null, ServiceabilityFormId);
          }

          break;
        case ServiceTypeEnum.HOA:
          navWithParams(history, buildMessagePath(MessagePageTypeEnum.HOA), null, null, ServiceabilityFormId);
          break;
        case ServiceTypeEnum.PACLEC:
          navWithParams(history, buildMessagePath(MessagePageTypeEnum.PACLEC), null, null, ServiceabilityFormId);
          break;
      }
    }
    
    return {
      ...result.data,
      offer: offerData,
      environment: samRecords.environment,
      controlNumber: samRecords.controlNumber
    } as ServiceabilityResponseType;
  }
);

/**
 * Action that when passed a path and history will push the path to history.
 * Also allows you to add some data to the state to be used on the new page.
 *
 * @param history: history from useHistory react-router-dom
 * @param path: The path to push to history
 * @param dispatch: Optional - dispatch from useDispatch react-redux
 * @param navData: Optional - Data to add to state for use in the page being navigated to
 * @param backAnchor: Optional - Anchor link to center viewport when user navigates back
 */

// TODO This should get refactored to have default parameters - see <Ticket here> for more info
export const navWithParams = (history, path, dispatch?, navData?, backAnchor?) => {
  if (dispatch && navData) {
    dispatch(updateNavData(navData));
  }

  // Set scrollRestoration to "manual" if there's an added backAnchor
  // This allows manual restoration of the viewport when the back button is pressed
  if (backAnchor) {
    window.history.scrollRestoration = 'manual';
    history.replace(`${history.location.pathname}#${backAnchor}`);
  } else {
    window.history.scrollRestoration = 'auto';
  }
  if (['/serviceability-check'].includes(window?.location?.pathname) && path.includes('/buy-autopay')) {
    window.open(window?.location?.origin + path, '_blank');
  } else {
    history.replace(path);
  }
};

export const getAvailableProducts = createAsyncThunk(
  'app/getAvailableProducts',
  async (payload: { address: ServiceLocation; technology: string }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const { technology, address } = payload;
    const requestTechnology = !!state.serviceability?.iONT ? 'iont' : technology;
    const result = await AppApi.getAvailableProducts({
      address,
      technology: requestTechnology,
      leadAuditId: state.leadAuditId
    });
    return result.data.length ? (result.data as FiberProduct[]) : null;
  }
);

export const getAvailableProductsFrapi = createAsyncThunk(
  'app/getAvailableProductsFrapi',
  async (payload: { address: ServiceLocation; technology: string }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const { technology, address } = payload;
    const requestTechnology = !!state.serviceability?.iONT ? 'iont' : technology;
    const env = state.serviceability?.environment;
    const controlNumber = state.serviceability?.controlNumber;
    const result = await AppApi.getAvailableProductsFrapi({
      address,
      technology: requestTechnology,
      leadAuditId: state.leadAuditId,
      env,
      controlNumber
    });
    return result.data.length ? (result.data as FiberProduct[]) : null;
  }
);

export const sendVerificationCode = createAsyncThunk('app/sendVerificationCode', async (payload, { dispatch }) => {
  const res = await AppApi.sendVerificationCode(payload);
  dispatch(updateMessagePageLoading(false));
  return res.data;
}) as any;

export const verifyCode = createAsyncThunk('app/verifyCode', async (payload) => {
  const res = await AppApi.verifyCode(payload);
  return res.data;
}) as any;

export const getProspect = createAsyncThunk('prospect/retrieveProspect', async (prospectId) => {
  const result = await AppApi.getProspect(prospectId);
  return result.data;
});

export const createProspect = createAsyncThunk('prospect/createProspect', async (prospectInfo: ProspectSubmission) => {
  const result = await AppApi.createProspect(prospectInfo);
  return result.data;
});

export const submitLeadForm = createAsyncThunk('leadForm/createProspect', async (leadFormPayload: LeadForm) => {
  const updatedProspect = {
    ...leadFormPayload,
    mobileNumber: leadFormPayload.mobileNumber.toString()
  };

  const prospectBody = {
    prospect: updatedProspect,
    prospectId: getLocalStorageValue('leadID')
  };

  const result = await AppApi.updateProspect(prospectBody);
  return result.data;
});

export const updateProspect = createAsyncThunk('prospect/updateProspect', async (data: { history; payload }, {}) => {
  const result = await AppApi.updateProspect(data.payload);
  // const PreSaleProspect = getLocalStorageValue('PreSaleProspect');

  // ==== Disabling 2FA validation ==== //
  //
  // if (result.data.token) {
  //   if (data.payload.toggles.smsEnabled) {
  //     navWithParams(data.history, `/customer-message?messagePageType=${MessagePageTypeEnum.CODE_VERIFICATION_SMS}`);
  //   } else if (data.payload.toggles.marketingEmailEnabled) {
  //     navWithParams(data.history, `/customer-message?messagePageType=${MessagePageTypeEnum.CODE_VERIFICATION_EMAIL}`);
  //   } else {
  //     navWithParams(
  //       data.history,
  //       `/customer-message?messagePageType=${MessagePageTypeEnum.CODE_VERIFICATION_SUCCESSFUL}`
  //     );
  //   }
  // } else {
  //   navWithParams(
  //     data.history,
  //     `/customer-message?messagePageType=${MessagePageTypeEnum.CODE_VERIFICATION_SUCCESSFUL}`
  //   );
  // }
  //
  // ==== Disabling 2FA validation ==== //

  /*
  if (PreSaleProspect) {
    removeLocalStorageItem('PreSaleProspect');
    navWithParams(data.history, PreSaleProspect);
  } else
    navWithParams(
      data.history,
      `/customer-message?messagePageType=${MessagePageTypeEnum.CODE_VERIFICATION_SUCCESSFUL}`
    );
  */

  navWithParams(data.history, `/customer-message?messagePageType=${MessagePageTypeEnum.CODE_VERIFICATION_SUCCESSFUL}`);
  return { response: result.data, prospectInfo: data.payload };
});

export const sharedHandlers = (builder: ActionReducerMapBuilder<AppState>) => {
  return builder
    .addCase(getServiceability.fulfilled, (state, { payload }) => {
      state.serviceability = payload;
    })
    .addCase(getServiceability.rejected, (_state, { error }) => {
      console.error('getServiceability FAILED', error);
      // Trilia_.COM_Why Fiber_Serviceability Fail_Server Error
      sendGtagConversion('DC-8577835/ftrfiber/trili00b+standard');
      sendGtagConversion('DC-8577835/ftrfiber/trili00i+standard');
      // JSS Tracking Event
      trackJssGoalItem(ServiceGoalTypes.CHECKED, `${error.message}`);
      trackJssGoalItem(ServiceGoalTypes.FAILED, `${error.message}`);
    })
    .addCase(getAvailableProducts.fulfilled, (state, { payload }) => {
      state.fiberProducts = payload;
    })
    .addCase(getAvailableProductsFrapi.fulfilled, (state, { payload }) => {
      state.fiberProducts = payload;
    })
    .addCase(getAvailableProducts.rejected, (_state, { error }) => {
      _state.fiberProducts = null;
      console.error('PRODUCT FETCH FAILED', error);
    })
    .addCase(getAvailableProductsFrapi.rejected, (_state, { error }) => {
      _state.fiberProducts = null;
      console.error('PRODUCT FETCH FAILED', error);
    })
    .addCase(sendVerificationCode.fulfilled, (state, { payload }) => {
      state.prospectData!.token = payload.token;
    })
    .addCase(sendVerificationCode.rejected, (_state, { error }) => {
      console.error('ERROR SENDING CODE', error);
    })
    .addCase(verifyCode.rejected, (_state, { error }) => {
      console.error('VERIFICATION SUBMISSION ERROR', error);
    })
    .addCase(getProspect.fulfilled, (state, { payload }) => (state.prospectInfo = payload))
    .addCase(getProspect.rejected, (_state, { error }) => console.error('RETRIEVING PROSPECTS FAILED', error))
    .addCase(createProspect.fulfilled, (state, { payload }) => {
      setLocalStorageValue('leadID', payload.id);
      state.prospectData!.id = payload.id;
    })
    .addCase(createProspect.rejected, (_state, { error }) => {
      console.error('CREATING PROSPECT FAILED', error);
    })
    .addCase(updateProspect.fulfilled, (state, { payload }) => {
      state.prospectData!.token = payload.response.token;
    })
    .addCase(updateProspect.rejected, (_state, { error }) => {
      console.error('UPDATING PROSPECT FAILED', error);
    })
    .addCase(submitLeadForm.fulfilled, (state, { payload }) => {
      state.leadFormData.id = payload.id;
      state.leadFormData.token = payload.token;
      setLocalStorageValue('leadToken', payload.token);
    })
    .addCase(submitLeadForm.rejected, (_state, { error }) => {
      console.error('CREATING PROSPECT FAILED', error);
      _state.openLeadFormModal = false;
    });
};
