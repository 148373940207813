import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectVoiceProductDetailsModalFields } from '../../store/selectors';

export const VoiceProductDetailsModal = ({ className }) => {
  const [mobile, setMobile] = useState(false);
  /**
   * To add the resize event listener
   */
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return function cleanup() {
      window.removeEventListener('resize', resize);
    };
  }, []);
  /**
   * Method to check mobile or desktop
   */
  const resize = () => {
    setMobile(window.innerWidth < 768 ? true : false);
  };
  /**
   * To update the margin for the full screen modal
   */
  useEffect(() => {
    const modalElement: any = document.getElementsByClassName('full-screen-modal-content');
    if (mobile) {
      modalElement && (modalElement[0].style.margin = '40px 10px');
    } else {
      modalElement && (modalElement[0].style.margin = '4rem 7.9375rem');
    }
  }, [mobile]);
  const popUpFields = useSelector(selectVoiceProductDetailsModalFields);
  return (
    <div className={`${className} fsm-accepted-cards`}>
      <h2 className="modal-title"> {popUpFields?.SelectLearnMoreModal?.fields?.learnMoreModalTitle?.value} </h2>
      <div className="modal-disclaimer-text">
        <p>{popUpFields?.SelectLearnMoreModal?.fields?.learnMoreModalContent?.value}</p>
      </div>
    </div>
  );
};

const StyledVoiceProductDetailsModal = styled(VoiceProductDetailsModal)`
  ${({ theme }) => `
    &.fsm-accepted-cards {
      font-size: 1rem;
      text-align: center;

      .modal-disclaimer-text {
        max-height: 30rem;
        overflow-y: auto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #141928;

        p {
          margin-bottom: 0;
        }
      }

      .modal-card-images {
        max-width: 390px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        .accepted-card-img {
          width: 72px;
          height: 50px;
          border-radius: 3px;
          background-color: #333;
        }
      }
      
      
      .modal-title {
        color: ${theme.mineShaft};
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      .confirm-button {
        display: block;
        margin-top: 3rem;
      }
    }
  `}
  ${({ theme }) => theme.tabletPortrait`
  `}
  ${({ theme }) => theme.mobile`
    &.fsm-accepted-cards {
      .modal-disclaimer-text {
        max-height: 23rem;
        font-size: 14px;
        overflow-y: auto;
        margin: 0 10px 0 10px;
      }
    }
  `}
`;

export default StyledVoiceProductDetailsModal;
