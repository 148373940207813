const STORAGE_KEY = 'smbAdobeAnalytics';
const DEFAULT_ADOBE_ANALYTICS = '{"eVar22": "business:prospect", "eVar49": "unverified service area"}';

export const isAdobeAnalyticsEqual = (a = {}, b = {}) => {
  let isEqual;

  try {
    isEqual = JSON.stringify(a) === JSON.stringify(b);
  } catch (err) {
    isEqual = a === b;
  }

  return isEqual;
};

export const getValidAdobeAnalytics = (reduxAdobeAnalytics) => {
  const storageAdobeAnalytics = getAdobeAnalytics();

  if (isAdobeAnalyticsEqual(reduxAdobeAnalytics, storageAdobeAnalytics)) {
    return reduxAdobeAnalytics;
  }

  if (!storageAdobeAnalytics) {
    return reduxAdobeAnalytics;
  }

  return storageAdobeAnalytics;
};

export const getAdobeAnalytics = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = localStorage.getItem(STORAGE_KEY);

  if (!storageAdobeAnalytics) {
    return;
  }

  let adobeAnalytics;

  try {
    adobeAnalytics = JSON.parse(storageAdobeAnalytics);
  } catch (error) {
    adobeAnalytics = JSON.parse(DEFAULT_ADOBE_ANALYTICS);
    console.error(error);
  }

  return adobeAnalytics;
};

export const getAdobeAnalyticsPageName = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();

  return storageAdobeAnalytics?.pageName;
};

export const getAdobeAnalyticsUserType = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();

  return storageAdobeAnalytics?.eVar22;
};

export const getAdobeAnalyticsEvars = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();

  return storageAdobeAnalytics?.evars;
};

export const getAdobeAnalyticsEvents = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();

  return storageAdobeAnalytics?.events;
};

export const saveAdobeAnalyticsPagename = (pageName) => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();
  let locationService;

  try {
    locationService = JSON.stringify({ ...storageAdobeAnalytics, pageName });
  } catch (error) {
    locationService = DEFAULT_ADOBE_ANALYTICS;
    console.error(error);
  }

  localStorage.setItem(STORAGE_KEY, locationService);
};

export const saveAdobeAnalyticsUserType = (eVar22) => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();
  let adobeAnalytics;

  try {
    adobeAnalytics = JSON.stringify({ ...storageAdobeAnalytics, eVar22 });
  } catch (error) {
    adobeAnalytics = DEFAULT_ADOBE_ANALYTICS;
    console.error(error);
  }

  localStorage.setItem(STORAGE_KEY, adobeAnalytics);
};

export const saveAdobeAnalyticsServiceArea = (eVar49) => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();
  let adobeAnalytics;

  try {
    adobeAnalytics = JSON.stringify({ ...storageAdobeAnalytics, eVar49 });
  } catch (error) {
    adobeAnalytics = DEFAULT_ADOBE_ANALYTICS;
    console.error(error);
  }

  localStorage.setItem(STORAGE_KEY, adobeAnalytics);
};

export const saveAdobeAnalyticsEvars = (evars) => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();
  let adobeAnalytics;

  try {
    adobeAnalytics = JSON.stringify({
      ...storageAdobeAnalytics,
      evars: { ...storageAdobeAnalytics.evars, ...evars }
    });
  } catch (error) {
    adobeAnalytics = DEFAULT_ADOBE_ANALYTICS;
    console.error(error);
  }

  localStorage.setItem(STORAGE_KEY, adobeAnalytics);
};

export const saveAdobeAnalyticsEvents = (newEvents) => {
  if (typeof window === 'undefined') {
    return;
  }

  const storageAdobeAnalytics = getAdobeAnalytics();
  let events = storageAdobeAnalytics.events;

  if (events) {
    events = `${events},${newEvents}`;
  } else {
    events = newEvents;
  }

  let adobeAnalytics;

  try {
    adobeAnalytics = JSON.stringify({
      ...storageAdobeAnalytics,
      events
    });
  } catch (error) {
    adobeAnalytics = DEFAULT_ADOBE_ANALYTICS;
    console.error(error);
  }

  localStorage.setItem(STORAGE_KEY, adobeAnalytics);
};

export const resetAdobeAnalytics = () => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(STORAGE_KEY, DEFAULT_ADOBE_ANALYTICS);
};
