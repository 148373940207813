export default {
  addressNotServiceable: {
    header: "We don't have service available in your area yet.",
    tryCallingUs: 'If you have questions, give us a call at',
    phoneNumber: '1.844.591.4912'
  },
  siteCoreErrorModal: {
    header: 'Something went wrong.',
    tryCallingUs: 'If this issue persists please call us at',
    phoneNumber: '1.844.591.4912'
  },
  apiErrorModal: {
    header: 'We were unable to complete your request at the moment.',
    tryCallingUs: 'Please try again or call us at',
    phoneNumber: '1.844.591.4912'
  }
};
