import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Button from '../../components/Button';

const CreditFreezeModal = () => {
  return (
    <div className="fsm-body">
      <Text
        className="fsm-header-copy mb-1"
        tag="h1"
        field={{ value: `Credit Bureau (Equifax) has reported a Freeze on the Customer's Account` }}
      />
      <Text
        className="fsm-try-calling-us mb-5"
        tag="h3"
        field={{ value: `Please contact Equifax Customer Care: 1-888-378-4329 or https://www.equifax.com` }}
      />
      <Button
        model="primary"
        type="button"
        onClick={() => {
          window.open('/serviceability-check', '_self');
        }}
      >
        {'Save and Exit the Quote'}
      </Button>
    </div>
  );
};

export default CreditFreezeModal;
