import * as React from 'react';
import styled from 'styled-components';

interface LoadingSpinnerPropsType {
  className?: string;
  color?: string;
}

const LoadingSpinner = ({ className, color }: LoadingSpinnerPropsType) => {
  const dots = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className={className}>
      <div className={`circle-loader`}>
        {dots.map((ind) => (
          <span key={ind} color={color}></span>
        ))}
      </div>
    </div>
  );
};

const StyledLoadingSpinner = styled(LoadingSpinner)`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  .circle-loader {
    width: 1.875rem;
    height: 1.875rem;
    display: inline-block;
    position: relative;

    span {
      height: 6px;
      width: 6px;
      background-color: ${({ color, theme }) => (color ? color : theme.white)};
      border-radius: 50%;
      position: absolute;
      -webkit-animation: 0.8s opaque ease-in-out infinite both;
      animation: 0.8s opaque ease-in-out infinite both;
    }
    span:nth-child(1) {
      top: 0px;
      left: 12px;
    }
    span:nth-child(2) {
      top: 4px;
      left: 20px;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    span:nth-child(3) {
      top: 12px;
      left: 24px;
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    span:nth-child(4) {
      top: 20px;
      left: 20px;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    span:nth-child(5) {
      top: 24px;
      left: 12px;
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }
    span:nth-child(6) {
      top: 20px;
      left: 4px;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
    span:nth-child(7) {
      top: 12px;
      left: 0px;
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }
    span:nth-child(8) {
      top: 4px;
      left: 4px;
      -webkit-animation-delay: 0.7s;
      animation-delay: 0.7s;
    }
  }

  @-webkit-keyframes opaque {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
  }

  @keyframes opaque {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;

export default StyledLoadingSpinner;
