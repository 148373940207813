import FullScreenModal from 'components/FullScreenModal';
import LoadingOverlay from 'components/LoadingOverlay';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import RouteHandler from 'RouteHandler';
import { resetAdobeEvarsAndEvents, updateShowGetAQuoteAfterCheckServiceability } from 'store/appSlice';
import { selectAdobeAnalytics, selectAnalyticsCartId, selectLoadingState, selectOpenModal } from 'store/selectors';
import { getValidAdobeAnalytics, resetAdobeAnalytics } from 'utils/adobeAnalyticsHandler';
import { adobeTrackPageLoad } from 'utils/adobeAnalyticsHelper';
import scrollToHashURL from 'utils/scrollToHashURL';
import ScrollToTopWrapper from 'utils/ScrollToTopWrapper';
import { removeLocalStorageItem } from 'utils/storageUtil';
import useSitecoreContext from 'utils/useSitecoreContext';

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*'
];

const App = () => {
  const dispatch = useDispatch();
  const sitecoreContext = useSitecoreContext();
  const siteName = sitecoreContext?.site?.name;
  const adobeAnalyticsRedux = useSelector(selectAdobeAnalytics);
  const adobeAnalyticsStorage = getValidAdobeAnalytics(useSelector(selectAdobeAnalytics));
  const adobeAnalytics = siteName === 'ftr-smb' ? adobeAnalyticsStorage : adobeAnalyticsRedux;
  const routeRenderFunction = (props) => <RouteHandler route={props} />;
  const openModal = useSelector(selectOpenModal);
  const displayFullScreenSpinner = useSelector(selectLoadingState);
  const analyticsCartId = useSelector(selectAnalyticsCartId);
  const location = useLocation();

  useEffect(() => {
    // !! TODO !! Remove the pageName conditional when we move FXP Resi off the root route
    // Clean up everything in Adobe data layer that isn't an "every-page" variable
    if (typeof siteName !== 'undefined') {
      // !! TODO !! Remove the pageName conditional when we move FXP Resi off the root route
      const smbPageName = location.pathname === '/' ? `fbe:home` : `fbe:${location.pathname.replace('/', '')}`;
      const pageName = location.pathname === '/' ? '/fiber-expansion/resi' : location.pathname;
      /**
       * evar22 the only user type that can get to BF (/buy/order) at the moment is a resi:prospect
       * if user is not at /buy/order, select evar22 from adobeAnalytics state
       */
      const eVar33 = location.pathname.includes('/buy/') ? 'ftr-ecom' : null;

      adobeTrackPageLoad({
        ...adobeAnalytics,
        // FIX THIS
        events:
          analyticsCartId !== '' && location.pathname.includes('/buy/')
            ? `${adobeAnalytics.events}`
            : adobeAnalytics.events,
        pageName: siteName === 'ftr-smb' ? smbPageName : pageName,
        eVar22: location.pathname === '/buy/order' ? 'resi:prospect' : adobeAnalytics.eVar22,
        eVar33
      });
      // Clean up everything in Adobe data layer that isn't an "every-page" variable
      dispatch(resetAdobeEvarsAndEvents());
      resetAdobeAnalytics();

      // clean up every get a quote display dependencies, to hide CTA
      if (
        location.pathname === '/' ||
        location.hash === '#serviceability-form' ||
        (siteName === 'ftr-smb' &&
          location.pathname.trim() === '/customer-message' &&
          location.search.trim() === '?messagePageType=CODE_VERIFICATION_SUCCESSFUL')
      ) {
        removeLocalStorageItem('leadID');
        removeLocalStorageItem('leadToken');
        dispatch(updateShowGetAQuoteAfterCheckServiceability(false));
      }
    }
  }, [location.pathname, siteName]);

  useEffect(() => {
    scrollToHashURL(location);
  }, [location.hash]);

  useEffect(() => {
    displayFullScreenSpinner || openModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [displayFullScreenSpinner, openModal]);

  //To remove the img added by the ad systems
  useEffect(() => {
    window.addEventListener('load', () => {
      const imageElement = document.querySelector<HTMLElement>(
        "[src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D4db758dd-c400-978c-a3c3-4061267454d7%26type%3D55%26m%3D1&ex-fch=416613&ex-src=www.frontier.com&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D4DB758DD-C400-978C-A3C3-4061267454D7']"
      );
      if (imageElement) {
        imageElement.style.display = 'none';
      }
    });
  }, []);

  return (
    <ScrollToTopWrapper>
      <Switch>
        {routePatterns.map((routePattern) => (
          <Route key={routePattern} path={routePattern} render={routeRenderFunction} />
        ))}
      </Switch>
      {openModal && <FullScreenModal id={openModal} />}
      {displayFullScreenSpinner && <LoadingOverlay />}
    </ScrollToTopWrapper>
  );
};

export default App;
