import { SelectProductResponse } from 'store/api/orderApiTypes';
import { SelectProductCofig, SelectProductCofigOption } from '../../api/orderApiTypes';
export const initialState: OrderQuoteState = {
  completeOrderProductSelection: { fetchedError: false, fetchedSuccess: true, isFetching: false },
  configureOrderProduct: { fetchedError: false, fetchedSuccess: true, isFetching: false },
  deleteOrderProduct : { isFetching: false },
  isVoiceDirectoryOptionsFetched: true,
  activeBuyFlowProductOption: [],
  quoteId: '',
  quoteNumber: '',
  uuid: '',
  ebbVerified: false,
  serviceAddress: {
    addressId: {
      environmentCode: '',
      samControlNumber: 0
    },
    number: '',
    preDirectional: '',
    streetName: '',
    postDirectional: '',
    city: '',
    state: '',
    postalCode: '',
    streetNameSuffix: ''
  },
  environment: '',
  controlNumber: 0,
  firstName: '',
  lastName: '',
  productOffers: [],
  childProducts: [],
  updatedConfigLineItems: [],
  schedule: {
    scheduleId: '',
    appointmentStart: ''
  },
  deposit: {
    owed: 0,
    paid: {
      amount: '',
      depositAmount: 0,
      backBalances: []
    }
  },
  backBalanceOwed: {
    phoneNumber: 0,
    amountDue: 0
  },
  productsModifiable: true,
  scheduleModifiable: true,
  cancelAllowed: true,
  customerTelephoneNumber: 0,
  taxes: [],
  quoteStatus: '',
  billingAddress: {
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: ''
  },
  mobileTelephone: 0,
  emailAddress: '',
  salesJourneyMilestone: [],
  hasRouterExtenderConfiguration: false,
  notes: [],
  technology: '',
  isStepOneComplete: false,
  hasAddressDiscrepancy: false,
  allowEditPreviousAddress: false,
  resumedWithAddressDiscrepancy: false,
  isPosIdRequired: false
};

export type OrderQuoteState = {
  isVoiceDirectoryOptionsFetched: boolean;
  activeBuyFlowProductOption: SelectProductCofigOption[] | [];
  quoteId: string;
  quoteNumber: string;
  uuid: string;
  ebbVerified: boolean;
  serviceAddress: {
    addressId: {
      environmentCode: string;
      samControlNumber: 0;
    };
    number: string;
    preDirectional: string;
    streetName: string;
    postDirectional: string;
    city: string;
    state: string;
    postalCode: string;
    streetNameSuffix: string;
  };
  configureOrderProduct: {
    isFetching: boolean;
    fetchedError: boolean;
    fetchedSuccess: boolean;
  };
  deleteOrderProduct: {
    isFetching: boolean;
  };
  completeOrderProductSelection: {
    isFetching: boolean;
    fetchedError: boolean;
    fetchedSuccess: boolean;
  };
  updatedConfigLineItems: any[];
  environment: string;
  controlNumber: number | 0;
  firstName: string;
  lastName: string;
  productOffers: (ProductOffer | SelectProductResponse)[];
  childProducts: (ChildProduct | SelectProductCofig)[];
  schedule: {
    scheduleId: string;
    appointmentStart: string;
    appointmentEnd?: null;
    expirationDate?: null;
  };
  deposit: {
    owed: number | 0;
    paid: {
      amount: string;
      depositAmount: number | 0;
      backBalances: BackBalance[];
    };
  };
  backBalanceOwed: {
    phoneNumber: number | 0;
    amountDue: number | 0;
  };
  productsModifiable: true;
  scheduleModifiable: true;
  cancelAllowed: true;
  customerTelephoneNumber: number | 0;
  taxes: Tax[];
  quoteStatus: string;
  billingAddress: {
    address: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
  };
  mobileTelephone: number | 0;
  emailAddress: string;
  salesJourneyMilestone: SalesJourney[];
  hasRouterExtenderConfiguration: boolean;
  notes: Notes[];
  technology: 'FIBER' | 'COPPER' | '';
  isStepOneComplete: boolean;
  hasAddressDiscrepancy: boolean;
  allowEditPreviousAddress: boolean;
  resumedWithAddressDiscrepancy: boolean;
  isPosIdRequired: boolean;
};

type Notes = {
  noteType: string;
  note: string;
};

type SalesJourney = {
  name: string;
  fulfilled: string;
};

export type ChildProduct = {
  childProductId: string;
  description: string;
  price: string | 0;
  rateType: string;
  unit: string;
  type: string;
};
export type ProductOffer = {
  name: string;
  productId: string;
  quoteItemId: string;
  description: string;
  monthlyPrice: number | 0;
  oneTimeCharge: number | 0;
  price: { value: string };
  configurations: any;
};
type BackBalance = {
  amountDue: number | 0;
  accountId: {
    usi: number | 0;
    phoneNumber: {
      phoneNumber: number | 0;
      sequenceNumber: number | 0;
    };
  };
};

type Tax = {
  type: 'BACKPAY' | 'DEPOSIT' | 'FEE' | 'STATE_TAX' | 'FEDERAL_TAX';
  cost: {
    value: string;
    currency: string;
    unit: string;
  };
};
