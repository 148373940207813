import { css } from 'styled-components';

export const sizes = {
  desktop: 1440,
  tabletLandscape: 1024,
  tabletPortrait: 768,
  heroMobile: 500, // Special case size for hero images
  mobile: 375
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

let $black = '#000000';
let $aqua = '#96FFF5';
let $aquaLight = '#96FFF599'; //aqua with 60% opacity
let $blue = '#455a7e';
let $crimson = '#FF0037';
let $crimsonLight = '#FF003733'; //crimson with 20% opacity
let $dustyGray = '#999999';
let $gray = '#58595b';
let $marigold = '#fcb832';
let $mineShaft = '#141928';
let $navy = '#141928';
let $red = '#df4841';
let $sand = '#f3f4f4';
let $seashell = '#e1e1e1';
let $silver = '#cccccc';
let $teal = '#3a676f';
let $white = '#ffffff';

// Rebrand Color Palette
let $primaryRed = '#ff0037';
let $secondaryAqua = '#96fff5';
let $darkGray = '#141928';
let $dark1 = '#141928';
let $dark2 = '#2c303e';
let $dark3 = '#5b5e69';
let $dark4 = '#898c93';
let $dark5 = '#c4c5c9';
let $dark6 = '#f3f4f4';

// Alert Tier Colors
let $lightYellow = '#fff3cd';
let $lightYellowBorder = '#ffeeba';
let $lightCyan = '#d4edda';
let $lightCyanBorder = '#c3e6cb';
let $lightPink = '#f8d7da';
let $lightPinkBorder = '#ebccd1';
let $lightBlue = '#cce5ff';
let $lightBlueBorder = '#b8d4ff';

const colors = {
  aqua: $aqua,
  black: $black,
  blue: $blue,
  crimson: $crimson,
  crimsonLight: $crimsonLight,
  dustyGray: $dustyGray,
  gray: $gray,
  marigold: $marigold,
  mineShaft: $mineShaft,
  navy: $navy,
  red: $red,
  sand: $sand,
  seashell: $seashell,
  silver: $silver,
  teal: $teal,
  white: $white,
  lightYellow: $lightYellow,
  lightYellowBorder: $lightYellowBorder,
  lightCyan: $lightCyan,
  lightCyanBorder: $lightCyanBorder,
  lightPink: $lightPink,
  lightPinkBorder: $lightPinkBorder,
  lightBlue: $lightBlue,
  lightBlueBorder: $lightBlueBorder,
  aquaLight: $aquaLight,
  primaryRed: $primaryRed,
  secondaryAqua: $secondaryAqua,
  darkGray: $darkGray,
  dark1: $dark1,
  dark2: $dark2,
  dark3: $dark3,
  dark4: $dark4,
  dark5: $dark5,
  dark6: $dark6
};
export const theme = {
  headerHeight: '7.8125rem',
  headerHeightMobile: '5rem',
  footerHeight: '11rem',
  inlineAddressHeight: '6.5rem',
  ...colors
};
