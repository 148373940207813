import { runSafeSsr } from './browserUtil';

declare var gtag;

export function sendGtagConversion(tagId: string) {
  runSafeSsr(
    () => {
      return null;
    },
    () => {
      return gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: tagId
      });
    }
  );
}
