import { getUserToken, getUserUID } from 'utils/partnerPortalUserStorage';

const generateRequestHeaders = () => {
  const token = getUserToken();
  const uid = getUserUID();

  if (!token || !uid) {
    return false;
  }

  return {
    headers: {
      uid,
      id_token: token
    }
  };
};

export default generateRequestHeaders;
