import { PORT_IN_ENTITY_ID } from 'constants/voiceDirectory';
import { LISTED_PRODUCTS_ID, VOICE_PRODUCT_NAMES } from 'constants/voiceProductNames';

export const correctDLOptionNameFromResume = (voiceDirectory) => {
  const activeVoiceOptions = voiceDirectory.options.filter((voiceDirectoryItem) => voiceDirectoryItem.active);
  return activeVoiceOptions.map((voiceOption) =>
    voiceOption.configurationType === 'Entity'
      ? { ...voiceOption, name: correctDLNamesFromResume(activeVoiceOptions) }
      : voiceOption
  );
};

const correctDLNamesFromResume = (voiceSelection) => {
  const voiceOption = voiceSelection.find(
    (voice) => voice.configurationType === 'Entity' && voice.id !== PORT_IN_ENTITY_ID
  );
  const voiceArray = Array.isArray(voiceSelection) ? voiceSelection : [];

  if (voiceOption.id === LISTED_PRODUCTS_ID) {
    const directoryListingCharacteristic = voiceArray.find(
      (item) => item.configurationType === 'Characteristic' && item.name !== 'Listing Choice'
    );
    const correctedListedOptionName = VOICE_PRODUCT_NAMES.find(
      (product) => product.id === directoryListingCharacteristic?.id
    ) || { name: '' };
    return correctedListedOptionName.name;
  }

  const correctedDLOptionFromResume = VOICE_PRODUCT_NAMES.find((product) => product.id === voiceOption.id) || {
    name: ''
  };
  return correctedDLOptionFromResume.name;
};
