// IDS used for FrapiDigitalVoiceProductCard component

export const PORT_IN_ENTITY_ID = '3aab52eb-afc6-40aa-aa29-8eb5cab7da73';
export const PORT_IN_TELEPHONE_NUMBER_ID = 'ea03ebe8-0a86-47eb-b313-220067e14d47';

export const FORM_FIELDS_IDS_ARRAY = [
  'c9311cee-e49d-40d6-941d-f98dc3cb3b8b',
  '439708de-e4f0-4de1-9cda-91e5e522944d',
  '4e2187a7-fa44-444a-a9db-4e0046ba67f8',
  '09488b38-7ff0-4b22-90f5-0ceb0fc7e8f9',
  '8bc1f8c4-4f9a-4ce7-8945-5688d6aae704',
  'e6839323-b353-41a3-ac4c-c77127662feb',
  'd8562dc2-03ee-4cd7-9dde-f60e982e4aa9',
  'f50a33dd-c7ba-4e62-8781-3d3298a22f9c',
  '2679b39c-a64e-4767-974a-d2af4261d51f',
  PORT_IN_TELEPHONE_NUMBER_ID
];
