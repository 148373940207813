import axios from 'axios';
import generateRequestHeaders from 'utils/generateRequestHeaders';
import { AssetsData, getRolesAssets } from 'utils/getAssets';
import { getUserUID } from 'utils/partnerPortalUserStorage';

const include = [
  // 'identities-active',
  // 'identities-all',
  // 'identities-global',
  // 'loginIDs',
  // 'emails',
  'profile',
  'data',
  // 'regSource',
  // 'irank',
  'isLockedOut',
  // 'lastLoginLocation',
  // 'rba',
  // 'subscriptions',
  'userInfo',
  // 'preferences',
  'groups'
  // 'id_token'
].join(',');

const extraProfileFields = [
  // 'languages',
  // 'address',
  // 'phones',
  // 'education',
  // 'educationLevel',
  // 'honors',
  // 'publications',
  // 'patents',
  // 'certifications',
  // 'likes',
  // 'professionalHeadline',
  // 'bio',
  // 'industry',
  // 'specialities',
  // 'work',
  // 'skills',
  // 'religion',
  // 'politicalView',
  // 'interestedIn',
  // 'relationshipStatus',
  // 'hometown',
  // 'favorites',
  // 'followersCount',
  // 'followingCount',
  'username'
  // 'name',
  // 'locale',
  // 'verified',
  // 'timezone',
  // 'samlData'
].join(',');

export interface Organization {
  orgName: string;
  bpid: string;
  roles: string[];
  orgId: string;
  status: string;
}

export interface Groups {
  organizations: Organization[] | [];
}

export interface AccountInfoProfile {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
}

export interface AccountInfoData {
  mobileTN: string;
  subAgentCompany: string;
  type: string;
  CRISID: string;
}

export interface AccountInfo {
  callId: string;
  errorCode: number;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
  UID: number;
  data: AccountInfoData;
  groups: Groups;
  isActive: boolean;
  isLockedOut: boolean;
  isRegistered: boolean;
  isVerified: boolean;
  profile: AccountInfoProfile;
}

export interface AccountData {
  mobileTN: string;
  subAgentCompany: string;
  type: string;
  CRISID: string;
}

export interface AccountPayload {
  Data?: AccountInfo;
  ErrorDetails?: AccountInfo;
  Params: any;
}

export interface SearchedAccountError {
  apiVersion: number;
  callId: string;
  errorCode: number;
  errorDetails: string;
  errorMessage: string;
  statusCode: number;
  statusReason: string;
  time: string;
}
export interface Account {
  lastLoginTimestamp: number;
  lastLogin: Date;
  UID: string;
  createdTimestamp: number;
  profile: AccountInfoProfile;
  lockedUntil: Date;
  groups: Groups;
  isActive: boolean;
  data: AccountData;
}

export interface SearchedAccount {
  apiVersion: number;
  callId: string;
  errorCode: number;
  objectsCount: number;
  results: Partial<Account>[] | [];
  statusCode: number;
  statusReason: string;
  totalCount: number;
}
export interface SearchedAccountPayload {
  Data: SearchedAccount | null;
  ErrorDetails: SearchedAccountError | null;
  Params: any;
}

export type GetAccountInfo = (partnerPortalApiUrl: string, userUID?: string | null) => Promise<AccountPayload>;

export type GetOrganizationRoles = (account?: AccountInfo, bpid?: string | null) => string[] | null;

export type GetOrganization = (account?: AccountInfo, bpid?: string | null) => Organization | null;

export type GetUserRolesInOrganization = ({
  account,
  assets,
  uid,
  bpid
}: {
  account?: AccountInfo;
  assets?: AssetsData;
  uid?: string;
  bpid?: string;
}) => string[] | null;

export type SearchAccount = (partnerPortalApiUrl: string, userUID?: string | null) => Promise<SearchedAccountPayload>;

export type SearchBy = (partnerPortalApiUrl: string, query: string) => Promise<SearchedAccountPayload>;

export type SearchAgents = (
  partnerPortalApiUrl: string,
  bpId: string | null,
  agentId: string | null
) => Promise<SearchedAccountPayload>;

export type HasUUID = (partnerPortalApiUrl: string, uuid: string) => Promise<boolean>;

export type SearchUsers = (partnerPortalApiUrl: string) => Promise<SearchedAccountPayload>;

export type OrganizationInfo = (partnerPortalApiUrl: string, bpid?: string | null) => Promise<OrganizationInterface>;

export type DeleteAccount = (partnerPortalApiUrl: string, bpid?: string | null) => Promise<AccountDeletePayload>;
export interface ErrorDetailsInterface {
  apiVersion: number;
  callId: string;
  errorCode: number;
  errorDetails: string;
  errorMessage: string;
  statusCode: number;
  statusReason: string;
  time: string;
}

export interface InfoInterface {
  city: string[];
  data: string[];
  state: string[];
  video: string[];
  vioce?: string[];
  voice?: string[];
  country: string[];
  vendorID: string[];
  zip_code: string[];
  FTRSecure: string[];
  channelID: string[];
  integrated: string[];
  vendorName: string[];
  channelName: string[];
  street_address: string[];
}

export interface DataInterface {
  apiVersion: number;
  bpid: string;
  callId: string;
  description: string;
  errorCode: number;
  info: InfoInterface;
  lastUpdated: string;
  lastUpdatedTimestamp: number;
  memberLimit: number;
  orgId: string;
  orgName: string;
  source: string;
  status: string;
  statusCode: number;
  statusReason: string;
  time: string;
  type: string;
}

export interface AccountDeletedData {
  apiVersion: number;
  callId: string;
  errorCode: number;
  statusCode: number;
  statusReason: string;
  time: string;
}

export interface AccountDeletePayload {
  Data: AccountDeletedData | null;
  ErrorDetails: ErrorDetailsInterface | null;
  Params: object;
}

export interface OrganizationInterface {
  Data: DataInterface | null;
  ErrorDetails: ErrorDetailsInterface | null;
  Params: object;
}

export const getOrganization: GetOrganization = (account, bpid) => {
  if (!account || !bpid) {
    return null;
  }

  const organizations = account?.groups.organizations;
  const organization = organizations.find((org) => org.bpid === bpid);

  if (!organization) {
    return null;
  }

  return organization;
};

export const getOrganizationRoles: GetOrganizationRoles = (account, bpid) => {
  if (!account || !bpid) {
    return null;
  }

  const organization = getOrganization(account, bpid);

  if (!organization) {
    return null;
  }

  return organization.roles;
};

export const getUserRolesInOrganization: GetUserRolesInOrganization = ({ account, assets, uid, bpid }) => {
  if (!account || !assets || !uid || !bpid) {
    return null;
  }

  const organizationRoles = getOrganizationRoles(account, bpid);
  const roles = getRolesAssets(assets, uid);

  const userRoles = roles?.reduce<string[] | []>((arr, role) => {
    if (!organizationRoles?.includes(role.id)) {
      return arr;
    }

    return [...arr, role.id];
  }, []);

  if (!userRoles?.length) {
    return null;
  }

  return userRoles;
};

export const searchAccount: SearchAccount = (partnerPortalApiUrl, userUID) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();
    const uid = getUserUID();

    if (!headers || (!uid && !userUID)) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<SearchedAccountPayload>(
        `${partnerPortalApiUrl}gigya/api/accountsSearch`,
        {
          query: `SELECT groups FROM accounts WHERE UID = '${uid}'`
        },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export const searchBy: SearchBy = (partnerPortalApiUrl, query) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();

    if (!headers) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<SearchedAccountPayload>(
        `${partnerPortalApiUrl}gigya/api/accountsSearch`,
        { query },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export const getOrganizationInfo: OrganizationInfo = (partnerPortalApiUrl, bpid) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();

    if (!headers || !bpid) {
      return null;
    }

    try {
      const { data, status, statusText } = await axios.post<OrganizationInterface>(
        `${partnerPortalApiUrl}gigya/api/getOrganizationInfo`,
        {
          bpid
        },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export const searchAgents: SearchAgents = (partnerPortalApiUrl, bpid, agentid) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();

    if (!headers) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<SearchedAccountPayload>(
        `${partnerPortalApiUrl}gigya/api/accountsSearch`,
        {
          query: `SELECT user FROM accounts WHERE groups.organizations.bpid = '${bpid}' AND groups.organizations.roles = '${agentid}' LIMIT 10000`
        },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export const hasUUID: HasUUID = (partnerPortalApiUrl, uid) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();

    if (!headers) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<SearchedAccountPayload>(
        `${partnerPortalApiUrl}gigya/api/accountsSearch`,
        {
          query: `SELECT profile.username FROM accounts WHERE profile.username = '${uid}'`
        },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      const account = data.Data?.results[0];
      const hasAccount = account?.profile?.username.toLowerCase() === uid.toLowerCase();

      resolve(hasAccount);
    } catch (err) {
      reject(err);
    }
  });

export const searchUsers: SearchUsers = (partnerPortalApiUrl) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();

    if (!headers) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<SearchedAccountPayload>(
        `${partnerPortalApiUrl}gigya/api/accountsSearch`,
        {
          query: 'SELECT * FROM accounts WHERE groups IS NOT NULL LIMIT 10000'
        },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export const deleteAccount: DeleteAccount = (partnerPortalApiUrl, accountUID) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();

    if (!headers) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<AccountDeletePayload>(
        `${partnerPortalApiUrl}gigya/api/deleteAccount`,
        {
          UID: accountUID
        },
        { ...headers }
      );

      if (data?.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

const getAccountInfo: GetAccountInfo = (partnerPortalApiUrl, userUID) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();
    const uid = getUserUID();

    if (!headers) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<AccountPayload>(
        `${partnerPortalApiUrl}gigya/api/getAccountInfo`,
        {
          uid: userUID || uid,
          include,
          extraProfileFields
        },
        { ...headers }
      );

      if (data.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export default getAccountInfo;
