import { Checkbox } from '@material-ui/core';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTermsAndConditionsAccepted } from 'store/selectors';
import Button from '../../components/Button';
import fakeTermsAndConditions from '../../constants/fakeTermsAndConditions';
import { updateOpenModal, updateTermsAndConditionsAccepted } from '../../store/appSlice';

const TermsAndConditionsModalContent = (props) => {
  const dispatch = useDispatch();
  const termsAndConditionsAccepted = useSelector(selectTermsAndConditionsAccepted);
  const toggleTermsAndConditions = (event) => {
    dispatch(updateOpenModal(null));
    dispatch(updateTermsAndConditionsAccepted(event.target.checked));
  };
  const downloadPDF = () => {
    window.open('https://frontier.com/~/media/corporate/terms/general-terms-residential.ashx?la=en', '_blank');
  };

  return (
    <div className="ts-fsm-body">
      <Text tag="h3" className="fsm-header" field={{ value: 'Terms and Conditions' }} />
      <div className="fsm-t-and-cs" dangerouslySetInnerHTML={{ __html: fakeTermsAndConditions }} />
      <div className="fsm-accept-row">
        <div className="fsm-accept-checkbox">
          <Checkbox checked={termsAndConditionsAccepted} onChange={toggleTermsAndConditions} />
          <Text tag="p" field={{ value: 'I accept these Terms and Conditions' }} />
        </div>
        <Button model="primary" buttonId="terms-and-conditions" onClick={downloadPDF}>
          Download PDF
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditionsModalContent;
