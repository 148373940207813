import { DIGITAL_VOICE_FULL_PRICE_VALUE } from 'constants/displayValues';
import { useSelector, useStore } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  updateAccountUuid, updateAddressDescErrorCode, updateAllowEditPreviousAddress, updateAvailableSecureProductAddons, updateBuyFlowOrder,
  updateCancellationReasonModalField, updateCommunicationPreferences, updateCurrentSecureProductsConfigured,
  updatedVoiceSelected, updateEeroSecureAddon, updateExpertInstallProduct, updateFreeRouter, updateFullInstallSelect, updateFundingAccountLastFourDigits, updateInstallationTime,
  updateIsAutopayEnrolled,
  updateIsCustomerInformatonValidated,
  updateIsEbbFlow, updateIsEeroSecureSelected, updateIsExpertInstallSelected, updateIsPaperlessEnrolled,
  updateIsSecureOptionsSelected, updateIsStepTwoCompleted, updateKeepCurrentPhoneNumber,
  updateLoadingState, updateOfferType, updateOpenCancellationReasonModal,
  updateOpenQuotePreviewModal, updatePaidRouter, updatePaidToday,
  updatePartialInstallSelect,
  updatePersonalInfo,
  updatePortabilityTelephoneNumber, updatePortedTelephoneNumber, updatePPCustomerInfo,
  updateProductListTechnology,
  updateQuoteId,
  updateQuoteNumber, updateRecordLocatorNumber, updateResumedWithAddressDiscrepancy, updateResumePaidRoutersCount, updateSelectedEeroSecureAddonId, updateSelectedPaperlessProduct, updateSelectedProduct, updateSelectedSecureProductAddonsIds, updateSelectedSecureProductIds,
  updateServiceLocation,
  updateVoiceDirectorySelection,
  updateVoiceSelectionOption,
  updateVoiceTermsAccepted,
  updateWholeHomeWifiProduct,
  updateWholeHomeWifiProductId
} from 'store/appSlice';
import { selectCancellationReasonModalField } from 'store/selectors/sharedSelectors';
import { OrderSummary, PriceType, QuoteResumeResponse } from 'store/state.model';
import { PORT_IN_ENTITY_ID, PORT_IN_TELEPHONE_NUMBER_ID } from '../constants/voiceDirectory';
import { useAppDispatch } from '../store';
import {
  updateActiveBuyFlowProductOption,
  updateHasRouterExtenderConfiguration,
  updateOrderState
} from '../store/slices/OrderQuote/orderQuoteSlice';
import { getBillingAutopayData, getResumeQuote } from '../store/slices/OrderQuote/orderQuoteThunks';
import { navWithParams } from '../store/thunks';
import { parseSalesJourneyMilestone } from '../utils/parseSalesJourneyMilestone';
import { buildBuyFlowUrl } from './buyFlowUrlSearchParams';
import { correctDLOptionNameFromResume } from './correctDLNamesFromResume';
import { getProductOfferConfigurationById } from './getProductOfferConfigurationById';

const constants = {
  QuickQuoteUrl: '/quickquote',
  HomeShieldFamilyAddon: '260f4253-85ba-438a-9ffa-a36a77db6767',
  fullInstallPriceId : '481d3b4f-2c10-4787-9603-94891e58d28d',
  partialInstallPriceId: '6e48218e-5000-4c59-98e3-92e575abdc83',
  wholeHomeWifiId: '06cbe317-ccfd-461c-9504-df01e8a522da'
};
interface QuoteRowField {
  agent: string;
  agentId: string;
  dataService: string | undefined;
  requestedDate: string | undefined;
  installationDate: string | undefined;
  name: string | undefined;
  quoteId: string;
  quoteStatus: string;
  quoteType: string;
}

const useActionsOrders = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cancellationFields = useSelector(selectCancellationReasonModalField);

  const handleNavigateToBuyFlow = (data, quoteId): void => {
    const request = {
      address: data.address,
      city: data.city,
      state: data.state
    };
    const samRecords = {
      environment: data.environment,
      controlNumber: data.controlNumber
    };
    const technologyType = data.offer || 'FIBER';
    const searchParams = buildBuyFlowUrl(
      request,
      samRecords,
      data.zipCode,
      // TODO: change it to get technology from quote resume request
      technologyType
    );
    const copperOrder = data.offer === 'COPPER' ? '-copper' : '';
    const iontOrder = data.offer === 'IONT' ? '-iont' : '';
    const recordLocatorNumberParam = data.recordLocatorNumber ? `&recordLocatorNumber=${data.recordLocatorNumber}` : '';
    navWithParams(history, `/buy-autopay/order${copperOrder}${iontOrder}?${searchParams}&quoteId=${quoteId}${recordLocatorNumberParam}`);
  };
  const reduxStore = useStore().getState();
  const handleEditQuote = async (editQuoteId: string): Promise<void> => {
    try {
      const { payload } = (await dispatch(getResumeQuote({ quoteId: editQuoteId }))) as {
        payload: QuoteResumeResponse;
      };

      const salesJourneyMilestone = parseSalesJourneyMilestone(payload.salesJourneyMilestone);

      if (payload.errorCode) {
        dispatch(updateAddressDescErrorCode(payload.errorCode));
      }
      dispatch(updateIsStepTwoCompleted(payload.isStepTwoComplete));
      if (payload.ebbVerified) {
        dispatch(updateIsEbbFlow(true));
        dispatch(updateIsAutopayEnrolled(payload.isStepThreeComplete ? payload.autoPay : true));
      } else {
        dispatch(updateIsAutopayEnrolled(salesJourneyMilestone.autoPayRequired));
      }
      if (payload.offer) dispatch(updateOfferType(payload.offer))


      dispatch(updateIsCustomerInformatonValidated(true));

      dispatch(updateCommunicationPreferences(payload.communicationPreferences));
      dispatch(updatePortedTelephoneNumber(payload.numberPortability));

      const internetProduct = payload.productOffers.find((offer) => offer.type === 'Broadband');
      const isSecureProductSelected = payload.productOffers.find((offer) => offer.type === 'Secure')?.type;
      if (isSecureProductSelected) {
        dispatch(updateIsSecureOptionsSelected(true))
        dispatch(updateCurrentSecureProductsConfigured(true));
      }
      const nonConfigurableConfigurations = internetProduct?.configurations.find(
        (config) => config.type === 'NonConfigurable'
      );
      const countTotalPaidRouter = nonConfigurableConfigurations?.options.filter(
        (cfg) => cfg.name === 'Router/Extender' && cfg.active
      ).length;

      const scPaidRouters = reduxStore?.appState?.unsortedProductList?.filter((i) => i?.fields?.productGuid?.value === internetProduct?.productId);

      dispatch(updateFreeRouter(scPaidRouters[0]?.fields?.productRouters[0].fields));

      if (countTotalPaidRouter) {
        dispatch(updatePaidRouter(scPaidRouters[0]?.fields?.productRouters[countTotalPaidRouter].fields));
        dispatch(updateResumePaidRoutersCount(countTotalPaidRouter));
      }

      const isEeroSecureAddonActive = payload.productOffers
        .find((item) => item.type === 'Broadband')
        ?.configurations.find((item) => item.type === 'NonConfigurable')
        ?.options.find((item) => item.name === 'eero Secure')?.active;

      if (isEeroSecureAddonActive) {
        dispatch(updateIsEeroSecureSelected(true));
      }

      const scProductObject = reduxStore?.appState?.unsortedProductList?.filter((i) => i?.fields?.productGuid?.value === internetProduct?.productId);
      const scEeroSecureObject = scProductObject[0]?.fields?.addons.find((item) => item?.fields?.title?.value === 'eero Secure + $3.00/mo')?.fields;
      const scWholeHomeObject = scProductObject[0]?.fields?.addons.find((item) => item?.fields?.title?.value === 'Whole-Home Wi-Fi + $10.00/mo')?.fields;

      const hasRouterExtenderConfig = nonConfigurableConfigurations?.options.find(
        (i) => i.id === 'cdfba0fb-c58b-444e-9c47-c20097560561'
      );
      dispatch(updateHasRouterExtenderConfiguration(!!hasRouterExtenderConfig));

      dispatch(updateProductListTechnology(payload.technology));

      // update appstate with necessary fields returned from orders/quote-resume response
      dispatch(updateRecordLocatorNumber(payload.recordLocatorNumber || ''));
      dispatch(updateQuoteId(payload.quoteId));
      dispatch(updateQuoteNumber(payload.quoteNumber));
      dispatch(updateAccountUuid(payload.accountUuid));
      dispatch(
        updatePPCustomerInfo({
          firstName: payload.firstName || '',
          lastName: payload.lastName || '',
          telephoneNumber: payload.mobileTelephone || '',
          email: payload.emailAddress || ''
        })
      );
      dispatch(
        updatePersonalInfo({
          firstName: payload.firstName || '',
          lastName: payload.lastName || '',
          mobileNumber: payload.mobileTelephone || '',
          email: payload.emailAddress || ''
        })
      );
      dispatch(updateAllowEditPreviousAddress(payload.hasAddressDiscrepancy))
      dispatch(updateResumedWithAddressDiscrepancy(payload.hasAddressDiscrepancy))
      // dispatch(updateIsPosIdRequired(!payload.isPosIdCleared))

      const secureProductIds: string[] = [];
      const secureProductAddonIds: string[] = [];
      const secureProductAddonData: any = {};
      const lineItems: OrderSummary['lineItems'] = [];

      // updating installation schedule when customer alredy selected a date

      if (payload.schedule.appointmentStart)
        dispatch(
          updateInstallationTime({
            scheduleId: payload.schedule.scheduleId,
            start: payload.schedule.appointmentStart,
            end: payload.schedule.appointmentEnd,
            expirationDate: payload.schedule.expirationDate || null
          })
        );

      payload.childProducts.forEach((item) => {

        if ((item.description.includes('Expert Installation'))) {
          // TODO: use correct default values for sku, name and description.
          // it will be needed to grab from product offers but there's no id to merge internet product with activation fee

          const oneTimeCharge = item.price === '0.00' ? 'FREE' : parseInt(item.price, 10).toFixed(2).toString();

          const priceData = {
            unit: item.unit,
            value: oneTimeCharge,
            currency: 'USD'
          };
          const activationFeeLineItem = {
            cost: { ...priceData },
            product: {
              sku: '',
              name: '',
              description: '',
              price: { ...priceData },
              type: item.type
            }
          };
          lineItems.push(activationFeeLineItem);
        }
      });

      let voiceSelectionOption = false;
      let isEligibleForCreditCheckSkipped = false;
      payload.productOffers.forEach((item) => {
        lineItems.push({ product: { ...item, sku: item.productId }, cost: item.price });


        if (item.type === 'Broadband' || item.type === 'INTERNET') {
          dispatch(updateSelectedProduct(item.productId));

          if (parseInt(item?.dataspeed?.downloadInKbps, 10) < 1000000) {
            isEligibleForCreditCheckSkipped = payload.productOffers.length < 2; //only skip credit check if no other products are chosen beside < 1gbps internet
          }
          const requiredOptions = item.configurations?.find((config) => config.type === 'RequiredChoice')?.options;
          const etfOption = requiredOptions && requiredOptions.filter(({ active }) => active === false) || [];
          const eeroAddons = item.configurations?.find((config) => config.type === 'NonConfigurable')?.options.filter((eero) => eero?.name === 'eero Secure' && eero?.active)[0]?.id;
          const wholeHomeWifi = item.configurations?.find((config) => config.type === 'NonConfigurable')?.options.filter((whwifi) => whwifi?.id === constants?.wholeHomeWifiId && whwifi?.active)[0]?.id;
          const expertInstallation = requiredOptions?.filter((expertCharges) => expertCharges?.name?.includes("Expert Installation") && expertCharges?.active) || [];

          if (eeroAddons) {
            dispatch(updateEeroSecureAddon(scEeroSecureObject));
            dispatch(updateSelectedEeroSecureAddonId([eeroAddons]));
          }

          if(wholeHomeWifi) {
            dispatch(updateWholeHomeWifiProduct(scWholeHomeObject));
            dispatch(updateWholeHomeWifiProductId([wholeHomeWifi]));
          }

          if (etfOption?.length > 0 && salesJourneyMilestone.autoPayRequired) {
            dispatch(updateActiveBuyFlowProductOption(etfOption));
          } else {
            const sweetener = item.configurations?.find((config) => config.type === 'Sweetener')?.options;
            sweetener && dispatch(updateActiveBuyFlowProductOption(sweetener.filter(opt => opt.active)));
          }
         if(expertInstallation?.length > 0) {
          dispatch(updateIsExpertInstallSelected(true));
          dispatch(updateExpertInstallProduct([expertInstallation[0]]));
          if(expertInstallation[0]?.id === constants?.fullInstallPriceId){
            dispatch(updateFullInstallSelect(true));
          }
          if(expertInstallation[0]?.id === constants?.partialInstallPriceId){
            dispatch(updateFullInstallSelect(false));
            !payload.isStepOneComplete && dispatch(updatePartialInstallSelect(true));
          }
         }
        }

        if (item.type === 'Voice') {
          // override price from response so it appears correctly in order summary
          const value = DIGITAL_VOICE_FULL_PRICE_VALUE.toFixed(2);
          const voiceItemClone = { ...item, price: { ...item.price, value } };
          dispatch(updatedVoiceSelected(voiceItemClone));
          voiceSelectionOption = true;
          dispatch(updateVoiceTermsAccepted(true));

          const voiceDirectory = item.configurations?.find((config) => config.type === 'RequiredChoice');

          if (voiceDirectory) {
            const correctedDLOptionsFromResume = correctDLOptionNameFromResume(voiceDirectory)

            dispatch(updateVoiceDirectorySelection(correctedDLOptionsFromResume));
          }

          if (voiceDirectory) {
            const isPortingNumberSelected = getProductOfferConfigurationById(voiceDirectory.options, PORT_IN_ENTITY_ID);
            const portInTelephoneNumber = getProductOfferConfigurationById(
              voiceDirectory.options,
              PORT_IN_TELEPHONE_NUMBER_ID
            );

            if (portInTelephoneNumber?.value) {
              dispatch(updatePortabilityTelephoneNumber(portInTelephoneNumber?.value));
            }

            if (!!isPortingNumberSelected && isPortingNumberSelected.active)
              dispatch(updateKeepCurrentPhoneNumber(true));
          }
        }

        if (item.type === 'Secure') {
          secureProductAddonData[item.productId] = []
          item.configurations.filter((pc) => pc.type === 'RequiredChoice').forEach((rc) => {
            rc.options.forEach((rcOption) => {
              //Family Add on Id
              if (rcOption.configurationType === 'Entity' && rcOption.id === constants.HomeShieldFamilyAddon) {
                if (rcOption.active) {
                  secureProductAddonIds.push(rcOption.id);
                }
                secureProductAddonData[item.productId].push({
                  "productTitle": {
                    "value": rcOption.name
                  },
                  "productPrice": { "value": rcOption.price },
                  "strikethroughPrice": { "value": "" },
                  "productGuid": {
                    "value": rcOption.id
                  },
                  "productFeatures": []
                })
              }
            })
          })
          secureProductIds.push(item.productId);
        }
        if (item.type === 'Add On' && item.name === 'Printed Bill Fee') {
          dispatch(updateIsPaperlessEnrolled(payload.eBill));
          dispatch(updateSelectedPaperlessProduct({ ...item, active: payload.eBill }));
        } else {
          dispatch(updateIsPaperlessEnrolled(true));
        }
      });

      if (voiceSelectionOption) {
        dispatch(updateVoiceSelectionOption('Yes'));
      } else {
        dispatch(updateVoiceSelectionOption('No'));
      }

      const additionalFeesData: { type: string; cost: PriceType }[] = [...payload.taxes];

      const totalPaidToday = parseFloat(payload?.deposit?.paid.amount || '0');
      if (payload?.deposit?.paid?.depositAmount) {
        additionalFeesData.push({
          type: 'DEPOSIT',
          cost: {
            value: payload?.deposit?.paid?.depositAmount.toFixed(2),
            currency: 'USD',
            unit: 'ONE_TIME'
          }
        });
      }

      if (payload?.backBalanceOwed?.amountDue) {
        additionalFeesData.push({
          type: 'BACKPAY',
          cost: {
            value: parseFloat(payload.backBalanceOwed.amountDue).toFixed(2),
            currency: 'USD',
            unit: 'ONE_TIME'
          }
        });
      } else if (payload?.deposit?.paid?.backBalances) {
        additionalFeesData.push({
          type: 'BACKPAY',
          cost: {
            value: payload?.deposit?.paid?.backBalances.toFixed(2),
            currency: 'USD',
            unit: 'ONE_TIME'
          }
        });
      }

      if (totalPaidToday > 0) {
        // to update quote recap modal
        dispatch(updatePaidToday(totalPaidToday));
      }
      if (payload.autoPay) {
        const billingData = (await dispatch(getBillingAutopayData({ quoteId: editQuoteId })));
        const fundingAccountLastFourDigits =
        {
          method: billingData?.payload?.type,
          digits: billingData?.payload?.lastFourDigits
        };
        dispatch(updateFundingAccountLastFourDigits(fundingAccountLastFourDigits));
      }
      dispatch(updateAvailableSecureProductAddons(secureProductAddonData));
      dispatch(updateSelectedSecureProductIds(secureProductIds));
      dispatch(updateSelectedSecureProductAddonsIds(secureProductAddonIds));


      dispatch(
        updateBuyFlowOrder({
          summary: {
            total: {
              value: lineItems.reduce((acc, curr) => acc + parseFloat(curr.cost.value), 0).toString(),
              currency: 'USD',
              unit: 'MONTHLY'
            },
            lineItems,
            additionalFees: additionalFeesData
          },
          orderId: payload.quoteId,
          paymentUrl: '',
          isCreditCheckSkipped: payload.ebbVerified && isEligibleForCreditCheckSkipped,
          existingBillingTelephoneNumber: '',
          telephoneNumber: payload?.customerTelephoneNumber?.toString()
        })
      );

      if (location.pathname.toLowerCase() === constants.QuickQuoteUrl) {
        // we need to set address and env data on redux if current page is /QuickQuote
        dispatch(
          updateServiceLocation({
            address: `${payload.serviceAddress.number} ${payload.serviceAddress.streetName} ${payload.serviceAddress.streetNameSuffix}`,
            address2: '',
            city: payload.serviceAddress.city,
            state: payload.serviceAddress.state,
            postalCode: (payload.serviceAddress.postalCode).substring(0, 5),
            isBusiness: false,
            samRecords: {
              environment: payload.environment,
              contronlNumber: payload.controlNumber
            }
          })
        );
      }

      const navigateToBuyFlowData = {
        address: `${payload.serviceAddress.number} ${payload.serviceAddress.streetName} ${payload.serviceAddress.streetNameSuffix}`,
        city: payload.serviceAddress.city,
        state: payload.serviceAddress.state,
        environment: payload.environment,
        controlNumber: payload.controlNumber,
        zipCode: payload.serviceAddress.postalCode,
        offer: payload.technology,
        recordLocatorNumber: payload.recordLocatorNumber
      };

      handleNavigateToBuyFlow(navigateToBuyFlowData, editQuoteId);
    } catch (err) {
      console.error(err);
    }
  };

  const handlePreviewQuote = async (quoteId: string): Promise<void> => {
    dispatch(updateLoadingState(true));
    try {
      const result = await dispatch(getResumeQuote({ quoteId }));
      dispatch(updateLoadingState(false));
      if (result.payload) {
        dispatch(updateOrderState(result.payload));
        dispatch(updateOpenQuotePreviewModal(true));
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Handle Cancel Quote,
   * gets current cancellation values and update them
   * fires the openning of the cancellation reason modal
   *
   * @param rowData the quote row data
   */
  const handleCancelQuote = (rowData: QuoteRowField) => {
    try {
      const params = { ...cancellationFields, date: rowData.requestedDate, quoteId: rowData.quoteId };
      dispatch(updateCancellationReasonModalField(params));
      dispatch(updateOpenCancellationReasonModal(true));
    } catch (err) {
      console.error(err);
    }
  };

  const handleActionsClick = (rowData: QuoteRowField, action: string | null): void => {
    if (!action) return;

    switch (action) {
      case 'resumeQuote':
        handleEditQuote(rowData.quoteId);
        break;
      case 'previewQuote':
        handlePreviewQuote(rowData.quoteId);
        break;
      case 'changeInstall':
        break;
      case 'cancel':
        handleCancelQuote(rowData);
        break;
      default:
        return;
    }
  };

  return { handleActionsClick, handleEditQuote };
};

export default useActionsOrders;
