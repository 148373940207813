import React, { useEffect, useState } from 'react';
import RadioButton from '../RadioButton';

interface IRadioGroup {
  /**
   *  The radio group must have share the same name (the value of the name attribute) to be treated as a group.
   */
  groupName: string;
  /**
   *  Expected type Array[string].
   */
  group: any;
  /**
   * return the selected radio selected index as an number param
   */
  onChange?: (options, groupName?: string) => void;

  isDisabled?:boolean;

  includeGroupNameInCallback?: boolean;

  defaultValue?: string;
}

const RadioGroup = ({ group, groupName, onChange, includeGroupNameInCallback, isDisabled, defaultValue }: IRadioGroup) => {
  const initialState = group?.map(() => false)

  const [radioOptions, setRadioOptions] = useState(initialState);

  const handleChange = (n: number) => {
    const newRadioButtonsState = handleChangeCheckedItem(n);

    if (onChange) {
      if(includeGroupNameInCallback) {
        onChange(newRadioButtonsState, groupName);
      } else {
        onChange(newRadioButtonsState);
      }
    }
  };

  const handleChangeCheckedItem = (n: number) => {
    const newRadioButtonsState = radioOptions.map((_, i) => {
      return i === n;
    })
    
    setRadioOptions(newRadioButtonsState);
    return newRadioButtonsState;
  }

  useEffect(() => {
    if(defaultValue) {
      const itemIndex = group.findIndex(item => item.toLowerCase() === defaultValue.toLowerCase())
      handleChangeCheckedItem(itemIndex);
    } 
  }, [defaultValue])

  return group?.map((radio, index) => (
    <RadioButton
      name={groupName}
      checked={radioOptions[index]}
      onChange={() => handleChange(index)}
      key={index}
      disabled={isDisabled===true}
      field={{ value: radio }}
    />
  ));
};

export default RadioGroup;
