import { runSafeSsr } from './browserUtil';

export const getSearchParams = (param?: string) => {
  const searchParams = runSafeSsr(
    () => {
      return {};
    },
    () => {
      const params = {};
      const paramsList = window?.location?.search.replace('?', '').split('&');

      for (const paramItem of paramsList) {
        const entry = paramItem.split('=');
        const key = entry[0];
        const value = entry[1] === 'null' || entry[1] === 'undefined' ? '' : entry[1];

        params[key] = decodeURIComponent(value).replace(/\+/g, ' ');
      }

      return params;
    }
  );

  return param ? searchParams[param] : searchParams;
};
