import { Link } from '@sitecore-jss/sitecore-jss-react';
import { LinkField, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import LoadingSpinner from 'components/LoadingSpinner/index';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBtnClicked } from 'store/appSlice';
import { selectBtnClicked, selectIsFetching } from 'store/selectors';
import styled from 'styled-components';
import { theme as projTheme } from 'styles/theme.js';
import { uid } from 'utils/uidGenerator';

interface ButtonProps {
  className?: string;
  model?: string;
  size?: string;
  children?: any;
  isLink?: boolean;
  disabled?: boolean;
  buttonId?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  field?: LinkField | LinkFieldValue | undefined;
  showLinkTextWithChildrenPresent?: boolean;
  form?: string;
  onClick?: (event?: any) => void;
}

const Button = ({
  className,
  model,
  size,
  children,
  isLink,
  buttonId,
  onClick,
  showLinkTextWithChildrenPresent,
  field,
  ...props
}: ButtonProps) => {
  const isFetching = useSelector(selectIsFetching);
  const btnClicked = useSelector(selectBtnClicked);
  const [buttonIdIsActive, setButtonIdIsActive] = useState(false);
  const [btnID, setBtnID] = useState(buttonId || uid());
  const [linkField, updateLinkField] = React.useState({ ...field });
  useEffect(() => {
    if (!buttonId) {
      return;
    }

    setBtnID(buttonId);
  }, [buttonId]);

  const dispatch = useDispatch();
  useEffect(() => {
    setButtonIdIsActive(btnID === btnClicked);
  }, [btnClicked]);

  useEffect(() => {
    if (!field?.value?.querystring) {
      return updateLinkField({ ...field });
    }

    updateLinkField({
      ...linkField,
      value: {
        ...field.value,
        href: `${field.value.href}?${field.value.querystring}`
      }
    });
  }, [field?.value?.href, field?.value?.querystring]);

  const onButtonClick = (): void => {
    dispatch(updateBtnClicked(btnID));
    onClick && onClick();
  };

  const blurOnFetching = (ref) => {
    if (isFetching && buttonIdIsActive) {
      ref?.blur();
    }
  };

  className += size ? ` btn-${size}` : ' btn-small';
  className += model ? ` btn-${model}` : ' btn-default';
  className += isFetching && buttonIdIsActive ? ` btn-loading-state` : '';
  className += ' ' + field?.value?.class;

  return isLink ? (
    <Link
      {...props}
      onClick={onButtonClick}
      className={`btn ${className}`}
      field={linkField!}
      showLinkTextWithChildrenPresent={false}
    >
      {!!children && <span className="btn-label">{children}</span>}
    </Link>
  ) : (
    <button id={btnID} onClick={onButtonClick} ref={blurOnFetching} className={`btn ${className}`} {...props}>
      <span className="btn-label">{children}</span>
      <LoadingSpinner color={model === 'primary-ghost' ? projTheme.red : null} className={`spinner`} />
    </button>
  );
};

const StyledButton = styled(Button)`
  display: inline-block;
  white-space: nowrap;
  border: none;
  height: auto;
  border-radius: 0.25rem;
  text-align: center;
  object-fit: contain;
  font-weight: bold;
  line-height: 0.9em;
  padding: 10px 15px;
  transition: opacity background-color color 0.25s;
  box-sizing: border-box;
  min-width: 160px;
  position: relative;
  cursor: pointer;

  & > .btn-label {
    display: inline-block;
    & + .spinner {
      display: none;
    }
  }

  &.btn-loading-state {
    .btn-label {
      display: none;
      & + .spinner {
        display: flex;
        height: 90%;
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 0;
  }
  & + .btn {
    margin-left: 10px;
  }
  &.btn-small {
    font-size: 18px;
    min-width: unset;
  }
  &.btn-medium {
    font-size: 20px;
  }
  &.btn-large {
    font-size: 22px;
  }

  &.pp-login-btn {
    line-height: 1.15;
  }

  ${({ theme }) => `
    &.btn-default {
      background-color: transparent;
      border: 1px solid ${theme.silver};
      color: ${theme.gray};
      &:hover {
        &:not(:disabled) {
          background-color: ${theme.gray};
          border: 1px solid ${theme.gray};
          color: ${theme.gre1};
        }
      }
      &:focus {
        &:not(:disabled) {
          background-color: transparent;
          border: 1px solid ${theme.silver};
          color: ${theme.gray};
        }
      }
    }
    &.btn-primary {
      background-color: ${theme.crimson};
      border: 1px solid ${theme.crimson};
      color: ${theme.white};
      &:hover {
        &:not(:disabled) {
          svg {
            fill: ${theme.white};
          }
          .circle-loader {
            span {
              background-color: ${theme.white};
            }
          }
          background-color: ${theme.darkGray};
          border: 1px solid ${theme.darkGray};
          color: ${theme.white};
          .btn-label{
            color: ${theme.white};
          }
        }
      }
      &:focus {
        &:not(:disabled) {
          background-color: ${theme.darkGray};
          border: 1px solid ${theme.darkGray};
          color: ${theme.white};
        }
      }
    }
    &.btn-primary-ghost {
      background-color: transparent;
      border: 1px solid ${theme.silver};
      color: ${theme.crimson};
      &:hover {
        &:not(:disabled) {
          .circle-loader {
            span {
              background-color: ${theme.white};
            }
          }
          background-color: ${theme.crimson};
          border: 1px solid ${theme.crimson};
          color: ${theme.white};
        }
      }
      &:focus {
        &:not(:disabled) {
          background-color: transparent;
          border: 1px solid ${theme.silver};
          color: ${theme.crimson};
        }
      }
    }
    &.btn-secondary {
      background-color: ${theme.secondaryAqua};
      border: 1px solid ${theme.secondaryAqua};
      color: ${theme.darkGray};
      &:hover {
        &:not(:disabled) {
          .circle-loader {
            span {
              background-color: ${theme.white};
            }
          }
          background-color: ${theme.darkGray};
          border: 1px solid ${theme.darkGray};
          color: ${theme.white};
        }
      }
      &:focus {
        &:not(:disabled) {
          background-color: ${theme.darkGray};
          border: 1px solid ${theme.darkGray};
          color: ${theme.white};
        }
      }
    }
    &.btn-secondary-ghost {
      background-color: transparent;
      border: 1px solid ${theme.silver};
      color: ${theme.blue};
      &:hover {
        &:not(:disabled) {
          .circle-loader {
            span {
              background-color: ${theme.white};
            }
          }
          background-color: ${theme.blue};
          border: 1px solid ${theme.blue};
          color: ${theme.white};
        }
      }
      &:focus {
        &:not(:disabled) {
          background-color: transparent;
          border: 1px solid ${theme.silver};
          color: ${theme.blue};
        }
      }
    }
    &.btn-link {
      background-color: transparent;
      border: none;
      padding: 0;
      color: ${theme.mineShaft};

      .btn-label {
        font-weight: 600;
        text-decoration: underline;
        text-underline-offset: 2px;
      }

      &.btn-loading-state {
        padding: 0;

        .btn-label {
          display: inline-block;
          vertical-align: bottom;

          & + .spinner {
            display: none;
          }
        }
      }

      &:hover {
        &:not(:disabled) {
          text-decoration: none;
        }
      }
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
    &.btn-loading-state {
      padding: 0.1875rem;
      pointer-events: none;
    }
  `}

  ${({ theme }) => theme.tabletPortrait`
    padding: 10px 24px;
    &.btn-default {
      &:hover {
        .circle-loader {
          span {
            color: ${theme.gre1};
          }
        }
      }
    }
    &.btn-primary {
      &:focus {
        &:not(:disabled) {
          background-color: ${theme.white};
          border: 1px solid ${theme.silver};
          color: ${theme.crimson};
        }
      }
    }
    &.btn-primary-ghost {
      &:hover {
        .circle-loader {
          span {
            background-color: ${theme.white};
          }
        }
      }
    }
    &.btn-secondary {
      &:hover {
        .circle-loader {
          span {
            background-color: ${theme.blue};
          }
        }
      }
    }
    &.btn-secondary-ghost {
      &:hover {
        .circle-loader {
          span {
            background-color: ${theme.white};
          }
        }
      }
    }
  `}

  ${({ theme }) => theme.tabletLandscape`
    &.btn-primary {
      &:hover {
        &:not(:disabled) {
          .circle-loader {
            span {
              background-color: ${theme.white};
            }
          }
          background-color: ${theme.crimson};
          border: 1px solid ${theme.crimson};
          color: ${theme.white};
        }
      }
    }
  `}
`;

export default StyledButton;
