import { loadConfig } from 'config/config';

const config = loadConfig();

export const serviceabilityApiConfig = {
  headers: {
    /** comment out this object to use w mockoon */

    apikey: config.serviceabilityApiKey
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  },
  baseURL: config.serviceabilityApiUrl
};

export const frapiCustomerAcquisitionApiConfig = {
  headers: {
    // /** comment out this object to use w mockoon */
    apikey: config.frapiCustomerAcquisitionApiKey,
    'requesting-application': 'PartnerPortal'
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  },
  baseURL: config.frapiCustomerAcquisitionApiUrl
};
