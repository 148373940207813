import React from 'react';
import styled from 'styled-components';
import { selectEmailAlreadyEnrolledError } from '../../store/selectors/sharedSelectors';
import { useSelector } from 'react-redux';
const AutoPayEmailAlreadyEnrollmentErrorModal = styled.div`
  display: flex;
  flex-direction: column;
  .email-enrolled-error-title {
    font-family: PPObjectSans;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #333;
  }
  .email-enrolled-error-content {
    font-family: PPObjectSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    margin: 16px 0 32px;
    color: #333;
  }
`;
const EmailAlreadyEnrolledErrorModal = () => {
  const emailAlreadyEnrolledError = useSelector(selectEmailAlreadyEnrolledError);
  
  return (
    <AutoPayEmailAlreadyEnrollmentErrorModal>
      <span className="email-enrolled-error-title">{emailAlreadyEnrolledError.modalTitle}</span>
      <span className="email-enrolled-error-content">{emailAlreadyEnrolledError.modalText}</span>
    </AutoPayEmailAlreadyEnrollmentErrorModal>
  );
};
export default EmailAlreadyEnrolledErrorModal;
