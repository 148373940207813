import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTopWrapper = (props) => {
  useEffect(() => {
    if (window && !window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, [props.location]);
  return <>{props.children}</>;
};

export default withRouter(ScrollToTopWrapper);
