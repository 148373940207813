import * as axios from 'axios';
import modalIds from 'constants/modalIds';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
/* eslint-disable no-underscore-dangle */
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { updateIsFetching, updateIsAddressDiscrepancyModalOpen, updateRetainSSN } from 'store/appSlice';
import { ThemeProvider } from 'styled-components';
import AppRoot from './AppRoot';
import { loadClientSideSitecoreConfig } from './config/sitecore-context-config';
import apiErrors from './constants/apiErrors';
import { default as ebbErrors } from './constants/ebbErrors';
// import i18ninit from './i18n';
import './index.scss';
import { initializeInvoca } from './invoca/invoca-initializer';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import { setServerSideRenderingState } from './RouteHandler';
import store from './store';
import {
  updateBtnClicked,
  updateErrorModal,
  updateIsPosIdModalOpen,
  updateOpenModal,
  updateIsCalendarErrorOpen,
  updateOpenEmergencyNumberServiceDisclosureModal,
  updateIsPosIdRequired,
  updateHasCreditFreeze
} from './store/appSlice';
import { media, theme } from './styles/theme';
import config from './temp/config';

let renderFunction = ReactDOM.render;

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // push the initial SSR state into the route handler, where it will be used
  setServerSideRenderingState(__JSS_STATE__);

  // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
  renderFunction = ReactDOM.hydrate;

  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;

  const siteCoreConfig = loadClientSideSitecoreConfig(__JSS_STATE__.sitecore.context);

  initializeInvoca(siteCoreConfig.invocaTag);
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState = __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : null;

const graphQLClient = GraphQLClientFactory(config.graphQLEndpoint, false, initialGraphQLState);

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
//
// BRF-150 :REMOVED To improve JSS performance
/*
i18ninit(initLanguage)
  .then(() => Loadable.preloadReady())
  .then(() => {
    // HTML element to place the app into
    const rootElement = document.getElementById('root');

    renderFunction(
      <Provider store={store}>
        <ThemeProvider theme={{ ...theme, ...media }}>
          <AppRoot path={window.location.pathname} Router={BrowserRouter} graphQLClient={graphQLClient} />{' '}
        </ThemeProvider>
      </Provider>,
      rootElement
    );
  });
*/

Loadable.preloadReady().then(() => {
  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  renderFunction(
    <Provider store={store}>
      <ThemeProvider theme={{ ...theme, ...media }}>
        <AppRoot path={window.location.pathname} Router={BrowserRouter} graphQLClient={graphQLClient} />{' '}
      </ThemeProvider>
    </Provider>,
    rootElement
  );
});

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

// TODO: add any pre-request & post-response proc's we need to do
axios.interceptors.request.use(
  (req) => {
    store.dispatch(updateIsFetching(true));
    return req;
  },
  (error) => {
    store.dispatch(updateIsFetching(false));
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    store.dispatch(updateIsFetching(false));
    if (response.status === 504) {
      console.log('GATEWAY TIMEOUT RESPONSE RECEIVED:');
      console.log(response);
    }

    return response;
  },
  (error) => {
    store.dispatch(updateIsFetching(false));

    // Determine if CC001 - Credit Check Fail error code - launch POSID
    if (!!error?.response?.data?.errorCode && error?.response?.data?.errorCode === 'CC001') {
      //store.dispatch(updateIsPosIdRequired(true));
      store.dispatch(updateIsPosIdModalOpen(true));
      return Promise.reject(
        !!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error
      );
    }
    // Determine if CC002 - Credit Check Fail error code - Credit Freeze
    if (!!error?.response?.data?.errorCode && error?.response?.data?.errorCode === 'CC002') {
      //store.dispatch(updateHasCreditFreeze(true));
      store.dispatch(updateOpenModal(modalIds.creditFreezeModal))
      return Promise.reject(
        !!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error
      );
    }

    // Determine if CC003 - Credit Check Fail error code - Address Discrepancy
    if (!!error?.response?.data?.errorCode && error?.response?.data?.errorCode === 'CC003') {
      store.dispatch(updateRetainSSN(true));
      store.dispatch(updateIsAddressDiscrepancyModalOpen(true));
      return Promise.reject(
        !!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error
      );
    }

    // Determine error code and set it up for the modal
    if (!!error?.response?.data?.errorCode && apiErrors.apiErrorCodes.hasOwnProperty(error.response.data.errorCode)) {
      // Hide PosId if error show up
      store.dispatch(updateIsPosIdModalOpen(false));
      const errData = error.response.data;
      store.dispatch(updateErrorModal(errData.errorCode));
    } else {
      store.dispatch(updateErrorModal('standard'));
    }

    // Determine if error code is from EBB and skip it
    if (
      !!error?.response?.data?.errorCode &&
      ebbErrors.ebbNoEligibleErrorCodes.hasOwnProperty(error.response.data.errorCode)
    ) {
      return Promise.reject(
        !!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error
      );
    }
    if (!!error?.response?.data?.errorCode && ebbErrors.ebbErrorCodes.hasOwnProperty(error.response.data.errorCode)) {
      return Promise.reject(
        !!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error
      );
    }

    // Determine if CF012 - Frapi Error code when Appointment has expired - show error box
    if (!!error?.response?.data?.errorCode && error?.response?.data?.errorCode === 'CF012') {
      store.dispatch(updateIsCalendarErrorOpen(true));
      // close 911 modal if open
      store.dispatch(updateOpenEmergencyNumberServiceDisclosureModal(false));
      return Promise.reject(
        !!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error
      );
    }

    // Only play the error modal if the error does not have special treatment
    if (!apiErrors.apiErrorCodesWithCustomBehavior.hasOwnProperty(error?.response?.data?.errorCode)) {
      store.dispatch(updateOpenModal(modalIds.apiErrorModal));
    } else if (apiErrors.apiErrorCodesWithCustomBehavior.TO001 === error?.response?.data?.errorCode) {
      store.dispatch(updateOpenModal(modalIds.timeOutModal));
    }
    if (!!error?.response?.data?.errorCode && error?.response?.data?.errorCode === 'CF008') {
      return {
        errorCode: error?.response?.data?.errorCode
      };
    }
    return Promise.reject(!!error?.response?.data?.errorCode ? new Error(JSON.stringify(error.response.data)) : error);
  }
);
