import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceabilityRequestType } from 'models/AddressFormTypes';
import { ApiProductType } from 'models/Enums';
import { AppApi } from 'store/api/appApi';
import { FrapiAppApi } from 'store/api/FrapiAppApi';
import { creditCheck } from 'store/api/orderApi';
import { CreditCheck } from 'store/api/orderApiTypes';
import { updateGmtOffset, updateServiceLocation } from 'store/appSlice';
import { AppState, BuyFlowProduct, FiberProduct, OrderType, ServiceLocation } from 'store/state.model';
import { getSearchParams } from 'utils/getSearchParams';
import { getUsername } from 'utils/partnerPortalUserStorage';
import { createCustomer } from '../slices/OrderQuote/orderQuoteThunks';

const getAdditionalInstallSchedules = async (result, state) => {
  const afterTimestamp =
    result.data?.installationSchedule[result.data?.installationSchedule.length - 1].windows[0].start;

  const installationScheduleParams = {
    orderId: result.data.orderId,
    queryString: `?afterTimestamp=${afterTimestamp}&limit=14`
  };
  const installationResult = await AppApi.getInstallationSchedule(
    {
      ...installationScheduleParams
    },
    state.cacheId
  );

  const orderAndTimes = {
    ...result.data,
    installationSchedule: result.data.installationSchedule.concat(installationResult.data.installationSchedule),
    lastDateTimeStamp: installationResult.data.lastDateTimeStamp
  };

  return orderAndTimes;
};

export const getBuyFlowServiceability = createAsyncThunk(
  'app/getAvailableBuyflowProducts',
  async (
    payload: {
      history;
      request: ServiceabilityRequestType;
      leadId: string;
      technology: string;
    },
    { dispatch, getState }
  ) => {
    const { request, leadId, technology } = payload;
    const newState: any = getState();
    const state: AppState = newState.appState;
    const trafficSource = state.trafficSource;
    const leadAuditId = state.leadAuditId;
    const requestTechnology = !!state.serviceability?.iONT ? 'iont' : technology;
    const username = getUsername();
    const ppAditionalHeaders = state.ppAditionalHeaders;
    const result = await AppApi.getAvailableBuyflowProducts({
      request,
      leadId,
      leadAuditId,
      trafficSource,
      technology: requestTechnology,
      username,
      ppAditionalHeaders
    });
    dispatch(updateServiceLocation(request));
    /**
     * updated to access product array from response
     * may require change later
     */
    return result;
  }
);
export const getChaseIframeUrl = createAsyncThunk('app/getChaseIframeUrl', async (_, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;
  const cacheId = state.cacheId;
  const result = await AppApi.getChaseIframeUrl(cacheId);
  return result.data;
});

export const getFiserveIframeUrl = createAsyncThunk('app/getFiserveIframeUrl', async (_, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;
  const accountUuid = state.accountUuid || newState.orderQuote.accountUuid;
  const result = await AppApi.getFiserveIframeUrl(accountUuid);
  return result.data;
});

export const cancelOrder = createAsyncThunk('app/cancelOrder', async (payload: { orderId }, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;
  const result = await AppApi.buyFlowOrdersInterface({
    params: null,
    orderId: payload.orderId,
    type: 'cancel',
    cacheId: state.cacheId
  });
  return result.data;
});

// ! Used by OrderSummary. Needs refactor with new UI & API
export const submitOrder = createAsyncThunk('app/submitOrder', async (_, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;

  const order = {
    tosAccepted: state.termsAndConditionsAccepted,
    ...(state.voipConditionsAccepted && { emergencyLimitationsAcknowledged: state.voipConditionsAccepted })
  };

  const result = await AppApi.submitOrder(order, state.buyFlowOrder.orderId, state.cacheId);
  return result.data;
});

export const confirmBuyFlowOrder = createAsyncThunk(
  'app/confirmBuyFlowOrder',
  async (payload: { orderId; type: 'confirmation'; params: { additionalOrderDetails } }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;

    const result = await AppApi.buyFlowOrdersInterface({ ...payload, cacheId: state.cacheId });
    return result.data;
  }
);

export const reserveBuyFlowInstallationTime = createAsyncThunk(
  'app/reserveBuyFlowInstallationTime',
  async (payload: { orderId; type: 'reservation'; params: { startTime; endTime } }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await AppApi.buyFlowOrdersInterface({ ...payload, cacheId: state.cacheId });
    return result.data;
  }
);
export const submitBuyFlowPayment = createAsyncThunk(
  'app/submitBuyFlowPayment',
  async (
    payload: {
      orderId;
      type: 'payment';
      params: {
        type: 'CREDIT_CARD' /** hard code CREDIT_CARD */;
        tokenizedPayment /** customer ref number */;
        autopayEnabled /** false */;
        paperlessBillingEnabled /** false */;
        alternateBillingAddress: ServiceLocation | null /** null for now */;
      };
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await AppApi.buyFlowOrdersInterface({ ...payload, cacheId: state.cacheId });
    return result.data;
  }
);

export const submitBuyFlowAutoPayment = createAsyncThunk(
  'app/submitBuyFlowAutoPayment',
  async (
    payload: {
      orderId;
      type: 'autopay';
      params: {
        fundingAccountToken;
      };
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await AppApi.buyFlowOrdersInterface({ ...payload, cacheId: state.cacheId });
    return result.data;
  }
);

export const submitBuyFlowAutoPaymentFrapi = createAsyncThunk(
  'app/submitBuyFlowAutoPaymentFrapi',
  async (
    payload: {
      type: 'autopay' | 'paperless';
      params: {
        fundingAccountToken?: string;
        emailAddress?: string;
        enrollInPaperless?: boolean;
        isPrimaryEmailAddress?: boolean;
      };
      requestingApplication?: string;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowBillingInterfaceFrapi({
      ...payload,
      ...(payload.type === 'paperless' && { type: `paperless?enrollInPaperless=${payload.params.enrollInPaperless}` }),
      quoteId: state.quoteId
    });
    return result.data;
  }
);

export const getInstallationSchedulesFrapi = createAsyncThunk(
  'app/getInstallationSchedulesFrapi',
  async (
    payload: {
      type: 'installation-schedule';
      gmtOffset?: number;
      observeDST?: boolean;
      startDate?: string;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.getInstallationSchedulesFrapi({
      ...payload,
      quoteId: state.quoteId
    });
    return result.data;
  }
);

export const cancelInstallationSchedulesFrapi = createAsyncThunk(
  'app/cancelInstallationSchedulesFrapi',
  async (
    payload: {
      type: 'installation-schedule';
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.cancelInstallationSchedulesFrapi({
      ...payload,
      quoteId: state.quoteId
    });
    return result.data;
  }
);

export const createBuyFlowOrder = createAsyncThunk(
  'app/createBuyFlowOrder',
  async (
    payload: {
      order: {
        firstName;
        lastName;
        mobileNumber;
        email;
        dob;
        skus;
        serviceLocation: ServiceLocation;
        directoryListing;
        ssn;
        driversLicenseNumber;
        state;
        cacheId: string;
      };
      installationScheduleLimit?: number;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;

    const result = await AppApi.createBuyFlowOrder(payload, state.cacheId);

    if (result?.data?.installationSchedule && result?.data?.installationSchedule?.length > 0) {
      const combinedResult = await getAdditionalInstallSchedules(result, state);
      return combinedResult;
    } else {
      return result.data;
    }
  }
);

export const securityQuestions = createAsyncThunk(
  'app/securityQuestions',
  async (
    payload: {
      securityQuestionList;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;

    const result = await AppApi.securityQuestions(payload, state.cacheId, state.quoteId);

    if (result?.data?.installationSchedule && result?.data?.installationSchedule?.length > 0) {
      const combinedResult = await getAdditionalInstallSchedules(result, state);
      return combinedResult;
    } else {
      return { ...result.data, statusCode: result.status, orderId: state.quoteId };
    }
  }
);
/**
 * Takes a list of single play products & double play products
 * sort them into associated array
 * calculate the price differences, assumes products are given in relevant order
 * @param products list of buyflow products
 * @returns an object with price differences between each product
 */
const getProductPriceDiff = (products: BuyFlowProduct[]) => {
  const singlePlayProducts: BuyFlowProduct[] = [];
  const doublePlayProducts: BuyFlowProduct[] = [];
  const differences = {};

  // seperate products into array
  products.forEach((p) => {
    const type = p.name.slice(0, 2);
    if (type === ApiProductType.SinglePlay) {
      singlePlayProducts.push(p);
    } else if (type === ApiProductType.DoublePLay) {
      doublePlayProducts.push(p);
    }
  });
  // find price differences between products
  const length = singlePlayProducts.length;
  for (let i = 0; i < length; i++) {
    differences[i] = (
      Number(doublePlayProducts[i]?.price?.value) - Number(singlePlayProducts[i]?.price?.value)
    )?.toFixed(2);
  }
  return differences;
};

export const getMoreInstallationTimes = createAsyncThunk(
  'app/getMoreInstallationTimes',
  async (
    payload: {
      orderId: string;
      lastDateTimeStamp: string;
      limit: number;
    },
    { getState }
  ) => {
    const alteredPayload = {
      orderId: payload.orderId,
      type: `installation-schedule?afterTimestamp=${payload.lastDateTimeStamp}&limit=${payload.limit}`
    };
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await AppApi.buyFlowOrdersInterface({ ...alteredPayload, cacheId: state.cacheId });
  }
);

export const updateBuyFlowProspect = createAsyncThunk(
  'prospect/updateBuyFlowProspect',
  async (payload: { campaign; prospectInfo }, {}) => {
    const result = await AppApi.updateBuyFlowProspect({
      campaign: payload.campaign,
      prospectInfo: payload.prospectInfo
    });

    return { response: result.data };
  }
);

export const getAvailableProductsFrapi = createAsyncThunk(
  'app/getAvailableProductsFrapi',
  async (payload: { address: ServiceLocation; technology: string }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const { technology, address } = payload;
    const requestTechnology = !!state.serviceability?.iONT ? 'iont' : technology;
    const env = state.serviceability?.environment;
    const controlNumber = state.serviceability?.controlNumber;

    const result = await FrapiAppApi.getAvailableProductsFrapi({
      address,
      technology: requestTechnology,
      leadAuditId: state.leadAuditId,
      env,
      controlNumber
    });
    const data = result.data.length > 0 ? (result.data as FiberProduct[]) : null;
    return data;
  }
);

export const getAvailableProductsFrapiLowIncome = createAsyncThunk(
  'app/getAvailableProductsFrapiLowIncome',
  async (payload: { technology: string }, { dispatch, getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const { technology } = payload;
    const requestTechnology = !!state.serviceability?.iONT ? 'iont' : technology;
    const env = state.serviceability?.environment;
    const controlNumber = state.serviceability?.controlNumber;
    const trafficSource = state.trafficSource;
    const leadAuditId = state.leadAuditId;
    const quoteId = state?.quoteId;
    const isResumeQuote = getSearchParams('quoteId') || '';
    const result = await FrapiAppApi.getAvailableBuyflowProductsFrapi({
      leadAuditId,
      trafficSource,
      technology: requestTechnology,
      env,
      controlNumber,
      quoteId,
      isLowIncome: true,
      isLowIncomeResume: isResumeQuote
    });

    const gmtOffset = result.data.gmtOffset;
    if (typeof gmtOffset === 'number') {
      dispatch(updateGmtOffset({ value: gmtOffset, manualEntry: false }));
    }

    return result;
  }
);

/* FRAPI SERVICEABILITY AVAILABLE PRODUCTS */
export const getBuyFlowServiceabilityFrapi = createAsyncThunk(
  'app/getAvailableBuyflowProductsFrapi',
  async (
    payload: {
      history;
      request: ServiceabilityRequestType;
      leadId: string;
      technology: string;
      env: string;
      controlNumber: string;
      quoteId?: string;
    },
    { dispatch, getState }
  ) => {
    const { request, leadId, technology, env, controlNumber, quoteId } = payload;
    const newState: any = getState();
    const state: AppState = newState.appState;
    const trafficSource = state.trafficSource;
    const leadAuditId = state.leadAuditId;
    const envForCall = !!env ? env : state.serviceability?.environment;
    const controlNumberForCall = !!controlNumber ? controlNumber : state.serviceability?.controlNumber;
    const isEBBEligible = state?.isEligibleEBBData;
    const isLowIncome = !!isEBBEligible && isEBBEligible?.isEligible === true ? true : false;
    const requestTechnology = !!state.serviceability?.iONT ? 'FIBER' : technology;

    const result = await FrapiAppApi.getAvailableBuyflowProductsFrapi({
      request,
      leadId,
      leadAuditId,
      trafficSource,
      technology: requestTechnology,
      env: envForCall,
      controlNumber: controlNumberForCall,
      isLowIncome,
      ...(quoteId && { quoteId })
    });
    dispatch(updateServiceLocation(request));
    /**
     * updated to access product array from response
     * may require change later
     */

    const gmtOffset = result.data.gmtOffset;
    if (typeof gmtOffset === 'number') {
      dispatch(updateGmtOffset({ value: gmtOffset, manualEntry: false }));
    }

    return result.data;
  }
);

/* FRAPI CHASE IFRAME */
export const getChaseIframeUrlFrapi = createAsyncThunk('app/getChaseIframeUrlFrapi', async (_, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;
  const accountUuid = state.accountUuid || newState.orderQuote.accountUuid;
  const result = await FrapiAppApi.getChaseIframeUrlFrapi(accountUuid);
  return result.data;
});

/* FRAPI CANCEL QUOTE IFRAME */
export const cancelOrderFrapi = createAsyncThunk('app/cancelOrderFrapi', async ({}, { getState }) => {
  // REVIEW
  const newState: any = getState();
  const state: AppState = newState.appState;
  const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({
    params: null,
    type: 'cancel',
    quoteId: state.quoteId // Change to quoteId
  });
  return result.data;
});

export const submitOrderFrapi = createAsyncThunk('app/submitOrderFrapi', async (_, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;

  const order = {
    tosAccepted: state.termsAndConditionsAccepted,
    ...(state.voipConditionsAccepted && { emergencyLimitationsAcknowledged: state.voipConditionsAccepted })
  };

  const result = await FrapiAppApi.submitOrderFrapi(order, state.quoteId);
  return result.data;
});

export const confirmBuyFlowOrderFrapi = createAsyncThunk(
  'app/confirmBuyFlowOrderFrapi',
  async (payload: { type: 'confirmation' }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({ ...payload, quoteId: state.quoteId });
    return result.data;
  }
);

export const reserveBuyFlowInstallationTimeFrapi = createAsyncThunk(
  'app/reserveBuyFlowInstallationTimeFrapi',
  async (payload: { type: 'reservation'; params: { startTime; endTime } }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({ ...payload, quoteId: state.quoteId });
    return result.data;
  }
);

export const submitBuyFlowPaymentFrapi = createAsyncThunk(
  'app/submitBuyFlowPaymentFrapi',
  async (
    payload: {
      type: 'payment';
      params: {
        firstName?;
        lastName?;
        total;
        accountId?;
        unpaidBalance?;
        depositFee?;
        paymentMethod;
        fundingAccountToken;
      };
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({ ...payload, quoteId: state.quoteId });
    return result;
  }
);

export const createBuyFlowOrderFrapi = createAsyncThunk(
  'app/createBuyFlowOrderFrapi',
  async (
    payload: {
      order: {
        firstName;
        lastName;
        mobileNumber;
        email;
        dob;
        productIds;
        serviceLocation: ServiceLocation;
        directoryListing;
        ssn;
        driversLicenseNumber;
        driversLicenseState;
        cacheId: string;
      };
      installationScheduleLimit?: number;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const quoteId = state.quoteId;

    const result = await FrapiAppApi.createBuyFlowOrderFrapi(payload, quoteId);

    return result.data;
  }
);

export const updateBuyFlowProspectFrapi = createAsyncThunk(
  'prospect/updateBuyFlowProspectFrapi',
  async (payload: { campaign; prospectInfo }, {}) => {
    const result = await FrapiAppApi.updateBuyFlowProspect({
      campaign: payload.campaign,
      prospectInfo: payload.prospectInfo
    });

    return { response: result.data };
  }
);

export const startCreditCheck = createAsyncThunk('app/startCreditCheck', async (payload: CreditCheck, _) => {
  const result = await creditCheck(payload);
  return result.data;
});

export const previousAddressVerify = createAsyncThunk(
  'prospect/previousAddressVerify',
  async (payload: { previousAddress: ServiceLocation }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const accountUuid = state.accountUuid || newState.orderQuote.accountUuid;
    const result = await FrapiAppApi.previousAddressVerify({
      accountUuid: accountUuid,
      previousAddress: payload.previousAddress,
      quoteId: state.quoteId,
      isUnknownAddress : state.addressUnKnownField
    });

    return { response: result.data };
  }
);

export const getCreditCheckStatus = createAsyncThunk(
  'app/getCreditCheckStatus',
  async (payload: { quoteId: string }, { getState }) => {
    const { quoteId } = payload;
    const newState: any = getState();
    const state: AppState = newState.appState;
    const currentQuoteId = quoteId || state.quoteId;

    const result = await FrapiAppApi.getCreditCheckStatus(currentQuoteId);

    return result.data;
  }
);

export const buyFlowHandlers = (builder: ActionReducerMapBuilder<AppState>) => {
  return builder
    .addCase(getMoreInstallationTimes.fulfilled, (state, { payload }: any) => {
      state.installationSchedule = payload.installationSchedule;
      state.lastDateTimeStamp = payload.lastDateTimeStamp;
    })
    .addCase(getMoreInstallationTimes.rejected, (_state, { error }) => {
      console.error('getMoreInstallationTimes FAILED', error);
    })
    .addCase(previousAddressVerify.rejected, (state, { error }) => {
      console.error('previousAddressVerify FAILED', error);
      state.hasAddressDiscrepancy = true;
    })
    .addCase(getBuyFlowServiceability.fulfilled, (state, response) => {
      /**
       * updated to access product array from response
       * may require change later
       */
      const {
        data: { availableProducts: products, cacheId }
      } = response.payload;
      state.cacheId = cacheId;
      state.buyFlowProducts = products;
      state.voicePrice = getProductPriceDiff(products)[0];
    })
    .addCase(getBuyFlowServiceability.rejected, (_state, { error }) => {
      console.error('getBuyFlowServiceability FAILED', error);
    })
    .addCase(getAvailableProductsFrapiLowIncome.fulfilled, (state, response) => {
      /**
       * updated to access product array from response
       * may require change later
       */
      const {
        data: { availableProducts: products, cacheId }
      } = response.payload;
      state.cacheId = cacheId;
      const isResumeQuote = getSearchParams('quoteId') || '';
      if (isResumeQuote) {
        state.hasActiveProdIdChanged = state.activeBuyFlowInternetProduct;
      }
      state.activeBuyFlowInternetProduct = '';
      state.isEligibleEBBData = { isEligible: true, showBanner: true, ebbBannerErrorCode: undefined };
      state.buyFlowProducts = products.map((product: any) => {
        return { ...product, type: product.type.toUpperCase() };
      });
      const voiceProduct: any = products.find((p) => p.type.toUpperCase() === 'VOICE');
      state.voicePrice = !!voiceProduct?.price?.value ? voiceProduct?.price?.value : 25.0;
    })
    .addCase(getChaseIframeUrl.fulfilled, (state, { payload }) => {
      state.buyFlowChaseIframeUrl = payload.paymentUrl;
    })
    .addCase(getChaseIframeUrl.rejected, (_state, { error }) => {
      console.error('getChaseIframeUrl FAILED', error);
    })
    .addCase(getFiserveIframeUrl.fulfilled, (state, { payload }) => {
      const {
        paymentUrl: { checkdetailsurl, carddetailsurl }
      } = payload;
      state.buyFlowFiserve = {
        paymentUrlCard: carddetailsurl,
        paymentUrlACH: checkdetailsurl
      };
    })
    .addCase(getFiserveIframeUrl.rejected, (_state, { error }) => {
      console.error('getFiserveIframeUrl FAILED', error);
    })
    .addCase(confirmBuyFlowOrder.fulfilled, (state, { payload }) => {
      state.confirmationNumber = payload.confirmationNumber;
    })
    .addCase(confirmBuyFlowOrder.rejected, (_state, { error }) => {
      console.error('Could not confirm order', error);
    })
    .addCase(reserveBuyFlowInstallationTime.fulfilled, (state, { payload }) => {
      state.buyFlowOrderSummary = payload;
    })
    .addCase(reserveBuyFlowInstallationTime.rejected, (_state, { error }) => {
      console.error('Could not reserve installation time', error);
    })
    .addCase(submitBuyFlowPayment.fulfilled, (state, { payload }) => {
      state.installationTimes = payload;
    })
    .addCase(submitBuyFlowPayment.rejected, (_state, { error }) => {
      console.error('Could not process payment', error);
    })
    .addCase(createBuyFlowOrder.fulfilled, (state, { payload }) => {
      const {
        orderId,
        installationSchedule,
        summary,
        existingBillingTelephoneNumber,
        paymentUrl,
        telephoneNumber
      } = payload;
      state.buyFlowOrder = { orderId, summary, paymentUrl: '', existingBillingTelephoneNumber, telephoneNumber };
      if (payload.installationSchedule) {
        state.installationSchedule = payload.installationSchedule;
      }
    })
    .addCase(createBuyFlowOrder.rejected, (_state, { error }) => {
      console.error('Could not create the order', error);
    })

    .addCase(securityQuestions.fulfilled, (state, { payload }) => {
      const {
        orderId,
        installationSchedule,
        summary,
        existingBillingTelephoneNumber,
        paymentUrl,
        telephoneNumber
      } = payload;
      state.buyFlowOrder = { orderId, summary, paymentUrl: '', existingBillingTelephoneNumber, telephoneNumber };
      if (payload.installationSchedule) {
        state.installationSchedule = payload.installationSchedule;
      }
    })
    .addCase(securityQuestions.rejected, (_state, { error }) => {
      console.error('Could not create the order', error);
    })

    .addCase(submitOrder.fulfilled, (state, { payload }) => {
      state.confirmationNumber = payload.confirmationNumber;
    })
    .addCase(submitOrder.rejected, (_state, { error }) => {
      console.error('Could not process order', error);
    })
    .addCase(cancelOrder.fulfilled, (state: AppState) => {
      state.buyFlowOrder = {} as OrderType;
      state.buyFlowProducts = [];
    })
    .addCase(cancelOrder.rejected, (_state, { error }) => {
      console.error('Could not cancel the order', error);
    })
    .addCase(getBuyFlowServiceabilityFrapi.fulfilled, (state, response) => {
      /**
       * updated to access product array from response
       * may require change later
       */

      const { availableProducts: availableProducts, quoteId, quoteNumber, accountUuid } = response.payload;
      const voiceProduct = availableProducts.find((p) => p.type.toUpperCase() === 'VOICE');
      if (!!accountUuid) state.accountUuid = accountUuid;
      if (!!quoteId) state.cacheId = quoteId;
      if (!!quoteId) state.quoteId = quoteId;
      if (!!quoteNumber) state.quoteNumber = quoteNumber;
      state.voicePrice = !!voiceProduct?.price?.value ? voiceProduct?.price?.value : 25.0;
      state.buyFlowProducts = availableProducts.map((item) => {
        return { ...item, type: item.type.toUpperCase() };
      });
    })
    .addCase(getBuyFlowServiceabilityFrapi.rejected, (_state, { error }) => {
      console.error('getBuyFlowServiceabilityFrapi FAILED', error);
    })
    .addCase(getChaseIframeUrlFrapi.fulfilled, (state, { payload }) => {
      state.buyFlowChaseIframeUrl = payload.paymentUrl;
    })
    .addCase(getChaseIframeUrlFrapi.rejected, (_state, { error }) => {
      console.error('FRAPI getChaseIframeUrl FAILED', error);
    })
    .addCase(confirmBuyFlowOrderFrapi.fulfilled, (state, { payload }) => {
      state.confirmationNumber = payload.confirmationNumber;
    })
    .addCase(confirmBuyFlowOrderFrapi.rejected, (_state, { error }) => {
      console.error('FRAPI Could not confirm order', error);
    })
    .addCase(submitBuyFlowPaymentFrapi.rejected, (_state, result) => {
      console.error('Could not process payment', result.error);
    })
    .addCase(createBuyFlowOrderFrapi.fulfilled, (state, { payload }) => {
      const {
        orderId,
        installationSchedule,
        summary,
        existingBillingTelephoneNumber,
        paymentUrl,
        telephoneNumber
      } = payload;
      const bfOrderId = !!orderId ? orderId : 'frapi'; // need to figure out orderId
      state.buyFlowOrder = {
        orderId: bfOrderId,
        summary,
        paymentUrl: '',
        existingBillingTelephoneNumber,
        telephoneNumber
      };

      if (payload.installationSchedule) {
        state.installationSchedule = payload.installationSchedule;
      }
    })
    .addCase(submitOrderFrapi.fulfilled, (state, { payload }) => {
      state.confirmationNumber = payload.confirmationNumber;
    })
    .addCase(submitOrderFrapi.rejected, (_state, { error }) => {
      console.error('Could not process order', error);
    })
    .addCase(cancelOrderFrapi.fulfilled, (state: AppState) => {
      state.buyFlowOrder = {} as OrderType;
      state.buyFlowProducts = [];
    })
    .addCase(cancelOrderFrapi.rejected, (_state, { error }) => {
      console.error('Could not cancel the order', error);
    })
    .addCase(createCustomer.fulfilled, (state: AppState, data) => {
      const newbuyFlowOrder = {
        summary: data.payload.response.summary,
        existingBillingTelephoneNumber: data.payload.response.existingBillingTelephoneNumber,
        telephoneNumber: data.payload.response.telephoneNumber
      };
      state.buyFlowOrder = { ...state.buyFlowOrder, ...newbuyFlowOrder };
    })
    .addCase(createCustomer.rejected, (_, { error }) => {
      console.error('Could not select order product', error);
    })
    .addCase(getCreditCheckStatus.fulfilled, (state, { payload }) => {
      const { summary, existingBillingTelephoneNumber, telephoneNumber } = payload as any;
      state.buyFlowOrder = {
        ...state.buyFlowOrder,
        summary,
        paymentUrl: '',
        existingBillingTelephoneNumber,
        telephoneNumber
      };
    })
    .addCase(getCreditCheckStatus.rejected, (_, { error }) => {
      console.error('Could not resume credit check', error);
    })
    .addCase(startCreditCheck.fulfilled, (state, { payload }) => {
      const { summary, existingBillingTelephoneNumber, telephoneNumber } = payload as any; // Todo pick between AddCustomerResponse or CreditCheckResponse
      state.buyFlowOrder = {
        ...state.buyFlowOrder,
        summary,
        paymentUrl: '',
        existingBillingTelephoneNumber,
        telephoneNumber
      };
    })
    .addCase(startCreditCheck.rejected, (_, { error }) => {
      console.error('Could not start credit check', error);
    })
    .addCase(reserveBuyFlowInstallationTimeFrapi.fulfilled, (state, { payload }) => {
      state.recordLocatorNumber = payload.recordLocatorNumber;
    })
    .addCase(reserveBuyFlowInstallationTimeFrapi.rejected, (_state, { error }) => {
      console.error('Could not get the record locator number from the order', error);
    });
};
