import { Image } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const BankingInfoModalContent = (props) => {
  return (
    <div className="fsm-body">
      <Image field={{ value: { src: 'images/check-img.png', alt: 'image of check' } }} />
    </div>
  );
};

export default BankingInfoModalContent;
