import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCovidModalResponse, updateOpenModal } from 'store/appSlice';
import { selectCovid19Notice } from 'store/selectors';
import styled from 'styled-components';
import Button from '../../components/Button';
import RadioButton from '../../components/RadioButton';

const CreditCheckCovid19Notice = ({ className }) => {
  const dispatch = useDispatch();
  const covid19Notice = useSelector(selectCovid19Notice);
  const [covidResposne, setCovidResponse] = useState(null);

  const handleClick = () => {
    dispatch(updateCovidModalResponse(covidResposne));
    dispatch(updateOpenModal(null));
  };

  //TODO: Update all content e.g buttontext to come from sitecore
  return (
    <div className={`${className} fsm-body covid-19-notice`}>
      <Text className="fsm-header-copy" tag="h1" field={{ value: covid19Notice.modalTitle }} />
      <Text tag="p" field={{ value: covid19Notice.modalText1 }} />

      <RadioButton
        key={`stat-covid-19-yes`}
        name={'covid-19'}
        checked={covidResposne}
        field={{ value: 'Yes' }} //This needs to come from sitecore
        onChange={() => setCovidResponse(true)}
      />
      <RadioButton
        key={`stat-covid-19-no`}
        name={'covid-19'}
        checked={covidResposne !== null && !covidResposne}
        field={{ value: 'No' }} //This needs to come from sitecore
        onChange={() => setCovidResponse(false)}
      />
      <Text tag="p" field={{ value: covid19Notice.modalText2 }} />
      <Button
        buttonId="bfast-credit-check-submit"
        id="covid-19-modal-button"
        model="primary"
        type="submit"
        disabled={covidResposne === null}
        onClick={handleClick}
      >
        {covid19Notice.modalButtonText}
      </Button>
    </div>
  );
};

const StyledCreditCheckCovid19Notice = styled(CreditCheckCovid19Notice)`
  ${({ theme }) => `
    &.fsm-body.covid-19-notice {
        max-width: none;
        text-align: left;

        .fsm-header-copy {
            font-weight: bold;
            margin-bottom: 0;
            font-size: 1.5rem;
        }

        p {
            margin: 1.5rem 0;
        }

        .radio-bullet {
            margin-right: 0.75rem;
        }
    }
  `}
  ${({ theme }) => theme.tabletPortrait`
    &.fsm-body.covid-19-notice {
      .covid-19-notice-content {
        max-height: calc(100vh - 15.2rem);
        overflow-x: hidden;
        overflow-y: auto;
        margin: 1.25rem 0;
        position: relative;

        p:first-child {
          margin-top: 0;
        }
      }
    }
  `}
`;

export default StyledCreditCheckCovid19Notice;
