import ClearIcon from '@material-ui/icons/Clear';
import { ApiErrorModalContent } from 'ModalContent/ApiErrorModalContent/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearErrorModal, updateIsAutopayEnrolled, updateIsPaperlessBillingSumitted, updateProgressStepIndex, updateRenderPlaceholder } from 'store/appSlice';
import { selectErrorModalCode, selectOpenModal } from 'store/selectors';
import styled from 'styled-components';
import modalIds from '../../constants/modalIds';
import AcceptedCardsModal from '../../ModalContent/AcceptedCardsModal';
import AddressNotServiceabbleModalContent from '../../ModalContent/AddressNotServiceableModalContent';
import AutoPayTooltip from '../../ModalContent/AutoPayTooltip';
import BankingInfoModalContent from '../../ModalContent/BankingInfoModalContent';
import BatteryBackupModalContent from '../../ModalContent/BatteryBackupModalContent';
import CallClientModalContent from '../../ModalContent/CallClientModalContent';
import CreditCheckCovid19Notice from '../../ModalContent/CreditCheckCovid19Notice';
import EbbErrorModal from '../../ModalContent/EBB';
import InternetPickerProductModal from '../../ModalContent/InternetPickerProductModal';
import ModTwoInternetPickerProductModal from '../../ModalContent/ModTwoInternetPickerProductModal';
import NumberNotPortableModal from '../../ModalContent/NumberNotPortableModal';
import TermsAndConditionsModalContent from '../../ModalContent/TermsAndConditionsModalContent';
import TimeOutModal from '../../ModalContent/TimeOutModal';
import VerificationCodeNotAcceptedModalContent from '../../ModalContent/VerificationCodeNotAcceptedModalContent';
import VerifierQuestionsModalContent from '../../ModalContent/VerifierQuestionsModalContent';
import VoiceProductDetailsModal from '../../ModalContent/VoiceProductDetailsModal';
import breakpoints from '../../styles/breakpoints';
import AutoPayNotRegisteredErrorModal from "../AutoPayNotRegisteredErrorModal";
import ChangeAutoPayEnrollmentModal from "../ChangeAutoPayEnrollmentModal";
import EmailAlreadyEnrolledErrorModal from "../EmailAlreadyEnrolledErrorModal";
import ExistingOpenQuoteWithPaidDepositModal from "../../ModalContent/ExistingOpenQuoteWithPaidDepositModal";
import CreditFreezeModal from "../../ModalContent/CreditFreezeModal";

const FullScreenModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const openModal = useSelector(selectOpenModal);
  const selectedErrorModalCode = useSelector(selectErrorModalCode);
  const modalCardClass = openModal + '-card';
  const renderModalContent = () => {
    const copyProps = { ...props };
    switch (openModal) {
      case modalIds.checkingInfo:
        return <BankingInfoModalContent props={copyProps} />;
      case modalIds.termsAndConditions:
        return <TermsAndConditionsModalContent props={copyProps} />;
      case modalIds.addressNotServiceable:
        return <AddressNotServiceabbleModalContent props={copyProps} />;
      case modalIds.ExistingOpenQuoteWithPaidDepositModal:
        return <ExistingOpenQuoteWithPaidDepositModal />
      case modalIds.sitecoreErrorModal:
        return <div dangerouslySetInnerHTML={{ __html: copyProps.siteCoreError }} />;
      case modalIds.apiErrorModal:
        return <ApiErrorModalContent />;
      case modalIds.verificationCodeNotAccepted:
        return <VerificationCodeNotAcceptedModalContent />;
      case modalIds.creditCheckCovid19Notice:
        return <CreditCheckCovid19Notice />;
      case modalIds.internetPickerProductModal:
        return <InternetPickerProductModal />;
      case modalIds.modTwoInternetPickerProductModal:
        return <ModTwoInternetPickerProductModal />;
      case modalIds.virifierQuestionsModal:
        return <VerifierQuestionsModalContent />;
      case modalIds.callClientModal:
        return <CallClientModalContent />;
      case modalIds.timeOutModal:
        return <TimeOutModal />;
      case modalIds.batteryBackupModal:
        return <BatteryBackupModalContent />;
      case modalIds.acceptedCardsModal:
        return <AcceptedCardsModal />;
      case modalIds.numberNotPortableModal:
        return <NumberNotPortableModal />;
      case modalIds.autoPayTooltip:
        return <AutoPayTooltip />;
      case modalIds.VoiceProductDetailsModal:
        return <VoiceProductDetailsModal className={'voice-product-details-modal'} />;
      case modalIds.ChangeAutoPayEnrollmentModal:
        return <ChangeAutoPayEnrollmentModal />;
      case modalIds.EmailAlreadyEnrolledErrorModal:
        return <EmailAlreadyEnrolledErrorModal />;
      case modalIds.AutoPayRegistrationErrorModal:
        return <AutoPayNotRegisteredErrorModal />
      case modalIds.ebbSubmitFormError:
        return <EbbErrorModal onClick={() => dispatch(clearErrorModal())} />;
      case modalIds.creditFreezeModal:
        return <CreditFreezeModal />
      default:
        return;
    }
  };
  const autopayErrorModal = () => {
    dispatch(updateIsPaperlessBillingSumitted(true));
    dispatch(updateIsAutopayEnrolled(false));
    const renderPlaceholderValues = {
      renderStepOne: true,
      renderStepTwo: true,
      renderStepThree: true,
      renderStepFour: true
    }
    dispatch(updateProgressStepIndex(3));
    dispatch(updateRenderPlaceholder(renderPlaceholderValues))
  }

  return (
    <div className={`${props.className} ${openModal} ${props.id === openModal ? '' : 'hidden'}`} id={openModal}>
      <div className={`modal-card ${modalCardClass}`}  >
        <button className="closing-x" onClick={() => {
          if (openModal === modalIds.AutoPayRegistrationErrorModal) {
            autopayErrorModal();
          }
          if(openModal === modalIds.ExistingOpenQuoteWithPaidDepositModal || selectedErrorModalCode === 'PENDING_QUOTE') {
            history.replace("/openQuote");
          }
          if(selectedErrorModalCode === 'STEP1_PENDING_QUOTE'){
            history.replace("/serviceability-check");
          }
          dispatch(clearErrorModal())
        }}>
          <ClearIcon />
        </button>
        <div className="full-screen-modal-content">{renderModalContent()}</div>
      </div>
    </div>
  );
};

// TODO: Clean up the styles here. Most of these were extracted into the ModalContent components
const StyledFullScreenModal = styled(FullScreenModal)`
  ${({ theme }) => `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.68);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-phone-container{
      display: flex;
      justify-content: center;
    }
    &#pos-id-modal {
      .modal-card {
        .closing-x {
          display: none;
        }
        .full-screen-modal-content {
          padding-top: 0;
          text-align: center;
          margin: 3em;
        }
        .fsm-header {
          font-size: 28px;
          margin-bottom: 1em;
        }
      }
    }

    .hidden {
      display: none;
    }

    .modal-card {
      background-color: ${theme.white};
      width: 21rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: auto;

      @media screen and (min-width: ${breakpoints.sm}) {
        width: 45rem;
      }

      .closing-x {
        position: absolute;
        right: 1rem;
        top: 1rem;
        border: none;
        background-color: transparent;
        align-self: flex-end;
        color: ${theme.crimson};
        margin: 0;
        padding: 0;
        height: 1.625rem;
        width: 1.625rem;

        @media screen and (min-width: ${breakpoints.sm}) {
          right: 1.9375rem;
          top: 1.9375rem;
        }
      }

      .full-screen-modal-content {
        margin: 1rem 0.8rem;
        padding-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: ${breakpoints.sm}) {
          margin: 4rem 1rem;
        }
        @media screen and (max-width: ${breakpoints.sm}) {
          padding-top: 4rem;
        }

      }
    }

    &.internet-picker-product-modal{
      .modal-card {
        max-width: 90vw;
        width: auto;

        @media screen and (min-width: ${breakpoints.sm}) {
          padding-bottom;
          width: auto;
        }

      }
    }
    .fsm-body {
      text-align: center;
      max-width: 31rem;
      .fsm-header-bold{
        font-weight: 600 !important;
        margin-bottom: 1rem !important;
      }
      .fsm-header-copy {
        font-size: 1.5rem;
        margin-bottom: 3.125rem;
        font-weight: 300;

        &.call-client {
          font-weight: 900;
        }
      }

      .fsm-try-calling-us {
        font-size: 0.875rem;
        &.f-16 {
          font-size: 1.2rem;
        }
        a.u-text {
          text-decoration: underline;
          text-decoration-thickness: from-font;
        }
      }

      .fsm-phone-number {
        font-size: 1.0813rem;
        text-decoration: underline;
        font-weight: bold;
      }
      .fsm-phone-number.light {
        color: #4c4c4c;
        font-weight: normal;
        text-decoration: none;
        font-size: 0.875rem;
      }
      img {
        width: 100%;
      }

      .call-client-button {
        margin-top: 2.5rem;
      }

    }
    .modal-z {
      z-index:5;
    }

    .api-error-modal-card {
      width: 53.5rem;
      height: fit-content;
      object-fit: contain;
    }

    .ts-fsm-body {
      &.fsm-header {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        font-weight: 300;
      }

      .fsm-t-and-cs {
        height: 37.125rem;
        overflow-y: scroll;
        margin-bottom: 4rem;
        border: solid 0.0625rem ${theme.seashell};
        padding 1rem;

        p {
          font-size: 0.875rem;
        }
      }

      .fsm-accept-row {
        display: flex;
        justify-content: space-between;

        .fsm-accept-checkbox {
          display: flex;
          height: 1rem;

          p {
            margin: 0;
            font-size: 0.875rem;
          }

          .makeStyles-icon-13 {
            background-color: ${theme.seashell};
          }
        }
      }
    }

    &.verifier-questions-modal {
      .modal-card {
        justify-content: left;
        max-height: calc(100vh - 2.2rem);
        overflow-y: auto;
        .full-screen-modal-content {
          margin: auto;
        }
      }
    }

    &.credit-check-covid-19-notice {
      .modal-card {
        .full-screen-modal-content {
          margin: 3rem;
        }

        .closing-x {
          display: none;
        }
      }
    }
    &.number-not-portable-modal {
      .modal-card {
        .full-screen-modal-content {
          margin: 3rem 1rem;
          padding: 0;
        }

        .closing-x {
          display: none;
        }
      }
    }

    &.timeout-modal {
      .modal-card {
        .closing-x {
          display: none;
        }

        .ts-fsm-body {
          text-align: center;
        }

        .fsm-header {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 2.5rem;
        }

        p {
          font-size: 0.875rem;
          margin-bottom: 2.5rem;
        }
      }
    }
    &.battery-backup-modal {
      .modal-card {
        .closing-x {
          display: none;
        }

        .full-screen-modal-content {
          margin: 3rem;
        }
      }
    }
    &.auto-pay-tooltip {
      .modal-card {
        .closing-x {
          right: 1.375rem;
          top: 1.375rem;
        }
    }


  `}
  ${({ theme }) => theme.tabletPortrait`
    width: 100%;
    height: 100%;
    .modal-card {
      max-height: 100%;
      width: 90%;
      .full-screen-modal-content {
        margin: 3rem 1.5rem;
      }
    }
    &.credit-check-covid-19-notice {
      .modal-card {
        overflow: scroll;
        height: calc(100vh - 2.5rem);
        max-height: calc(100vh - 15.2rem);
        .full-screen-modal-content {
          margin: 2.5rem 1rem;
          position: absolute;
          top: 0;
        }
      }
    }

    &.battery-backup-modal {
      .modal-card {
        .full-screen-modal-content {
          margin: 3rem 1rem;
        }
      }
    }
    &.number-not-portable-modal {
      .modal-card {
        .full-screen-modal-content {
          margin: 3rem 1rem;
          padding: 0;
        }
        .closing-x {
          display: none;
        }
      }
    }
    &.auto-pay-tooltip {
      .modal-card {
        .closing-x {
          right: 0.688rem;
          top: 0.688rem;
        }
    }
    &.pos-id-modal {
      .modal-card {
        .closing-x {
          display: none;
        }
        .full-screen-modal-content {
          padding-top: 0;
          text-align: center;
          margin: 3em;
        }
        .fsm-header {
          font-size: 28px;
          margin-bottom: 1em;
        }
      }
    }
  `}
`;

export default StyledFullScreenModal;
