import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Props } from 'models';
import React from 'react';
import styled from 'styled-components';

interface PriceProps extends Props {
  currencySymbol?: string;
  price?: string;
  frequency?: string;
  dollarsFontSize: string | '2.8813rem';
}

const Price = ({ className, currencySymbol = '$', price = '0.00', frequency }: PriceProps) => {
  const splitPrice = price.toString().split('.');
  return (
    <div className={className}>
      <Text tag="p" className="price-currency-symbol" field={{ value: currencySymbol }} />
      &nbsp;
      <div className="price-value">
        <p className="price-value-dollars">{splitPrice[0]}</p>
        <p className="price-value-cents">{splitPrice[1]}</p>
      </div>
      {frequency && <Text tag="h2" className="price-frequency" field={{ value: frequency }} />}
    </div>
  );
};

const StyledPrice = styled(Price)`
  ${({ dollarsFontSize }) => `
    display: flex;
    align-items: flex-end;
    .price-currency-symbol {
      margin: 0;
      font-size: calc(${dollarsFontSize} * 0.667);
      line-height: ${dollarsFontSize};
      font-weight: 300;
    }
    .price-value {
      display: flex;
      align-items: flex-start;
      font-size: ${dollarsFontSize};
      line-height: ${dollarsFontSize};

      p {
        margin: 0;
        font-weight: 400;
      }
      .price-value-cents {
        font-size: calc(${dollarsFontSize} / 2);
        margin-top: calc(-${dollarsFontSize} / 4.5);
      }
    }
    .price-frequency {
      font-weight: 400;
      margin: calc(${dollarsFontSize} / 4.5) 0 0;
      font-size: calc(${dollarsFontSize} / 2);
      line-height: ${dollarsFontSize};
      color: inherit !important;
    }
  `}
  ${({ theme }) => theme.mobile`
    .price-currency-symbol {
      font-size: 1rem;
      line-height: 1.4;
    }
    .price-value {
      .price-value-cents {
        align-self: baseline;
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.85;
      }
      .price-value-dollars {
        font-size: 1.5rem;
      }
    }
    .price-frequency {
      align-self: inherit;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.25;
      margin-top: 0;
    }
  `}
`;

export default StyledPrice;
