export default {
  Ave: 'Avenue',
  Blvd: 'Boulevard',
  Ct: 'Court',
  Dr: 'Drive',
  Ln: 'Lane',
  Pkway: 'Parkway',
  Pl: 'Place',
  Rd: 'Road',
  St: 'Street',
  Wy: 'Way'
};
