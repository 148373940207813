import { createSlice } from '@reduxjs/toolkit';
import { errorReducer } from 'store/reducers';

export const initialErrorState = {
  valdiate: null,
  allValid: null,
  addressValid: null,
  personalInfoValid: null,
  installationValid: null,
  creditCardValid: null,
  bankAccountValid: null,
  billMeLater: {
    creditCardValid: null,
    bankAccountValid: null
  }
};

export const errorSlice = createSlice({
  name: 'errorState',
  initialState: initialErrorState,
  reducers: { ...errorReducer }
});

export const { updateErrorState, updateErrorModal, clearErrorModal } = errorSlice.actions;
export default errorSlice.reducer;
