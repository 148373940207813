import { MergedProductType } from 'components/ProductList';
import { InternetProduct } from 'models';
import { SelectProductCofigOption, SelectProductResponse } from './api/orderApiTypes';

export interface DeEnrollModalState {
  cancelModalCancelButtonText: { value: string };
  cancelModalContinueButtonText: { value: string };
  cancelModalText: { value: string };
  cancelModalTitle: { value: string };
}

export interface EmailAlreadyEnrolledError {
  modalText: { value: string };
  modalTitle: { value: string };
}

export interface AutoPayRegistrationError {
  modalText: { value: string };
}
export interface ProgressBarSteps {
  stepNumber: {
    value: string;
  };
  title: {
    value: string;
  };
}

export interface TelephonePortingFormValues {
  accountNumber: string;
  accountName: string;
  accountStreetAddress: string;
  accountAddressLine2: string;
  accountCity: string;
  accountState: string;
  accountZipCode: string;
  accountPin: string;
  accountPassword: string;
  telephoneNumber: string;
}

export interface AppState {
  selectedPaperlessProduct: any;
  linkedSku: string;
  mergedBuyFlowProducts: MergedProductType[];
  analyticsCartId: string;
  covidModalResponse: boolean | null;
  verifierQuestionResponse: any[] | null;
  existingQuotePaidDeposit: any;
  cacheId: string;
  activeBuyFlowInternetProduct: string;
  hasActiveProdIdChanged: string;
  validateAllForms: boolean;
  autoPay: boolean;
  cart: Product[];
  confirmationNumber: number | null;
  deposit: number;
  installationTime: null;
  installationTimes: any[];
  lastDateTimeStamp: string;
  installationSchedule: any[];
  notificationPreferences: NotificationPreferences;
  buyFlowProducts: BuyFlowProduct[];
  paperless: boolean;
  paymentMethod: PaymentType | null;
  personalInfo: PersonalInfo;
  accountUuid: string | null;
  quote: string | number | null;
  quoteId: any;
  quoteNumber: string | null;
  receiveInstallationUpdates: boolean;
  lastPostalCode: string;
  serviceLocation: ServiceLocation | null;
  gmtOffset: { value: number | null; manualEntry: boolean };
  alternateBillingAddress: ServiceLocation | null;
  termsAndConditionsAccepted: boolean;
  voipConditionsAccepted: boolean;
  openModal: boolean | null;
  orderIsValid: boolean;
  paymentInfo: any | null;
  serviceability: ServiceabilityResponseType | null;
  modernizationEligible: boolean;
  fiberProducts: FiberProduct[] | null;
  prospectInfo: ProspectInfo | null;
  prospectData: ProspectData | { [key: string]: any };
  leadFormData: ProspectData | { [key: string]: any };
  getAQuoteCTA: any;
  openLeadFormModal: boolean;
  productListTechnology: string | null;
  navData: any | null;
  messagePageLoading: boolean;
  isFetching: boolean;
  voiceTermsAccepted: boolean;
  showImportantMessage: boolean;
  isServiceabilityLoading: boolean;
  isSecureOptionsSelected: boolean;
  btnClicked: string | null;
  adobeAnalytics: any;
  errorModal: ErrorModal;
  voiceSelected: BuyFlowProduct | null;
  voicePrice: number;
  buyFlowOrderSummary: OrderSummary;
  buyFlowOrder: OrderType;
  buyFlowChaseIframeUrl: string;
  buyFlowFiserve: PaymentUrlType;
  siteCoreFiberDisclaimers: any[];
  siteCoreBuyFlowDisclaimers: any[];
  covid19Notice: Covid19Notice;
  verifierQuestions: VerifierQuestions;
  callClientModal: CallClientModal;
  callClientModalResponse: boolean | null;
  sixMonthAddress: ServiceLocation | null;
  productsPrettyNames: PrettyProduct[] | null;
  productsBySku: any | null;
  bFastLastSku: string | null;
  loadingState: boolean;
  customLoadingMessage: string | null;
  serviceAddressInteractionPage: string | null;
  featureMap: any;
  progressBarSteps: ProgressBarSteps[];
  routingParamsMap: any;
  openComparisonModal: boolean;
  totalEstimatedFirstBill: number;
  leadAuditId: string;
  trafficSource: string | null;
  disclaimerModal: DisclaimerModal;
  acceptedCardsModal: any[];
  numberNotPortableModal: any;
  paidToday: number | null;
  progressStepIndex: number | null;
  searchUsersList: any[];
  assetsList: any[];
  ppUserData: any;
  ppCustomerInfo: PPCustomerInfo;
  newUserModal: boolean;
  deactivateUserModal: boolean;
  reactivateUserModal: boolean;
  unlockUserModal: boolean;
  cancelQuoteModal: boolean;
  resetPasswordModal: boolean;
  ppUpdateUserStatus: {
    uid: string;
    status: string;
  };
  ebbAck: EBBAckowledgement;
  codeToProducMap: any | null;
  selectedProductCode: any | null;
  isAutopayEnrolled: boolean;
  autoPayTermsAccepted: boolean;
  isPaperlessBillingSumitted: boolean;
  isPaperlessEnrolled: boolean | null;
  ppAditionalHeaders: PPAditionalHeaders;
  isFrapiProduct: boolean;
  openEmergencyNumberServiceDisclosureModal: boolean;
  recordLocatorNumber: string;
  openQuotePreviewModal: boolean;
  openQuoteRecapModal: boolean;
  voiceSelectionOption: 'Yes' | 'No' | null;
  openCancellationReasonModal: boolean;
  cancellationReasonModalField: string;
  voiceDirectorySelection: SelectProductCofigOption[] | SelectProductCofigOption | null;
  serviabilityMovingAddress: any;
  snackbarIsOpen: boolean;
  deEnrollModalState: DeEnrollModalState;
  emailAlreadyEnrolledError: EmailAlreadyEnrolledError;
  autopayRegistrationError: AutoPayRegistrationError;
  quoteDataToCSV: any | null;
  selectedSecureProductIds: string[];
  selectedSecureProductAddonsIds: string[];
  selectedAvailableSecureProductAddons: any;
  currentSecureProductsConfigured: boolean;
  unsortedProductList: any[];
  highSpeedApprovalOption: boolean;
  isCustomerInformationValidated: boolean;
  isQuoteValidated: boolean;
  isQuoteBeingValidated: boolean;
  isEligibleEBBData: {
    isEligible: boolean;
    showBanner: boolean;
    ebbBannerErrorCode?: string;
  };
  telephonePortingFormValues: TelephonePortingFormValues;
  isPosIdModalOpen: boolean;
  isPosIdRequired: boolean;
  hasCreditFreeze: boolean;
  isCreditCheckShown: boolean;
  hasAddressDiscrepancy: boolean;
  resumedWithAddressDiscrepancy: boolean;
  renderPlaceholder: {
    renderStepOne: boolean;
    renderStepTwo: boolean;
    renderStepThree: boolean;
    renderStepFour: boolean;
  };
  keepCurrentPhoneNumber: boolean;
  summaryTotalValue: number | null;
  summaryBackpayValue: number | null;
  summaryDepositValue: number | null;
  shouldActionsButtonsBeEnabled: boolean;
  portabilityTelephoneNumber: string | null;
  isCalendarErrorOpen: boolean;
  isInternetProductSelectionActive: boolean;
  isEbbFlow: boolean;
  paidRouter: any;
  freeRouter: any;
  resumePaidRoutersCount: any;
  fundingAccountLastFourDigits: {
    method: string | number | null;
    digits: string | number | null;
  };
  pendingInOrderNumber: string | null;
  addressDiscrepancyModal: boolean;
  addressUnKnownField: boolean;
  allowEditPreviousAddress: boolean;
  retainSSN: boolean;
  addressDescrepancyErrorCode: string;
  isStepTwoCompleted: boolean;
  communicationPreferences: null | any[];
  offerType: string;
  eeroSecureAddon: any;
  portedTelephoneNumber: string | null;
  isEeroSecureSelected: boolean;
  selectedEeroSecureAddonId: string[];
  wholeHomeWifiProduct: any;
  wholeHomeWifiProductId: string[];
  isExpertInstallSelected: boolean;
  expertInstallProduct: any;
  partialInstallSelect: boolean;
  fullInstallSelect: boolean;
}

export interface PPAditionalHeaders {
  channel: string;
  vendor: string;
  office: string;
  assisted: boolean;
  requestingApplication: string;
  soldBy: string;
  issuedBy: string;
}

export interface LeadFormNotificationPreferences {
  marketingEmailEnabled: boolean;
  smsEnabled: boolean;
}

export interface LeadForm {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: number;
  businessName: string;
  serviceLocation: ServiceLocation;
  industryType: string;
  companySize: string;
  isCurrentSMB: boolean;
  notificationPreferences: LeadFormNotificationPreferences;
}

export interface ModTwoMergedProductType extends MergedProductType {
  id?: string;
}

export interface ErrorModal {
  errorCode: string;
  contentSelector: string;
  errorContent: {
    header: string;
    phoneNumber: string;
    tryCallingUs: string;
    endingText?: string;
  };
}

// TODO: FIXME Having "ProspectData" and "ProspectInfo" is confusing. Need better names
// Response from Prospect Creation
export interface ProspectData {
  id: string;
  token: string;
}

export interface PrettyProduct {
  prettyName: string;
  sku?: string;
  type: string;
}

// Retrieved Existing Prospect
export interface ProspectInfo {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: number;
  businessName: string;
  serviceLocation: ServiceLocation;
  notificationPreferences: NotificationPreferences;
}

export interface NotificationPreferences {
  alternateMobileNumber: string;
  installationScheduleUpdatesEnabled: boolean;
  marketingEmailEnabled: boolean;
  alternateMailingAddress: ServiceLocation;
}

export interface PersonalInfo {
  email: string | null;
  mobileNumber: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface Product extends InternetProduct {
  speeds: Speeds;
  price: number;
  highlights: string[];
}

export interface BuyFlowProduct {
  sku: string;
  name: string;
  dataspeed?: { downloadInKbps: string; uploadInKbps: string };
  description: string;
  type: string;
  downstream: string;
  price: PriceType;
  productId: string;
  sweeteners?: { name: string; price: string }[];
}
export interface BuyFlowProductWithAddons extends BuyFlowProduct {
  addons?: any;
}

export interface ModTwoBuyFlowProduct {
  id: string;
  sku: string;
  name: string;
  type: string;
  linkedSku: string[];
  price: PriceType;
  // 1: ask if which data type linkedSky will be
  // 2: ask if which kind of data type do we need from previous model (downstream, price ...)
}

export type DefaultValueType = { value: string };
export type ProductDetailsType = { checkboxColor: DefaultValueType; detail: DefaultValueType };
export type ProductImageType = { jss: { value: { alt: string; src: string } } };
export interface ModTwoBuyFlowSitecoreProduct {
  buyflowAnnotation: null | string;
  cardPlanPeriod: DefaultValueType;
  confirmationAnnotation: null | string;
  id: string;
  internetSku: DefaultValueType;
  linkedItem: DefaultValueType;
  noPriceRender: DefaultValueType;
  priceDisclaimer: DefaultValueType;
  priceSubText: DefaultValueType;
  productAdPrice: DefaultValueType;
  productType: DefaultValueType;
  productDescription: DefaultValueType;
  productDetails: ProductDetailsType[];
  productDisclaimer: DefaultValueType;
  productImage: ProductImageType;
  productImageCopy: DefaultValueType;
  productImageSpeed: DefaultValueType;
  productName: DefaultValueType;
  productSku: DefaultValueType;
  productSkuType: DefaultValueType;
  voiceSku: DefaultValueType;
}

export interface ProductMapperData {
  name: string;
  description: string;
  type: string;
  isDoublePlay: boolean;
  linkedSku: string[];
  disclaimer: string;
  price: string;
}
export interface Speeds {
  down: number;
  up: number;
}

export interface EBBAckowledgement {
  accountId: string;
  acceptAck?: boolean;
}

// TS CONSTANTS VERSIONS
export enum ServiceTypeEnum {
  SERVICEABLE_FIBER = 'SERVICEABLE_FIBER',
  SERVICEABLE = 'SERVICEABLE',
  UNSERVICEABLE = 'UNSERVICEABLE',
  PENDING_ORDER = 'PENDING_ORDER',
  PENDING_QUOTE_C = 'PENDING_QUOTE_C',
  PENDING_QUOTE_F = 'PENDING_QUOTE_F',
  EXISTING_FIBER = 'EXISTING_FIBER',
  EXISTING_UPGRADEABLE_FIBER = 'EXISTING_UPGRADEABLE_FIBER',
  EXISTING_PLANNED_FIBER = 'EXISTING_PLANNED_FIBER',
  EXISTING_COPPER = 'EXISTING_COPPER',
  EXISTING_UPGRADEABLE_COPPER = 'EXISTING_UPGRADEABLE_COPPER',
  EXISTING_VOICE = 'EXISTING_VOICE',
  HOA = 'HOA',
  PACLEC = 'PACLEC',
  MULTIPLE = 'MULTIPLE'
}

export enum ServiceGoalTypes {
  FAILED = 'FAILED',
  CHECKED = 'CHECKED',
  UNSERVICEABLE = 'UNSERVICEABLE',
  SERVICEABLE = 'SERVICEABLE',
  SERVICEABLE_FIBER = 'SERVICEABLE_FIBER',
  SERVICEABLE_COPPER = 'SERVICEABLE_COPPER',
  FUTURE_SERVICEABLE = 'FUTURE_SERVICEABLE',
  NEW_SERVICEABLE = 'NEW_SERVICEABLE',
  EXISTING = 'EXISTING',
  EXISTING_FIBER = 'EXISTING_FIBER',
  EXISTING_COPPER = 'EXISTING_COPPER',
  UPGRADEABLE = 'UPGRADEABLE',
  UPSELL_FIBER = 'UPSELL_FIBER',
  UPSELL_COPPER = 'UPSELL_COPPER'
}

export enum ServiceGoalLabels {
  FAILED = 'Error',
  CHECKED = 'Completed',
  UNSERVICEABLE = 'Not Serviceable',
  SERVICEABLE = 'Serviceable',
  SERVICEABLE_FIBER = 'Serviceable Fiber',
  SERVICEABLE_COPPER = 'Serviceable Copper',
  FUTURE_SERVICEABLE = 'Future Serviceable',
  NEW_SERVICEABLE = 'New Serviceable',
  EXISTING = 'Existing',
  EXISTING_FIBER = 'Existing Fiber',
  EXISTING_COPPER = 'Existing Copper',
  UPGRADEABLE = 'Products Available',
  UPSELL_FIBER = 'Fiber Available',
  UPSELL_COPPER = 'Copper Available'
}

export enum ServiceGoalIDs {
  FAILED = '8C7FE7B4-C031-470E-B2B4-048B79862458',
  CHECKED = 'E3F05DC5-2CAF-4C77-8586-6BF546FBF17D',
  UNSERVICEABLE = '4F435708-5D0A-40DA-B1EF-A76EFBF29ACC',
  SERVICEABLE = '0FC5AA06-BEDE-440C-9BA5-E63AD4D68084',
  SERVICEABLE_FIBER = '7D73A9F5-42C0-4C4C-89E6-A0DAB15AFE89',
  SERVICEABLE_COPPER = '7B5DB417-9FD2-4DA2-B5D3-CB58418FD6B6',
  FUTURE_SERVICEABLE = 'FBA6AAE4-1ED3-43F7-89C6-3D63212EB891',
  NEW_SERVICEABLE = 'B0ABE77C-5396-457A-A352-30D42166C60C',
  EXISTING = '02087354-2462-4DF9-9DBA-4D90465BAEC6',
  EXISTING_FIBER = 'B97EBA50-82AA-4A3F-A216-B7CB9503FE1C',
  EXISTING_COPPER = '99313E58-C054-4BEB-A908-7C95EFDE3FE0',
  UPGRADEABLE = 'C5FD0539-364B-48C4-A00A-EEF8927E5EEC',
  UPSELL_FIBER = 'A24EC948-C9DB-4AA5-8F54-1A94D4ADE70B',
  UPSELL_COPPER = '3137509A-3F36-445F-AB00-B4C2BE6131E8',
  FIBER_ORDER = '897E3806-569C-4B89-B126-4C0DFDA199D5',
  COPPER_ORDER = '2ED6443B-0770-469E-B1CA-3ABC8AB94862',
  FIBER_PAYMENT_SUCESS = '56B6E07E-9FA4-4888-B60B-F1842354B1C8',
  COPPER_PAYMENT_SUCESS = '65F2CE64-0968-405A-88F7-C8EC97ADC98A',
  FIBER_PAYMENT_DENIED = '873B6E6B-9FC0-4906-A967-3E93A493F02B',
  COPPER_PAYMENT_DENIED = '3534CA2D-B7C3-4901-852E-AAE4DE836C2A',
  PAYMENT_CAPTURED_COPPER = '4A92B6EA-E364-4DBE-82EF-0FE51BBA9B1F',
  PAYMENT_CAPTURED_FIBER = '21715161-537A-4D53-B26C-09369D36A594',
  PAYMENT_ATTEMPT_COPPER = 'E8981C5F-57D9-4792-AAA2-D509BB4F1866',
  PAYMENT_ATTEMPT_FIBER = '421B7A0E-6678-4FAC-AB43-1A2B473F43BC'
}

export enum MessagePageTypeEnum {
  CODE_VERIFICATION_SMS = 'CODE_VERIFICATION_SMS',
  CODE_VERIFICATION_EMAIL = 'CODE_VERIFICATION_EMAIL',
  CODE_VERIFICATION_SUCCESSFUL = 'CODE_VERIFICATION_SUCCESSFUL',
  NO_FIBER_VISIT_DOTCOM = 'NO_FIBER_VISIT_DOTCOM',
  IDENTITY_VERIFICATION_FAILED = 'IDENTITY_VERIFICATION_FAILED',
  CREDIT_CHECK_FAILED = 'CREDIT_CHECK_FAILED',
  FRAUD_ALERT = 'FRAUD_ALERT',
  CREDIT_FREEZE = 'CREDIT_FREEZE',
  SECURITY_PIN_ERROR = 'SECURITY_PIN_ERROR',
  REQUEST_FAIL = 'REQUEST_FAIL',
  PAYMENT_NOT_APPROVED = 'PAYMENT_NOT_APPROVED',
  HOA = 'HOA',
  PACLEC = 'PACLEC'
}

enum PaymentType {
  Bank_Account = 'BANK_ACCOUNT',
  Credit_Card = 'CREDIT_CARD'
}

// ! TS CONSTANTS VERSION

// ** FIBER INTERFACES

export interface ServiceabilityResponseType {
  serviceType: ServiceTypeEnum;
  futureServiceDate: string;
  fiberModernization: boolean;
  correctedAddress: {
    cityName: string;
    plus4: string;
    stateAbbreviation: string;
    streetName: string;
    streetNumber: string;
    streetSuffix: string;
    zipCode: string;
  };
  iONT: boolean;
  leadAuditId?: string;
  environment?: string;
  controlNumber?: string;
  pendingInOrderNumber?: string;
  offer?: string;
}

export interface PredictiveTextServiceabilityResponseType {
  serviceType: ServiceTypeEnum;
  futureServiceDate: string;
  fiberModernization: boolean;
  correctedAddress: {
    cityName: string;
    plus4: string;
    stateAbbreviation: string;
    streetName: string;
    streetNumber: string;
    streetSuffix: string;
    zipCode: string;
  };
  iONT: boolean;
  offer: string;
}

export interface FiberProduct extends InternetProduct {
  description: string;
  price: string;
  upstream: string;
  downstream: string;
  metadata: any[];
}

export interface PriceType {
  value: string;
  currency: string;
  unit: string;
  discountValue?: string;
  depositPaid?: string;
}

export interface NotificationPreferences {
  marketingEmailEnabled: boolean;
  smsEnabled?: boolean;
}

export interface ServiceLocation {
  address: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  isBusiness?: boolean;
  checkEmpty?: () => boolean;
}
export class ServiceAddressClass {
  constructor(
    obj: {
      address: string;
      address2?: string;
      city: string;
      state: string;
      postalCode: string;
      isBusiness?: boolean;
    } | null
  ) {
    if (obj) {
      const { address, address2, city, state, postalCode, isBusiness } = obj;
      this.address = address;
      this.address2 = address2 ? address2 : '';
      this.city = city;
      this.state = state;
      this.isBusiness = isBusiness;
      this.postalCode = postalCode;
      return {
        ...obj,
        checkEmpty: this.checkEmpty
      };
    } else {
      const { address, address2, city, state, postalCode, isBusiness, checkEmpty } = this;
      return {
        address,
        address2: address2 ? address2 : '',
        city,
        state,
        postalCode,
        isBusiness,
        checkEmpty
      };
    }
  }
  address: string = '' as string;
  address2?: string = '';
  city: string = '';
  state: string = '';
  postalCode: string = '';
  isBusiness?: boolean;
  checkEmpty(): boolean {
    return !!this.address && !!this.city && !!this.state && !!this.postalCode && (!!this.address2 || !!!this.address2);
  }
}

export interface OrderSummary {
  total: {
    value: string;
    currency: string;
    unit: string;
  };
  lineItems: { product: ProductType; cost: PriceType }[];
  additionalFees: { type: string; cost: PriceType }[];
}

export interface ProductType {
  sku: string;
  productId?: string;
  name: string;
  description: string;
  type: string;
  price: PriceType;
}

export interface OrderType {
  orderId: string;
  paymentUrl: string;
  summary: OrderSummary;
  existingBillingTelephoneNumber: string;
  telephoneNumber: string;
  isCreditCheckSkipped?: boolean;
}

export interface Covid19Notice {
  modalActive: boolean;
  modalTitle: string;
  modalText1: string;
  modalText2: string;
  modalButtonText: string;
  stat: string | null;
}

export interface VerifierQuestions {
  modalActive: boolean;
  modalTitle: string;
  securityQuestions: any[];
  modalButtonText: string;
}

export interface DisclaimerModal {
  disclaimers: any[];
  regionCode: string;
  regionName: string;
}

export interface PPCustomerInfo {
  firstName: string;
  lastName: string;
  telephoneNumber: string;
  email: string;
  disabled: boolean;
  formErrors: any;
}

export interface CallClientModal {
  modalErrorHeader: string;
  modalErrorText: string;
  modalErrorButtonText: string;
  modalResultSummaryTitle: string;
  modalResultSummaryContent: string;
  modalResultSummaryButtonText: string;
  orderId: string;
}

export interface ReservationWindow {
  start: string;
  end: string;
}
export interface FrapiReserveInstallationProps {
  scheduleId: string;
  customerName?: string;
  primaryPhoneNumber?: string;
  secondaryPhoneNumber?: string;
  notes?: string;
}

export interface CancelReasonProps {
  cancelReason: string;
  quoteId: string;
}

// ! ** FIBER  INTERFACES ** //

export interface PaymentUrlType {
  paymentUrlCard: string;
  paymentUrlACH: string;
}

// Generated by https://quicktype.io

export interface QuoteResumeResponse {
  quoteId: string;
  quoteNumber: string;
  accountUuid: string;
  serviceAddress: ServiceAddress;
  environment: string;
  controlNumber: string;
  firstName: string;
  lastName: string;
  productOffers: SelectProductResponse[];
  childProducts: ChildProduct[];
  schedule: Schedule;
  deposit: Deposit;
  backBalanceOwed: BackBalanceOwed;
  productsModifiable: boolean;
  scheduleModifiable: boolean;
  cancelAllowed: boolean;
  customerTelephoneNumber: number;
  taxes: Tax[];
  quoteStatus: string;
  billingAddress: BillingAddress;
  mobileTelephone: string;
  emailAddress: string;
  notes: any[];
  currentTasks: CurrentTask[];
  technology: string;
  ebbVerified: boolean;
  salesJourneyMilestone: SalesJourneyMilestone[];
  autoPay: boolean;
  eBill: boolean;
  isStepThreeComplete: boolean;
  recordLocatorNumber: string;
  errorCode: string;
  hasAddressDiscrepancy: boolean;
  isPosIdCleared: boolean;
  isStepOneComplete: boolean;
  isStepTwoComplete: boolean;
  communicationPreferences: any[];
  offer?: string;
  numberPortability?: number;
}

interface BackBalanceOwed {
  phoneNumber: number;
  amountDue: string;
}

interface BillingAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface ChildProduct {
  childProductId: string;
  description: string;
  price: string;
  rateType: string;
  unit: string;
  type: string;
}

interface CurrentTask {
  taskId: string;
  taskName: string;
}

interface Deposit {
  owed: number;
  paid: Paid;
}

interface Paid {
  amount: string;
  depositAmount: number;
  backBalances: number;
}
interface Price {
  value: string;
  currency: string;
  unit: string;
}

export interface SalesJourneyMilestone {
  name: string;
  fulfilled: string;
}

interface Schedule {
  scheduleId: null;
  appointmentStart: null;
  appointmentEnd: null;
  expirationDate?: null;
}

interface ServiceAddress {
  addressId: AddressID;
  number: string;
  preDirectional: string;
  streetName: string;
  postDirectional: string;
  city: string;
  state: string;
  postalCode: string;
  streetNameSuffix: string;
  secondaryDesignator: string;
  secondaryNumber: string;
}

interface AddressID {
  environmentCode: string;
  samControlNumber: string;
}

interface Tax {
  type: string;
  cost: Price;
}
