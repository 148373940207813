import { AppState } from 'store/state.model';
import { DataType } from 'utils/adobeAnalyticsHelper';

export const analyticsReducers = {
  updateAdobePageName: (state, { payload }) => {
    state.adobeAnalytics.pageName = payload;
  },
  updateAdobeUserType: (state, { payload }) => {
    state.adobeAnalytics.eVar22 = payload;
  },
  updateAdobeEvars: (state: AppState, { payload }: { payload: DataType }) => {
    state.adobeAnalytics.evars = { ...state.adobeAnalytics.evars, ...payload };
  },
  // ! TODO: Update these reducers to only add events if it doesnt exist
  updateAdobeEvents: (state, { payload }) => {
    state.adobeAnalytics.events = state.adobeAnalytics.events ? `${state.adobeAnalytics.events},${payload}` : payload;
  },
  resetAdobeEvarsAndEvents: (state) => {
    const basicAnalyticsData = state.adobeAnalytics;
    basicAnalyticsData.evars && delete basicAnalyticsData.evars;
    basicAnalyticsData.events && delete basicAnalyticsData.events;
    state.adobeAnalytics = basicAnalyticsData;
  },
  updateCartId: (state, { payload }) => {
    state.analyticsCartId = payload;
  }
};
