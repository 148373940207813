export enum SCLinkType {
  ANCHOR = 'anchor',
  EXTERNAL = 'external'
}

export enum ApiProductType {
  SinglePlay = 'SP',
  DoublePLay = 'DP'
}
export enum DirectoryListing {
  UNPUBLISHED = 'UNPUBLISHED',
  UNLISTED = 'UNLISTED',
  LISTED_FULL_ADDRESS = 'LISTED_FULL_ADDRESS',
  LISTED_CITY_ONLY = 'LISTED_CITY_ONLY',
  LISTED_WITHOUT_ADDRESS = 'LISTED_WITHOUT_ADDRESS'
}

export enum TaxType {
  FEDERAL_TAX = 'FEDERAL_TAX',
  STATE_TAX = 'STATE_TAX',
  DEPOSIT = 'DEPOSIT',
  BACKPAY = 'BACKPAY'
}

export enum MessageWithImageReplaceType {
  DATE = '[date_token]'
}
