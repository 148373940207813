import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { selectExistingQuotePaidDeposit } from 'store/selectors/buyFlowSelectors';
import useActionsOrders from 'utils/useActionsOrders';
import { updateOpenModal } from 'store/appSlice';

const ExistingOpenQuoteWithPaidDepositModal = () => {
  const selectedExistingQuotePaidDeposit = useSelector(selectExistingQuotePaidDeposit);
  const errorData = selectedExistingQuotePaidDeposit.errors.data;
  const dispatch = useDispatch();
  const { handleEditQuote } = useActionsOrders();
  let errorText = errorData.message.split(".");
  const quoteNumber = errorText[2].split(",")[0].split(":")[1];
  const quoteId = errorText[2].split(",")[1].split(":")[1];

  
  const handleResumeQuoteCall = () => {
    handleEditQuote(quoteId.trim());
    dispatch(updateOpenModal(null));
  }
  return (
    <div className="fsm-body">
      <Text className="fsm-header-copy mb-3" tag="h1" field={{ value: `${errorText[0]}.` }} />
      <span
        className="fsm-try-calling-us mb-0 f-16"
      >Please resume Quote <a className='u-text' onClick={handleResumeQuoteCall}>{quoteNumber}</a>.</span>
      <Text
        className="fsm-try-calling-us mb-0 f-16"
        tag="h3"
        encode={false}
        field={{ value: `If further assistance is needed, please call` }}
      />
      <Text
        className="fsm-try-calling-us f-16"
        tag="h3"
        encode={false}
        field={{ value: `844-591-4880.` }}
      />
    </div>
  );
};

export default ExistingOpenQuoteWithPaidDepositModal;
