/** Small helper to run code safely on browser or on server.
 * The assumption here is these both calculate the same value. Just one happens on the server, one ahappens on the browser
 * @param runOnServer function that executes code in a server context
 * @param runOnBrowser function that executes code in a browser context. The window object should be fully available here.
 * @return T the type you want returned.
 */
export function runSafeSsr<T>(runOnServer: () => T, runOnBrowser: () => T): T {
  if (typeof window !== 'undefined') {
    return runOnBrowser();
  } else {
    return runOnServer();
  }
}

/**
 * Helper to run a one-off function only on the browser
 * This assumes this is some side effecting call like loading an anlytics script, result of the function is discarded
 */
export function runOnlyOnBrowser(fn: () => void) {
  if (typeof window !== 'undefined') {
    fn();
  }
}

export function isIOS() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  );
}

export const pushWithParams = (
  history,
  path: string,
  params?: string | null,
  hash?: string | null,
  preserveParams: boolean = false
) => {
  runOnlyOnBrowser(() => {
    if (preserveParams) {
      history.push({
        pathname: path,
        search: params ? history.location.search.concat(params) : history.location.search,
        hash: hash ?? ''
      });
    } else {
      history.push({ pathname: path, search: params ?? '', hash: hash ?? '' });
    }
  });
};

export function runOnlyOnSafari(fn: () => void) {
  if (typeof window !== 'undefined') {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent.toLowerCase();
    const isSafari = window.hasOwnProperty('safari') || userAgent.includes('safari');
    // console.log('IS SAFARI', isSafari);
    // console.log('IS IOS', isIOS());
    if (isSafari || isIOS()) fn();
  }
}
