import { AppState, OrderType } from 'store/state.model';
export const buyFlowReducers = {
  updateCart: (state, { payload }) => {
    state.cart = payload;
  },
  updateInstallationTime: (state, { payload }) => {
    state.installationTime = payload;
  },
  updateNotificationPreferences: (state, { payload }) => {
    state.notificationPreferences = payload;
  },
  updateTermsAndConditionsAccepted: (state, { payload }) => {
    state.termsAndConditionsAccepted = payload;
  },
  updateEmergencyVoip: (state, { payload }) => {
    state.voipConditionsAccepted = payload;
  },
  updateSelectedProductCode: (state, { payload }) => {
    state.selectedProductCode = payload;
  },
  updateSelectedProduct: (state, { payload }) => {
    state.activeBuyFlowInternetProduct = payload;
  },
  updateSelectedVoiceProduct: (state, { payload }) => {
    state.voiceSelectedProduct = payload;
  },
  updateBuyFlowProducts: (state, { payload }) => {
    state.buyFlowProducts = payload;
  },
  updateMergedBuyFlowProducts: (state, { payload }) => {
    state.mergedBuyFlowProducts = payload;
  },
  updatedVoiceSelected: (state, { payload }) => {
    state.voiceSelected = payload;
  },
  updateBuyFlowDisclaimers: (state, { payload }) => {
    state.siteCoreBuyFlowDisclaimers = payload;
  },
  updateBuyFlowOrderSummary: (state, { payload }) => {
    state.buyFlowOrderSummary = payload;
  },
  updateCovid19Notice: (state, { payload }) => {
    state.covid19Notice = payload;
  },
  updateSixMonthAddress: (state, { payload }) => {
    state.sixMonthAddress = payload;
  },
  updateCovidModalResponse: (state, { payload }) => {
    state.covidModalResponse = payload;
  },
  updatePrettyProducts: (state, { payload }) => {
    state.productsPrettyNames = payload;
  },
  updateCodeToProduct: (state, { payload }) => {
    state.codeToProductMap = payload;
  },
  updateProductsBySku: (state, { payload }) => {
    state.productsBySku = payload;
  },
  updateBFastLastSku: (state, { payload }) => {
    state.bFastLastSku = payload;
  },
  setFeatureMap: (state, { payload }) => {
    state.featureMap = payload;
  },
  updateProgressBarSteps: (state, { payload }) => {
    state.progressBarSteps = payload;
  },
  updateBuyFlowOrder: (state: AppState, { payload }: { payload: OrderType }) => {
    state.buyFlowOrder = payload;
  },
  updateVoicePrice: (state, { payload }) => {
    state.voicePrice = payload;
  },
  updateLinkedSku: (state, { payload }) => {
    state.linkedSku = payload;
  },
  updateVerifierQuestion: (state, { payload }) => {
    state.verifierQuestion = payload;
  },
  updateExistingQuotePaidDeposit: (state, { payload }) => {
    state.existingQuotePaidDeposit = payload;
  },
  updateVerifierQuestionResponse: (state, { payload }) => {
    state.verifierQuestionResponse = payload;
  },
  updateCallClientModal: (state, { payload }) => {
    state.callClientModal = payload;
  },
  updateCallClientModalResponse: (state, { payload }) => {
    state.callClientModalResponse = payload;
  },
  resetBuyFlowProducts: (state: AppState) => {
    state.buyFlowProducts = [];
  },
  updateDisclaimerModal: (state, { payload }) => {
    state.disclaimerModal = payload;
  },
  updateAcceptedCardsModal: (state, { payload }) => {
    state.acceptedCardsModal = payload;
  },
  updateNumberNotPortableModal: (state, { payload }) => {
    state.numberNotPortableModal = payload;
  },
  updateKeepCurrentPhoneNumber: (state, { payload }) => {
    state.keepCurrentPhoneNumber = payload;
  },
  updateInstallationSchedule: (state, { payload }) => {
    state.installationSchedule = payload;
  },
  updatePaidToday: (state, { payload }) => {
    state.paidToday = payload;
  },
  updateProgressStepIndex: (state, { payload }) => {
    state.progressStepIndex = payload;
  },
  updateIsAutopayEnrolled: (state, { payload }) => {
    state.isAutopayEnrolled = payload;
  },
  updateOfferType: (state, { payload }) => {
    state.offerType = payload;
  },
  updateAutoPayTermsAccepted: (state, { payload }) => {
    state.autoPayTermsAccepted = payload;
  },
  updateIsPaperlessBillingSumitted: (state, { payload }) => {
    state.isPaperlessBillingSumitted = payload;
  },
  updateIsPaperlessEnrolled: (state, { payload }) => {
    state.isPaperlessEnrolled = payload;
  },
  updateIsSecureOptionsSelected: (state, { payload }) => {
    state.isSecureOptionsSelected = payload;
  },
  updateQuoteDataToCSV: (state, { payload }) => {
    state.quoteDataToCSV = payload;
  },
  updateCurrentSecureProductsConfigured: (state: AppState, { payload }: { payload: boolean }) => {
    state.currentSecureProductsConfigured = payload;
  },
  updateSelectedSecureProductIds: (state: AppState, { payload }: { payload: AppState['selectedSecureProductIds'] }) => {
    state.selectedSecureProductIds = payload;
  },
  updateSelectedSecureProductAddonsIds: (
    state: AppState,
    { payload }: { payload: AppState['selectedSecureProductAddonsIds'] }
  ) => {
    state.selectedSecureProductAddonsIds = payload;
  },
  updateAvailableSecureProductAddons: (
    state: AppState,
    { payload }: { payload: AppState['selectedAvailableSecureProductAddons'] }
  ) => {
    state.selectedAvailableSecureProductAddons = { ...state.selectedAvailableSecureProductAddons, ...payload };
  },
  updateSelectedPaperlessProduct: (state, { payload }) => {
    state.selectedPaperlessProduct = { ...state.selectedPaperlessProduct, ...payload };
  },
  updateVoiceSelectionOption: (state: AppState, { payload }: { payload: AppState['voiceSelectionOption'] }) => {
    state.voiceSelectionOption = payload;
  },
  updateVoiceDirectorySelection: (state: AppState, { payload }: { payload: AppState['voiceDirectorySelection'] }) => {
    state.voiceDirectorySelection = payload;
  },
  resetPastQuoteId: (state) => {
    state.quoteId = -1;
  },
  resetPastActiveBuyFlowInternetProduct: (state) => {
    state.activeBuyFlowInternetProduct = '';
  },
  updateUnsortedProductList: (state, { payload }) => {
    state.unsortedProductList = payload;
  },
  updateHighSpeedApprovalOption: (state, { payload }) => {
    state.highSpeedApprovalOption = payload;
  },
  updateIsPosIdModalOpen: (state, { payload }) => {
    state.isPosIdModalOpen = payload;
  },
  updateIsPosIdRequired: (state, { payload }) => {
    state.isPosIdRequired = payload;
  },
  updateHasCreditFreeze: (state, { payload }) => {
    state.hasCreditFreeze = payload;
  },
  updateHasAddressDiscrepancy: (state, { payload }) => {
    state.hasAddressDiscrepancy = payload;
  },
  updateIsCalendaErrorOpen: (state, { payload }) => {
    state.isCalendarErrorOpen = payload;
  },
  updateShouldActionsButtonsBeEnabled: (state: AppState, { payload }) => {
    state.shouldActionsButtonsBeEnabled = payload;
  },
  updatePortabilityTelephoneNumber: (state: AppState, { payload }) => {
    state.portabilityTelephoneNumber = payload;
  },
  updateIsCalendarErrorOpen: (state: AppState, { payload }) => {
    state.isCalendarErrorOpen = payload;
  },
  updateIsInternetProductSelectionActive: (state: AppState, { payload }) => {
    state.isInternetProductSelectionActive = payload;
  },
  updateIsEbbFlow: (state: AppState, { payload }) => {
    state.isEbbFlow = payload;
  },
  updateAddressDescErrorCode: (state: AppState, { payload }) => {
    state.addressDescrepancyErrorCode = payload;
  },
  updateIsStepTwoCompleted: (state: AppState, { payload }) => {
    state.isStepTwoCompleted = payload;
  },
  updateFundingAccountLastFourDigits: (state: AppState, { payload }) => {
    state.fundingAccountLastFourDigits = payload;
  },
  updatePendingInOrderNumber: (state: AppState, { payload }) => {
    state.pendingInOrderNumber = payload;
  },
  updateIsAddressDiscrepancyModalOpen: (state: AppState, { payload }) => {
    state.addressDiscrepancyModal = payload;
  },
  updateAddressUnknownField: (state: AppState, { payload }) => {
    state.addressUnKnownField = payload;
  },
  updateAllowEditPreviousAddress: (state: AppState, { payload }) => {
    state.allowEditPreviousAddress = payload;
  },
  updateResumedWithAddressDiscrepancy: (state: AppState, { payload }) => {
    state.resumedWithAddressDiscrepancy = payload;
  },
  updateRetainSSN: (state: AppState, { payload }) => {
    state.retainSSN = payload;
  },
  updateCommunicationPreferences: (state: AppState, { payload }) => {
    state.communicationPreferences = payload;
  },
  updateEeroSecureAddon: (state: AppState, { payload }) => {
    state.eeroSecureAddon = payload;
  },
  updatePortedTelephoneNumber: (state: AppState, { payload }) => {
    state.portedTelephoneNumber = payload;
  },
  updateIsEeroSecureSelected: (state: AppState, { payload }) => {
    state.isEeroSecureSelected = payload;
  },
  updateSelectedEeroSecureAddonId: (
    state: AppState,
    { payload }: { payload: AppState['selectedEeroSecureAddonId'] }
  ) => {
    state.selectedEeroSecureAddonId = payload;
  },
  updateWholeHomeWifiProduct: (state: AppState, { payload }) => {
    state.wholeHomeWifiProduct = payload;
  },
  updateWholeHomeWifiProductId: (state: AppState, { payload }: { payload: AppState['wholeHomeWifiProductId'] }) => {
    state.wholeHomeWifiProductId = payload;
  },
  updateIsExpertInstallSelected: (state: AppState, { payload }) => {
    state.isExpertInstallSelected = payload;
  },
  updateExpertInstallProduct: (state: AppState, { payload }) => {
    state.expertInstallProduct = payload;
  },
  updatePartialInstallSelect: (state: AppState, { payload }) => {
    state.partialInstallSelect = payload;
  },
  updateFullInstallSelect: (state: AppState, { payload }) => {
    state.fullInstallSelect = payload;
  }
};
