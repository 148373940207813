import React from 'react';
import Styled from './styles';
import Button from '../../components/Button';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { selectNavData } from 'store/selectors';

interface Props {
  onClick: () => {};
}

const EbbErroModal = ({ onClick }: Props) => {
  const navData = useSelector(selectNavData);
  const { title = '', text = '' } = navData?.errorMsg;

  return (
    <Styled.Root>
      <Styled.Title>
        <RichText field={title} />
      </Styled.Title>
      <Styled.Text>
        <RichText field={text} />
      </Styled.Text>
      <Styled.ButtonWrapper>
        <Button model="primary" onClick={onClick}>
          Return to form
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Root>
  );
};

export default EbbErroModal;
