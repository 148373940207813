import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCallClientModal } from 'store/selectors';
import Button from '../../components/Button';
import { updateCallClientModalResponse, updateOpenModal } from 'store/appSlice';
import { confirmBuyFlowOrder } from 'store/thunks/buyFlowThunks';
import { useHistory } from 'react-router-dom';

export const CallClientModalContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [switchModals, setSwitchModals] = useState(false);
  const modalContent = useSelector(selectCallClientModal);

  const handleSubmit = () => {
    if (!switchModals) {
      dispatch(confirmBuyFlowOrder({ orderId: modalContent.orderId, type: 'confirmation', params: {} as any }));
      setSwitchModals(true);
    } else {
      history.push('/');
      dispatch(updateCallClientModalResponse(true));
      dispatch(updateOpenModal(null));
    }
  };

  return (
    <div className="fsm-body">
      <RichText
        className="fsm-header-copy call-client"
        tag="h1"
        field={{ value: switchModals ? modalContent.modalResultSummaryTitle : modalContent.modalErrorHeader }}
      />
      <h3 className="call-client-text">
        {switchModals ? modalContent.modalResultSummaryContent : modalContent.modalErrorText}
      </h3>
      {switchModals && <h3 className="call-client-text">{modalContent.orderId}</h3>}
      <Button className="call-client-button" model="primary" type="submit" onClick={handleSubmit}>
        {switchModals ? modalContent.modalResultSummaryButtonText : modalContent.modalErrorButtonText}
      </Button>
    </div>
  );
};

export default CallClientModalContent;
