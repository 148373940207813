import { Text } from '@sitecore-jss/sitecore-jss-react';
import PhoneNumber from 'components/PhoneNumber';
import React from 'react';
import errorConstants from '../../constants/errors';

const AddressNotServiceabbleModalContent = (props) => {
  return (
    <div className="fsm-body">
      <Text className="fsm-header-copy" tag="h1" field={{ value: errorConstants.addressNotServiceable.header }} />
      <Text
        className="fsm-try-calling-us"
        tag="h3"
        field={{ value: errorConstants.addressNotServiceable.tryCallingUs }}
      />

      <PhoneNumber
        className="fsm-phone-number"
        phoneNumberLinkTarget={errorConstants.addressNotServiceable.phoneNumber.split('.').join()}
      >
        {errorConstants.addressNotServiceable.phoneNumber}
      </PhoneNumber>
      {/* <a
        href={`tel:+${errorConstants.addressNotServiceable.phoneNumber.split('.').join()}`}
        className="fsm-phone-number"
      >
        {errorConstants.addressNotServiceable.phoneNumber}
      </a> */}
    </div>
  );
};

export default AddressNotServiceabbleModalContent;
