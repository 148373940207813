import { AppState } from 'store/state.model';

export const sharedActionReducers = {
  updateOpenModal: (state, { payload }) => {
    state.openModal = payload;
  },
  updateServiceabilityFormLoading: (state, { payload }) => {
    state.isServiceabilityLoading = payload;
  },
  updateServiceablility: (state, { payload }) => {
    state.serviceability = payload;
  },
  updateServiceabilityMovingAddess: (state, { payload }) => {
    state.serviabilityMovingAddress = payload;
  },
  updateModernizationEligible: (state, { payload }) => {
    state.modernizationEligible = payload;
  },
  updateFiberProducts: (state, { payload }) => {
    state.fiberProducts = payload;
  },
  updateNavData: (state, { payload }) => {
    state.navData = payload;
  },
  updateIsFetching: (state, { payload }) => {
    state.isFetching = payload;
  },
  updateVoiceTermsAccepted: (state, { payload }) => {
    state.voiceTermsAccepted = payload;
  },
  updateBtnClicked: (state, { payload }) => {
    state.btnClicked = payload;
  },
  updateMessagePageLoading: (state, { payload }) => {
    state.messagePageLoading = payload;
  },
  updatePersonalInfo: (state, { payload }) => {
    state.personalInfo = payload;
  },
  updateProductListTechnology: (state, { payload }) => {
    state.productListTechnology = payload;
  },
  updateLastPostalCode: (state, { payload }) => {
    state.lastPostalCode = payload;
  },
  updateServiceLocation: (state, { payload }) => {
    state.serviceLocation = payload || {
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: ''
    };
  },
  updateGmtOffset: (state, { payload }) => {
    state.gmtOffset = { value: payload.value, manualEntry: payload.manualEntry };
  },
  updateAlternateBillingAddress: (state, { payload }) => {
    state.alternateBillingAddress = payload;
  },
  updateFiberDisclaimers: (state, { payload }) => {
    state.siteCoreFiberDisclaimers = payload;
  },
  updateLoadingState: (state, { payload }) => {
    state.loadingState = payload;
  },
  updateCustomLoadingMessage: (state, { payload }) => {
    state.customLoadingMessage = payload;
  },
  updateServiceAddressInteractionPage: (state, { payload }) => {
    state.serviceAddressInteractionPage = payload;
  },
  updateOpenLeadFormModal: (state, { payload }) => {
    state.openLeadFormModal = payload;
  },
  updateGetAQuoteText: (state, { payload }) => {
    state.getAQuoteCTA.text = payload;
  },
  updateShowGetAQuoteAfterCheckServiceability: (state, { payload }) => {
    state.getAQuoteCTA.showGetAQuoteAfterCheckServiceability = payload;
  },
  updateShowingProspecForm: (state, { payload }) => {
    state.getAQuoteCTA.showingProspecForm = payload;
  },
  updateProspectId: (state, { payload }) => {
    state.prospectData.id = payload;
  },
  updateQuoteId: (state: AppState, { payload }) => {
    state.quoteId = payload;
  },
  updateQuoteNumber: (state: AppState, { payload }) => {
    state.quoteNumber = payload;
  },
  updateAccountUuid: (state: AppState, { payload }) => {
    state.accountUuid = payload;
  },
  updateShowImportantMessage: (state, { payload }) => {
    state.showImportantMessage = payload;
  },
  updateRoutingParamsMap: (state, { payload }) => {
    state.routingParamsMap = payload;
  },
  updateComparisonModal: (state, { payload }) => {
    state.openComparisonModal = payload;
  },
  updateTotalEstimatedFirstBill: (state, { payload }) => {
    state.totalEstimatedFirstBill = payload;
  },
  updateLeadAuditId: (state: AppState, { payload }) => {
    state.leadAuditId = payload;
  },
  updateTrafficSource: (state: AppState, { payload }) => {
    state.trafficSource = payload;
  },
  updatePPUserData: (state, { payload }) => {
    state.ppUserData = payload;
  },
  updatePPCustomerInfo: (state, { payload }) => {
    state.ppCustomerInfo = payload;
  },
  updateDeactivateUserModal: (state, { payload }) => {
    state.deactivateUserModal = payload;
  },
  updateNewUserModal: (state, { payload }) => {
    state.newUserModal = payload;
  },
  updatePPUpdateUserStatus: (state, { payload }) => {
    state.ppUpdateUserStatus = payload;
  },
  updateReactivateUserModal: (state, { payload }) => {
    state.reactivateUserModal = payload;
  },
  updateUnlockUserModal: (state, { payload }) => {
    state.unlockUserModal = payload;
  },
  updateCancelQuoteModal: (state, { payload }) => {
    state.cancelQuoteModal = payload;
  },
  updatePPTermsAndConditionsModal: (state, { payload }) => {
    state.ppTermsAndConditionsModal = payload;
  },
  updateResetPasswordModal: (state, { payload }) => {
    state.resetPasswordModal = payload;
  },
  updateOpenPosIDModal: (state, { payload }) => {
    state.openPosIDModal = payload;
  },
  updatePpAditionalHeaders: (state, { payload }) => {
    state.ppAditionalHeaders = payload;
  },
  updateIsFrapiProduct: (state, { payload }) => {
    state.isFrapiProduct = payload;
  },
  updateOpenQuotes: (state, { payload }) => {
    state.openQuotes = payload;
  },
  updateLowIncomeModal: (state, { payload }) => {
    state.lowIncomeModal = payload;
  },
  updatePPUserListSearch: (state, { payload }) => {
    state.ppUserListSearch = payload;
  },
  updateVoiceProductDetailsModalFields: (state, { payload }) => {
    state.voiceProductDetailsModalFields = payload;
  },
  updateOpenEmergencyNumberServiceDisclosureModal: (state, { payload }) => {
    state.openEmergencyNumberServiceDisclosureModal = payload;
  },
  updateRecordLocatorNumber: (state, { payload }) => {
    state.recordLocatorNumber = payload.recordLocatorNumber;
  },
  updateOpenQuotePreviewModal: (state, { payload }) => {
    state.openQuotePreviewModal = payload;
  },
  updateOpenQuoteRecapModal: (state, { payload }) => {
    state.openQuoteRecapModal = payload;
  },
  updateOpenCancellationReasonModal: (state, { payload }) => {
    state.openCancellationReasonModal = payload;
  },
  updateCancellationReasonModalField: (state, { payload }) => {
    state.cancellationReasonModalField = payload;
  },
  updateSnackbarIsOpen: (state, { payload }) => {
    state.snackbarIsOpen = payload;
  },
  updateDeEnrollmentModal: (state, { payload }) => {
    state.deEnrollModalState = payload;
  },
  updateEmailAlreadyEnrolledError: (state, { payload }) => {
    state.emailAlreadyEnrolledError = payload;
  },
  updateAutoPayRegistrationError: (state, { payload }) => {
    state.autopayRegistrationError = payload;
  },
  updateIsCustomerInformatonValidated: (state: AppState, { payload }) => {
    state.isCustomerInformationValidated = payload;
  },
  updateIsQuoteValidated: (state: AppState, { payload }) => {
    state.isQuoteValidated = payload;
  },
  updateIsQuoteBeingValidated: (state: AppState, { payload }) => {
    state.isQuoteBeingValidated = payload;
  },
  updateIsEligibleEBBData: (state: AppState, { payload }: { payload: AppState['isEligibleEBBData'] }) => {
    state.isEligibleEBBData = payload;
  },
  updateTelephonePortingFormValues: (state: AppState, { payload }) => {
    state.telephonePortingFormValues = payload;
  },
  updateIsCreditCheckShown: (state: AppState, { payload }) => {
    state.isCreditCheckShown = payload;
  },
  updateRenderPlaceholder: (state: AppState, { payload }) => {
    state.renderPlaceholder = payload;
  },
  updateSummaryTotalValue: (state: AppState, { payload }) => {
    state.summaryTotalValue = payload;
  },
  updateSummaryBackpayValue: (state: AppState, { payload }) => {
    state.summaryBackpayValue = payload;
  },
  updateSummaryDepositValue: (state: AppState, { payload }) => {
    state.summaryDepositValue = payload;
  },
  updatePaidRouter:(state: AppState, { payload }) => {
    state.paidRouter = payload;
  },
  updateFreeRouter:(state: AppState, { payload }) => {
    state.freeRouter = payload;
  },
  updateResumePaidRoutersCount:(state: AppState, { payload }) => {
    state.resumePaidRoutersCount = payload;
  }
};
