import { format, parseISO } from 'date-fns';
import find from 'lodash-es/find';
import { searchUsers } from './getAccountInfo';
import normalizeQuoteService from './normalizeQuoteService';

const normalizeQuotes = async ({ partnerPortalApiUrl, quotes }) => {
  if (!quotes || !quotes.length || !partnerPortalApiUrl) {
    return quotes;
  }

  try {
    const { Data: users } = await searchUsers(partnerPortalApiUrl);

    const normalizedQuotes = quotes.map((quote) => {
      const user = find(users?.results, { UID: quote.agent });

      if (!user) {
        return quote;
      }

      return {
        ...quote,
        agent: `${user.profile.firstName} ${user.profile.lastName}`,
        agentId: quote.agent,
        name: quote?.name !== 'undefined' ? quote?.name : '', // Replace undefined name with blank space
        requestedDateISO: quote.requestedDate,
        requestedDate: format(parseISO(quote.requestedDate), 'MM/dd/yyyy'),
        videoService: normalizeQuoteService(quote.videoService),
        voiceService: normalizeQuoteService(quote.voiceService),
        securityService: normalizeQuoteService(quote.securityService),
        dataService: normalizeQuoteService(quote.dataService)
      };
    });

    return normalizedQuotes;
  } catch (err) {
    console.error(err);
    return quotes;
  }
};

export default normalizeQuotes;
