import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ProductionConfiguration, SelectProductCofig } from 'store/api/orderApiTypes';
import { updateBuyFlowOrder } from 'store/appSlice';
import {
  addCustomer,
  completeProductSelection,
  configureProduct,
  creditCheck,
  deleteProduct,
  getQuoteResume,
  selectProduct,
  getAutopayData,
  updateCustomer
} from '../../api/orderApi';
import {
  AddCustomerType,
  CompleteProductSelectionType,
  ConfigureProductType,
  CreditCheck,
  DeleteProductType,
  SelectProductCofigOption,
  SelectProductResponse,
  SelectProductType
} from '../../api/orderApiTypes';
import { OrderQuoteState } from './types';

const dispatchUpdateBuyFlowOrder = (dispatch, getState, response): void => {
  const rootState = getState() as RootState;
  const appState = rootState.appState;
  const newbuyFlowOrderState = {
    ...appState.buyFlowOrder,
    summary: {
      ...appState.buyFlowOrder.summary,
      lineItems: response.data.lineItems
    }
  };

  dispatch(updateBuyFlowOrder(newbuyFlowOrderState));
};

export const configureOrderProduct = createAsyncThunk(
  'orders/configureProduct',
  async (payload: ConfigureProductType<SelectProductCofig | ProductionConfiguration | SelectProductCofigOption>, { dispatch, getState }) => {
    const response = await configureProduct(payload);

    dispatchUpdateBuyFlowOrder(dispatch, getState, response);

    return { response: response.data };
  }
);

export const selectOrderProduct = createAsyncThunk(
  'orders/selectProduct',
  async (payload: SelectProductType, { dispatch, getState }) => {
    const response = await selectProduct(payload);

    dispatchUpdateBuyFlowOrder(dispatch, getState, response);

    return { response: response.data };
  }
);

export const deleteOrderProduct = createAsyncThunk(
  'orders/deleteProduct',
  async (payload: DeleteProductType, { dispatch, getState }) => {
    const response = await deleteProduct(payload);

    dispatchUpdateBuyFlowOrder(dispatch, getState, response);

    return { response: payload.productId };
  }
);

export const createCustomer = createAsyncThunk('orders/customer', async (payload: AddCustomerType, _) => {
  const response = await addCustomer(payload);

  return { response: response.data };
});

export const updateCustomerInfo = createAsyncThunk('orders/customer', async (payload: AddCustomerType, _) => {
  const response = await updateCustomer(payload);

  return { response: response.data };
});

export const completeOrderProductSelection = createAsyncThunk(
  'orders/completeProductSelection',
  async (payload: CompleteProductSelectionType, _) => {
    const response = await completeProductSelection(payload);

    return { response: response.data };
  }
);

export const startCreditCheck = createAsyncThunk('orders/startCreditCheck', async (payload: CreditCheck, _) => {
  const response = await creditCheck(payload);

  return { reponse: response };
});

export const getResumeQuote = createAsyncThunk('orders/getQuoteResume', async (payload: { quoteId: string }) => {
  const result = await getQuoteResume(payload.quoteId);

  return result.data;
});


export const getBillingAutopayData = createAsyncThunk('billing/autopay', async (payload: { quoteId: string }) => {
  const result = await getAutopayData(payload.quoteId);

  return result.data;
});

export const orderQuoteHandlers = (builder: ActionReducerMapBuilder<OrderQuoteState>) => {
  return builder
    .addCase(getResumeQuote.fulfilled, (state: OrderQuoteState, data) => {
      for (const key in data.payload) {
        if (data.payload.hasOwnProperty(key) && !['hasAddressDiscrepancy'].includes(key)) state[key] = data.payload[key];
      }
      if (data.payload.productOffers.find((item) => item.type === 'Voice')) {
        state.isVoiceDirectoryOptionsFetched = true;
      }
      state.completeOrderProductSelection = { fetchedError: false, fetchedSuccess: true, isFetching: false };
      state.configureOrderProduct = { fetchedError: false, fetchedSuccess: true, isFetching: false };
    })
    .addCase(getResumeQuote.rejected, (state, { error }) => {
      state.isVoiceDirectoryOptionsFetched = true;
      state.configureOrderProduct.isFetching = false;
      state.configureOrderProduct.isFetching = false;
      state.configureOrderProduct.fetchedError = true;
      state.configureOrderProduct.fetchedSuccess = false;
      console.error('Could not fetch resume quote data', error);
    })
    .addCase(selectOrderProduct.fulfilled, (state: OrderQuoteState, data) => {
      state.completeOrderProductSelection.isFetching = false;
      state.configureOrderProduct.isFetching = false;
      if (data.meta.arg.product?.productType === 'Voice') state.isVoiceDirectoryOptionsFetched = true;

      const responseOffers = data.payload.response;
      const responseOfferType = data.payload.response.type;
      state.productOffers = [
        ...(state.productOffers as SelectProductResponse[]).filter(
          (offer) => offer.type === 'Secure' || offer.type !== responseOfferType
        ),
        responseOffers
      ];
    })
    .addCase(selectOrderProduct.rejected, (state: OrderQuoteState, data) => {
      state.configureOrderProduct.isFetching = false;
      if (data.meta.arg.product?.productType === 'Voice') state.isVoiceDirectoryOptionsFetched = true;
      console.error('Could not select order product', data.error);
    })
    .addCase(selectOrderProduct.pending, (state, data) => {
      state.completeOrderProductSelection.isFetching = true;
      state.configureOrderProduct.isFetching = true;
      if (data.meta.arg.product?.productType === 'Voice') state.isVoiceDirectoryOptionsFetched = false;
    })
    .addCase(configureOrderProduct.pending, (state: OrderQuoteState, _) => {
      state.completeOrderProductSelection.isFetching = false;
      state.configureOrderProduct.isFetching = true;
      state.configureOrderProduct.fetchedError = false;
      state.configureOrderProduct.fetchedSuccess = true;
    })
    .addCase(configureOrderProduct.fulfilled, (state: OrderQuoteState, data) => {
      const configurations = data.payload.response.configurations;
      const isBroadBandConfiguration = data.payload.response.type === 'Broadband' ? true : false;
      const nonConfigurableConfigurations = configurations.find((item) => item.type === 'NonConfigurable');
      const hasRouterExtenderConfig = nonConfigurableConfigurations?.options.find(
        (i) => i.id === 'cdfba0fb-c58b-444e-9c47-c20097560561'
      );

      const hasRouterExtender = !!hasRouterExtenderConfig && isBroadBandConfiguration ? true : false;
      state.isVoiceDirectoryOptionsFetched = true;
      state.configureOrderProduct.isFetching = false;
      state.configureOrderProduct.fetchedError = false;
      state.configureOrderProduct.fetchedSuccess = true;
      state.childProducts = configurations;
      if (isBroadBandConfiguration) {
        state.hasRouterExtenderConfiguration = hasRouterExtender;
      }
    })
    .addCase(configureOrderProduct.rejected, (state: OrderQuoteState, { error }) => {
      state.isVoiceDirectoryOptionsFetched = true;
      state.configureOrderProduct.isFetching = false;
      state.configureOrderProduct.fetchedError = true;
      state.configureOrderProduct.fetchedSuccess = false;
      console.error('Could not configure order', error);
    })
    .addCase(deleteOrderProduct.pending, (state: OrderQuoteState) => {
      state.deleteOrderProduct.isFetching = true;
    })
    .addCase(deleteOrderProduct.rejected, (state: OrderQuoteState) => {
      state.deleteOrderProduct.isFetching = false;
    })
    .addCase(deleteOrderProduct.fulfilled, (state: OrderQuoteState, data) => {
      state.deleteOrderProduct.isFetching = false;
      state.productOffers = [
        ...(state.productOffers as SelectProductResponse[]).filter((offer) => offer.productId !== data.payload.response)
      ];
    })
    .addCase(completeOrderProductSelection.pending, (state: OrderQuoteState, _) => {
      state.completeOrderProductSelection.isFetching = true;
      state.completeOrderProductSelection.fetchedSuccess = true;
      state.completeOrderProductSelection.fetchedError = false;
    })
    .addCase(completeOrderProductSelection.fulfilled, (state: OrderQuoteState, _) => {
      state.completeOrderProductSelection.isFetching = false;
      state.completeOrderProductSelection.fetchedSuccess = true;
      state.completeOrderProductSelection.fetchedError = false;
    })
    .addCase(completeOrderProductSelection.rejected, (state: OrderQuoteState, _) => {
      state.completeOrderProductSelection.isFetching = false;
      state.completeOrderProductSelection.fetchedSuccess = false;
      state.completeOrderProductSelection.fetchedError = true;
    });
};
