import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { MergedProductType } from 'components/ProductList';
import { analyticsReducers, buyFlowReducers, ebbReducers, errorReducer, sharedActionReducers } from 'store/reducers';
import { buyFlowHandlers, ebbFlowHandlers, sharedHandlers } from 'store/thunks';
import {
  AppState,
  CallClientModal,
  Covid19Notice,
  DisclaimerModal,
  OrderSummary,
  OrderType,
  PaymentUrlType,
  PPCustomerInfo,
  VerifierQuestions
} from './state.model';

export const ProductType = {
  INTERNET: 'INTERNET',
  VIDEO: 'VIDEO',
  VOICE: 'VOICE',
  STREAMING: 'STREAMING',
  SWEETNER: 'SWEETNER',
  BROADBAND: 'BROADBAND',
  SECURE: 'SECURE'
};

export const initialState: AppState = {
  isServiceabilityLoading: false,
  voiceTermsAccepted: false,
  deEnrollModalState: {
    cancelModalCancelButtonText: {
      value: ''
    },
    cancelModalContinueButtonText: {
      value: ''
    },
    cancelModalText: {
      value: ''
    },
    cancelModalTitle: {
      value: ''
    }
  },
  emailAlreadyEnrolledError: {
    modalText: {
      value: ''
    },
    modalTitle: {
      value: ''
    }
  },
  autopayRegistrationError: {
    modalText: {
      value: ''
    }
  },
  linkedSku: '',
  mergedBuyFlowProducts: [] as MergedProductType[],
  featureMap: {} as any,
  cacheId: '',
  validateAllForms: false,
  autoPay: true,
  cart: [],
  confirmationNumber: null,
  deposit: 0,
  installationTime: null,
  installationTimes: [],
  lastDateTimeStamp: '',
  hasActiveProdIdChanged: '',
  installationSchedule: [],
  notificationPreferences: {
    alternateMobileNumber: '',
    installationScheduleUpdatesEnabled: false,
    marketingEmailEnabled: false,
    alternateMailingAddress: {
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: ''
    }
  },
  buyFlowProducts: [],
  paperless: true,
  paymentMethod: null,
  personalInfo: {
    email: null,
    mobileNumber: null,
    firstName: null,
    lastName: null
  },
  accountUuid: '',
  quote: null,
  quoteId: -1,
  quoteNumber: '',
  receiveInstallationUpdates: true,
  lastPostalCode: '',
  serviceLocation: {
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: ''
  },
  gmtOffset: { value: null, manualEntry: true },
  alternateBillingAddress: null,
  termsAndConditionsAccepted: false,
  voipConditionsAccepted: false,
  openModal: null,
  orderIsValid: false,
  paymentInfo: null,
  serviceability: null,
  modernizationEligible: false,
  fiberProducts: [],
  prospectInfo: null,
  prospectData: { id: '' },
  leadFormData: { id: '' },
  getAQuoteCTA: {
    text: '',
    showGetAQuoteAfterCheckServiceability: false
  },
  openLeadFormModal: false,
  productListTechnology: '',
  navData: null,
  messagePageLoading: false,
  isFetching: false,
  showImportantMessage: false,
  btnClicked: '',
  adobeAnalytics: { evars: { eVar49: 'unverified service area' } },
  errorModal: {
    errorCode: '',
    contentSelector: '',
    errorContent: {
      header: '',
      phoneNumber: '',
      tryCallingUs: ''
    }
  },
  activeBuyFlowInternetProduct: '',
  voiceSelected: null,
  voicePrice: 10.0,
  buyFlowOrderSummary: {} as OrderSummary,
  buyFlowOrder: {} as OrderType,
  buyFlowChaseIframeUrl: '',
  buyFlowFiserve: {} as PaymentUrlType,
  siteCoreFiberDisclaimers: [] as any,
  siteCoreBuyFlowDisclaimers: [] as any,
  covid19Notice: {} as Covid19Notice,
  verifierQuestions: {} as VerifierQuestions,
  sixMonthAddress: null,
  covidModalResponse: null,
  verifierQuestionResponse: null,
  existingQuotePaidDeposit: null,
  callClientModal: {} as CallClientModal,
  callClientModalResponse: null,
  productsPrettyNames: null,
  productsBySku: null,
  bFastLastSku: null,
  analyticsCartId: '',
  loadingState: false,
  customLoadingMessage: '',
  serviceAddressInteractionPage: null,
  progressBarSteps: [],
  routingParamsMap: {},
  openComparisonModal: false,
  totalEstimatedFirstBill: 0,
  leadAuditId: '',
  trafficSource: '',
  disclaimerModal: {} as DisclaimerModal,
  acceptedCardsModal: [],
  numberNotPortableModal: [],
  paidToday: 0.0,
  progressStepIndex: null,
  searchUsersList: [],
  assetsList: [],
  ppUserData: {} as any,
  ppCustomerInfo: {} as PPCustomerInfo,
  ppUpdateUserStatus: {} as any,
  deactivateUserModal: false,
  cancelQuoteModal: false,
  newUserModal: false,
  reactivateUserModal: false,
  unlockUserModal: false,
  resetPasswordModal: false,
  ebbAck: {
    accountId: '',
    acceptAck: false
  },
  codeToProducMap: null,
  selectedProductCode: null,
  isAutopayEnrolled: true,
  autoPayTermsAccepted: false,
  isPaperlessEnrolled: null,
  isSecureOptionsSelected: false,
  isPaperlessBillingSumitted: false,
  ppAditionalHeaders: {
    channel: '',
    vendor: '',
    office: '',
    assisted: true,
    requestingApplication: 'PartnerPortal',
    soldBy: '16181',
    issuedBy: '16181'
  },
  isFrapiProduct: false,
  openEmergencyNumberServiceDisclosureModal: false,
  recordLocatorNumber: '',
  openQuotePreviewModal: false,
  openCancellationReasonModal: false,
  cancellationReasonModalField: '',
  openQuoteRecapModal: false,
  voiceSelectionOption: null,
  voiceDirectorySelection: null,
  serviabilityMovingAddress: {
    showModal: false,
    serviceType: null,
    searchParams: null,
    productPath: null,
    hasPendingQuotes: null
  },
  snackbarIsOpen: false,
  quoteDataToCSV: null,
  selectedSecureProductIds: [],
  selectedSecureProductAddonsIds: [],
  selectedAvailableSecureProductAddons: {},
  currentSecureProductsConfigured: false,
  unsortedProductList: [],
  highSpeedApprovalOption: false,
  isCustomerInformationValidated: false,
  isQuoteValidated: false,
  isQuoteBeingValidated: false,
  isEligibleEBBData: {
    showBanner: false,
    isEligible: false
  },
  telephonePortingFormValues: {
    accountNumber: '',
    accountName: '',
    accountStreetAddress: '',
    accountAddressLine2: '',
    accountCity: '',
    accountState: '',
    accountZipCode: '',
    accountPin: '',
    accountPassword: '',
    telephoneNumber: ''
  },
  isPosIdModalOpen: false,
  isPosIdRequired: false,
  hasCreditFreeze: false,
  isCreditCheckShown: true,
  hasAddressDiscrepancy: false,
  resumedWithAddressDiscrepancy: false,
  renderPlaceholder: {
    renderStepOne: true,
    renderStepTwo: false,
    renderStepThree: false,
    renderStepFour: false
  },
  keepCurrentPhoneNumber: false,
  summaryTotalValue: null,
  summaryBackpayValue: null,
  summaryDepositValue: null,
  shouldActionsButtonsBeEnabled: false,
  portabilityTelephoneNumber: null,
  isCalendarErrorOpen: false,
  isInternetProductSelectionActive: false,
  isEbbFlow: false,
  paidRouter: {} as any,
  freeRouter: {} as any,
  resumePaidRoutersCount: 0,
  fundingAccountLastFourDigits: { method: '', digits: '' },
  selectedPaperlessProduct: {} as any,
  addressDiscrepancyModal: false,
  addressUnKnownField: false,
  allowEditPreviousAddress: false,
  retainSSN: false,
  addressDescrepancyErrorCode: '',
  pendingInOrderNumber: null,
  isStepTwoCompleted: false,
  communicationPreferences: null,
  offerType: '',
  eeroSecureAddon: null,
  portedTelephoneNumber: null,
  isEeroSecureSelected: false,
  selectedEeroSecureAddonId: [],
  wholeHomeWifiProduct: null,
  wholeHomeWifiProductId: [],
  isExpertInstallSelected: false,
  expertInstallProduct: null,
  partialInstallSelect: false,
  fullInstallSelect: false
};

const combinedReducers = (builder) =>
  combineReducers<any>({
    sharedHandlers: sharedHandlers(builder),
    buyFlowHandlers: buyFlowHandlers(builder),
    ebbFlowHandlers: ebbFlowHandlers(builder)
  });
export const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    ...buyFlowReducers,
    ...sharedActionReducers,
    ...analyticsReducers,
    ...ebbReducers,
    ...errorReducer
  },
  extraReducers: (builder) => combinedReducers(builder)
});

export const {
  updateCart,
  updateInstallationTime,
  updateNotificationPreferences,
  updatePersonalInfo,
  updateLastPostalCode,
  updateServiceLocation,
  updateGmtOffset,
  updateTermsAndConditionsAccepted,
  updateEmergencyVoip,
  updateOpenModal,
  updateOpenPosIDModal,
  updateErrorModal,
  updateServiceablility,
  updateModernizationEligible,
  updateFiberProducts,
  updateNavData,
  updateIsFetching,
  updateBtnClicked,
  updateAdobePageName,
  updateAdobeUserType,
  updateAdobeEvars,
  updateAdobeEvents,
  resetAdobeEvarsAndEvents,
  updateMessagePageLoading,
  clearErrorModal,
  updateProductListTechnology,
  updateSelectedProduct,
  updateSelectedVoiceProduct,
  updateBuyFlowProducts,
  updatedVoiceSelected,
  updateBuyFlowDisclaimers,
  updateFiberDisclaimers,
  updateAlternateBillingAddress,
  updateBuyFlowOrderSummary,
  updateCovid19Notice,
  updateSixMonthAddress,
  updateCovidModalResponse,
  updatePrettyProducts,
  updateProductsBySku,
  updateBFastLastSku,
  updateCartId,
  updateLoadingState,
  updateCustomLoadingMessage,
  updateServiceAddressInteractionPage,
  setFeatureMap,
  updateMergedBuyFlowProducts,
  updateProgressBarSteps,
  updateBuyFlowOrder,
  updateOpenLeadFormModal,
  updateProspectId,
  updateAccountUuid,
  updateGetAQuoteText,
  updateShowGetAQuoteAfterCheckServiceability,
  updateShowingProspecForm,
  updateVoicePrice,
  updateLinkedSku,
  updateVerifierQuestion,
  updateExistingQuotePaidDeposit,
  updateVerifierQuestionResponse,
  updateCallClientModal,
  updateCallClientModalResponse,
  resetBuyFlowProducts,
  updateShowImportantMessage,
  updateRoutingParamsMap,
  updateComparisonModal,
  updateTotalEstimatedFirstBill,
  updateLeadAuditId,
  updateTrafficSource,
  updateDisclaimerModal,
  updateAcceptedCardsModal,
  updateNumberNotPortableModal,
  updateInstallationSchedule,
  updatePaidToday,
  updateIsAddressDiscrepancyModalOpen,
  updateAddressUnknownField,
  updateRetainSSN,
  updateAllowEditPreviousAddress,
  updateResumedWithAddressDiscrepancy,
  updateProgressStepIndex,
  updateIsAutopayEnrolled,
  updateOfferType,
  updateIsPaperlessEnrolled,
  updateIsSecureOptionsSelected,
  updatePPUserData,
  updatePPCustomerInfo,
  updateCancelQuoteModal,
  updatePPTermsAndConditionsModal,
  updateLowIncomeModal,
  updatePPUpdateUserStatus,
  updateDeactivateUserModal,
  updateNewUserModal,
  updateReactivateUserModal,
  updateUnlockUserModal,
  updateResetPasswordModal,
  ebbUpdateAcknowledgementAccepted,
  ebbUpdateAccountId,
  updateCodeToProduct,
  updateSelectedProductCode,
  updatePpAditionalHeaders,
  updateIsFrapiProduct,
  updateOpenQuotes,
  updatePPUserListSearch,
  updateVoiceProductDetailsModalFields,
  updateOpenEmergencyNumberServiceDisclosureModal,
  updateRecordLocatorNumber,
  updateOpenQuotePreviewModal,
  updateOpenCancellationReasonModal,
  updateCancellationReasonModalField,
  updateOpenQuoteRecapModal,
  updateVoiceSelectionOption,
  updateVoiceDirectorySelection,
  updateServiceabilityMovingAddess,
  updateSnackbarIsOpen,
  updateDeEnrollmentModal,
  updateEmailAlreadyEnrolledError,
  updateAutoPayRegistrationError,
  updateAutoPayTermsAccepted,
  updateIsPaperlessBillingSumitted,
  updateQuoteDataToCSV,
  updateSelectedSecureProductIds,
  updateSelectedSecureProductAddonsIds,
  updateAvailableSecureProductAddons,
  updateSelectedPaperlessProduct,
  updateCurrentSecureProductsConfigured,
  updateIsCustomerInformatonValidated,
  updateIsQuoteValidated,
  updateIsQuoteBeingValidated,
  resetPastActiveBuyFlowInternetProduct,
  resetPastQuoteId,
  updateVoiceTermsAccepted,
  updateServiceabilityFormLoading,
  updateUnsortedProductList,
  updateHighSpeedApprovalOption,
  updateQuoteId,
  updateQuoteNumber,
  updateIsEligibleEBBData,
  updateTelephonePortingFormValues,
  updateHasAddressDiscrepancy,
  updateIsPosIdModalOpen,
  updateIsPosIdRequired,
  updateHasCreditFreeze,
  updateIsCalendaErrorOpen,
  updateIsCreditCheckShown,
  updateRenderPlaceholder,
  updateKeepCurrentPhoneNumber,
  updateSummaryTotalValue,
  updateCommunicationPreferences,
  updateSummaryBackpayValue,
  updateSummaryDepositValue,
  updateShouldActionsButtonsBeEnabled,
  updatePortabilityTelephoneNumber,
  updateIsCalendarErrorOpen,
  updateIsInternetProductSelectionActive,
  updateIsEbbFlow,
  updatePaidRouter,
  updateFreeRouter,
  updateResumePaidRoutersCount,
  updateFundingAccountLastFourDigits,
  updatePendingInOrderNumber,
  updateAddressDescErrorCode,
  updateIsStepTwoCompleted,
  updateEeroSecureAddon,
  updatePortedTelephoneNumber,
  updateIsEeroSecureSelected,
  updateSelectedEeroSecureAddonId,
  updateWholeHomeWifiProduct,
  updateWholeHomeWifiProductId,
  updateIsExpertInstallSelected,
  updateExpertInstallProduct,
  updatePartialInstallSelect,
  updateFullInstallSelect
} = appSlice.actions;
export default appSlice.reducer;
