export default {
  ebbErrorCodes: {
    EB012: 'INCOMPLETE_BQP_INFORMATION',
    EB005: 'INVALID_ADDRESS',
    // EB005: 'INVALID_ADDRESS_LINE',
    EB006: 'INVALID_CITY_STATE_ZIP',
    // EB007: 'INVALID_LASTNAME',
    EB007: 'INVALID_NAME',
    EB008: 'INVALID_LASTNAME',
    EB009: 'INVALID_SSN',
    EB010: 'INVALID_TRIBAL_ID',
    // EB010: 'INVALID_TRIBALID_FORMAT',
    // EB007: 'LASTNAME_REQUIRED',
    // EB009: 'SSN_AND_TRIBAL_BQP',
    // EB005: 'SUBSCRIBER_ADDRESS_CANNOT_BE_PO_BOX',
    EB011: 'INCOMPLETE_BQP_INFORMATION',
    // EB005: 'SUBSCRIBER_BQP_MATCH',
    EB013: 'INVALID_APPLICATION_ID'
  },
  ebbNoEligibleErrorCodes: {
    EB004: 'APPLICATION_NOT_COMPLETE',
    EB001: 'APPLICATION_NOT_FOUND',
    // EB004: 'APPLICATION_PENDING',
    EB002: 'STATE_FED_FAIL',
    // EB001: 'SUBSCRIBER_NOT_FOUND',
    // EB001: 'SUBSCRIBER_NOT_FOUND_ERROR',
    // EB001: 'TPIV_FAIL',
    // EB001: 'TPIV_FAIL_DECEASED',
    // EB001: 'TPIV_FAIL_DOB',
    // EB001: 'TPIV_FAIL_IDENTITY_NOT_FOUND',
    // EB001: 'TPIV_FAIL_NAME_SSN4',
    EB003: 'TRIBAL_BENEFIT_FLAG_DEFICIENT_CONSUMER_INFO'
    // EB003: 'TRIBAL_BENEFIT_FLAG_NONTRIBAL_CONSUMER_LOCATION',
    // EB003: 'TRIBAL_BENEFIT_FLAG_NONTRIBAL_NLAD_LOCATION',
  },

  ebbErrorContent: {
    INCOMPLETE_BQP_INFORMATION: {
      header: ``,
      text:
        'Please make sure the {Benefit Qualifying Person Information} you entered on our form matches the USAC application exactly.'
    },
    INVALID_ADDRESS: {
      header: ``,
      text: 'Please make sure the {address} you entered on our form matches the USAC application exactly.'
    },
    INVALID_CITY_STATE_ZIP: {
      header: ``,
      text: 'Please make sure the {city, state and zip} you entered on our form matches the USAC application exactly.'
    },
    INVALID_NAME: {
      header: ``,
      text: 'Please make sure the {name} you entered on our form matches the USAC application exactly.'
    },
    INVALID_LASTNAME: {
      header: ``,
      text: 'Please make sure the {name} you entered on our form matches the USAC application exactly.'
    },
    INVALID_SSN: {
      header: ``,
      text: 'Please make sure the {SSN} you entered on our form matches the USAC application exactly.'
    },
    INVALID_TRIBAL_ID: {
      header: ``,
      text: 'Please make sure the {Tribal ID} you entered on our form matches the USAC application exactly.'
    },
    INVALID_APPLICATION_ID: {
      header: ``,
      text: 'The application id you provided is invalid.  Please try again'
    }
  }
};
