import * as React from 'react';
import Styled from './PPButton.styled';
import { ButtonProps } from './PPButton.types';

const Button: React.FC<ButtonProps> = ({ children, isLoading, variant = 'primary', ...htmlProps }: ButtonProps) => {
  if (isLoading) {
    return (
      <Styled.Button {...htmlProps} disabled={true} variant={variant}>
        Loading...
      </Styled.Button>
    );
  }

  return (
    <Styled.Button {...htmlProps} variant={variant}>
      {children}
    </Styled.Button>
  );
};

Button.defaultProps = {
  isLoading: false,
  variant: 'primary',
  type: 'button'
};

export default Button;
