/**
 * TODO: put types on params
 * change function name to buildBFSearchParams
 * use in a pretty way, use all values
 *
 */

export const buildBuyFlowUrl = (request, samRecords, postalCode, technologyType) => {
  const urlParams = {
    street_line: request.address,
    // unitNumber: request.address2 || null,
    unitNumber: '',
    // isUnit: !!request.address2,
    isUnit: '',
    // unitType: isSmbProspect.value && address2 ? 'suite' : address2 ? 'apartment' : null,
    // unitType: null,
    unitType: '',
    city: request.city,
    state: request.state,
    zip: postalCode,
    // productId: sku,
    // productId: null,
    productId: '',
    // leadId: prospectData.id,
    // leadId: null,
    leadId: '',
    // icid: adobeInternalCampaignId.value,
    // icid: null,
    icid: '',
    // interactionPage: serviceAddressInteractionPage,
    // interactionPage: null,
    interactionPage: '',
    technology: technologyType === 'IONT' ? 'FIBER' : technologyType,
    env: samRecords?.environment,
    controlNumber: samRecords?.controlNumber
    /*isFrapiProducts: isFrapiProducts.value*/
  };

  const searchParams = new URLSearchParams(urlParams);
  return searchParams;
};
