import store from 'store';
import { updateAdobePageName } from 'store/appSlice';
import { saveAdobeAnalyticsPagename } from 'utils/adobeAnalyticsHandler';
/**
 * this function executes an analytrics tracking script
 * @param  data -data object to pass to analytics that includes all events and vars
 * @param type - the tracking type
 * @param pageName - friendly link name or url for which this event is firing from
 */
export const adobeTrackPageLoad = (adobeAnalytics: any, data: DataType = {}): void => {
  if (typeof window !== 'undefined' && window.s_dtm) {
    store.dispatch(updateAdobePageName(adobeAnalytics.pageName));
    saveAdobeAnalyticsPagename(adobeAnalytics.pageName);
    // User Type (eVar22) "every-page" variable
    const userType = adobeAnalytics.eVar22 ? adobeAnalytics.eVar22 : 'visitor';
    const eVar49 = adobeAnalytics.eVar49 ? adobeAnalytics.eVar49 : 'unverified service area';

    // Combine data from data layer and helper to build dataObject
    const dataObject = {
      pageName: adobeAnalytics.pageName,
      eVar22: userType,
      eVar49,
      events: adobeAnalytics.events ? adobeAnalytics.events : null,
      ...adobeAnalytics.evars,
      ...data
    };

    !dataObject.events && delete dataObject.events;

    // removing eVar22 and eVar49 when page is 'buy/confirmation'
    const isConfirmationPage = adobeAnalytics.pageName === '/buy/confirmation' ? true : false;

    isConfirmationPage && delete dataObject.eVar22;
    isConfirmationPage && delete dataObject.eVar49;

    // Call Adobe with final data object, track type, and optional descriptive pageName
    window.s_dtm.trackData(dataObject);
  } else {
    console.error('cannot perform adobeTrackPageLoad function, one or more arguments are incorrect');
  }
};

export const adobeTrackData = (
  data: DataType,
  trackType: TrackType = TrackType.Standard,
  descriptivePageName: string | null = null
): void => {
  if (typeof window !== 'undefined' && window.s_dtm) {
    const pageName = store.getState().appState.adobeAnalytics.pageName;
    // Call Adobe with final data object, track type, and optional descriptive pageName
    data.pageName = data.pageName ? data.pageName : pageName;
    window.s_dtm.trackData(data, trackType, descriptivePageName);
  } else {
    console.error('cannot perform adobeTrackPageLoad function, one or more arguments are incorrect');
  }
};

/**
 * @property events - takes a string of comma seperated event values
 * @property pageName - descriptive link or route
 * @property eVar2 - form name
 * @property eVar14 - communication preferences
 * @property eVar22 - user type
 * @property eVar49 - serviceable area
 * @property eVar50 - location lookup. e.g: fiber:zipcode
 * @property eVar99 - Activation method
 * @property eVar88 - Api error
 */
export type DataType = {
  events?: string;
  pageName?: string;
  eVar2?: string;
  eVar14?: string;
  eVar22?: string;
  eVar49?: 'unverified service area' | 'not in service area' | 'in service area';
  eVar50?: string;
  eVar99?: string;
  eVar88?: string;
  [key: string]: any;
};

export enum PageName {
  Landing = 'ftr:landing',
  Order = 'ftr:order',
  Summary = 'ftr:order-summary',
  FiberResidential = 'ftr:fiber-expansion:residential',
  FiberBusiness = 'ftr:fiber-expansion:business'
}
export class DataObject {
  constructor(args: DataType) {
    Object.assign(this, args);
  }
  /**
   * adds an event to current event string
   * @param event an event string. e.g: event1
   */
  public addEvent(event: string): string {
    if (!this.events) return (this.events = event.trim());
    const eventArray = this.events?.split(',');
    eventArray?.push(event.trim());
    return (this.events = eventArray?.join());
  }
  /**
   * adds a list of events to current event string
   * @param event an array ofevent strings. e.g: ['event1', 'event2']
   */
  public addEvents(events: string[]): string {
    if (!this.events) return (this.events = events.join());
    events.forEach((element) => this.addEvent(element));
    return this.events;
  }

  public removeEvent(event: string): void {
    const eventArr = this.events?.split(',');
    const newEventArr = eventArr?.filter((e) => e !== event);
    this.events = newEventArr?.join();
  }

  public getEvents = () => this.events;

  public resetEvents(): void {
    this.events = undefined;
  }
}
// tslint:disable-next-line:no-empty-interface
export interface DataObject extends DataType {}

// UTILITY

/**
 * @property Standard - a standard click
 * @property Download - a click to download a file
 * @property Exit - a click event that takes you  off the main site
 */
export enum TrackType {
  Standard = 'tl_o',
  Download = 'tl_d',
  Exit = 'tl_e'
}
