import { runOnlyOnSafari } from './browserUtil';

const scrollToHashURL = (location) => {
  if (location.hash) {
    runOnlyOnSafari(() => {
      const scroll = () => {
        const targetEl = document.getElementById(location.hash.replace('#', ''));
        const elRect = targetEl ? targetEl.getBoundingClientRect() : null;

        if (targetEl && elRect) {
          window.scrollTo({
            top: window.scrollY + elRect.y - 125,
            behavior: 'smooth'
          });

          if (elRect.y > 130 && elRect.y < 120) window.requestAnimationFrame(scroll);
        }
      };
      setTimeout(() => {
        window.requestAnimationFrame(scroll);
      }, 1000);
    });
  }
};

export default scrollToHashURL;
