import { Forms } from 'constants/forms';
import apiErrors from 'constants/apiErrors';

const CONSTANTS = {
  paperBillFeeProdId: '46524048-3557-4a40-a5ba-5f16497c395e'
};

export const errorReducer = {
  updateErrorState: (state, { payload }) => {
    switch (payload.form) {
      case Forms.ADDRESS:
        state.addressValid = payload.valid;
        break;
      case Forms.PERSONALINFO:
        state.personalInfoValid = payload.valid;
        break;
      case Forms.CREDITCARD:
        state.creditCardValid = payload.valid;
        break;
      case Forms.BANKACCOUNT:
        state.bankAccountValid = payload.valid;
        break;

      default:
        break;
    }
  },
  updateErrorModal: (state, { payload }) => {
    const paperLessProd = state.buyFlowProducts.filter((item) => item.type === 'ADD ON'&& item.productId === CONSTANTS.paperBillFeeProdId)?.[0];
    const paperBillPrice = paperLessProd?.price?.value || 0 
    const errorModalState = state.errorModal;
    errorModalState.errorCode = payload?.type || payload;
    errorModalState.contentSelector = apiErrors.apiErrorCodes[errorModalState.errorCode];
    errorModalState.errorContent = { ...apiErrors.apiErrorContent[errorModalState.contentSelector] };
    if(errorModalState.errorCode === "CF020") {
      errorModalState.errorContent.tryCallingUs = errorModalState.errorContent.tryCallingUs.replace("$XX.XX", '$'+parseFloat(paperBillPrice.toString()).toFixed(2));
    }
    if(errorModalState?.errorCode === "PENDING_QUOTE" || errorModalState?.errorCode === "STEP1_PENDING_QUOTE"){
      errorModalState.errorContent.header = (errorModalState.errorContent.header.replace("######", payload.data)).replace('"','');
    }
    state.errorModal = { ...errorModalState };
  },
  clearErrorModal: (state) => {
    state.openModal = null;
    state.errorModal = {
      errorCode: '',
      contentSelector: '',
      errorContent: {
        header: '',
        phoneNumber: '',
        tryCallingUs: ''
      }
    };
  }
};
