import { config } from 'dotenv';
import { runSafeSsr } from 'utils/browserUtil';

/**
 * Type holding application configuration values. Think: Things that change from environment to environment
 * @property customerAcquisitionApiUrl - Customer Acquisition API URL
 * @property customerAcquisitionApiKey - Customer Acquisition API Key
 * @property serviceabilityApiUrl - Customer Acquisition API URL
 * @property serviceabilityApiUrl - Customer Acquisition API URL
 */
export interface AppConfig {
  customerAcquisitionApiUrl: string;
  customerAcquisitionApiKey: string;
  frapiCustomerAcquisitionApiUrl: string;
  frapiCustomerAcquisitionApiKey: string;
  serviceabilityApiUrl: string;
  serviceabilityApiKey: string;
  tosApiUrl: string;
  tosApiKey: string;
  tosUserName: string;
  tosAuthToken: string;
  autoCompleteApiKey: string;
  sitecoreApiKey: string;
  sitecoreApiUrl: string;
  buyFlowBaseURL: string;
  authUser: string;
  authPassword: string;
  ciuApiUrl: string;
  sitecoreOAuthHostUrl: string;
  addressManagementUrl: string;
  addressManagementApiKey: string;
  eventsUrl: string;
  eventsApiKey: string;
}

// Let the AppConfig get loaded from dotenv
declare var process: {
  env: {
    REACT_APP_CUSTOMER_ACQUISITION_API_URL: string;
    REACT_APP_CUSTOMER_ACQUISITION_API_KEY: string;
    REACT_APP_FRAPI_CUSTOMER_ACQUISITION_API_URL: string;
    REACT_APP_FRAPI_CUSTOMER_ACQUISITION_API_KEY: string;
    REACT_APP_SERVICEABILITY_API_URL: string;
    REACT_APP_SERVICEABILITY_API_KEY: string;
    REACT_APP_OSS_TOS_API_URL: string;
    REACT_APP_OSS_TOS_API_KEY: string;
    REACT_APP_OSS_TOS_USERNAME: string;
    REACT_APP_OSS_TOS_AUTH_TOKEN: string;
    REACT_APP_OSS_CIU_API_URL: string;
    REACT_APP_GOOGLE_AUTOCOMPLETE_KEY: string;
    REACT_APP_SITECORE_API_KEY: string;
    REACT_APP_SITECORE_LAYOUT_SERVICE_HOST: string;
    REACT_APP_SITECORE_ACCESS_TOKEN_HOST_URL: string;
    REACT_APP_BUYFLOW_BASE_URL: string;
    REACT_APP_AUTH_USER: string;
    REACT_APP_AUTH_PASSWORD: string;
    NODE_ENV: string;
    REACT_APP_ADDRESS_MANAGEMENT_API_URL: string;
    REACT_APP_ADDRESS_MANAGEMENT_API_KEY: string;
    REACT_APP_EVENTS_API_URL: string;
    REACT_APP_EVENTS_API_KEY: string;
  };
};

// Let the AppConfig get loaded from the window object on the browser
declare global {
  interface Window {
    app: AppConfig;
    s_dtm: any;
  }
}

/**
 * Load the AppConfig
 * Depnending on whether you're in a server or browser context, this will come from .env or the window object respectively
 * @return the AppConfig
 */
export function loadConfig(): AppConfig {
  const isProdEnv = process.env.NODE_ENV === 'production';

  return runSafeSsr(
    () => {
      // We DON'T want to use dotenv in production environment
      // Because environment variables will be provided by sitecore
      return loadServerConfig(!isProdEnv);
    },
    () => {
      // One last check here. If we are in DISCONNECTED or CONNECTED mode, we need to load environment from dotenv
      // Assuming that a non-production bundle will be disconnected or connected
      if (isProdEnv) {
        return window.app;
      } else {
        return loadServerConfig(!isProdEnv);
      }
    }
  );
}

// Load configuration via dotenv
function loadServerConfig(useDotEnv: boolean): AppConfig {
  // Load variables into dotenv
  if (useDotEnv) {
    config();
  }

  return {
    customerAcquisitionApiUrl: process.env.REACT_APP_CUSTOMER_ACQUISITION_API_URL,
    customerAcquisitionApiKey: process.env.REACT_APP_CUSTOMER_ACQUISITION_API_KEY,
    frapiCustomerAcquisitionApiUrl: process.env.REACT_APP_FRAPI_CUSTOMER_ACQUISITION_API_URL,
    frapiCustomerAcquisitionApiKey: process.env.REACT_APP_FRAPI_CUSTOMER_ACQUISITION_API_KEY,
    serviceabilityApiUrl: process.env.REACT_APP_SERVICEABILITY_API_URL,
    serviceabilityApiKey: process.env.REACT_APP_SERVICEABILITY_API_KEY,
    tosApiUrl: process.env.REACT_APP_OSS_TOS_API_URL,
    tosApiKey: process.env.REACT_APP_OSS_TOS_API_KEY,
    tosUserName: process.env.REACT_APP_OSS_TOS_USERNAME,
    tosAuthToken: process.env.REACT_APP_OSS_TOS_AUTH_TOKEN,
    autoCompleteApiKey: process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY,
    sitecoreApiKey: process.env.REACT_APP_SITECORE_API_KEY,
    sitecoreApiUrl: process.env.REACT_APP_SITECORE_LAYOUT_SERVICE_HOST,
    sitecoreOAuthHostUrl: process.env.REACT_APP_SITECORE_ACCESS_TOKEN_HOST_URL,
    buyFlowBaseURL: process.env.REACT_APP_BUYFLOW_BASE_URL,
    authUser: process.env.REACT_APP_AUTH_USER,
    authPassword: process.env.REACT_APP_AUTH_PASSWORD,
    ciuApiUrl: process.env.REACT_APP_OSS_CIU_API_URL,
    addressManagementUrl: process.env.REACT_APP_ADDRESS_MANAGEMENT_API_URL,
    addressManagementApiKey: process.env.REACT_APP_ADDRESS_MANAGEMENT_API_KEY,
    eventsUrl: process.env.REACT_APP_EVENTS_API_URL,
    eventsApiKey: process.env.REACT_APP_EVENTS_API_KEY
  };
}
