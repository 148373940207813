import { Text } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const VerificationCodeNotAcceptedModalContent = (props) => {
  return (
    <div className="fsm-body">
      <Text className="fsm-header-copy" tag="h1" field={{ value: 'The code you entered did not match.' }} />
      <Text className="fsm-try-calling-us" tag="h3" field={{ value: 'Please try again.' }} />
    </div>
  );
};

export default VerificationCodeNotAcceptedModalContent;
