import axios from 'axios';
import reverse from 'lodash-es/reverse';
import slice from 'lodash-es/slice';
import generateRequestHeaders from 'utils/generateRequestHeaders';
import { getUserSpace, getUserUID } from 'utils/partnerPortalUserStorage';

export interface AttributeAssets {
  video?: string[];
  officeManagerRoleID?: string[];
  agentRoleID?: string[];
  FTRChannelManagerRoleID?: string[];
  FTRRegionalManagerRoleID?: string[];
  editableFields?: string[];
  partnerAdminRoleID?: string[];
  channelName?: string;
  channelID?: string;
  roles?: string[];
}

export interface AllowedAssets {
  type: string;
  path: string;
  actions: string[];
  attributes: AttributeAssets;
}

export interface AssetsData {
  callId: string;
  errorCode: number;
  errorDetails?: string;
  errorMessage?: string;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
  allowedAssets: AllowedAssets[];
  businessEntities: any;
}

export interface AssetsPayload {
  Data?: AssetsData;
  ErrorDetails?: AssetsData;
  Params: any;
}

export interface Role {
  id: string;
  label: string;
}

export type GetRolesAssets = (assets?: AssetsData, userUID?: string | null) => Role[] | null;

export type GetAssets = (
  partnerPortalApiUrl: string,
  userUID?: string | null,
  userBpId?: string | null
) => Promise<AssetsPayload>;

export type GetAgentRoleID = (
  partnerPortalApiUrl: string,
  userUID?: string | null,
  userBpId?: string | null
) => Promise<string | null>;

export type GetNotAllowedUserRoles = (
  organizationRoles: Role[] | null,
  userRolesInOrganization?: string[] | null,
  newOrEditedUserRoles?: string[]
) => any;

export type GetUserLevel = (organizationRoles: Role[], userRoles: string[]) => number;

export type GetRoleNames = (organizationRoles?: Role[] | null, roleIds?: string[] | null) => string[] | [];

export type GetRoleLevel = (organizationRoleIds?: string[] | null, userRoleIds?: string[] | null) => number;

export const getRolesAssets: GetRolesAssets = (assets, uid) => {
  if (!assets || !uid) {
    return null;
  }

  const asset = assets?.allowedAssets.find((allowedAsset) => !!allowedAsset?.attributes?.roles);

  if (!asset || !asset?.attributes?.roles) {
    return null;
  }

  let roles: Role[] | null;

  try {
    /* tslint:disable */
    roles = eval(asset?.attributes?.roles[0]) as Role[];
    /* tslint:enable */
  } catch {
    roles = null;
  }

  return roles;
};

export const getNotAllowedUserRoles: GetNotAllowedUserRoles = (
  organizationRoles,
  userRolesInOrganization,
  newOrEditedUserRoles = []
) => {
  if (!organizationRoles || !organizationRoles.length || !userRolesInOrganization || !userRolesInOrganization.length) {
    return organizationRoles;
  }

  const reverseOrganizationRoles = reverse([...organizationRoles]);

  // If user is agent can be unselected
  if (newOrEditedUserRoles[0] === reverseOrganizationRoles[0].id) {
    return [...slice(reverseOrganizationRoles, 1)];
  }

  /* TODO: post merge with 6/20 investigate if the following "if" statement will replace
  the one above "isNewUser" does not seem to be a pramenter present here
    // If user is agent, at New User can be unselected, if Edited cannot be changed
    if (newOrEditedUserRoles[0] === reverseOrganizationRoles[0].id) {
      return isNewUser ? [...slice(reverseOrganizationRoles, 1)] : reverseOrganizationRoles;
    }*/

  const userLevel = getUserLevel(organizationRoles, userRolesInOrganization);
  const notAllowedUserRoles =
    userLevel === 3 || userLevel === 4
      ? [...slice(reverseOrganizationRoles, userLevel + 1)]
      : [...slice(reverseOrganizationRoles, userLevel)];

  // If none was checked, can check any allowed options, if has roles, cannot check Agent
  if (newOrEditedUserRoles.length > 0) {
    notAllowedUserRoles.unshift({ ...reverseOrganizationRoles[0] });
  }

  return notAllowedUserRoles;
};

export const getUserLevel: GetUserLevel = (organizationRoles, userRoles) => {
  const reverseOrganizationRoles = reverse([...organizationRoles]);

  return userRoles.reduce((num, userArole) => {
    for (let roleLevel = 0; roleLevel < reverseOrganizationRoles.length; roleLevel++) {
      if (reverseOrganizationRoles[roleLevel].id === userArole) {
        num = Math.max(num, roleLevel);
        break;
      }
    }

    return num;
  }, 0);
};

export const getRoleNames: GetRoleNames = (organizationRoles, roleIds) => {
  if (!organizationRoles || !roleIds) {
    return [];
  }

  return roleIds?.reduce<string[] | []>((arr, roleId) => {
    const role = organizationRoles.find((rolePriority) => rolePriority.id === roleId);

    if (!role) {
      return arr;
    }

    return [...arr, role.label];
  }, []);
};

export const getRoleLevel: GetRoleLevel = (userRoleIds, organizationRoleIds) => {
  if (!organizationRoleIds || !organizationRoleIds.length || !userRoleIds || !userRoleIds.length) {
    return -1;
  }

  return userRoleIds.reduce((level, role) => {
    return Math.max(level, organizationRoleIds.indexOf(role));
  }, -1);
};

const getAssets: GetAssets = (partnerPortalApiUrl, userUID, userBpId) =>
  new Promise(async (resolve, reject) => {
    const headers = generateRequestHeaders();
    const uid = getUserUID();
    const bpid = getUserSpace();

    if (!headers || (!userUID && !uid) || (!bpid && !userBpId)) {
      return reject('You must be logged in.');
    }

    try {
      const { data, status, statusText } = await axios.post<AssetsPayload>(
        `${partnerPortalApiUrl}gigya/api/getAssets`,
        { uid: userUID || uid, bpid: userBpId || bpid },
        { ...headers }
      );

      if (data.ErrorDetails) {
        return reject(data.ErrorDetails);
      }

      if (status !== 200) {
        return reject(statusText);
      }

      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export default getAssets;

export const getAgentRoleID: GetAgentRoleID = async (partnerPortalApiUrl, userUID, userBpId) => {
  let roleID: string | null = null;

  try {
    const assets = await getAssets(partnerPortalApiUrl, userUID, userBpId);
    const allowedAssets = assets.Data?.allowedAssets;
    const allowedAsset = allowedAssets?.find((asset) => asset.path === 'userManagement');

    roleID = allowedAsset?.attributes.agentRoleID?.length ? allowedAsset?.attributes.agentRoleID[0] : null;
  } catch (err) {
    console.error(err);
  }

  return roleID;
};
