import PhoneIcon from '@material-ui/icons/Phone';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useSitecoreContext from 'utils/useSitecoreContext';

interface PhoneNumberPropsType {
  className?: string;
  children: any;
  phoneNumberLinkTarget: string;
  asIconButton: boolean;
  asIconLink: boolean;
  viewBox?: string;
  isStatic?: boolean;
}

const PhoneNumber = ({
  className = '',
  phoneNumberLinkTarget,
  asIconButton,
  asIconLink,
  viewBox,
  isStatic = false
}: Partial<PhoneNumberPropsType>) => {
  const sitecoreContext = useSitecoreContext();
  const isInvocaEnabled = sitecoreContext.route?.fields?.invocaEnabled?.value;

  useEffect(() => {
    // @ts-ignore
    const intervalID = setInterval(() => {
      if (typeof window === 'undefined' || !isInvocaEnabled || isStatic) {
        return;
      }

      // @ts-ignore
      window.Invoca?.PNAPI?.run();
      // @ts-ignore
      clearInterval(intervalID);
    }, 1000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div>
    {(asIconButton || asIconLink) && <StyledLinkPhoneNumberIcon viewBox={viewBox} />}
    <p className={`${className} ${isStatic ? '' : 'InvocaSpanClass'}`}>{phoneNumberLinkTarget}</p>
    </div>
  );
};

const StyledLinkPhoneNumberIcon = styled(PhoneIcon)`
  && {
    fill: ${({ theme }) => theme.white};
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
`;

const StyledPhoneNumber = styled(PhoneNumber).attrs(
  ({ asIconButton = false, asIconLink = false, ...props }): Partial<PhoneNumberPropsType> => ({
    asIconButton,
    asIconLink,
    ...props
  })
)`
  ${({ asIconLink, theme }) =>
    asIconLink &&
    `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${theme.mineShaft};
    font-family: PPObjectSans, sans-serif;
    font-size: 16px;
    font-weight: 900;
    text-decoration: underline;

    ${StyledLinkPhoneNumberIcon}${StyledLinkPhoneNumberIcon} {
      fill: ${theme.crimson};
    }

    & > span {
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
      }

      text-decoration: underline;
    }
  `}
  ${({ asIconButton, theme }) =>
    asIconButton &&
    `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border: none;
    height: auto;
    font-size: 16px;
    min-width: unset;
    border-radius: 0.25rem;
    text-align: center;
    object-fit: contain;
    font-weight: 900;
    line-height: 0.9em;
    padding: 15px 30px;
    transition: opacity background-color color 0.25s;
    box-sizing: border-box;
    min-width: 160px;
    position: relative;
    cursor: pointer;
    background-color: ${theme.crimson};
    border: 1px solid ${theme.crimson};
    color: ${theme.white};

    & > span {
      display: inline-block;
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }

    & + & {
      margin-left: 10px;
    }

    &:focus,
    &:hover {
      text-decoration:none;

      &:not(:disabled) {
        background-color: #be2227;
        border: 1px solid #be2227;
        color: ${theme.white};
      }
    }
  `}

  ${({ asIconButton, theme }) =>
    asIconButton &&
    theme.tabletPortrait`
    padding: 10px 24px;
  `}

  ${({ asIconButton, theme }) =>
    asIconButton &&
    theme.tabletLandscape`
    &:hover:not(:disabled) {
      background-color: ${theme.crimson};
      border: 1px solid ${theme.crimson};
    }
  `}
`;

export default StyledPhoneNumber;
