import React from 'react';
import { useSelector } from 'react-redux';
import { selectAcceptedCardsModal } from 'store/selectors';
import styled from 'styled-components';
import { CREDIT_CARD_PRIORITY_LIST, CARD_DEFAULT_PRIORITY } from '../../constants/creditCardPriority';

export const AcceptedCardsModal = ({ className }) => {
  const acceptedCardsModalItems = useSelector(selectAcceptedCardsModal);
  const acceptedCards = acceptedCardsModalItems.map((obj) => {
    const priorityObject = CREDIT_CARD_PRIORITY_LIST.find(
      (priorityItem) => priorityItem.creditCardBrand === obj.fields.image.value.alt
    );
    const priorityCard = { priority: priorityObject?.priority ?? CARD_DEFAULT_PRIORITY, ...obj };
    return priorityCard;
  });

  const sortedAcceptedCards = acceptedCards.sort((a, b) => {
    return a.priority - b.priority;
  });

  return (
    <div className={`${className} fsm-accepted-cards`}>
      <h2 className="modal-title"> Accepted Cards </h2>
      <div className="modal-disclaimer-text">
        <p>We accept Visa, Mastercard, American Express, Discover, NYCE, PLUS and Star.</p>
      </div>
      <div className="modal-card-images">
        {sortedAcceptedCards?.map((item) => (
          <img key={item.id} src={item.fields?.image?.value?.src} alt={item.fields?.image?.value?.alt} />
        ))}
      </div>
    </div>
  );
};

const StyledAcceptedCardsModal = styled(AcceptedCardsModal)`
  ${({ theme }) => `
    &.fsm-accepted-cards {
      font-size: 1rem;
      text-align: center;

      .modal-disclaimer-text {
        max-height: 30rem;
        overflow-y: auto;
        font-size: 16px;
        margin: 16px 74px 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #141928;

        p {
          margin-bottom: 0;
        }
      }

      .modal-card-images {
        max-width: 390px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        .accepted-card-img {
          width: 72px;
          height: 50px;
          border-radius: 3px;
          background-color: #333;
        }
      }
      
      
      .modal-title {
        color: ${theme.mineShaft};
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      .confirm-button {
        display: block;
        margin-top: 3rem;
      }
    }
  `}
  ${({ theme }) => theme.tabletPortrait`
  `}
  ${({ theme }) => theme.mobile`
    &.fsm-accepted-cards {
      .modal-disclaimer-text {
        max-height: 23rem;
        font-size: 0.875rem;
        overflow-y: auto;
        margin: 16px auto 32px;
      }
    }
  `}
`;

export default StyledAcceptedCardsModal;
