import React from 'react';
import styled from 'styled-components';
import { selectAutopayRegistrationError } from '../../store/selectors/sharedSelectors';
import { useSelector } from 'react-redux';
const AutoPayRegistrationErrorModal = styled.div`
  display: flex;
  flex-direction: column;
  .autopay-enrolled-error-title {
    font-family: PPObjectSans;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #333;
  }
  .autopay-enrolled-error-content {
    font-family: PPObjectSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    margin: 16px 0 32px;
    color: #333;
  }
`;
const AutoPayNotRegisteredErrorModal = () => {
  const autoPayErrorModalContent = useSelector(selectAutopayRegistrationError);
  
  return (
    <AutoPayRegistrationErrorModal>
      <span className="autopay-enrolled-error-content">{autoPayErrorModalContent.modalText}</span>
    </AutoPayRegistrationErrorModal>
  );
};
export default AutoPayNotRegisteredErrorModal;
