import React from 'react';
import { useDispatch } from 'react-redux';
import { updateOpenModal } from 'store/appSlice';
import styled from 'styled-components';
import Button from '../../components/Button';

const NumberNotPortableModal = ({ className }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateOpenModal(null));
  };

  return (
    <StyledModal className={`${className} fsm-number-not-portable-modal`}>
      <h2 className="modal-title">Sorry, telephone number is not portable</h2>
      <Button onClick={handleClick} className="btn-primary ">
        Continue With New Telephone Number
      </Button>
    </StyledModal>
  );
};

const StyledNumberNotPortableModal = styled(NumberNotPortableModal)``;

const StyledModal = styled.div`
  text-align: center;

  .modal-title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export default StyledNumberNotPortableModal;
