import { Text } from '@sitecore-jss/sitecore-jss-react';
import { TextProp } from 'models';
import React from 'react';
import styled from 'styled-components';
import { uid } from 'utils/uidGenerator';

interface RadioButtonProps {
  id?: string;
  name: string;
  field?: TextProp;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

function RadioButton({ id, field, checked, onChange, name, className, disabled = false }: RadioButtonProps) {
  id = id || uid();

  const labelContainerStyle = { display: 'flex' };
  return (
    <>
      <label className={className} htmlFor={id} style={disabled ? { opacity: 0.5, ...labelContainerStyle } : { ...labelContainerStyle }}>
        <div>
          <input id={id} name={name} type="radio" checked={checked} onChange={onChange} disabled={disabled} />
          <span className="radio-bullet"></span>
        </div>
        <div>
          {field && <Text className="radio-text" tag="span" field={field} />}
        </div>
      </label>
    </>
  );
}

const StyledRadioButton = styled(RadioButton)`
  ${({ theme }) => `
    cursor: pointer;
    display: block;

    input {
      opacity: 0;
      position: absolute;

      &:checked {
        & + .radio-bullet {
          &:before {
            opacity: 1;
            width: 0.875rem;
            height: 0.875rem;
          }
        }
      }
    }

    .radio-bullet {
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid ${theme.gray};
      background: ${theme.white};
      border-radius: 100%;
      margin-right: 8px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-radius: inherit;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        background: ${theme.crimson};
        opacity: 0;
        transition: 0.3s;
      }
    }

    .radio-text {
      line-height: 1.5rem;
      vertical-align: top;
      width: 1.5rem;
    }
  `}
`;

export default StyledRadioButton;
