function normalizeToPatternWithDDD(phoneNumberString) {
  return phoneNumberString?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

function normalizeToPatternUSA(phoneNumberString) {
  return phoneNumberString?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

export const formatPhoneNumber = (phoneNumberString: string, phoneNumberPattern: string) => {
  if (phoneNumberString === null || phoneNumberString === '') {
    return null;
  }

  phoneNumberString = phoneNumberString?.toString().replace(/[^\d]/g, '');

  if (phoneNumberPattern === 'usePatternWithDDD') {
    return normalizeToPatternWithDDD(phoneNumberString);
  }

  if (phoneNumberPattern === 'usePatternUSA') {
    return normalizeToPatternUSA(phoneNumberString);
  }
};

export default formatPhoneNumber;
