const normalizeQuoteService = (data: string) => {
  if (/^\s*(y|yes)\s*$/i.test(data)) {
    return 'Yes';
  }

  if (/^\s*(n|no)\s*$/i.test(data)) {
    return 'No';
  }

  return data;
};

export default normalizeQuoteService;
