import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MergedProductType } from 'components/ProductList';
import InternetProductComparison from 'ModalContent/InternetPickerProductModal/InternetProductComparison';
import { ApiProductType } from 'models/Enums';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectFeatureMap } from 'store/selectors';
import { selectMergedBuyFlowProducts } from 'store/selectors/buyFlowSelectors';
import styled from 'styled-components';

const InternetPickerProductModal = (props) => {
  const { className } = props;
  const featureMap = useSelector(selectFeatureMap);
  const products: MergedProductType[] = useSelector(selectMergedBuyFlowProducts).filter(
    (product) => product.productDescription.value.slice(0, 2) === ApiProductType.SinglePlay
  );
  const sortedProducts = products.slice().sort((a, b) => Number(b.productImageSpeed.value) - Number(a.productImageSpeed.value));

  const sortFeatureMap = () => {
    const newFeatureMap = [] as any;
    for (const [feature, prods] of Object.entries(featureMap)) {
      newFeatureMap.push({ feature, prods });
    }
    return newFeatureMap.sort((a, b) => b.prods.length - a.prods.length);
  };
  const rows = sortFeatureMap();
  return (
    <div className={className}>
      <Text encode={false} field={{ value: 'Compare Available Internet Options' }} tag="h2" />
      <InternetProductComparison featureMap={featureMap} products={sortedProducts} rows={rows} />
    </div>
  );
};

const StyledInternetPickerProductModal = styled(InternetPickerProductModal)`
  h2 {
    color: #141928;
    font-size: 1.5rem;
    line-height: 1.33;
    // Negative margin top is used in order to get the design adjustment without touch the general modal values
    margin: -2rem auto 2.5rem auto;
    text-align: center;
  }
  ${({ theme }) => theme.mobile`
    width: 100%;
    h2 {
      overflow-wrap: anywhere;
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
      font-family: PPObjectSans, sans-serif;
      font-size: 1.5rem;
      line-height: 1.33;
    }
  `}
`;

export default StyledInternetPickerProductModal;
