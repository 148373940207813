import styled, { css } from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import SnackbarStyled from '../PPSnackbar/PPSnackbar.styled';
import { StyledButtonProps } from './PPButton.types';

const variantsStyle = {
  primary: css`
    border: 1px solid ${({ theme }) => theme.crimson};
    background-color: ${({ theme }) => theme.crimson};
    color: ${({ theme }) => theme.white};
  `,
  outlined: css`
    border: 1px solid currentColor;
    background-color: transparent;
    color: ${({ theme }) => theme.crimson};
  `,
  underlined: css`
    border: 1px solid transparent;
    text-decoration: underline;
    background-color: transparent;
    color: ${({ theme }) => theme.mineShaft};
  `,
  secondary: css`
    border: 1px solid ${({ theme }) => theme.crimson};
    background-color: ${({ theme }) => theme.crimson};
    color: ${({ theme }) => theme.white};
    &:hover {
      &:not(:disabled) {
        .circle-loader {
          span {
            background-color: ${({ theme }) => theme.crimson};
          }
        }
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.silver};
        color: ${({ theme }) => theme.crimson};
      }
    }
    &:focus {
      &:not(:disabled) {
        background-color: ${({ theme }) => theme.crimson};
        border: 1px solid ${({ theme }) => theme.crimson};
        color: ${({ theme }) => theme.white};
      }
    }
    &:disabled {
      background-color: ${({ theme }) => theme.blue};
      border: 1px solid ${({ theme }) => theme.blue};
      opacity: 0.3;
      cursor: not-allowed;
    }
  `
};

const Button = styled.button.attrs(({ theme, ...attrs }: StyledButtonProps) => attrs)`
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: PPObjectSans, sans-serif;
  font-weight: 600;
  line-height: 1.25;
  white-space: nowrap;
  text-decoration: none;
  padding: 10px 24px;
  height: 40px;
  transition: opacity background-color color 0.25s;
  text-align: center;
  min-width: ${({ isLoading }) => (isLoading ? '0' : '160px')};
  ${({ variant }) => variantsStyle[variant || 'primary']};

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  ${SnackbarStyled.Root} & {
    font-size: 14px;
    font-weight: 900;
    flex-shrink: 0;
    min-width: 0;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width: ${breakpoints.lg}) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

export default {
  Button
};
