import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import { SnackbarProps } from './PPSnackbar.types';

const Root = styled.div.attrs((attrs: SnackbarProps) => attrs)`
  background-color: ${({ isLoginFlow, theme }) => (isLoginFlow ? '#fceaea' : theme.white)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.25s;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media screen and (min-width: ${breakpoints.lg}) {
    position: static;
    margin-bottom: ${({ isLoginFlow }) => (isLoginFlow ? '0' : '22px')};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 0;

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 21px 0;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    button {
      margin-right: 40px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 20px;
  width: 100%;

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 22px 15px 22px 40px;
    margin: auto;
    max-width: 1280px;
  }
`;

const Message = styled.p`
  font-size: 14px;
  font-family: PPObjectSans, sans-serif;
  font-weight: normal;
  line-height: 1.14;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0;
  padding: 0 10px;

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0;
  }
`;

export default {
  Root,
  Wrapper,
  Content,
  Message
};
