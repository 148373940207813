import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MergedProductType } from 'components/ProductList';
import InternetProductComparison from 'ModalContent/ModTwoInternetPickerProductModal/InternetProductComparison';
import { ApiProductType } from 'models/Enums';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCodeToProductMap } from 'store/selectors/buyFlowSelectors';
import styled from 'styled-components';

const ModTwoInternetPickerProductModal = (props) => {
  const { className } = props;
  const codeToProductMap = useSelector(selectCodeToProductMap)

  interface ProductsAndFeatures {
    products: MergedProductType[],
    featureMap: any
  }

  const getProductListAndFeatures = (): ProductsAndFeatures => {
    if (!codeToProductMap) {
      return { products: [], featureMap: {} };
    }

    const internetProductKeys = Object.keys(codeToProductMap)?.filter(
      (key) => ((key?.split("_")?.length === 2)
        && (key?.slice(0, 2) === ApiProductType.SinglePlay)));

    const featureMapLocal = {}
    const internetProductList: MergedProductType[] = [];
    internetProductKeys?.forEach((key) => {
      const internetProduct = codeToProductMap[key];

      if (!!internetProduct) {
        internetProduct?.productDetails?.forEach((detailItem) => {
          const detailKey = detailItem.detail.value;
          if (!featureMapLocal[detailKey]) {
            featureMapLocal[detailKey] = []
          }
          featureMapLocal[detailKey].push(internetProduct.sku);
        });
        internetProductList.push(internetProduct);
      }
    });
    return { products: internetProductList, featureMap: featureMapLocal };
  }

  const { products, featureMap } = getProductListAndFeatures();

  const sortFeatureMap = () => {
    const newFeatureMap = [] as any;
    for (const [feature, prods] of Object.entries(featureMap)) {
      newFeatureMap.push({ feature, prods });
    }
    return newFeatureMap.sort((a, b) => b.prods.length - a.prods.length);
  };

  const rows = sortFeatureMap();

  return (
    <div className={className}>
      <Text field={{ value: 'Compare Available Internet Options' }} tag="h2" />
      <InternetProductComparison featureMap={featureMap} products={products} rows={rows} />
    </div>
  );
};

const StyledModTwoInternetPickerProductModal = styled(ModTwoInternetPickerProductModal)`
  h2 {
    color: #141928;
    font-size: 1.5rem;
    line-height: 1.33;
    margin: 0rem auto 2.5rem auto;
    text-align: center;
  }
  ${({ theme }) => theme.mobile`
    width: 100%;
    h2 {
      overflow-wrap: anywhere;
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }
  `}
`;

export default StyledModTwoInternetPickerProductModal;
