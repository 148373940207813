import { runSafeSsr } from '../utils/browserUtil';

/**
 * Configuration items that change depending on context
 */

export interface SitecoreContextConfig {
  invocaTag?: string;
}

declare global {
  interface Window {
    __JSS_STATE__: any;
  }
}

/**
 * Load the clientside sitecore context from the __JSS_STATE__
 */
export function loadSSRSitecoreContext() {
  runSafeSsr(
    () => {
      return {};
    },
    () => {
      return JSON.parse(window.__JSS_STATE__.innerHTML).sitecore.context;
    }
  );
}

export function loadClientSideSitecoreConfig(sitecoreContext): SitecoreContextConfig {
  return {
    invocaTag: sitecoreContext.invocaConfiguration?.invocaTag
  };
}
