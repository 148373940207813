import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceabilityRequestType } from 'models/AddressFormTypes';
import { FrapiAppApi } from 'store/api/FrapiAppApi';
import { SelectProductResponse } from 'store/api/orderApiTypes';
import { updateServiceLocation, updateGmtOffset } from 'store/appSlice';
import { updateProductOffers } from 'store/slices/OrderQuote/orderQuoteSlice';
import { OrderQuoteState } from 'store/slices/OrderQuote/types';
import {
  AppState,
  BuyFlowProduct,
  CancelReasonProps,
  FiberProduct,
  FrapiReserveInstallationProps,
  OrderType,
  ServiceLocation
} from 'store/state.model';
import normalizeQuotes from 'utils/normalizeQuotes';
import { getUserOrganization, getUserSpace, getUserUID } from 'utils/partnerPortalUserStorage';

export const getAvailableProductsFrapi = createAsyncThunk(
  'app/getAvailableProductsFrapi',
  async (payload: { address: ServiceLocation; technology: string }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const { technology, address } = payload;
    const requestTechnology = !!state.serviceability?.iONT ? 'iont' : technology;
    const env = state.serviceability?.environment;
    const controlNumber = state.serviceability?.controlNumber;

    const result = await FrapiAppApi.getAvailableProductsFrapi({
      address,
      technology: requestTechnology,
      leadAuditId: state.leadAuditId,
      env,
      controlNumber
    });
    const data = result.data.length > 0 ? (result.data as FiberProduct[]) : null;
    return data;
    // return result.data.length > 0 ? (result.data as FiberProduct[]) : null;
  }
);

function getCopperProductName(matchingBuyFlowProduct: BuyFlowProduct | undefined) {
  const basicInternetSpeed = 11999;
  const downloadSpeed = Number(matchingBuyFlowProduct?.dataspeed?.downloadInKbps || '0');
  return downloadSpeed > basicInternetSpeed ? 'Frontier Internet' : 'Frontier Basic Internet';
}

/* FRAPI SERVICEABILITY AVAILABLE PRODUCTS */
export const getBuyFlowServiceabilityFrapi = createAsyncThunk(
  'app/getAvailableBuyflowProductsFrapi',
  async (
    payload: {
      history;
      request: ServiceabilityRequestType;
      technology: string;
      env: string;
      controlNumber: string;
      ppAdditionalHeaders: any;
      quoteId?: string;
    },
    { dispatch, getState }
  ) => {
    const { request, technology, env, controlNumber, ppAdditionalHeaders, quoteId } = payload;
    const newState: any = getState();
    const appState: AppState = newState.appState;
    const envForCall = !!env ? env : appState.serviceability?.environment;
    // const isLowIncome = appState.isEbbFlow
    const controlNumberForCall = !!controlNumber ? controlNumber : appState.serviceability?.controlNumber;

    const requestTechnology = !!appState.serviceability?.iONT ? 'FIBER' : technology;
    const result = await FrapiAppApi.getAvailableBuyflowProductsFrapi({
      technology: requestTechnology,
      env: envForCall,
      controlNumber: controlNumberForCall,
      ppAdditionalHeaders,
      ...(quoteId && { quoteId })
    });
    dispatch(updateServiceLocation(request));
    
    if (quoteId) {
      const availableProducts = result.data.availableProducts as BuyFlowProduct[];
      const orderState: OrderQuoteState = newState.orderQuote;
      const resumeTechnology = orderState.technology;
      const productOffers = orderState.productOffers as SelectProductResponse[];
      const isStepOneComplete = orderState.isStepOneComplete;

      const newProductOffers = productOffers.map((product) => {
        const matchingBuyFlowProduct = availableProducts.find((p) => p.productId === product.productId);
        const isCopperProduct = resumeTechnology === 'COPPER' && product.type === 'Broadband';
        // TODO: this is a temp fix for correctly displaying copper product names in Order Summary until siteCore fields are merged into availableProducts on quoteResume
        if (matchingBuyFlowProduct)
          matchingBuyFlowProduct.name = isCopperProduct
            ? getCopperProductName(matchingBuyFlowProduct)
            : matchingBuyFlowProduct.name;

        // return new product with additional discountValue and discountDuration fields and correct pricing for order summary
        return {
          ...product,
          price: matchingBuyFlowProduct?.price || product.price,
          dataspeed: matchingBuyFlowProduct?.dataspeed || product.dataspeed,
          name: isCopperProduct ? getCopperProductName(matchingBuyFlowProduct) : product.name
        };
      });
      dispatch(updateProductOffers(newProductOffers));
    }

    const gmtOffset = result.data.gmtOffset;
    if (typeof gmtOffset === 'number'){
      dispatch(updateGmtOffset({value: gmtOffset, manualEntry: false}));
    }

    /**
     * updated to access product array from response
     * may require change later
     */
    return result.data;
  }
);

/* FRAPI CHASE IFRAME */
export const getChaseIframeUrlFrapi = createAsyncThunk('app/getChaseIframeUrlFrapi', async (_, { getState }) => {
  const newState: any = getState();
  const state: AppState = newState.appState;
  const result = await FrapiAppApi.getChaseIframeUrlFrapi(state.accountUuid);
  return result.data;
});

/* FRAPI CANCEL QUOTE IFRAME */
export const cancelOrderFrapi = createAsyncThunk('app/cancelOrderFrapi', async ({}, { getState }) => {
  // REVIEW
  const newState: any = getState();
  const state: AppState = newState.appState;
  const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({
    params: null,
    type: 'cancel',
    quoteId: state.quoteId // Change to quoteId
  });
  return result.data;
});

export const cancelOpenQuoteFrapi = createAsyncThunk('app/cancelOrderFrapi', async (params: CancelReasonProps) => {
  const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({
    params: { cancelReason: params.cancelReason },
    type: 'cancel',
    quoteId: params.quoteId // Change to quoteId
  });
  return result.data;
});

export const submitOrderFrapi = createAsyncThunk(
  'app/submitOrderFrapi',
  async (payload: { params: { ppAdditionalHeaders } }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;

    const orderPayload = {
      tosAccepted: state.termsAndConditionsAccepted,
      emergencyLimitationsAcknowledged: state.voipConditionsAccepted
    };

    const ppAdditionalHeaders = payload;

    const result = await FrapiAppApi.submitOrderFrapi(state.quoteId, orderPayload, ppAdditionalHeaders);
    return result.data;
  }
);

export const confirmBuyFlowOrderFrapi = createAsyncThunk(
  'app/confirmBuyFlowOrderFrapi',
  async (payload: { type: 'confirmation'; params: { additionalOrderDetails } }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({ ...payload, quoteId: state.quoteId });
    return result.data;
  }
);

export const reserveBuyFlowInstallationTimeFrapi = createAsyncThunk(
  'app/reserveBuyFlowInstallationTimeFrapi',
  async (payload: { type: 'reservation'; params: FrapiReserveInstallationProps }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowOrderReserveInstallationTimeFrapi({ ...payload, quoteId: state.quoteId });
    return result.data;
  }
);

export const submitBuyFlowPaymentFrapi = createAsyncThunk(
  'app/submitBuyFlowPaymentFrapi',
  async (
    payload: {
      type: 'payment';
      params: {
        type: 'CREDIT_CARD' /** hard code CREDIT_CARD */;
        tokenizedPayment /** customer ref number */;
        autopayEnabled /** false */;
        paperlessBillingEnabled /** false */;
        alternateBillingAddress: ServiceLocation | null /** null for now */;
      };
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const result = await FrapiAppApi.buyFlowOrdersInterfaceFrapi({ ...payload, quoteId: state.quoteId });
    return result.data;
  }
);

export const checkNumberPortabilityFrapi = createAsyncThunk(
  'app/checkNumberPortabilityFrapi',
  async (
    payload: {
      phoneNumber;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const quoteId = state.quoteId;

    const result = await FrapiAppApi.checkNumberPortabilityFrapi(payload, quoteId);

    return result.data;
  }
);

export const createBuyFlowOrderFrapi = createAsyncThunk(
  'app/createBuyFlowOrderFrapi',
  async (
    payload: {
      order: {
        firstName;
        lastName;
        mobileNumber;
        email;
        dob;
        productIds;
        serviceLocation: ServiceLocation;
        directoryListing;
        ssn;
        driversLicenseNumber;
        driversLicenseState;
        cacheId: string;
      };
      installationScheduleLimit?: number;
      skipCreditCheck?: boolean;
    },
    { getState }
  ) => {
    const newState: any = getState();
    const state: AppState = newState.appState;
    const quoteId = state.quoteId;

    const result = await FrapiAppApi.createBuyFlowOrderFrapi(payload, quoteId);
    /*
    if (result?.data?.installationSchedule && result?.data?.installationSchedule?.length > 0) {
      const combinedResult = await getAdditionalInstallSchedulesFrapi(result, state);
      return combinedResult;
    } else {
      return result.data;
    }
    */
    return result.data;
  }
);

export const updateBuyFlowProspectFrapi = createAsyncThunk(
  'prospect/updateBuyFlowProspectFrapi',
  async (payload: { prospectInfo }, { getState }) => {
    const newState: any = getState();
    const state: AppState = newState.appState;

    const result = await FrapiAppApi.updateBuyFlowProspect({
      prospectInfo: payload.prospectInfo,
      quoteId: state.quoteId
    });

    return { response: result.data };
  }
);
export const getQuoteQuickViewFrapi = createAsyncThunk(
  'app/getQuoteQuickViewFrapi',
  async (payload: { getQuoteQuickViewPayload }) => {
    const AGENT_ROLE_DEV = '55856123-a77f-4979-8fdb-2ae5738fb44f';
    const AGENT_ROLE_PROD = '41b8652f-8a0f-4e2c-89f6-7a2aa41a27c3';
    const { sitecoreContext, ...restPayload } = payload.getQuoteQuickViewPayload;
    const userSpace = getUserSpace() as any;
    const uid = getUserUID() as string;
    const partnerId = userSpace.split('_')[1] || '';
    const userOrg = getUserOrganization() as any;
    const selectedOrg = userOrg.find((org) => org.bpid === userSpace);
    const agentRole = selectedOrg?.roles?.find((role) => role === AGENT_ROLE_DEV || role === AGENT_ROLE_PROD);

    let params;

    if (!!agentRole) {
      params = new URLSearchParams({
        ...restPayload,
        partnerId,
        agent: uid
      });
    } else {
      params = new URLSearchParams({
        ...restPayload,
        partnerId
      });
    }

    const result = await FrapiAppApi.getQuoteQuickViewFrapi(params);

    const normalizedQuotesResult = await normalizeQuotes({
      partnerPortalApiUrl: sitecoreContext,
      quotes: result.data.quotes
    });
    return { quotes: normalizedQuotesResult, totalQuotes: result.data.totalResults };
  }
);

export const buyFlowHandlersFrapi = (builder: ActionReducerMapBuilder<AppState>) => {
  return builder
    .addCase(getAvailableProductsFrapi.fulfilled, (state, { payload }) => {
      state.fiberProducts = payload;
    })
    .addCase(getQuoteQuickViewFrapi.fulfilled, (_state, { payload }) => {
      console.log('payload', payload);
    })
    .addCase(getAvailableProductsFrapi.rejected, (_state, { error }) => {
      _state.fiberProducts = null;
      console.error('PRODUCT FETCH FAILED', error);
    })
    .addCase(getBuyFlowServiceabilityFrapi.fulfilled, (state, response) => {
      /**
       * updated to access product array from response
       * may require change later
       */
      const { availableProducts: availableProducts, quoteId, quoteNumber, accountUuid } = response.payload;
      const voiceProduct = availableProducts.find((p) => p.type.toUpperCase() === 'VOICE');
      if (accountUuid) {
        state.accountUuid = accountUuid;
        state.cacheId = quoteId;
        state.quoteId = quoteId;
        state.quoteNumber = quoteNumber;
      }
      state.buyFlowProducts = availableProducts.map((item) => {
        return { ...item, type: item.type.toUpperCase() };
      });

      state.voicePrice = !!voiceProduct?.price?.value ? voiceProduct?.price?.value : 25.0;
    })
    .addCase(getBuyFlowServiceabilityFrapi.rejected, (_state, { error }) => {
      console.error('getBuyFlowServiceabilityFrapi FAILED', error);
    })
    .addCase(getChaseIframeUrlFrapi.fulfilled, (state, { payload }) => {
      state.buyFlowChaseIframeUrl = payload.paymentUrl;
    })
    .addCase(getChaseIframeUrlFrapi.rejected, (_state, { error }) => {
      console.error('FRAPI getChaseIframeUrl FAILED', error);
    })
    .addCase(confirmBuyFlowOrderFrapi.fulfilled, (state, { payload }) => {
      state.confirmationNumber = payload.confirmationNumber;
    })
    .addCase(confirmBuyFlowOrderFrapi.rejected, (_state, { error }) => {
      console.error('FRAPI Could not confirm order', error);
    })
    .addCase(submitBuyFlowPaymentFrapi.fulfilled, (state, { payload }) => {
      state.installationTimes = payload;
    })
    .addCase(submitBuyFlowPaymentFrapi.rejected, (_state, { error }) => {
      console.error('Could not process payment', error);
    })
    .addCase(createBuyFlowOrderFrapi.fulfilled, (state, { payload }) => {
      const {
        orderId,
        installationSchedule,
        summary,
        existingBillingTelephoneNumber,
        paymentUrl,
        telephoneNumber
      } = payload;
      const bfOrderId = !!orderId ? orderId : 'frapi'; // need to figure out orderId
      state.buyFlowOrder = {
        orderId: bfOrderId,
        summary,
        paymentUrl: '',
        existingBillingTelephoneNumber,
        telephoneNumber
      };

      if (payload.installationSchedule) {
        state.installationSchedule = payload.installationSchedule;
        // state.installationSchedule = installationScheduleToArray(new Date(payload.installationSchedule[0].date));
      }
    })
    .addCase(submitOrderFrapi.fulfilled, (state, { payload }) => {
      state.confirmationNumber = payload.confirmationNumber;
    })
    .addCase(submitOrderFrapi.rejected, (_state, { error }) => {
      console.error('Could not process order', error);
    })
    .addCase(cancelOrderFrapi.fulfilled, (state: AppState) => {
      state.buyFlowOrder = {} as OrderType;
      state.buyFlowProducts = [];
    })
    .addCase(cancelOrderFrapi.rejected, (_state, { error }) => {
      console.error('Could not cancel the order', error);
    })
    .addCase(reserveBuyFlowInstallationTimeFrapi.fulfilled, (state, { payload }) => {
      state.recordLocatorNumber = payload.recordLocatorNumber;
    })
    .addCase(reserveBuyFlowInstallationTimeFrapi.rejected, (_state, { error }) => {
      console.error('Could not get the record locator number from the order', error);
    });
};
