import * as axios from 'axios';
import { loadConfig } from 'config/config';
import { campaignId } from 'utils/parser';
import { getUserToken, getUserUID } from 'utils/partnerPortalUserStorage';
import { v4 as uuidv4 } from 'uuid';

const config = loadConfig();

const customerAcquisitionApiConfig = {
  headers: {
    // /** comment out this object to use w mockoon */
    apikey: config.customerAcquisitionApiKey,
    'requesting-application': 'PartnerPortal'
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  },
  baseURL: config.customerAcquisitionApiUrl
};

const serviceabilityApiConfig = {
  headers: {
    /** comment out this object to use w mockoon */

    apikey: config.serviceabilityApiKey
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  },
  baseURL: config.serviceabilityApiUrl
};

const partnerPortalApiConfig = {
  headers: {
    id_token: getUserToken(),
    uid: getUserUID()
  }
};

const tosApiConfig = {
  headers: {
    ApiKey: config.tosApiKey,
    UserName: config.tosUserName,
    OssAuthToken: config.tosAuthToken,
    'Content-Type': 'application/json-patch+json',
    accept: '*/*',
    CorrelationId: uuidv4(),
    'Access-Control-Allow-Origin': '*'
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  },
  baseURL: config.tosApiUrl
};

const addressManagementConfig = {
  baseURL: config.addressManagementUrl,
  headers: {
    apikey: config.addressManagementApiKey
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  }
};

const eventsConfig = {
  baseURL: config.eventsUrl,
  headers: {
    apikey: config.eventsApiKey
  },
  auth: {
    username: config.authUser,
    password: config.authPassword
  }
};

console.log(eventsConfig);

const eventsConfigTypes = {
  QUOTEREVIEWEMAILEVENT: {
    name: 'QuoteReviewEmail',
    domain: 'Communication',
    subDomain: 'Notification',
    source: 'PartnerPortal',
    eventName: 'QuoteReviewEmailEvent'
  }
};

const certifyInternetUsageConfig = (accessToken) => ({
  headers: {
    ApiKey: config.tosApiKey,
    UserName: config.tosUserName,
    OssAuthToken: config.tosAuthToken,
    'Content-Type': 'application/json',
    accept: '*/*',
    CorrelationId: uuidv4(),
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${accessToken}`
  },
  baseURL: config.ciuApiUrl
});

const getOAuthAccessToken = async () => {
  const rootUrl = window.location.origin;
  const oAuthHostUrl = rootUrl.includes('localhost') ? 'https://stg.getfrontier.com' : rootUrl;
  const oAuthURL = `${oAuthHostUrl}/api/access/getAccessToken`;

  let accessToken = '';
  try {
    const { data } = await axios.get(oAuthURL);
    accessToken = data?.Data?.oauth?.accessToken;
  } finally {
    return accessToken;
  }
};

export const AppApi = {
  /** CAMPAIGN SERVICEABILITY API */
  getServiceability: (samRecords, isSMB, isModCampaign, isAcp) => {
    const id = isSMB ? 'SMB' : 'fiber_expansion';
    const cmpID = campaignId(id);

    return axios.get(
      `serviceability?${!isAcp ? 'includeQuote=true&' : ''}env=${samRecords.environment}&controlNumber=${samRecords.controlNumber
      }`,
      {
        ...serviceabilityApiConfig,
        headers: !!cmpID
          ? { ...serviceabilityApiConfig.headers, 'campaign-id': isModCampaign ? 'mod_ring' : cmpID }
          : { ...serviceabilityApiConfig.headers }
      }
    );
  },
  getAvailableProducts: ({ address, technology, leadAuditId }) => {
    return axios.post(`available-products?technology=${technology}`, address, {
      ...serviceabilityApiConfig,
      headers: !!leadAuditId ? { ...serviceabilityApiConfig.headers } : { ...serviceabilityApiConfig.headers }
    });
  },
  /** CAMPAIGN AVAILABLE PRODUCTS FRAPI */
  getAvailableProductsFrapi: ({ address, technology, leadAuditId, env, controlNumber }) => {
    return axios.get(`available-products?technology=${technology}&env=${env}&controlNumber=${controlNumber}`, {
      ...serviceabilityApiConfig,
      headers: !!leadAuditId ? { ...serviceabilityApiConfig.headers } : { ...serviceabilityApiConfig.headers }
    });
  },
  sendVerificationCode: ({ id, channel }) => {
    return axios.post(`prospects/${id}/notification-tokens`, null, {
      ...serviceabilityApiConfig,
      params: { channel }
    });
  },
  verifyCode: ({ id, code, token }) => {
    return axios.post(`prospects/${id}/verification`, { code, token }, serviceabilityApiConfig);
  },
  getProspect: (prospectId) => {
    return axios.get(`prospects/${prospectId}`, serviceabilityApiConfig);
  },
  createProspect: ({ serviceLocation, campaign }) => {
    let data = { serviceLocation };
    let requestConfig = { ...serviceabilityApiConfig };

    if (campaign) {
      data = { serviceLocation, campaignId: campaign };
      requestConfig = {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          'campaign-id': campaign
        }
      };
    }

    return axios.post('prospects', data, requestConfig);
  },
  updateProspect: ({ prospect, prospectId, campaign }) => {
    let data = prospect;
    let requestConfig = { ...serviceabilityApiConfig };

    if (campaign) {
      data = { ...prospect, campaignId: campaign };
      requestConfig = {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          'campaign-id': campaign
        }
      };
    }

    if (prospect?.campaignId) {
      data = { ...prospect, campaignId: campaign };
      requestConfig = {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          'campaign-id': prospect.campaignId
        }
      };
    }

    return axios.put(`prospects/${prospectId}`, data, requestConfig);
  },

  /** BFAST CUSTOMER ACQUISITION API */
  updateBuyFlowProspect: ({ prospectInfo, campaign }) => {
    let data = prospectInfo;
    let requestConfig = { ...customerAcquisitionApiConfig };
    if (campaign) {
      data = { ...prospectInfo, campaignId: campaign };
      requestConfig = {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          'campaign-id': campaign
        }
      };
    }

    return axios.put(`prospects/${prospectInfo.prospectId}`, data, requestConfig);
  },

  getAvailableBuyflowProducts: ({
    request,
    leadId,
    technology,
    leadAuditId,
    trafficSource,
    username,
    ppAditionalHeaders
  }) => {
    let headers = { ...customerAcquisitionApiConfig.headers };
    /*if (username) {
      headers = {
        ...headers,
        agent: username,
        channel: ppAditionalHeaders.channel,
        vendor: ppAditionalHeaders.vendor,
        office: ppAditionalHeaders.office,
        assisted: ppAditionalHeaders.assisted,
        requestingApplication: ppAditionalHeaders.vendor,
        soldBy: ppAditionalHeaders.soldBy,
        issuedBy: ppAditionalHeaders.issuedBy
      };
    } */
    return axios
      .post(`available-products?leadId=${leadId}&technology=${technology}`, request, {
        ...customerAcquisitionApiConfig,
        headers: !!leadAuditId
          ? { ...headers, 'lead-audit-id': leadAuditId, 'traffic-source': trafficSource }
          : { ...headers }
      })
      .then((response) => response);
  },
  getChaseIframeUrl: (cacheId) => {
    return axios.get('payment-url', {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers,
        'Cache-Id': cacheId
      }
    });
  },
  getFiserveIframeUrl: (accountUuid) => {
    return axios.get(`payment-url?account-uuid=${accountUuid}`, {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers
      }
    });
  },
  buyFlowOrdersInterface: ({ params, orderId, type, cacheId }) => {
    return axios.post(`orders/${orderId}/${type}`, params, {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers,
        'Cache-Id': cacheId
      }
    });
  },
  createBuyFlowOrder: ({ order }, cacheId) => {
    return axios.post(`orders`, order, {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers,
        'Cache-Id': cacheId
      }
    });
  },
  getInstallationSchedule: ({ orderId, queryString }, cacheId) => {
    return axios.get(`orders/${orderId}/installation-schedule${queryString}`, {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers,
        'Cache-Id': cacheId
      }
    });
  },
  submitOrder: (order, orderId, cacheId) => {
    return axios.post(`orders/${orderId}/confirmation`, order, {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers,
        'Cache-Id': cacheId
      }
    });
  },
  securityQuestions: ({ securityQuestionList }, cacheId, quoteId) => {
    return axios.post(`/orders/verify`, securityQuestionList, {
      ...customerAcquisitionApiConfig,
      headers: {
        ...customerAcquisitionApiConfig.headers,
        'Cache-Id': cacheId,
        'quote-id': quoteId
      }
    });
  },
  /* OSS TOS API  */
  updateTermsOfService: (accountUuid, data) => {
    return axios.put(`Customers/${accountUuid}`, data, tosApiConfig);
  },

  /* OSS CERTIFY INTERNET USAGE API  */
  certifyMonthlyUsage: async (data) => {
    let accessToken = '';
    try {
      accessToken = await getOAuthAccessToken();
    } finally {
      return axios.put(`Customers/certifyInternetUsage`, data, certifyInternetUsageConfig(accessToken));
    }
  },
  getAddressPredictions: (queryString) => {
    return axios.get(
      `AddressSearch/predictive?inFootPrint=false&serviceAddressRecord=false&${queryString}`,
      addressManagementConfig
    );
  },

  /* EVENTS  */
  postEvent: (type, body) => {
    return axios.post(`events`, body, {
      ...eventsConfig,
      headers: {
        ...eventsConfig.headers,
        ...eventsConfigTypes[type]
      }
    });
  },
  /*  ACP ENROLLMENT */
  submitEbbEnrollmentForm: (formData, isAlt) => {
    const source = { 'requesting-application': isAlt ? 'PartnerPortal' : 'ResiEcomm' };
    return axios.post('ebb-enrollment', formData, {
      ...serviceabilityApiConfig,
      headers: { ...serviceabilityApiConfig.headers, ...source }
    });
  }
};
