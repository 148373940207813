import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectOpenModal } from 'store/selectors';
import App from './App';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
import componentfactory from './temp/componentFactory';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*'
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({
  path,
  Router
  // graphQLClient
}) => {
  // Remove the generated stylesheet from the initial server side render
  React.useEffect(() => {
    const serverRenderedStyles = document.querySelector('#ssr-style');
    if (serverRenderedStyles) {
      // tslint:disable-next-line:no-unused-expression
      serverRenderedStyles.parentElement?.removeChild(serverRenderedStyles);
    }
  });

  const openModal = useSelector(selectOpenModal);

  const routeRenderFunction = (props) => <RouteHandler route={props} />;
  return (
    // <ApolloProvider client={graphQLClient}>
    <SitecoreContext componentFactory={componentfactory} contextFactory={SitecoreContextFactory}>
      <Router location={path} context={{}}>
        <App />
      </Router>
    </SitecoreContext>
    // </ApolloProvider>
  );
};

export default AppRoot;
