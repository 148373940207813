import { runOnlyOnBrowser } from '../utils/browserUtil';

export function initializeInvoca(tag?: string) {
  runOnlyOnBrowser(() => {
    // This script is dropped in from Invoca, so just ignore linting it
    /* tslint:disable */
    if (tag) {
      // @ts-ignore
      (function (i: any, n, v, o, c, a) {
        i.InvocaTagId = o;
        var s = n.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = ('https:' === n.location.protocol ? 'https://' : 'http://') + v;
        var fs = n.getElementsByTagName('script')[0];
        // @ts-ignore
        fs.parentNode.insertBefore(s, fs);
      })(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', tag);
    } else {
      console.log('No tag present, skipping invoca setup');
    }
  });
  /* tslint:enable */
}
