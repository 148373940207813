import React from 'react';
import styled from 'styled-components';

export const AutoPayTooltip = ({ className }) => (
  <div className={`${className} fsm-autopay-tooltip`}>
    <div className="modal-disclaimer-text">
      <p>Automatic payment is drafted on your billing due date.</p>
    </div>
  </div>
);

const StyledAutoPayTooltip = styled(AutoPayTooltip)`
  ${({ theme }) => `
    .fsm-autopay-tooltip {
      text-align: center;

      .modal-disclaimer-text {
        max-height: 30rem;
        max-width: 514px;
        overflow-y: auto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #141928;

        p {
          margin-bottom: 0;
        }
      }

    }
  `}
  ${({ theme }) => theme.mobile`
    .fsm-autopay-tooltip {
      .modal-disclaimer-text {
        max-height: 23rem;
        font-size: 0.875rem;
        overflow-y: auto;
        margin: 16px auto 32px;
      }
    }
  `}
`;

export default StyledAutoPayTooltip;
