import {
  CampaignInstance,
  EventInstance,
  GoalInstance,
  OutcomeInstance,
  PageViewInstance,
  trackingApi
} from '@sitecore-jss/sitecore-jss-tracking';
import {
  AppState,
  BuyFlowProduct,
  OrderType,
  ServiceGoalIDs,
  ServiceGoalLabels,
  ServiceGoalTypes,
  ServiceTypeEnum
} from 'store/state.model';
import { loadConfig } from '../config/config';
import { dataFetcher } from '../dataFetcher';

const getTrackingOptions = () => {
  const config = loadConfig();
  const trackingApiOptions = {
    host: config.sitecoreApiUrl,
    queryStringParameters: {
      sc_apikey: config.sitecoreApiKey
    },
    fetcher: dataFetcher
  };

  if (window && trackingApiOptions.host && trackingApiOptions.host === '/') {
    // Environment variable for the sitecoreApiUrl is configured to a relative path
    // in order to allow for multisite sitecore layout service to function properly
    // hence we need to use the current host, as the host for making the tracking api request.
    trackingApiOptions.host = `${window.location.protocol}//${window.location.host}`;
  }

  return trackingApiOptions;
};

/**
 * Utility function to track a sitecore goal using the sitecore JSS tracking API
 */
export async function trackGoal(goalId: string): Promise<string> {
  const trackingApiOptions = getTrackingOptions();

  await trackingApi.trackEvent(
    [
      {
        goalId
      }
    ],
    trackingApiOptions
  );

  return goalId;
}

/**
 * Utility function to track a JSS item utilizing the JSS tracking API and the specified extensions
 * See https://wiki.ftr.com/pages/viewpage.action?pageId=127935923#JSSAnalytics&Tracking-FTRExtensions for the list of extensions
 */
export async function trackJssItem(
  instance: FtrCampaignInstance | FtrGoalInstance | FtrEventInstance | FtrOutcomeInstance | FtrPageViewInstance
) {
  const trackingApiOptions = getTrackingOptions();
  try {
    await trackingApi.trackEvent([instance], trackingApiOptions);
  } catch (err) {
    console.error(`Error tracking item:  ${err}`);
  }
}

export async function trackJssGoalItem(e: string, d: string) {
  const goalLabel = ServiceGoalLabels[e];
  const goalId = ServiceGoalIDs[e];
  if (goalLabel && goalId) {
    const text = `Serviceability Check ${goalLabel}`;
    const JssTracking: FtrGoalInstance = {
      text,
      goalId,
      data: d,
      dataKey: d
    };
    trackJssItem(JssTracking);
  }
}

export function trackServiceabilityJssEvent(serviceType) {
  trackJssGoalItem(ServiceGoalTypes.CHECKED, serviceType);
  if (
    serviceType === ServiceTypeEnum.EXISTING_FIBER ||
    serviceType === ServiceTypeEnum.EXISTING_COPPER ||
    serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER ||
    serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
    serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER ||
    serviceType === ServiceTypeEnum.EXISTING_VOICE
  ) {
    trackJssGoalItem(ServiceGoalTypes.EXISTING, serviceType);
    if (serviceType === ServiceTypeEnum.EXISTING_FIBER) {
      trackJssGoalItem(ServiceGoalTypes.EXISTING_FIBER, serviceType);
    } else if (serviceType === ServiceTypeEnum.EXISTING_COPPER) {
      trackJssGoalItem(ServiceGoalTypes.EXISTING_COPPER, serviceType);
    } else if (serviceType === ServiceTypeEnum.EXISTING_PLANNED_FIBER) {
      trackJssGoalItem(ServiceGoalTypes.SERVICEABLE, serviceType);
      trackJssGoalItem(ServiceGoalTypes.FUTURE_SERVICEABLE, serviceType);
      trackJssGoalItem(ServiceGoalTypes.EXISTING_COPPER, serviceType);
    } else if (
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER ||
      serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_COPPER
    ) {
      trackJssGoalItem(ServiceGoalTypes.UPGRADEABLE, serviceType);
      trackJssGoalItem(ServiceGoalTypes.EXISTING_COPPER, serviceType);
      if (serviceType === ServiceTypeEnum.EXISTING_UPGRADEABLE_FIBER) {
        trackJssGoalItem(ServiceGoalTypes.UPSELL_FIBER, serviceType);
      } else {
        trackJssGoalItem(ServiceGoalTypes.UPSELL_COPPER, serviceType);
      }
    }
  } else if (serviceType === ServiceTypeEnum.SERVICEABLE || serviceType === ServiceTypeEnum.SERVICEABLE_FIBER) {
    trackJssGoalItem(ServiceGoalTypes.SERVICEABLE, serviceType);
    trackJssGoalItem(ServiceGoalTypes.NEW_SERVICEABLE, serviceType);
    if (serviceType === ServiceTypeEnum.SERVICEABLE_FIBER) {
      trackJssGoalItem(ServiceGoalTypes.SERVICEABLE_FIBER, serviceType);
    } else {
      trackJssGoalItem(ServiceGoalTypes.SERVICEABLE_COPPER, serviceType);
    }
  } else if (serviceType === ServiceTypeEnum.UNSERVICEABLE) {
    trackJssGoalItem(ServiceGoalTypes.UNSERVICEABLE, serviceType);
  }
}

export async function trackOrderSubmitJssEvent(confirmationNumber: string, appState: AppState) {
  const goalId: string =
    appState.productListTechnology === 'copper' ? ServiceGoalIDs['COPPER_ORDER'] : ServiceGoalIDs['FIBER_ORDER'];
  const goalLabel = appState.productListTechnology === 'copper' ? 'Copper' : 'Fiber';
  const modernizationEligible = appState.modernizationEligible;

  const totalEstimatedFirstBill = appState.totalEstimatedFirstBill;

  const { productListTechnology, activeBuyFlowInternetProduct, buyFlowProducts, voiceSelected } = appState;
  const selectedProduct: BuyFlowProduct | undefined = buyFlowProducts.find(
    (item) => item.sku === activeBuyFlowInternetProduct
  );
  if (appState.voiceSelected) {
    const {
      voiceSelected: { name, sku, price }
    } = appState;

    trackJssItem({
      dataKey: 'Ordered VOIP',
      data: `${name}|${sku}|${price.value}`,
      text: `${goalLabel} Ordered VOIP Goal`,
      goalId:
        productListTechnology === 'copper'
          ? '17EC36C5-CEC6-4C18-BB9B-46728DEE53EE'
          : '9C422ECF-E756-4F03-AC5D-C306AB09DFA8'
    });
    trackJssItem({
      dataKey: 'Ordered Product',
      data: `${name}|${sku}|${price.value}`,
      text: `${goalLabel} Ordered Product Goal`,
      goalId:
        productListTechnology === 'copper'
          ? 'A0F23ACA-C77D-4E72-B735-7C644FDFE9AB'
          : '1AAB0EE4-EDCE-4BA8-AA0A-5A70B1775C05'
    });
  } else {
    trackJssItem({
      dataKey: 'Ordered Product',
      data: `${selectedProduct?.name}|${selectedProduct?.sku}|${selectedProduct?.price.value}`,
      text: `${goalLabel} Ordered Product Goal`,
      goalId:
        productListTechnology === 'copper'
          ? 'A0F23ACA-C77D-4E72-B735-7C644FDFE9AB'
          : '1AAB0EE4-EDCE-4BA8-AA0A-5A70B1775C05'
    });
  }

  if (goalId) {
    const text = `${goalLabel} Order Submit Goal`;
    const JssTracking: FtrGoalInstance = {
      text,
      goalId,
      data: `${confirmationNumber}|${totalEstimatedFirstBill}`,
      dataKey: 'order'
    };
    trackJssItem(JssTracking);
  }
}

export function getGoalID(productListTechnology: string, paymentSuccess: boolean) {
  let goalId = '';

  if (paymentSuccess) {
    goalId =
      productListTechnology === 'fiber'
        ? ServiceGoalIDs['FIBER_PAYMENT_SUCESS']
        : ServiceGoalIDs['COPPER_PAYMENT_SUCESS'];
  } else {
    goalId =
      productListTechnology === 'fiber'
        ? ServiceGoalIDs['FIBER_PAYMENT_DENIED']
        : ServiceGoalIDs['COPPER_PAYMENT_DENIED'];
  }

  const paymentGeneralGoalId =
    productListTechnology === 'fiber'
      ? ServiceGoalIDs['PAYMENT_CAPTURED_FIBER']
      : ServiceGoalIDs['PAYMENT_CAPTURED_COPPER'];

  return { goalId, paymentGeneralGoalId };
}

export function getAdditionalFees(buyFlowOrder: OrderType) {
  const backPay = buyFlowOrder?.summary?.additionalFees.find((fee) => fee.type === 'BACKPAY');
  const deposit = buyFlowOrder?.summary?.additionalFees.find((item) => item.type === 'DEPOSIT');

  const successPaymentTrackingData = `$${deposit ? deposit.cost.value : '0.00'}|$${
    backPay ? backPay.cost.value : '0.00'
  }`;

  return successPaymentTrackingData;
}

export async function trackPayment(goalId: string, data: string, dataKey: string, text: string) {
  const paymentJssTracking: FtrGoalInstance = {
    goalId,
    data,
    dataKey,
    text
  };
  trackJssItem(paymentJssTracking);
}

export async function trackPaymentRequiredJssEvent(buyFlowOrder: OrderType, productListTechnology: string) {
  const goalId =
    productListTechnology === 'fiber' ? '68792A68-4EB2-49F2-9C2F-E425391D357F' : 'BE169B32-D49C-4295-8FBC-73BABA988759';
  const goalLabel = productListTechnology === 'fiber' ? 'Fiber' : 'Copper';
  const successPaymentTrackingData = getAdditionalFees(buyFlowOrder); // Requested to use the same data of Success payment goal

  // tracking payment required
  trackPayment(goalId, successPaymentTrackingData, 'Payment Required', `Payment Required|${goalLabel}`);
}

export async function trackPaymentSucessJssEvent(buyFlowOrder: OrderType, productListTechnology: string) {
  const successPaymentTrackingData = getAdditionalFees(buyFlowOrder);

  const { goalId, paymentGeneralGoalId } = getGoalID(productListTechnology, true);

  // tracking payment success
  trackPayment(goalId, successPaymentTrackingData, 'amount', 'Payment Capture Goal');

  // track general success payment
  trackPayment(paymentGeneralGoalId, `Sucess|${successPaymentTrackingData}`, 'payment', 'Payment Capture');
}

export async function trackPaymentDeniedJssEvent(productListTechnology: string) {
  const { goalId, paymentGeneralGoalId } = getGoalID(productListTechnology, false);

  // tracking payment denied
  trackPayment(goalId, 'Payment Denied', 'payment', 'Payment Denied');

  // track general denied payment
  trackPayment(paymentGeneralGoalId, `Payment Denied`, 'payment', 'Payment Capture');
}

export function trackPaymentAttempt(productListTechnology: string, buyFlowOrder: OrderType) {
  const paymentData = getAdditionalFees(buyFlowOrder);
  const copperOrFiber: string = productListTechnology === 'copper' ? 'Copper' : 'Fiber';
  const goalId = ServiceGoalIDs[`PAYMENT_ATTEMPT_${copperOrFiber.toUpperCase()}`];
  trackJssItem({
    goalId,
    data: paymentData,
    dataKey: 'Payment Attempted',
    text: `Payment Attempted ${copperOrFiber}`
  });
}
export interface FtrCampaignInstance extends CampaignInstance {
  /** GUID or alias (name) of campaign to trigger */
  campaignId: string;
  dataKey: string;
  data: string;
  text: string;
  [key: string]: any;
}

export interface FtrGoalInstance extends GoalInstance {
  /** GUID or alias (name) of goal to trigger */
  goalId: string;
  dataKey: string;
  data: string;
  text: string;
  [key: string]: any;
}

export interface FtrEventInstance extends EventInstance {
  /** GUID or alias (name) of event to trigger */
  eventId: string;
  dataKey: string;
  data: string;
  text: string;
  [key: string]: any;
}

export interface FtrOutcomeInstance extends OutcomeInstance {
  /** GUID or alias (name) of outcome to trigger */
  outcomeId: string;
  /** Currency code of outcome monetary value */
  currencyCode?: string;
  /** Revenue from outcome */
  monetaryValue?: number;
  dataKey: string;
  data: string;
  text: string;
  [key: string]: any;
}

export interface FtrPageViewInstance extends PageViewInstance {
  /** GUID of Sitecore page item to track (i.e. context item ID) */
  pageId: string;
  /** URL of page to track - should be absolute route path but can accept any value */
  url: string;
  dataKey: string;
  data: string;
  text: string;
  [key: string]: any;
}
