import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import appState from './appSlice';
import errorState from './errorSlice';
import orderQuoteReducer from './slices/OrderQuote/orderQuoteSlice';

const rootReducer = combineReducers({
  appState,
  errorState,
  orderQuote: orderQuoteReducer
});

const store = configureStore({
  reducer: rootReducer
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(
  //     // analyticsMiddlewares.sitecoreAnalyticsMiddleware
  //     // analyticsMiddlewares.adobeAnalyticsMiddleware
  //   )
});

/*
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(analyticsMiddlewares.sitecoreAnalyticsMiddleware)
});
*/

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
