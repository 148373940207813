import { runSafeSsr } from './browserUtil';

export const getQueryStringParams = (param?: string): string => {
  const searchParams = runSafeSsr(
    () => {
      return {};
    },
    () => {
      return window.location.search;
    }
  );

  return param ? searchParams[param] : searchParams;
};
