import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOpenModal, updateVerifierQuestionResponse } from 'store/appSlice';
import { selectVerifierQuestions } from 'store/selectors';
import styled from 'styled-components';
import Button from '../../components/Button';
import RadioGroup from '../../components/RadioGroup';

const VerifierQuestionsModalContent = ({ className }) => {
  const dispatch = useDispatch();
  const verifierQuestions = useSelector(selectVerifierQuestions);
  const [responseFilled, setResponseFilled] = useState(true);
  const [responseList, setResponseList] = useState([]);

  const handleSubmit = () => {
    dispatch(updateOpenModal(null));
    dispatch(updateVerifierQuestionResponse(responseList));
  };

  const onChange = (option, grpName) => {
    const numberChoice = option.indexOf(true);
    if (numberChoice !== -1) {
      const numberQuestion = parseInt(grpName, 10);
      let newArray = [...responseList];

      const existingAnswerIdx = newArray.findIndex((question) => question.questionNumber === numberQuestion);
      if (existingAnswerIdx !== -1) {
        //update
        newArray[existingAnswerIdx] = {
          questionNumber: numberQuestion,
          answerNumber: numberChoice + 1
        };
        setResponseList(newArray);
      } else {
        //add
        newArray.push({
          questionNumber: numberQuestion,
          answerNumber: numberChoice + 1
        });
        setResponseList(newArray);
      }

      if (newArray.length === verifierQuestions.securityQuestions.length) {
        setResponseFilled(false);
      }
    } else return;
  };

  return (
    <div className={`${className} fsm-body security-questions`}>
      <Text
        className="fsm-header-copy"
        tag="h1"
        field={{
          value:
            verifierQuestions.modalTitle ||
            'There is more than one name or Social Security number associated with this address. Please help us verify your identity by answering the following questions.'
        }}
      />
      {verifierQuestions.securityQuestions.map((input, i) => {
        const answerList = input.answerChoices.map((item) => item.text);
        return (
          <div key={i} className={'questionnaire-content'}>
            <div class="question">
              <Text className="number" tag="p" field={{ value: ('0' + (i + 1)).slice(-2) }} />
              <div class="question-text">
                <Text tag="p" field={{ value: input.question }} />
                <Text className="number required-text" tag="p" field={{ value: `This answer is required.` }} />
              </div>
            </div>
            <RadioGroup
              name={'radioGroup'}
              group={answerList}
              includeGroupNameInCallback={true}
              groupName={`${input.questionNumber}`}
              onChange={onChange}
            />
          </div>
        );
      })}
      <Button model="primary" type="submit" disabled={responseFilled} className="submit-button" onClick={handleSubmit}>
        {verifierQuestions.modalButtonText || 'Submit'}
      </Button>
    </div>
  );
};

const StyledVerifierQuestionsModalContent = styled(VerifierQuestionsModalContent)`
  ${({ theme }) => `
  &.fsm-body.security-questions {
      max-width: none;
      text-align: left;
      margin: 3.0625rem 5rem;
      
      .questionnaire-content {
        margin-bottom: 22px;
      }
      
      .fsm-header-copy {
          text-align:center;
          font-family: PPObjectSans, sans-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #333;
          margin: 0px 0px 39px 0px;
      }

      p {
          margin-bottom: 0;
      }

      .radio-bullet {
          margin-right: 0.75rem;
      }

      .question {
        display: flex;
        margin: 0px 0px 10px 0px;
        
        .number {
          color: ${theme.red};
          font-weight: bold;
          font-family: PPObjectSans, sans-serif;
          font-size: 16px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
        }
        
        .question-text {
          margin-left: 8px;
        }

        .required-text {
          font-size: 0.625rem;
          font-weight: bold;
          padding: 0 0 6px;
        }
      }

      
    }
  `}
  ${({ theme }) => theme.tabletPortrait`
    &.fsm-body.security-questions {
      margin: 3.0625rem 2rem;
      .security-questions-content {
        max-height: calc(100vh - 15.2rem);
        overflow-x: hidden;
        overflow-y: auto;
        margin: 1.25rem 0;
        position: relative;

        p:first-child {
          margin-top: 0;
        }
      }
    }
  `}
  ${({ theme }) => theme.mobile`
    &.fsm-body.security-questions {
      margin: 3.0625rem 2rem;
      .security-questions-content {
        max-height: calc(100vh - 15.2rem);
        overflow-x: hidden;
        overflow-y: auto;
        margin: 1.25rem 0;
        position: relative;

        p:first-child {
          margin-top: 0;
        }
      }
    }
  `}
  .submit-button {
    margin-top: 20px;
  }
`;

export default StyledVerifierQuestionsModalContent;
