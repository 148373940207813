import { OrderQuoteState } from '../store/slices/OrderQuote/types';

type ReturnType = {
  addressSearched: boolean;
  prospectCreated: boolean;
  sCaseCreated: boolean;
  creditChecked: boolean;
  depositCollected: boolean;
  depositRequired: boolean;
  customerDataUpdated: boolean;
  productConfigurationCompleted: boolean;
  lowIncomeTaskVerified: boolean;
  numberPortability: boolean;
  quoteValidated: boolean;
  quoteAccepted: boolean;
  posIdRequired: boolean;
  posIdFormCompleted: boolean;
  scheduleReserved: boolean;
  disclosureCompleted: boolean;
  billReviewCompleted: boolean;
  quoteScheduleReservationConvertedToOrder: boolean;
  registrationEbill: boolean;
  autoPayRequired: boolean;
  autoPayRegistrationSuccessful: boolean;
};

export function parseSalesJourneyMilestone(
  salesJourneyMilestone: OrderQuoteState['salesJourneyMilestone']
): Partial<ReturnType> {
  return salesJourneyMilestone.reduce((acc, curr) => {
    return { ...acc, [curr.name]: curr.fulfilled === 'true' };
  }, {});
}
