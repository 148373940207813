import { Text } from '@sitecore-jss/sitecore-jss-react';
import LoadingSpinner from 'components/LoadingSpinner';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLoadingMessage } from 'store/selectors';
import styled from 'styled-components';
import { theme as projTheme } from 'styles/theme.js';
import loadingMessages from 'constants/loadingMessages';
import { useAppDispatch } from 'store';
import { updateCustomLoadingMessage } from 'store/appSlice';

interface LoadingProps {
  className?: string;
}

const LoadingOverlay = ({ className }: LoadingProps) => {
  const dispatch = useAppDispatch();
  const resetToDefaultMessaging = () => {
    dispatch(updateCustomLoadingMessage(loadingMessages.defaultLoadingMessage));
  };
  useEffect(() => {
    return resetToDefaultMessaging();
  }, []);

  const customLoadingMessage = useSelector(selectLoadingMessage);

  return (
    <div className={`${className} loading-overlay active`}>
      <div className="loading-spinner-wrapper">
        <LoadingSpinner color={projTheme.red} className="loading-spinner" />
        <Text tag="span" field={{ value: customLoadingMessage || loadingMessages.defaultLoadingMessage }} />
      </div>
    </div>
  );
};

const StyledLoadingOverlay = styled(LoadingOverlay)`
  ${({ theme }) => `
		&.loading-overlay {
			height: 100%;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			left: 0;
			top: 0;
			transition: all .25s;
			width: 100%;
			z-index: 15;

			&:before {
				content: '';
				background-color: ${theme.white};
				height: 100%;
				opacity: 1;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}

			&.active {
				opacity: 1 !important;
				pointer-evenimport { useHistory } from 'react-router-dom';
ts: initial;
			}

			.loading-spinner-wrapper {
				position: absolute;
				text-align: center;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				max-width: 17.5rem;

				.loading-spinner {
					clear: both;
					display: inline-block;
					.circle-loader {
						span {
							background-color: ${theme.crimson}
						}
					}
				}

				span {
					color: ${theme.mineShaft};
					display: block;
					font-size: 1rem;
					line-height: 1.25;
				}
			}
		}
	`}
`;

export default StyledLoadingOverlay;
