import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ProductType } from 'store/appSlice';
import { BuyFlowProduct, CallClientModal, Covid19Notice, ServiceLocation, VerifierQuestions } from 'store/state.model';

export const selectCallClientModalResponse = (store): boolean => store.appState.callClientModalResponse;
export const selectHasAddressDiscrepancy = (store): boolean => store.appState.hasAddressDiscrepancy;
export const selectResumedWithAddressDiscrepancy = (store): boolean => store.appState.resumedWithAddressDiscrepancy;
export const selectIsAddressDiscrepancyModalOpen = (store): boolean => store.appState.addressDiscrepancyModal;
export const selectAddressUnknownField = (store): boolean => store.appState.addressUnKnownField;
export const selectAllowEditPreviousAddress = (store): boolean => store.appState.allowEditPreviousAddress;
export const selectVerifierQuestionsResponse = (store): any[] => store.appState.verifierQuestionResponse;
export const selectCovidModalResponse = (store): boolean => store.appState.covidModalResponse;
export const selectChaseIframeUrl = (store) => store.appState.buyFlowChaseIframeUrl;
export const selectFiserveIframeUrl = (store) => store.appState.buyFlowFiserve;
export const selectBuyFlowDisclaimers = (store) => store.appState.siteCoreBuyFlowDisclaimers;
// ! InternetPicker uses this
export const selectLinkedSku = (store) => store.appState.linkedSku;

export const selectBuyFlowProducts = (store: RootState) => store.appState.buyFlowProducts;
export const selectBuyFlowInternetProducts = createSelector([selectBuyFlowProducts], (products) => {
  return products?.filter((product) => product.type === ProductType.INTERNET || product.type === ProductType.BROADBAND);
});

export const selectInternetProductsIncludesCopper = createSelector([selectBuyFlowInternetProducts], (products) => {
  return products?.filter((product) => product.description.toLowerCase().includes('copper')).length >= 1;
});
export const selectActiveInternetProduct = (store) => store.appState.activeBuyFlowInternetProduct;
export const selectHasActiveProdIdChanged = (store) => store.appState.hasActiveProdIdChanged;
export const selectFeatureMap = (store) => store.appState.featureMap;

// ! InstallationSchedule uses this. Needs refactor with new UI & API
export const selectInstallationSchedule = (store) => store.appState.installationSchedule;

// ! OrderSummary uses this. Needs refactor with new UI & API
export const selectTermsAndConditionsAccepted = (store) => store.appState.termsAndConditionsAccepted;

export const selectDisclaimerModal = (store): boolean => store.appState.disclaimerModal;

export const selectAcceptedCardsModal = (store) => store.appState.acceptedCardsModal;

export const selectNumberNotPortableModal = (store) => store.appState.numberNotPortableModal;

export const selectKeepCurrentPhoneNumber = (store): boolean => store.appState.keepCurrentPhoneNumber;

export const selectPaidToday = (store) => store.appState.paidToday;

export const selectTotalEstimatedFirstBill = (store) => store.appState.totalEstimatedFirstBill;

export const selectProgressStepIndex = (store) => store.appState.progressStepIndex;

export const selectIsPosIdModalOpen = (store) => store.appState.isPosIdModalOpen;

export const selectPendingInOrderNumber = (store) => store.appState.pendingInOrderNumber;

export const selectIsPosIdRequired = (store) => store.appState.isPosIdRequired;

export const selectHasCreditFreeze = (store) => store.appState.hasCreditFreeze;

export const selectRetainSSN = (store) => store.appState.retainSSN;

// ! OrderConfirmation uses these. Needs refactor with new UI & API
export const selectBuyFlowOrderSummary = (store) => {
  const {
    serviceLocation,
    buyFlowProducts,
    activeBuyFlowInternetProduct,
    alternateBillingAddress,
    productsPrettyNames,
    voicePrice
  } = store.appState;

  const prettyProduct = productsPrettyNames?.find((item) => item.sku === activeBuyFlowInternetProduct);
  const selectedInternetProductBySku = buyFlowProducts?.find((item) => item.sku === activeBuyFlowInternetProduct);
  const selectedInternetProductByGuid = buyFlowProducts?.find(
    (item) => item.productId === activeBuyFlowInternetProduct
  );
  const selectedInternetProduct = !!selectedInternetProductBySku
    ? selectedInternetProductBySku
    : selectedInternetProductByGuid;

  // const selectedInternetProduct = buyFlowProducts?.find((item) => item.sku === activeBuyFlowInternetProduct);
  const internetProduct = { name: prettyProduct?.prettyName, price: selectedInternetProduct?.price.value };
  const prettyVoiceProduct = productsPrettyNames?.find((item) => item.type === 'VOICE');
  const voiceProduct = prettyVoiceProduct ? { name: prettyVoiceProduct?.prettyName, price: voicePrice } : null;

  return {
    serviceLocation,
    voiceProduct,
    alternateBillingAddress,
    internetProduct
  };
};

export const selectInstallationTime = (store) => store.appState.installationTime;
export const selectPersonalInfo = (store) => store.appState.personalInfo;
export const selectReceiveInstallationUpdates = (store) => store.appState.receiveInstallationUpdates;
export const selectConfirmationNumber = (store) => store.appState.confirmationNumber;
export const selectOrderSummary = (store) => store.appState.buyFlowOrderSummary;
export const selectBuyFlowOrder = (store) => store.appState.buyFlowOrder;
export const selectIsAutopayEnrolled = (store) => store.appState.isAutopayEnrolled;
export const selectAutoPayTermsAccepted = (store) => store.appState.autoPayTermsAccepted;
export const selectFundingAccountLastFourDigits = (store) => store.appState.fundingAccountLastFourDigits;
export const isPaperlessBillingSubmitted = (store) => store.appState.isPaperlessBillingSumitted;
export const selectIsPaperlessEnrolled = (store) => store.appState.isPaperlessEnrolled;
export const selectPrettyProducts = (store) => store.appState.productsPrettyNames;
export const selectProductsBySku = (store) => store.appState.productsBySku;
export const selectBFastLastSku = (store) => store.appState.bFastLastSku;
export const selectMergedBuyFlowProducts = (store) => store.appState.mergedBuyFlowProducts;
export const selectShowImportantMessage = (store) => store.appState.showImportantMessage;
export const selectErrorModalCode = (store) => store.appState.errorModal.errorCode;
export const selectVoiceDirectory = (state: RootState) => state.appState.voiceDirectorySelection;
export const selectPaidRouters = (state: RootState) => state.appState.paidRouter;
export const selectFreeRouters = (state: RootState) => state.appState.freeRouter;
export const selectResumePaidRoutersCount = (state: RootState) => state.appState.resumePaidRoutersCount;
export const selectEeroSecureAddon = (state: RootState) => state.appState.eeroSecureAddon;
export const selectedFullBuyFlowProduct = createSelector(
  [selectMergedBuyFlowProducts, selectActiveInternetProduct],
  (mergedBuyFlowProducts, activeBuyFlowInternetProduct) => {
    const filteredBuyFlowProductBySku = mergedBuyFlowProducts?.find(
      (item) => item?.productSku?.value === activeBuyFlowInternetProduct
    );
    const filteredBuyFlowProductByGuid = mergedBuyFlowProducts?.find(
      (item) => item?.productGuid?.value === activeBuyFlowInternetProduct
    );
    const filteredBuyFlowProduct = !!filteredBuyFlowProductBySku
      ? filteredBuyFlowProductBySku
      : filteredBuyFlowProductByGuid;
    const selectedBFProd = !!filteredBuyFlowProduct ? filteredBuyFlowProduct : mergedBuyFlowProducts?.[0];
    return selectedBFProd;
    // return mergedBuyFlowProducts?.find((item) => item.productSku.value === activeBuyFlowInternetProduct);
  }
);

export const selectOrderConfirmation = createSelector(
  [
    selectInstallationTime,
    selectConfirmationNumber,
    selectOrderSummary,
    selectPersonalInfo,
    selectBuyFlowOrder,
    selectBFastLastSku,
    selectIsAutopayEnrolled,
    selectPaidToday,
    selectVoiceDirectory
  ],
  (
    installationTime,
    confirmationNumber,
    buyFlowOrderSummary,
    personalInfo,
    buyFlowOrder,
    bFastLastSku,
    isAutoPayEnrolled,
    paidToday,
    directoryListing
  ) => {
    return {
      buyFlowOrderSummary,
      confirmationNumber,
      personalInfo,
      installationTime,
      buyFlowOrder,
      bFastLastSku,
      isAutoPayEnrolled,
      paidToday,
      directoryListing
    };
  }
);
export const selectChosenInstallationWindow = (store) => store.appState.installationTime;
// ! CommunicationPreferences uses these. Needs refactor with new UI & API
export const selectNotificationPrefs = (store) => store.appState.notificationPreferences;
export const selectNotificationPreferences = createSelector(
  [selectNotificationPrefs],
  (notificationPreferences) => notificationPreferences
);
export const selectAccountUuid = (store) => store.appState.accountUuid;
export const selectOrderAccountUuid = (store) => store.orderQuote.accountUuid;

// ! DigitalVoiceProductCard uses these. Needs refactor with new UI & API
export const selectCart = (store) => store.appState.cart;
export const selectBuyFlowVoiceProducts = createSelector([selectBuyFlowProducts], (products) =>
  products.filter((product) => product.type === ProductType.VOICE)
);
// ! useValidator custom hook uses this. It's currently orphaned but we might use it again. Don't delete
export const selectValidState = (store) => store.appState.validateAllForms;

export const selectVoiceSelected = (store) => store.appState.voiceSelected;

export const selectBFastVoiceProductSku = (store) => store.appState.voiceSelectedProduct;
export const selectExistingQuotePaidDeposit = (store) => store.appState.existingQuotePaidDeposit;

export const selectBFastVoiceProduct = createSelector(
  [selectBuyFlowProducts, selectBFastVoiceProductSku],
  (products: BuyFlowProduct[], sku: string) => {
    const productBySku = products.find((product) => product?.sku === sku);
    const productByGuid = products.find((product) => product?.productId === sku);
    const voiceProduct = !!productBySku ? productBySku : productByGuid;
    return voiceProduct;
    // return products.find((product) => product.sku === sku);
  }
);

export const selectBFastVoicePrice = (store): number => store.appState.voicePrice;

export const selectCovid19Notice = (store): Covid19Notice =>
  store.appState.covid19Notice || {
    modalActive: false,
    modalTitle: '',
    modalText1: '',
    modalText2: '',
    modalButtonText: '',
    stat: null
  };

export const selectVerifierQuestions = (store): VerifierQuestions =>
  store.appState.verifierQuestion || {
    modalActive: false,
    modalTitle: '',
    securityQuestions: [],
    modalButtonText: ''
  };

export const selectCallClientModal = (store): CallClientModal =>
  store.appState.callClientModal || {
    modalErrorHeader: '',
    modalErrorText: '',
    modalErrorButtonText: '',
    modalResultSummaryTitle: '',
    modalResultSummaryContent: '',
    modalResultSummaryButtonText: '',
    orderId: ''
  };

export const selectSixMonthAddress = (store): ServiceLocation =>
  store.appState.sixMonthAddress || {
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: ''
  };

export const selectProgressBarSteps = (store: RootState) => store.appState.progressBarSteps;
export const selectLeadAuditId = (store: RootState) => store.appState.leadAuditId;

export const selectCodeToProductMap = (store: RootState) => store.appState.codeToProducMap;
export const selectSelectedCodeToProduct = (store: RootState) => store.appState.selectedProductCode;
export const selectOrderQuoteId = (state: RootState) => state.appState.quoteId;
export const selectSecureProductIds = (state: RootState) => state.appState.selectedSecureProductIds;
export const selectCurrentSecureProductsConfigured = (state: RootState) =>
  state.appState.currentSecureProductsConfigured;
export const selectQuoteDataToCSV = (store) => store.appState.quoteDataToCSV;
export const selectSelectedSecureProductIds = (store: RootState) => store.appState.selectedSecureProductIds;
export const selectSelectedSecureProductAddonsIds = (store: RootState) => store.appState.selectedSecureProductAddonsIds;
export const selectAvailableSecureProductAddons = (store: RootState) =>
  store.appState.selectedAvailableSecureProductAddons;
export const selectPaperlessProduct = (store: RootState) => store.appState.selectedPaperlessProduct;
export const selectUnsortedProductList = (store) => store.appState.unsortedProductList;
export const selectHighSpeedApprovalOption = (store) => store.appState.highSpeedApprovalOption;
export const selectShouldActionsButtonsBeEnabled = (store: RootState) => store.appState.shouldActionsButtonsBeEnabled;
export const selectPortabilityTelephoneNumber = (store: RootState) => store.appState.portabilityTelephoneNumber;
export const selectIsCalendarErrorOpen = (store: RootState) => store.appState.isCalendarErrorOpen;
export const selectIsEbbFlow = (store: RootState) => store.appState.isEbbFlow;
export const selectAddressDescErrorCode = (store: RootState) => store.appState.addressDescrepancyErrorCode;
export const selectIsStepTwoCompleted = (store: RootState) => store.appState.isStepTwoCompleted;
export const selectIsInternetProductSelectionActive = (store: RootState) =>
  store.appState.isInternetProductSelectionActive;
export const selectCommunicationPreferences = (state: RootState) => state.appState.communicationPreferences;
export const selectOfferType = (state: RootState) => state.appState.offerType;
export const selectPortedTelephoneNumber = (state: RootState) => state.appState.portedTelephoneNumber;
export const selectIsEeroSecureSelected = (state: RootState) => state.appState.isEeroSecureSelected;
export const selectSelectedEeorSecureAddonId = (state: RootState) => state.appState.selectedEeroSecureAddonId;
export const selectWholeHomeWifiProduct = (state: RootState) => state.appState.wholeHomeWifiProduct;
export const selectSelectedWholeHomeWifiProductId = (state: RootState) => state.appState.wholeHomeWifiProductId;
export const selectExpertInstallProduct = (state: RootState) => state.appState.expertInstallProduct;
export const selectPartialInstall = (state: RootState) => state.appState.partialInstallSelect;
export const selectFullInstall = (state: RootState) => state.appState.fullInstallSelect;
