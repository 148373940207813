import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOpenModal, updateVoiceTermsAccepted } from 'store/appSlice';
import { selectDisclaimerModal } from 'store/selectors';
import styled from 'styled-components';
import Button from '../../components/Button';

export const BatteryBackupModal = ({ className }) => {
  const disclaimerModal = useSelector(selectDisclaimerModal);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateOpenModal(null));
    dispatch(updateVoiceTermsAccepted(true));
  };

  return (
    <div className={`${className} fsm-battery-backup`}>
      <h2 className="modal-title"> Battery Backup Unit Disclaimer </h2>
      <div className="modal-disclaimer-text p-2">
        <RichText field={{ value: disclaimerModal[0]?.disclaimers[0]?.disclaimerText?.value }} />
      </div>
      <Button
        model="primary"
        className="confirm-button"
        // @ts-ignore
        onClick={handleClick}
      >
        Confirm
      </Button>
    </div>
  );
};

const StyledBatteryBackupModal = styled(BatteryBackupModal)`
  ${({ theme }) => `
    &.fsm-battery-backup {
      font-size: 0.875rem;
      margin: -20px;
      .modal-disclaimer-text {
        max-height: 24rem;
        overflow-y: auto;
        padding-right: 22px;
      }

      strong {
        font-weight: 900;
      }
      
      .modal-title {
        color: ${theme.mineShaft};
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }

      .confirm-button {
        display: block;
        margin-top: 1.25rem;
      }
    }
  `}
  ${({ theme }) => theme.tabletPortrait`
  `}
  ${({ theme }) => theme.mobile`
    &.fsm-battery-backup {
      .modal-disclaimer-text {
        max-height: 23rem;
        overflow-y: auto;
      }
    }
  `}
`;

export default StyledBatteryBackupModal;
