export default {
  apiErrorKeys: {
    GENERIC_ISSUE: 'GENERIC_ISSUE',
    EXISTING_SERVICE: 'EXISTING_SERVICE',
    CREATE_ORDER: 'CREATE_ORDER',
    PROCESS_PAYMENT: 'PROCESS_PAYMENT',
    RESERVE_APPOINTMENT: 'RESERVE_APPOINTMENT',
    CONFIRM_ORDER: 'CONFIRM_ORDER',
    FTR_TERRITORY_CALL_FAILS: 'FTR_TERRITORY_CALL_FAILS',
    ADDRESS_VALIDATION_FAILS: 'ADDRESS_VALIDATION_FAILS',
    RETREIVE_FACILITIES_FAILS: 'RETREIVE_FACILITIES_FAILS',
    SERVICEABILITY_INVALID_ADDRESS: 'SERVICEABILITY_INVALID_ADDRESS',
    FIBER_PRODUCTS_CALL_FAIL: 'FIBER_PRODUCTS_CALL_FAIL',
    PROSPECT_CALL_FAILS: 'PROSPECT_CALL_FAILS',
    PROSPECT_INVALID_PHONE_NUMBER: 'PROSPECT_INVALID_PHONE_NUMBER',
    PROSPECT_VERIFICATION_CALL_FAILS: 'PROSPECT_VERIFICATION_CALL_FAILS',
    PROSPECT_VERIFICATION_CODE_INCORRECT: 'PROSPECT_VERIFICATION_CODE_INCORRECT',
    PROSPECT_NOTIFICATION_TOKENS_CALL_FAILS: 'PROSPECT_NOTIFICATION_TOKENS_CALL_FAILS',
    NOT_SERVICEABLE: 'NOT_SERVICEABLE',
    SESSION_TIME_OUT: 'SESSION_TIME_OUT',
    CREDIT_CHECK_FAILED: 'CREDIT_CHECK_FAILED',
    AUTOPAY_REGISTRATION_ERROR: 'AUTOPAY_REGISTRATION_ERROR',
    AUTOPAY_UPDATE_ERROR: 'AUTOPAY_UPDATE_ERROR',
    BILLING_ADDRESS_VERIFICATION_FAILED: 'BILLING_ADDRESS_VERIFICATION_FAILED',
    PAYMENT_DECLINED: 'PAYMENT_DECLINED',
    MULTIPLE_POSTAL_ADDRESSES: 'MULTIPLE_POSTAL_ADDRESSES',
    REQUEST_FAIL: 'REQUEST_FAIL',
    STANDARD: 'STANDARD',
    PAYMENT_NOT_APPROVED: 'PAYMENT_NOT_APPROVED',
    EBILL_REGISTRATION_ERROR: 'EBILL_REGISTRATION_ERROR',
    POSITIVE_IDENTIFICATION: 'POSITIVE_IDENTIFICATION',
    UNIQUE_EMAIL_ID_ERROR: 'UNIQUE_EMAIL_ID_ERROR'
  },
  apiErrorCodes: {
    NS001: 'NOT_SERVICEABLE',
    TO001: 'SESSION_TIME_OUT',
    CA001: 'MULTIPLE_POSTAL_ADDRESSES',
    CC001: 'CREDIT_CHECK_FAILED',
    CF001: 'REQUEST_FAIL',
    CF002: 'GENERIC_ISSUE',
    CF003: 'EXISTING_SERVICE',
    CF004: 'CREATE_ORDER',
    CF005: 'PROCESS_PAYMENT',
    CF006: 'RESERVE_APPOINTMENT',
    CF007: 'CONFIRM_ORDER',
    CF008: 'PAYMENT_NOT_APPROVED',
    CF020: 'EBILL_REGISTRATION_ERROR',
    CF013: 'NO_SCHEDULES_AVAILABLE',
    CF014: 'FRAPI_CONFIRM_ORDER',
    CF016: 'CREDIT_CHECK_FAILURE',
    CF017: 'AUTOPAY_REGISTRATION_ERROR',
    CF018: 'AUTOPAY_UPDATE_ERROR',
    CF019: 'UNAVAILABLE_RESERVE_APPOINTMENT',
    FA001: 'FRAUD_ALERT',
    FR002: 'CREDIT_FREEZE',
    TA001: 'IDENTITY_VERIFICATION_FAILED',
    TA002: 'BILLING_ADDRESS_VERIFICATION_FAILED',
    TA003: 'SECURITY_PIN_ERROR',
    PD001: 'PAYMENT_DECLINED',
    S001: 'FTR_TERRITORY_CALL_FAILS',
    S002: 'ADDRESS_VALIDATION_FAILS',
    S003: 'RETREIVE_FACILITIES_FAILS',
    S100: 'SERVICEABILITY_INVALID_ADDRESS',
    F001: 'FIBER_PRODUCTS_CALL_FAIL',
    PPO001: 'PROSPECT_CALL_FAILS',
    PG001: 'PROSPECT_CALL_FAILS',
    PPU001: 'PROSPECT_CALL_FAILS',
    PPU002: 'PROSPECT_INVALID_PHONE_NUMBER',
    PPV001: 'PROSPECT_VERIFICATION_CALL_FAILS',
    PPV002: 'PROSPECT_VERIFICATION_CODE_INCORRECT',
    PPN001: 'PROSPECT_NOTIFICATION_TOKENS_CALL_FAILS',
    standard: 'STANDARD',
    PENDING_QUOTE: 'PENDING_QUOTE',
    STEP1_PENDING_QUOTE: 'PENDING_QUOTE'
  },
  apiErrorCodesWithCustomBehavior: {
    TA001: 'TA001',
    TA003: 'TA003',
    // CC001: 'CC001',
    TO001: 'TO001'
  },
  apiErrorCodesWithAssistanceNeeded: {
    CF004: 'CF004',
    CF005: 'CF005',
    CF006: 'CF006',
    CF007: 'CF007',
    CF013: 'CF013',
    CF014: 'CF014',
    CF016: 'CF016',
    CF019: 'CF019',
    STANDARD: 'STANDARD'
  },
  ASSISTACE_NEEDED_ENABLED: false,
  apiErrorContent: {
    // each case needs a header and a phone number and try calling us text
    CREDIT_CHECK_FAILED: {
      header: `We're sorry. We were unable to retrieve your credit information.`,
      tryCallingUs: 'For help with this issue, please call',
      phoneNumber: '1.844.591.4912'
    },
    FRAUD_ALERT: {
      header: `There is important information regarding your credit.`,
      tryCallingUs: 'We can help. Please call us at',
      phoneNumber: '1.844.591.4912'
    },
    CREDIT_FREEZE: {
      header: `You have Frozen your credit.`,
      tryCallingUs: 'We can help. Please call us at',
      phoneNumber: '1.844.591.4912'
    },
    GENERIC_ISSUE: {
      header: `We are unable to process your request at this time and apologize for any inconvenience this may have caused.`,
      tryCallingUs:
        'Our customer service representatives are standing by and can assist you with this issue. Please call us at',
      phoneNumber: '1.844.591.4912'
    },
    EXISTING_SERVICE: {
      header: `It looks like there is already active service at this address.`,
      tryCallingUs:
        'If you believe this is an error, or if you are looking to change existing service, one of our customer service representatives will assist you. Please call us at',
      phoneNumber: '1.844.591.4912'
    },
    CREATE_ORDER: {
      header: `We are unable to complete your request at this time. We apologize for any inconvenience this may have caused.`,
      tryCallingUs: 'Your request has been sent to a Frontier Specialist who will contact you at',
      phoneNumber: ''
    },
    PROCESS_PAYMENT: {
      header: `We are unable to process your payment at this time due to a system issue. We apologize for any inconvenience this may have caused.`,
      tryCallingUs: 'Our customer service representatives are standing by and can assist you. Please call us at',
      phoneNumber: '1.844.591.4912'
    },
    RESERVE_APPOINTMENT: {
      header: `We are unable to process your payment at this time. We apologize for any inconvenience this may have caused.`,
      tryCallingUs:
        'Our customer service representatives are standing by and can assist you. Please call us at 1.844.591.4880',
      phoneNumber: ''
    },
    CONFIRM_ORDER: {
      header: `We are unable to confirm your order at this time. We apologize for any inconvenience this may have caused.`,
      tryCallingUs:
        'Our customer service representatives are standing by and can assist you with completing your order. Please call us at',
      phoneNumber: '1.844.591.4912'
    },
    FRAPI_CONFIRM_ORDER: {
      header: `We are unable to confirm your order at this time. We apologize for any inconvenience this may have caused.`,
      tryCallingUs:
        'Our customer service representatives are standing by and can assist you with completing your order. Please call us at',
      phoneNumber: '1.844.591.4880'
    },
    CREDIT_CHECK_FAILURE: {
      header: `We were unable to complete your request at this time.`,
      tryCallingUs: 'Please try again or call us at',
      phoneNumber: '1.844.591.4880'
    },
    AUTOPAY_REGISTRATION_ERROR: {
      header: 'Unable to register for Autopay service at this time.',
      tryCallingUs:
        'Please advise customer, "Please visit frontier.com to request and establish AutoPay after services are installed."',
      phoneNumber: ''
    },
    AUTOPAY_UPDATE_ERROR: {
      header: 'Unable to update your Autopay service at this time.',
      tryCallingUs:
        'Please advise customer, "Please visit frontier.com to request and manage AutoPay after services are installed."',
      phoneNumber: ''
    },
    BILLING_ADDRESS_VERIFICATION_FAILED: {
      header: `For some reason, we can't verify your address`,
      tryCallingUs: `Try again and if it doesn't work, call us at `,
      phoneNumber: '1.844.591.4912',
      endingText: ` so we can help you out.`
    },
    PAYMENT_DECLINED: {
      header: `We're sorry. We were unable to process your payment. Please try again with a different card.`,
      tryCallingUs: 'Try again and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    NOT_SERVICEABLE: {
      header:
        'Sorry, Frontier<sup>®</sup> FiberOptic Internet is not currently available in your area. Thank you for your interest. Please check your address and try again.',
      tryCallingUs: 'For additional information please call ',
      phoneNumber: '1.844.591.4912'
    },
    SESSION_TIME_OUT: {
      header: `Order time-out`,
      copy:
        'Un-submitted orders time-out after two hours and your order number is cancelled. Click OK to return and place a new order.',
      buttonText: 'OK'
    },
    FTR_TERRITORY_CALL_FAILS: {
      header: `We're sorry, we were unable to complete our serviceability check.`,
      tryCallingUs: 'Try again, and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    ADDRESS_VALIDATION_FAILS: {
      header: `For some reason, we can't verify your address`,
      tryCallingUs: `Try again and if it doesn't work, call us at `,
      phoneNumber: '1.844.591.4912',
      endingText: ` so we can help you out.`
    },
    RETREIVE_FACILITIES_FAILS: {
      header: `We're sorry, we could not process your request.`,
      tryCallingUs: 'Try again, and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    SERVICEABILITY_INVALID_ADDRESS: {
      header: `You've entered an invalid address. Please try again.`,
      tryCallingUs: '',
      phoneNumber: ''
    },
    PENDING_QUOTE: {
      header: `There is a pending order ###### for Frontier Service at this address.`,
      tryCallingUs: 'If further assistance is needed, please call ',
      phoneNumber: '1.800-921-8101'
    },
    FIBER_PRODUCTS_CALL_FAIL: {
      header: `We're sorry, we could not process your request.`,
      tryCallingUs: 'Try again, and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    PROSPECT_CALL_FAILS: {
      header: `We're sorry, we could not process your request.`,
      tryCallingUs: 'Try again, and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    PROSPECT_INVALID_PHONE_NUMBER: {
      header: `You've entered an invalid phone number. Please try again.`,
      tryCallingUs: '',
      phoneNumber: ''
    },
    PROSPECT_VERIFICATION_CALL_FAILS: {
      header: `We're sorry, we could not process your request.`,
      tryCallingUs: 'Try again, and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    PROSPECT_VERIFICATION_CODE_INCORRECT: {
      header: `You've entered an invalid code. Please try again.`,
      tryCallingUs: '',
      phoneNumber: ''
    },
    PROSPECT_NOTIFICATION_TOKENS_CALL_FAILS: {
      header: `We're sorry, we could not process your request.`,
      tryCallingUs: 'Try again, and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    REQUEST_FAIL: {
      header: `We're sorry, we could not process your request.`,
      tryCallingUs: 'For additional information please call ',
      phoneNumber: '1.844.591.4912'
    },
    MULTIPLE_POSTAL_ADDRESSES: {
      header: `We're sorry. We could not verify your address. Please select the correct address.`,
      tryCallingUs: 'Try again and if the problem continues, call ',
      phoneNumber: '1.844.591.4912'
    },
    STANDARD: {
      header: 'We were unable to complete your request at the moment.',
      tryCallingUs: 'Please try again or call us at',
      phoneNumber: '1.844.591.4912'
    },
    PAYMENT_NOT_APPROVED: {
      header: `Please advise the customer, "The payment method was declined, let's verify its correct or try another payment method."`,
      tryCallingUs: ``,
      phoneNumber: ''
    },
    EBILL_REGISTRATION_ERROR: {
      header: 'Unable to register for eBill service at this time.',
      tryCallingUs:
        'We were unable to set up Paperless Billing at this time. This account has been defaulted to receive a paper bill with a charge of $XX.XX. Frontier will work to enroll the account in Paperless Billing and Customer should receive communication when resolved.',
      phoneNumber: ''
    },
    NO_SCHEDULES_AVAILABLE: {
      header: `No schedules were presented for that locality.`,
      tryCallingUs: `Please contact STI at`,
      phoneNumber: '1.844.591.4880'
    },
    UNAVAILABLE_RESERVE_APPOINTMENT: {
      header: `We are unable to reserve your appointment at this time. We apologize for any inconvenience this may have caused.`,
      tryCallingUs: `Our customer service representatives are standing by and can assist you. Please call at`,
      phoneNumber: '1.844.591.4880'
    },
    POSITIVE_IDENTIFICATION: {
      header: `The Credit System was Unable to validate the customer's identity`,
      copy:
        '<p>Real time assistance is available from Frontier between the hours of <b>8am - 8pm Mon-Fri Eastern Time Zone</b> by calling <b>866.293.7026</b> with your customer on the line.</p>' +
        '<p>(This is an internal number only and this process can take up to 15 minutes to complete).</p>' +
        '<p>If resolved you can pull your quote back up and proceed. If outside of the stated hours the customer can self-serve by visiting <b>Frontier.com/ConfirmMe</b>.</p>' +
        '<p>Quotes are stored for <b>45 days.</b></p>',
      buttonText: 'Save quote and try again later',
      continuePOSButtonText: 'Continue if POS id was resolved'
    }
  }
};
