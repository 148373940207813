import CheckIcon from '@material-ui/icons/Check';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { MergedProductType } from 'components/ProductList';
import StyledPrice from 'components/StyledPrice';
import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

interface Props {
  products: MergedProductType[];
  className?: string;
  rows: any[];
  featureMap: any;
}
const InternetProductComparison = (props: Props) => {
  const { className, products, rows, featureMap } = props;

  return (
    <div className={className}>
      <div className="first-row">
        <div className="filler left-most-col" />
        {products.map((column, ind) => {
          return (
            <div key={ind} className={`col-width product-header standard-border border-box-${ind}`}>
              <Text field={column.productName} tag="span" className="product-name" />
              <Text className="product-subtext" tag="p" field={column.productSubText} />
              <div className="product-info">
                <div className="product-speed-container">
                  <Image className="product-speed-image" field={column.productImage.jss} />
                  {!Number.isNaN(column.productImageSpeed.value) && (
                    <div className="product-speed">
                      <Text
                        className="product-speed-value"
                        tag="p"
                        field={{ value: (parseInt(column.productImageSpeed.value, 10) / 1000).toString() }}
                      />
                      <Text className="product-speed-units" tag="p" field={{ value: 'Mbps' }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div id="detail-items">
        {rows.map((row, ind) => {
          return (
            <div key={ind} className="row-item">
              <div className="feature-title left-most-col" dangerouslySetInnerHTML={{ __html: row.feature }} />
              {products.map((column) =>
                featureMap[row.feature].find((sku) => column.sku === sku) ? (
                  <div className="check-cell col-width ">
                    {column?.productDetails?.find((item) => item?.detail?.value === row.feature)?.value?.value}
                  </div>
                ) : (
                  <div className="check-cell col-width ">-</div>
                )
              )}
            </div>
          );
        })}
        <div />
      </div>
      <div className="last-row">
        <div className="filler left-most-col" />
        {products.map((column, ind) => {
          return (
            <div key={ind} className={`standard-border border-box-${ind} col-width `}>
              <div className="price-cell">
                <StyledPrice
                  className="price-text"
                  currencySymbol={'$'}
                  price={column.price}
                  frequency={'/mo'}
                  dollarsFontSize={'1.5rem'}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="mobile-padding">&nbsp;</div>
    </div>
  );
};

const StyledInternetProductComparison = styled(InternetProductComparison)`
  ${({ theme }) => `
  max-height: 400px;
  display: grid;
  @media screen and (min-width: ${breakpoints.xl}) {
    max-height: 60vh;
  }
  .left-most-col {
    margin-right: 1rem;
  }

  .desc-cell{
    width: 16.25rem;
  }
  .col-width {
    width: 9.5rem;
  }
  .filler {
    height: 100%;
    width: 17rem;
  }
  .first-row {
    height: 12.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .standard-border {
      border-top: 1px solid ${theme.silver};
    }
    .border-box-0 {
      border-left: 1px solid ${theme.silver};
      border-top-left-radius: 0.3125rem;
    }
    .border-box-1 {
      border-left: 1px solid ${theme.silver};

    }
    .border-box-2 {
      border-left: 1px solid ${theme.silver};
      border-right: 1px solid ${theme.silver};
      border-top-right-radius: 0.3125rem;
    }
    .product-header {
      height: 100%;
      padding: 1rem .325rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .product-name{
      min-height: 40px;
    }

    img.product-speed-image {
      width: 4rem;
      height: 4rem;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
    }

    .product-speed-container {
      position: relative;
      height: 4rem;
      width: 4rem;
    }

    .product-speed {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 1.25rem;
      p {
        margin-bottom: 0;
      }
      .product-speed-value {
        font-size: 1.125rem;
        margin-top: 0.75rem;
      }
      .product-speed-units {
        0.625rem;
        margin-top: 0;
      }
    }
    .product-subtext {
      font-size: 0.625rem;
      line-height: 1.2;
      padding-top: 1rem;
      margin-bottom: 0;
      max-width: 5rem;

      @media screen and (min-width: ${breakpoints.md}) {
        max-width: 7.5rem;
      }
    }
  }
  #detail-items {
    margin-top: 0;
    div {
      font-size: 0.875rem;
    }
    .row-item {
      height: 3.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .feature-title {
        width: 17rem;
        line-height: 1.125rem;
      }
      .check-cell {
        padding: 0 .325rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-left: 1px solid ${theme.silver};
        :last-child {
          border-right: 1px solid ${theme.silver};
        }
      }
    }
  }
  .last-row {
    width: 100%;
    display: flex;
    justify-content: center;
    .standard-border {
      padding: 0rem 0.3125rem 0.3125rem 0.3125rem;
      border-bottom: 1px solid ${theme.silver}

      @media screen and (max-width: ${breakpoints.md}) {
        padding: 0.125rem;
      }

    }
    .border-box-0 {
      border-left: 1px solid ${theme.silver};
      border-bottom-left-radius: 0.3125rem;
      border-bottom: 1px solid ${theme.silver}
    }
    .border-box-1 {
      border-left: 1px solid ${theme.silver};
    }
    .border-box-2 {
      border-left: 1px solid ${theme.silver};
      border-right: 1px solid ${theme.silver};
      border-bottom-right-radius: 0.3125rem;
    }
    .standard-border:last-child{
      border-bottom: 1px solid ${theme.silver};
      border-right: 1px solid ${theme.silver};
      border-bottom-right-radius: 0.3125rem;
    }
    .price-cell {
      height: 5.063rem;
      background: ${theme.navy};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${theme.white};
      border-radius: 0.3125rem;
      padding: 0.25rem;
      .price-text {
        justify-content: center;
        margin-bottom: .5rem;
        .price-value .price-value-dollars p {
          font-size: 1.25rem;
          font-weight: 400;
        }
      }
      .price-subtext {
        font-size: 0.625rem;
        text-align: center;
      }
      .router-disclaimer {
        display: none;
      }
    }
  }

  .product-header:last-child:not(.check-cell){
    border-right: 1px solid ${theme.silver};
    border-top-right-radius: 0.3125rem;
  }

`}
  ${({ theme }) => theme.tabletPortrait`
  width: 100%;
  max-width: 90vw;
  max-height: calc(100vh - 300px);
  .desc-cell{
    width: 16.25rem;
  }
  .filler {
    display: none;
  }
  .left-most-col {
    margin: 0;
  }
  .mobile-padding{
    height: 50px;
  }
  #detail-items {
    width: 100%;
    .row-item {
      flex-wrap: wrap;
      height: unset;
      .feature-title {
        text-align: center;
        width: 100%;
        height: 3.25rem;
        background: ${theme.sand};
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid ${theme.silver};
        border-right: 1px solid ${theme.silver};
      }
      .check-cell {
        height: 3.125rem;
      }
    }
  }

`}
${({ theme }) => theme.mobile`
.first-row {
  height: 10.5rem;
  .product-header {
    justify-content: space-evenly;
  }
  .product-speed-container {
    height: 3rem;
    width: 3rem;
  }
  .product-header {
    span {
      height: 2.5rem;
      font-size: 1rem;
      font-family: PPObjectSans, sans-serif;
      line-height: 1.13;
      max-width: 5rem;

      @media screen and (min-width: ${breakpoints.md}) {
        max-width: 7.5rem;
        line-height: 1.25;
      }
    }
  }
  .product-speed {
    font-size: 0.875rem;
    margin-top: 0.3rem;
    .product-speed-value {
      font-size: 1rem;
      margin-top: 0.75rem;
    }
    .product-speed-units {
      font-size: 0.6rem;
    }
  }
}
  #detail-items {
    width: 100%;
    .row-item .feature-title {
      font-size: 0.75rem;
    }
  }
  .mobile-padding{
    height: 50px;
  }
  .last-row {
    margin-bottom: 3rem;


    .price-cell {
      .price-text {
        margin-right: 0.25rem;
        flex-wrap: wrap;
        margin-bottom: 0;
        p.price-currency-symbol {
          font-size: 1rem;
          line-height: 1rem;
        }
        .price-value {
          line-height: 1rem;
          p.price-value-dollars {
            font-size: 1.5rem;
          }
          p.price-value-cents {
            font-size: 0.875rem;
            line-height: normal;
          }
        }
        h2.price-frequency {
          line-height: 1rem;
          margin-top: 0;
          @media screen and (max-width: ${breakpoints.md}) {
            font-size: 0.6rem;
          }
        }
      }
      .price-subtext {
        display: none;
      }
      .router-disclaimer {
        align-self: flex-end;
        display: block;
      }
    }
  }
  .price-subtext {
    font-size: 0.5rem;
    color: ${theme.silver};
    margin: 5px 1rem;
  }
`}
`;

export default StyledInternetProductComparison;
