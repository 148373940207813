import { runSafeSsr } from 'utils/browserUtil';

const USER_PROFILE = 'PP_USER_PROFILE';
const USER_UID = 'PP_USER_UID';
const USER_TOKEN = 'PP_USER_TOKEN';
const USER_TOKEN_TIME = 'PP_USER_TOKEN_TIME';
const USER_SPACE = 'PP_USER_SPACE';
const USER_ORGANIZATION = 'PP_USER_ORGANIZATION';
const PP_USERNAME = 'PP_USERNAME';
const PP_OPENQUOTES = 'openQuotes';

const getUserProfile = () => {
  let userProfile;

  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      try {
        userProfile = JSON.parse(localStorage.getItem(USER_PROFILE) as string);
      } catch (err) {
        console.error(err);
      }

      return userProfile;
    }
  );
};

const setUserProfile = (profile) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      try {
        localStorage.setItem(USER_PROFILE, JSON.stringify(profile));
      } catch (err) {
        console.error(err);
      }
    }
  );
};

const getUserUID = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.getItem(USER_UID);
    }
  );
};

const setUserUID = (uid) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.setItem(USER_UID, uid);
    }
  );
};

const getUserToken = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.getItem(USER_TOKEN);
    }
  );
};

const setUserToken = (token) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.setItem(USER_TOKEN, token);
    }
  );
};

const getUserSpace = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.getItem(USER_SPACE);
    }
  );
};

const setUserSpace = (space) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.setItem(USER_SPACE, space);
    }
  );
};

const getUserTokenTime = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.getItem(USER_TOKEN_TIME);
    }
  );
};

const setUserTokenTime = (time) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.setItem(USER_TOKEN_TIME, time);
    }
  );
};

const getUserOrganization = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return JSON.parse(localStorage.getItem(USER_ORGANIZATION) as string);
    }
  );
};

const setUserOrganization = (organization) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return localStorage.setItem(USER_ORGANIZATION, JSON.stringify(organization));
    }
  );
};

const getUsername = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      return JSON.parse(localStorage.getItem(PP_USERNAME) as string);
    }
  );
};

const setUsername = (username) => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      localStorage.setItem(PP_USERNAME, JSON.stringify(username));
    }
  );
};

const clearUserStorage = () => {
  return runSafeSsr(
    () => {
      return null;
    },
    () => {
      localStorage.removeItem(USER_PROFILE);
      localStorage.removeItem(USER_UID);
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(USER_TOKEN_TIME);
      localStorage.removeItem(USER_SPACE);
      localStorage.removeItem(USER_ORGANIZATION);
      localStorage.removeItem(PP_USERNAME);
      localStorage.removeItem(PP_OPENQUOTES);
    }
  );
};

export {
  getUserProfile,
  setUserProfile,
  getUserUID,
  setUserUID,
  getUserToken,
  setUserToken,
  getUserSpace,
  setUserSpace,
  getUserTokenTime,
  setUserTokenTime,
  getUserOrganization,
  setUserOrganization,
  clearUserStorage,
  getUsername,
  setUsername
};
