import React from 'react';
import { runSafeSsr } from './browserUtil';

/**
 * Custom hook that can be used to store and get data from local storage.
 * Usage example:
 *   const [value, setValue] = useStateWithLocalStorage(
 *    'myValueInLocalStorage'
 *   );
 *
 * @param localStorageKey the storage key used for getting and setting the data
 */
export const useStateWithLocalStorage = (localStorageKey: string) => {
  const [value, setValue] = React.useState(
    runSafeSsr(
      () => {
        return '';
      },
      () => {
        return localStorage.getItem(localStorageKey) || '';
      }
    )
  );

  React.useEffect(() => {
    if (localStorage) {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);

  return [value, setValue] as const;
};

/**
 * Custom hook that can be used to store and get data from session storage.
 * Usage example:
 *   const [value, setValue] = useStateWithSessionStorage(
 *    'myValueInSessionStorage'
 *   );
 *
 * @param sessionStorageKey the storage key used for getting and setting the data
 */
export const useStateWithSessionStorage = (sessionStorageKey: string) => {
  const [value, setValue] = React.useState(
    runSafeSsr(
      () => {
        return '';
      },
      () => {
        return sessionStorage.getItem(sessionStorageKey) || '';
      }
    )
  );

  React.useEffect(() => {
    if (sessionStorage) {
      sessionStorage.setItem(sessionStorageKey, value);
    }
  }, [value]);

  return [value, setValue] as const;
};

/**
 * Simple helper function to store a value in localStorage
 *
 * @param key the storage key used for setting the data
 * @param value the value to store
 */
export const setLocalStorageValue = (key: string, value: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (localStorage) {
    localStorage.setItem(key, value);
  }
};

/**
 * Simple helper function to clear localStorage
 */
export const clearLocalStorageValue = () => {
  if (typeof window === 'undefined') {
    return;
  }

  if (localStorage) localStorage.clear();
};

/**
 * Simple helper function to remove some item in localStorage
 */
export const removeLocalStorageItem = (item) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (localStorage) localStorage.removeItem(item);
};

/**
 * Simple helper function to get a value from localStorage
 *
 * @param key the storage key used for getting the data
 */
export const getLocalStorageValue = (key: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  return localStorage ? localStorage.getItem(key) || '' : '';
};

/**
 * Simple helper function to store a value in localStorage
 *
 * @param key the storage key used for setting the data
 * @param value the value to store
 */
export const setSessionStorageValue = (key: string, value: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (sessionStorage) {
    sessionStorage.setItem(key, value);
  }
};

/**
 * Simple helper function to get a value from sessionStorage
 *
 * @param key the storage key used for getting the data
 */
export const getSessionStorageValue = (key: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  return sessionStorage ? sessionStorage.getItem(key) || '' : '';
};

/**
 * Simple helper function to clear sessionStorage
 */
export const clearSessionStorageValue = () => {
  if (typeof window === 'undefined') {
    return;
  }

  if (sessionStorage) sessionStorage.clear();
};
