import React from 'react';
import styled from 'styled-components';
import PhoneNumber from '../PhoneNumber';
import { Text } from '@sitecore-jss/sitecore-jss-react';

interface ContactPhoneProps {
    className?: string;
    msg: string,
    msgAfter?: string,
    contactPhone: any,
    hasIcon?: boolean,
    children?: React.ReactNode;
}

const ContactPhone: React.FunctionComponent<ContactPhoneProps> = ({ className, msg, msgAfter, contactPhone, hasIcon, ...props}:ContactPhoneProps ) => {
    return (
      <div className = {`${className} phone-container`}>
        <Text tag="p" className="call-text" field={{ value: msg }}/>
        {props.children}
        <PhoneNumber asIconLink={hasIcon} phoneNumberLinkTarget={contactPhone.value} viewBox="0 0 28 28">
          <Text tag="p" className="phone-number InvocaSpanClass" field={contactPhone} />
        </PhoneNumber>
        {msgAfter && (<span className="call-text-after">{msgAfter}</span>)}
      </div>
    );
};

const StyledContactPhone = styled(ContactPhone)`
    
    display: flex;
    padding: 0;
    margin: 2.5rem 0 0 0;
    flex-wrap: wrap;
    flex-direction: row;

    .call-text {
        padding: 0;
        margin: 0.125rem 0.5rem 0 0;
    }

    .call-text-after{
      margin-left: 5px;
    }

    .phone-number {
        font-weight: 900;
        text-decoration: underline;
        padding: 0;
        margin: 0;
    }

    svg {
        fill: ${({ theme }) => theme.mineShaft} !important;
    }
`;

export default StyledContactPhone;
  