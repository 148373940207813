import * as axios from 'axios';
import { frapiCustomerAcquisitionApiConfig } from './apiConfigs';

export const FrapiAppApi = {
  /** BFAST FRAPI CUSTOMER ACQUISITION API */
  updateBuyFlowProspectFrapi: ({ prospectInfo, quoteId }) => {
    let data = prospectInfo;
    let requestConfig = { ...frapiCustomerAcquisitionApiConfig };

    data = { ...prospectInfo };
    requestConfig = {
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        'quote-id': quoteId
      }
    };

    return axios.put(`prospects/${prospectInfo.prospectId}`, data, requestConfig);
  },
  getAvailableBuyflowProductsFrapi: ({
    technology,
    env,
    controlNumber,
    ppAdditionalHeaders,
    quoteId,
    isLowIncome,
    isLowIncomeResume
  }) => {
    const hasQuoteId = quoteId ? '&createQuote=false' : '';
    const hasLowIncomeOffers = isLowIncome
      ? isLowIncomeResume
        ? '&includeLowIncome=true&isLowIncomeResume=true'
        : '&includeLowIncome=true'
      : '';
    return axios
      .get(
        `available-products?technology=${technology.toUpperCase()}&env=${env}&controlNumber=${controlNumber}${hasQuoteId}${hasLowIncomeOffers}`,
        {
          ...frapiCustomerAcquisitionApiConfig,
          headers: {
            'quote-id': quoteId,
            ...frapiCustomerAcquisitionApiConfig.headers,
            ...ppAdditionalHeaders
          }
        }
      )
      .then((response) => response);
  },
  getChaseIframeUrlFrapi: (accountUuid) => {
    return axios.get(`payment-url?account-uuid=${accountUuid}`, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'account-uuid': accountUuid
      }
    });
  },
  getInstallationSchedulesFrapi: ({ type, quoteId, gmtOffset, observeDST, startDate }) => {
    const gmtOffsetParam = gmtOffset ? `gmtOffset=${gmtOffset}` : '';
    const observeDSTParam = typeof observeDST === 'boolean' ? `&observeDST=true` : '';
    const startDateParam = startDate ? `startDate=${startDate}` : '';
    return axios.get(`orders/${type}?${gmtOffsetParam}${observeDSTParam}${startDateParam}`, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  cancelInstallationSchedulesFrapi: ({ type, quoteId }) => {
    return axios.delete(`orders/${type}`, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  buyFlowOrdersInterfaceFrapi: ({ params, type, quoteId }) => {
    return axios.post(`orders/${type}`, params, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  buyFlowBillingInterfaceFrapi: ({ params, type, requestingApplication, quoteId }) => {
    return axios.post(`billing/${type}`, params, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId,
        ...(requestingApplication && { 'requesting-application': requestingApplication })
      }
    });
  },
  buyFlowOrderReserveInstallationTimeFrapi: ({ params, type, quoteId }) => {
    return axios.post(`orders/${type}?includeRecordLocator=true`, params, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  checkNumberPortabilityFrapi: (payload, quoteId) => {
    return axios.put(`voice/portability`, payload, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  createBuyFlowOrderFrapi: ({ order, skipCreditCheck }, quoteId) => {
    const skipCreditCheckQueryParameter = skipCreditCheck ? '?skipCreditCheck=true' : '';
    return axios.post(`orders${skipCreditCheckQueryParameter}`, order, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
        //'quote-id': 'requiredDeposit'
      }
    });
  },
  getInstallationScheduleFrapi: ({ orderId, queryString }, quoteId) => {
    return axios.post(`orders/${orderId}/installation-schedule${queryString}`, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  submitOrderFrapi: (quoteId, payload, ppAdditionalHeaders) => {
    return axios.post(`orders/confirmation`, payload, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        ...ppAdditionalHeaders,
        'quote-id': quoteId
      }
    });
  },
  securityQuestionsFrapi: ({ securityQuestionList }, quoteId) => {
    return axios.post(`security-questions`, securityQuestionList, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'quote-id': quoteId
      }
    });
  },
  getQuoteQuickViewFrapi: (params) => {
    return axios.get(`quote/quick-view?${params}`, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: { ...frapiCustomerAcquisitionApiConfig.headers, 'requesting-application': 'PartnerPortal' }
    });
  },
  getCreditCheckStatus: (quoteId) => {
    return axios.get('orders/credit-check/check-cleared', {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'requesting-application': 'PartnerPortal',
        'quote-id': quoteId
      }
    });
  },
  submitEbbEnrollmentFormFrapi: (formData, quoteId) => {
    return axios.post('ebb-enrollment', formData, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'requesting-application': 'PartnerPortal',
        'quote-id': quoteId
      }
    });
  },
  previousAddressVerify: ({accountUuid, previousAddress, quoteId, isUnknownAddress}) => {
    return axios.post(`orders/verify?isUnknownAddress=${isUnknownAddress}`, {accountUuid, previousAddress}, {
      ...frapiCustomerAcquisitionApiConfig,
      headers: {
        ...frapiCustomerAcquisitionApiConfig.headers,
        'requesting-application': 'PartnerPortal',
        'quote-id': quoteId
      }
    });
  }
};
